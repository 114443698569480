import {
    Dropdown,
    DropdownItem,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    Label,
    LabelType,
    SpinnerCircle,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import { CreatedByListViewCreatedByListView, QuestionnaireStatus } from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { RoutePath } from '../../../routes';
import { datetime } from '../../../utils/format';
import PersonInfoBlock from '../../common/PersonInfoBlock';
import styles from './QuestionnairesList.module.scss';

interface QuestionnairesListItemProps {
    id: string;
    name: string;
    status: number;
    usageCount: number;
    lastEdited: string;
    createdByListView: CreatedByListViewCreatedByListView;
}

interface QuestionnairesListItemDispatchProps {
    reloadQuestionnaires: () => void;
    doDeleteQuestionnaire: (id: string) => Promise<ThunkActionResult>;
}

interface QuestionnairesListItemState {
    isDeleteModalOpen: boolean;
    isDeleteSubmitting: boolean;
}

class QuestionnairesListItem extends Component<
    QuestionnairesListItemProps & QuestionnairesListItemDispatchProps & WithTranslation,
    QuestionnairesListItemState
    > {
    state: QuestionnairesListItemState = {
        isDeleteModalOpen: false,
        isDeleteSubmitting: false
    };

    render() {
        const { t, id, status, name: questionnaireName, usageCount, lastEdited, createdByListView } = this.props;
        const statusText = this.getStatusText(status);
        const statusLabelType = this.getStatusLabelType(status);
        const itemLink = RoutePath.questionnairesEditQuestionnaire(id);
        const dropdownItems = this.renderDropdownItems();

        return (
            <tr>
                <td>
                    <Link to={itemLink} title={questionnaireName} className='pl-4 pr-4'>
                        <Label type={statusLabelType} className='w-100'>
                            {statusText}
                        </Label>
                    </Link>
                </td>
                <td className={styles.colTitle}>
                    <Link to={itemLink} title={questionnaireName} className='pl-0'>
                        {questionnaireName}
                    </Link>
                </td>
                <td className={styles.colUsed}>
                    <Link to={itemLink} title={questionnaireName}>
                        <small>{t('questionnaires.used')}:</small>
                        <br />
                        <span className='font-bold'>{t('questionnaires.usedTimes', { count: usageCount })}</span>
                    </Link>
                </td>
                <td className={styles.colCreatedBy}>
                    <Link to={itemLink} title={questionnaireName}>
                        <small>{t('questionnaires.createdBy')}:</small>
                        <br />
                        <PersonInfoBlock
                            firstName={createdByListView.firstName}
                            lastName={createdByListView.lastName}
                            photoUrl={endpoints.getAtsUserAvatarEndpoint(createdByListView.id)}
                            size='tiny'
                            personNameClassName='font-bold'
                            className='mb-0'
                        />
                    </Link>
                </td>
                <td className={styles.colLastEdited}>
                    <Link to={itemLink} title={questionnaireName}>
                        <small>{t('questionnaires.lastEdited')}:</small>
                        <br />
                        <span className='font-bold'>{datetime(lastEdited)}</span>
                    </Link>
                </td>
                <td className='pr-4'>
                    {dropdownItems.length > 0 && (
                        <Dropdown trigger={this.renderActionsButton()} align='se'>
                            {dropdownItems}
                        </Dropdown>
                    )}

                    <InModal
                        isOpen={this.state.isDeleteModalOpen}
                        onClose={this.onDeleteQuestionnaireCancel}
                        className='animated a500 fadeInDown'
                        backdropClassNames='animated a500 backdropFadeIn'>
                        <InModalHeader titleText={t('questionnaires.deleteQuestionnaireTitle')} />
                        <InModalBody>
                            <span>
                                <Trans i18nKey='questionnaires.deleteQuestionnaire' values={{ questionnaireName }}>
                                    <strong>{{ questionnaireName }}</strong>
                                </Trans>
                            </span>
                        </InModalBody>
                        <InModalFooter>
                            <button
                                type='button'
                                className='btn btn-default font-bold btn-w-m'
                                onClick={this.onDeleteQuestionnaireCancel}
                                disabled={this.state.isDeleteSubmitting}>
                                {t('general.button.cancel')}
                            </button>
                            <button
                                type='button'
                                className='btn btn-danger font-bold btn-w-m d-flex justify-content-center align-items-center'
                                onClick={this.onDeleteQuestionnaireConfirm}
                                disabled={this.state.isDeleteSubmitting}>
                                {this.state.isDeleteSubmitting && (
                                    <SpinnerCircle className='ml-0 mr-2' spinnerSize='small' />
                                )}
                                {t('general.button.delete')}
                            </button>
                        </InModalFooter>
                    </InModal>
                </td>
            </tr>
        );
    }

    private renderDropdownItems = () => {
        const { t, status, usageCount } = this.props;
        const results: React.ReactNode[] = [];

        // allow deleting drafts and non-used questionnaires
        if (status === QuestionnaireStatus.DRAFT || usageCount <= 0) {
            results.push(
                <DropdownItem
                    key={`dropdown-item-delete-${this.props.id}`}
                    onClick={this.onDeleteQuestionnaireClick}
                    icon={<i className='fa fa-fw fa-times' />}
                    label={t('general.button.delete')}
                />
            );
        }

        return results;
    };

    private onDeleteQuestionnaireClick = () => {
        this.setState({
            isDeleteModalOpen: true
        });
    };

    private onDeleteQuestionnaireCancel = () => {
        this.setState({
            isDeleteModalOpen: false
        });
    };

    private onDeleteQuestionnaireConfirm = async () => {
        const { t } = this.props;

        this.setState({
            isDeleteSubmitting: true
        });

        const results = await this.props.doDeleteQuestionnaire(this.props.id);
        if (results.httpStatus < 300) {
            toastr.success(t('questionnaires.deleteSuccess.message'), this.props.name);
            this.props.reloadQuestionnaires();
        } else {
            toastr.error(t('questionnaires.deleteError.message'), t('questionnaires.deleteError.title'));
        }
    };

    private renderActionsButton = () => {
        return (
            <button className={classNames('btn btn-white', styles.btnActions)}>
                <i className='fa fa-ellipsis-v' />
            </button>
        );
    };

    private getStatusText(status: number) {
        const { t } = this.props;

        switch (status) {
            case 0:
                return t('questionnaires.status.draft');
            case 1:
                return t('questionnaires.status.active');
        }
    }

    private getStatusLabelType(status: number): LabelType {
        switch (status) {
            case 0:
                return LabelType.WARNING;
            default:
            case 1:
                return LabelType.INFO;
        }
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): QuestionnairesListItemDispatchProps => ({
    reloadQuestionnaires: () => dispatch(QuestionnairesThunks.getQuestionnaires(1, PAGE_SIZE_DEFAULT)),
    doDeleteQuestionnaire: async (id: string) => dispatch(QuestionnairesThunks.deleteQuestionnaire(id))
});

export default connect(null, mapDispatchToProps)(withTranslation()(QuestionnairesListItem));
