import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionnaireEducationAnswerEntry } from '../../../../api/questionnaires/questionnaires.dto';
import { date, nl2br } from '../../../../utils/format';
import { PropertyElement } from './ApplicationFormElement';

interface Props {
    className?: string;
    data: QuestionnaireEducationAnswerEntry;
}

export default (props: React.PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const { data } = props;

    return (
        <div>
            <div className='row'>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.school')}
                        value={data.school}
                    />
                </div>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.degree')}
                        value={data.degree}
                    />
                </div>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.fieldOfStudy')}
                        value={data.fieldOfStudy}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.startDate')}
                        value={date(data.startDate, 'dateMonthNameYear')}
                    />
                </div>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.endDate')}
                        value={data.endDate ? date(data.endDate, 'dateMonthNameYear') : '-'}
                    />
                </div>
                <div className='col-4'>
                    <PropertyElement
                        label={t('questionnaires.questionnaireForm.question.education.preview.currentlyStudying')}
                        value={t(data.currentlyStudyingHere ? 'general.yes' : 'general.no')}
                    />
                </div>
            </div>
            <PropertyElement
                label={t('questionnaires.questionnaireForm.question.education.preview.description')}
                value={nl2br(data.description || '-')}
            />
        </div>
    );
};
