import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { endpoints } from '../../../api/endpoints';
import { RoutePath } from '../../../routes';
import { TOOLTIP } from '../../../tooltips';
import { date } from '../../../utils/format';
import RecruiteeCvModal from '../common/RecruiteeCvModal';
import styles from './RecruiteesList.module.scss';

interface RecruiteesListItemProps {
    id: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    newestJobApplicationId: string;
    newestAccessibleJobApplicationId?: string;
    newestJobApplicationDate: string;
    newestResumeFileIdentifier?: string;
}

export default (props: RecruiteesListItemProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const photoUrl = endpoints.getRecruiteePhotoFileEndpoint(props.id);
    let cvUrl: string | undefined = undefined;
    if (props.newestJobApplicationId && props.newestResumeFileIdentifier) {
        cvUrl = endpoints.getJobApplicationFileEndpoint(props.newestJobApplicationId, props.newestResumeFileIdentifier);
    }

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <tr>
            <td>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}
                    className='pl-4 pr-0'>
                    <div className={styles.avatarContainer} style={{ backgroundImage: `url(${photoUrl})` }}>
                        <img src={photoUrl} alt={t('general.avatar')} />
                    </div>
                </Link>
            </td>
            <td className={classNames(styles.colTitle, 'pl-3 pb-4')}>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}
                    className='pl-0 pb-0'>
                    <div className={styles.recruiteeName}>
                        {props.firstName} {props.lastName}
                    </div>
                </Link>
                <div className='d-flex align-items-stretch'>
                    <button
                        onClick={openModal}
                        className={classNames('btn btn-white', styles.icon)}
                        data-tip={t('recruitees.viewResume')}
                        data-for={TOOLTIP.BOTTOM}>
                        <i className='fa fa-file-text' />
                    </button>
                    <Link
                        to={RoutePath.recruiteesRecruiteeDetails(
                            props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                        )}
                        className='flex-fill'></Link>
                </div>

                {cvUrl && <RecruiteeCvModal cvUrl={cvUrl} isOpen={isOpen} closeModal={closeModal} />}
            </td>
            <td className={styles.colPhone}>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}>
                    <div className='text-small'>{t('recruitees.phoneNumber')}:</div>
                    <div className={classNames('font-bold', styles.recruiteePhoto)}>{props.phoneNumber}</div>
                </Link>
            </td>
            <td className={styles.colEmail}>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}>
                    <div className='text-small'>{t('recruitees.email')}:</div>
                    <div className={classNames('font-bold', styles.recruiteeEmail)}>{props.email}</div>
                </Link>
            </td>
            <td className={styles.colLastApplication}>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}
                    className='pr-4'>
                    <div className='text-small'>{t('recruitees.lastApplication')}:</div>
                    <div className='font-bold'>{date(props.newestJobApplicationDate)}</div>
                </Link>
            </td>
            <td>
                <Link
                    to={RoutePath.recruiteesRecruiteeDetails(
                        props.newestAccessibleJobApplicationId || props.newestJobApplicationId
                    )}
                    className='pr-4'>
                    <div className={classNames('btn btn-primary btn-sm font-bold')}>{t('recruitees.seeProfile')}</div>
                </Link>
            </td>
        </tr>
    );
};
