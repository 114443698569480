import { UserAction, UserActionTypes } from './user.actions';
import { initialState, UserState } from './user.state';

export default (state: UserState = initialState, action: UserAction): UserState => {
    switch (action.type) {
        case UserActionTypes.GET_PROFILE_STARTED:
            return {
                ...state
            };

        case UserActionTypes.GET_PROFILE_FINISHED:
            return {
                ...state,
                ...action.response
            };

        case UserActionTypes.GET_PROFILE_FAILED:
            return {
                ...state
            };
        default:
            return state;
    }
};
