import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { GetDashboardQueryResult } from './dashboard.dto';

export enum DashboardActionTypes {
    GET_DASHBOARD_DATA_STARTED = 'GET_DASHBOARD_DATA_STARTED',
    GET_DASHBOARD_DATA_FINISHED = 'GET_DASHBOARD_DATA_FINISHED',
    GET_DASHBOARD_DATA_FAILED = 'GET_DASHBOARD_DATA_FAILED'
}

export const DashboardActions = {
    getDashboardDataStarted: () => createAction(DashboardActionTypes.GET_DASHBOARD_DATA_STARTED),
    getDashboardDataFinished: (response: GetDashboardQueryResult) =>
        createAction(DashboardActionTypes.GET_DASHBOARD_DATA_FINISHED, { response }),
    getDashboardDataFailed: (error: ErrorState) =>
        createAction(DashboardActionTypes.GET_DASHBOARD_DATA_FAILED, { error })
};

export type DashboardAction = ActionsUnion<typeof DashboardActions>;
