import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { RECRUITEE_MAX_SCORE } from '../../../api/recruitees/recruitees.dto';
import RecruiteeScoreStars from '../common/RecruiteeScoreStars';
import styles from './RecruiteeViewScore.module.scss';

interface Props {
    id: string;
    onScoreClick?: (score: number) => void;
    onReviewsClick?: () => void;
    starsCount: number;
    score: number;
    avgScore: number;
    reviewsCount: number;
    className?: string;
    displayScoreNumber?: boolean;
    disableHover?: boolean;
}

class RecruiteeViewScore extends Component<Props & WithTranslation> {
    render() {
        const { t } = this.props;
        let scoreText = this.props.score ? this.props.score.toPrecision(1) : t('recruiteeView.noScore');

        return (
            <>
                <div className={this.props.className}>
                    <div className={styles.starsContainer}>
                        {this.props.displayScoreNumber && <span className={styles.scoreNumber}>{scoreText}</span>}
                        <div data-tip={t('recruiteeView.scoreRecruitee')} data-for={this.props.id}>
                            <RecruiteeScoreStars
                                initialScore={this.props.score}
                                starsCount={RECRUITEE_MAX_SCORE}
                                onScoreChange={this.props.onScoreClick}
                                disableHover={this.props.disableHover}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.avgScoreContainer,
                            this.props.disableHover && styles.avgScoreContainer
                        )}
                        onClick={this.props.onReviewsClick}>
                        <span>{t('recruiteeView.averageScore')}:</span>
                        <span className='font-weight-bold ml-1'>{this.props.avgScore ? this.props.avgScore : '-'}</span>
                        {this.props.reviewsCount > 0 && (
                            <span className='ml-2'>
                                (
                                {`${this.props.reviewsCount} ${t('recruiteeView.reviews', {
                                    count: this.props.reviewsCount
                                })}`}
                                )
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(RecruiteeViewScore);
