export function listMapper<Type, Default, K extends string | number>(
    list: K[],
    items: Record<K, Type>,
    config?: ListMapperConfigSkip
): Type[];
export function listMapper<Type, Default, K extends string | number>(
    list: K[],
    items: Record<K, Type>,
    config: ListMapperConfigNotSkip<Default>
): Array<Type | Default>;

export function listMapper<Type, Default, K extends string | number>(
    list: K[],
    items: Record<K, Type>,
    config: ListMapperConfig<Default> = { skipUndefined: true }
) {
    const mapped: Array<Type | undefined> = list.map(listItem => items[listItem]);

    if (config.skipUndefined) {
        return mapped.filter((item): item is Type => item !== undefined);
    }
    return mapped.map(item => (item === undefined ? config.defaultValue : item));
}

type ListMapperConfig<Default> = ListMapperConfigSkip | ListMapperConfigNotSkip<Default>;

interface ListMapperConfigSkip {
    skipUndefined: true;
}
interface ListMapperConfigNotSkip<Default> {
    skipUndefined: false;
    defaultValue: Default;
}
