import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult } from './recruitersBrowser.dto';
import { RecruitersBrowserFilters, RecruitersBrowserItem } from './recruitersBrowser.state';

export enum RecruitersBrowserActionTypes {
    SET_RECRUITERS_BROWSER_FILTERS = 'SET_RECRUITERS_BROWSER_FILTERS',

    SELECT_RECRUITERS_BROWSER_RECRUITER = 'SELECT_RECRUITERS_BROWSER_RECRUITER',
    DESELECT_RECRUITERS_BROWSER_RECRUITER = 'DESELECT_RECRUITERS_BROWSER_RECRUITER',
    CLEAR_SELECTED_RECRUITERS_BROWSER_RECRUITERS = 'CLEAR_SELECTED_RECRUITERS_BROWSER_RECRUITERS',

    GET_RECRUITERS_BROWSER_RECRUITERS_STARTED = 'GET_RECRUITERS_BROWSER_RECRUITERS_STARTED',
    GET_RECRUITERS_BROWSER_RECRUITERS_FINISHED = 'GET_RECRUITERS_BROWSER_RECRUITERS_FINISHED',
    GET_RECRUITERS_BROWSER_RECRUITERS_FAILED = 'GET_RECRUITERS_BROWSER_RECRUITERS_FAILED',

    PRELOAD_RECRUITERS_BROWSER_RECRUITERS = 'PRELOAD_RECRUITERS_BROWSER_RECRUITERS',

    GET_RECRUITERS_SELECT_HIRING_MANAGER_STARTED = 'GET_RECRUITERS_SELECT_HIRING_MANAGER_STARTED',
    GET_RECRUITERS_SELECT_HIRING_MANAGER_FINISHED = 'GET_RECRUITERS_SELECT_HIRING_MANAGER_FINISHED',
    GET_RECRUITERS_SELECT_HIRING_MANAGER_FAILED = 'GET_RECRUITERS_SELECT_HIRING_MANAGER_FAILED',

    GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_STARTED = 'GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_STARTED',
    GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FINISHED = 'GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FINISHED',
    GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FAILED = 'GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FAILED'
}

export const RecruitersBrowserActions = {
    setRecruitersBrowserFilters: (filters: RecruitersBrowserFilters) =>
        createAction(RecruitersBrowserActionTypes.SET_RECRUITERS_BROWSER_FILTERS, { filters }),

    selectRecruitersBrowserRecruiter: (selectedRecruiter: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult) =>
        createAction(RecruitersBrowserActionTypes.SELECT_RECRUITERS_BROWSER_RECRUITER, { selectedRecruiter }),
    deselectRecruitersBrowserRecruiter: (id: string) =>
        createAction(RecruitersBrowserActionTypes.DESELECT_RECRUITERS_BROWSER_RECRUITER, { id }),
    clearSelectedRecruiters: () =>
        createAction(RecruitersBrowserActionTypes.CLEAR_SELECTED_RECRUITERS_BROWSER_RECRUITERS),

    getRecruitersBrowserRecruitersStarted: () =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_STARTED),
    getRecruitersBrowserRecruitersFinished: (response: GetRecruitersFinishedParams) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_FINISHED, { response }),
    getRecruitersBrowserRecruitersFailed: (error: ErrorState) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_FAILED, { error }),

    preloadRecruitersBrowserRecruiters: (recruiters: RecruitersBrowserItem[]) =>
        createAction(RecruitersBrowserActionTypes.PRELOAD_RECRUITERS_BROWSER_RECRUITERS, { recruiters }),

    getRecruitersHiringManagerStarted: () =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_STARTED),
    getRecruitersHiringManagerFinished: (response: GetRecruitersFinishedParams) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_FINISHED, { response }),
    getRecruitersHiringManagerFailed: (error: ErrorState) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_FAILED, { error }),

    getRecruitersRecruitmentOwnerStarted: () =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_STARTED),
    getRecruitersRecruitmentOwnerFinished: (response: GetRecruitersFinishedParams) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FINISHED, { response }),
    getRecruitersRecruitmentOwnerFailed: (error: ErrorState) =>
        createAction(RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FAILED, { error })
};

export interface GetRecruitersFinishedParams {
    ids: string[];
    items: Dictionary<RecruitersBrowserItem>;
}

export type RecruitersBrowserAction = ActionsUnion<typeof RecruitersBrowserActions>;
