import classNames from 'classnames';
import React from 'react';
import { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../api/questionnaires/builder/builder.actions';
import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import { QuestionnaireElementType } from '../../../api/questionnaires/questionnaires.dto';
import { State } from '../../../store/state';
import DragHandle from '../../common/DragHandle';
import { getComponentFormType, getComponentPreviewType, isComponentTypeWide } from './_util';
import QuestionItemAddButton from './QuestionItemAddButton';
import { DraggableEditorType } from './QuestionnaireElement';
import styles from './QuestionnaireElementEditor.module.scss';
import QuestionnaireElementEditorPreviewTools from './QuestionnaireElementEditorPreviewTools';

interface QuestionnaireElementEditorProps {
    /**
     * Index of this element in items list.
     */
    index: number;
    itemData: QuestionnaireBuilderElement;
}

interface StateProps {
    isDisabled: boolean;
}

interface DispatchProps {
    addItem: (element: QuestionnaireBuilderElement, index?: number) => void;
    removeItem: () => void;
    updateItem: (formData: QuestionnaireBuilderElement) => void;
    elementEditStart: () => void;
    elementEditEnd: () => void;
}

interface OwnState {
    editFormType: QuestionnaireElementType;
}

class QuestionnaireElementEditor extends Component<
    WithTranslation & QuestionnaireElementEditorProps & StateProps & DispatchProps,
    OwnState
> {
    state: OwnState = {
        editFormType: this.props.itemData.$type
    };

    render() {
        return (
            <>
                <Draggable
                    type={DraggableEditorType.DEFAULT}
                    draggableId={this.props.itemData.id}
                    index={this.props.index}
                    isDragDisabled={this.props.isDisabled}>
                    {(provided, snapshot) => (
                        <div
                            className={classNames('questionnaire-element-editor', snapshot.isDragging && 'dragging')}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div
                                className={classNames(
                                    styles.container,
                                    this.isEditing && styles.editing,
                                    isComponentTypeWide(this.props.itemData.$type) && styles.wide,
                                    this.props.isDisabled && styles.dragDisabled
                                )}>
                                <DragHandle className={styles.dragHandle} />
                                <div className={styles.fieldContainer}>
                                    {this.isEditing && this.renderForm()}
                                    <div className={styles.fieldPreview}>
                                        {!this.isEditing && this.renderPreview()}
                                        <div className={styles.fieldPreviewCover} />
                                    </div>
                                    {!this.isEditing && this.renderPreviewTools()}
                                </div>
                            </div>
                            <QuestionItemAddButton index={this.props.index + 1} />
                        </div>
                    )}
                </Draggable>
            </>
        );
    }

    private get isEditing(): boolean {
        return this.props.itemData.isEditing;
    }

    private renderPreview() {
        const PreviewComponent = getComponentPreviewType(this.props.itemData.$type);

        return <PreviewComponent itemData={this.props.itemData} />;
    }

    private renderPreviewTools() {
        if (this.props.isDisabled) {
            return null;
        }

        return (
            <QuestionnaireElementEditorPreviewTools
                type={this.props.itemData.$type}
                onEdit={this.handleEditQuestion}
                onDuplicate={this.handleCloneQuestion}
                onDelete={this.handleDeleteQuestion}
            />
        );
    }

    private renderForm() {
        const FormComponent = getComponentFormType(this.state.editFormType);

        return (
            <FormComponent
                itemData={this.props.itemData}
                index={this.props.index}
                exitEditing={this.handleExitEditing}
                save={this.handleSaveQuestionForm}
                handleTypeChange={this.handleTypeChange}
                elementType={this.state.editFormType}
            />
        );
    }

    private handleEditQuestion = () => {
        this.props.elementEditStart();
    };

    private handleExitEditing = () => {
        this.props.elementEditEnd();

        if (this.props.itemData.isAdding) {
            // the item was just being added -clicking "Cancel" means we need to remove it
            this.props.removeItem();
        } else {
            // reset element type
            this.setState({
                editFormType: this.props.itemData.$type
            });
        }
    };

    private handleSaveQuestionForm = (formData: QuestionnaireBuilderElement) => {
        // update item data
        this.props.updateItem({
            ...formData,
            // override the $type with the value of the selected option from the dropdown
            $type: this.state.editFormType,
            isEditing: false
        } as any);
    };

    private handleCloneQuestion = () => {
        const cloneData = {
            ...this.props.itemData,
            id: uuid.v4()
        };
        this.props.addItem(cloneData);
    };

    private handleDeleteQuestion = () => {
        this.props.removeItem();
    };

    private handleTypeChange = (value: QuestionnaireElementType) => {
        this.setState({
            editFormType: value
        });
    };
}

const mapStateToProps = (state: State): StateProps => ({
    isDisabled: !!state.questionnaireBuilder.elements.find(el => el.isEditing)
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: QuestionnaireElementEditorProps): DispatchProps => ({
    addItem: (element: QuestionnaireBuilderElement, index?: number) =>
        dispatch(QuestionnaireBuilderActions.addElement(element, index)),
    removeItem: () => dispatch(QuestionnaireBuilderActions.removeElement(props.itemData)),
    updateItem: (formData: QuestionnaireBuilderElement) =>
        dispatch(QuestionnaireBuilderActions.updateElement(formData)),
    elementEditStart: () => dispatch(QuestionnaireBuilderActions.editElementStart(props.itemData.id)),
    elementEditEnd: () => dispatch(QuestionnaireBuilderActions.editElementEnd(props.itemData.id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuestionnaireElementEditor));
