import { ListStateWithLoadingState, ListFiltersState } from '../_commons/state.common';
import { GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult } from './recruitersBrowser.dto';

export const initialSubState: RecruitersBrowserSubState = {
    list: [],
    items: {},
    filters: {
        searchQuery: '',
        skipUserIds: []
    },
    totalCount: 0,
    page: 0,
    isLoading: false,
    error: null
};

export const initialState: RecruitersBrowserState = {
    recruitmentTeam: {
        ...initialSubState
    },
    hiringManager: {
        ...initialSubState
    },
    recruitmentOwner: {
        ...initialSubState
    }
};

export interface RecruitersBrowserFilters {
    searchQuery: string;
    skipUserIds: string[];
}

export type RecruitersBrowserItem = GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult & {
    isSelected: boolean;
};

export type RecruitersBrowserSubState = ListStateWithLoadingState<string, RecruitersBrowserItem> &
    ListFiltersState<RecruitersBrowserFilters>;

export type RecruitersBrowserState = {
    recruitmentTeam: RecruitersBrowserSubState;
    hiringManager: RecruitersBrowserSubState;
    recruitmentOwner: RecruitersBrowserSubState;
};
