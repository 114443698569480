import { KeywordsAction, KeywordsActionTypes } from './keywords.actions';
import { initialState, KeywordsState } from './keywords.state';

export default (state: KeywordsState = initialState, action: KeywordsAction): KeywordsState => {
    switch (action.type) {
        case KeywordsActionTypes.GET_KEYWORDS_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case KeywordsActionTypes.GET_KEYWORDS_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: action.response.keywords,
                isLoading: false,
                error: null
            };

        case KeywordsActionTypes.GET_KEYWORDS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};
