import { ActionsUnion, createAction } from '../../_commons/actions.common';
import { ErrorState } from '../../_commons/state.common';
import { RecruitmentStatus } from '../recruitments.dto';

export enum RecruitmentAvailableStatusesActionTypes {
    GET_AVAILABLE_STATUSES_STARTED = 'RECRUITMENT_GET_AVAILABLE_STATUSES_STARTED',
    GET_AVAILABLE_STATUSES_FINISHED = 'RECRUITMENT_GET_AVAILABLE_STATUSES_FINISHED',
    GET_AVAILABLE_STATUSES_FAILED = 'RECRUITMENT_GET_AVAILABLE_STATUSES_FAILED'
}

export const RecruitmentAvailableStatusesActions = {
    getAvailableStatusesStarted: () =>
        createAction(RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_STARTED),
    getAvailableStatusesFinished: (response: GetAvailableStatusesFinishedParams) =>
        createAction(RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_FINISHED, { response }),
    getAvailableStatusesFailed: (error: ErrorState) =>
        createAction(RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_FAILED, { error })
};

interface GetAvailableStatusesFinishedParams {
    availableStatuses: RecruitmentStatus[];
}

export type RecruitmentAvailableStatusesAction = ActionsUnion<typeof RecruitmentAvailableStatusesActions>;
