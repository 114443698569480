import { TFunction } from 'i18next';
import React from 'react';

import { Activity, ActivityData } from '../../../../../api/recruitees/recruitees.dto';
import { dateTimeDayOfWeek } from '../../../../../utils/format';

export abstract class ActivityItem {
    constructor(protected itemData: Activity, protected activityData: ActivityData, protected t: TFunction) {}

    /**
     * Node on left side.
     */
    abstract renderNode(): React.ReactNode;

    /**
     * Title of the card.
     */
    abstract getTitle(): React.ReactNode;

    /**
     * Any content displayed below the title.
     */
    abstract getContent(): React.ReactNode;

    /**
     * Avatar and full name of the user related to this event item.
     */
    abstract getUserInfo(): React.ReactNode;

    /**
     * Formatted date string - when this event occurred / was created.
     */
    getDate(): string {
        return dateTimeDayOfWeek(this.itemData.creationTimestamp);
    }

    /**
     * Optional element to display below the title.
     */
    getSubtitle(): React.ReactNode {
        return null;
    }
}
