import { Dropdown, DropdownHeader, DropdownItem } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../api/questionnaires/builder/builder.actions';
import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import { QuestionnaireElementType, StructureQuestionnaireElementType } from '../../../api/questionnaires/questionnaires.dto';
import { State } from '../../../store/state';
import {
    AddQuestionnaireElementOption,
    createNewQuestionnaireElement,
    MENU_ITEMS_OTHER,
    MENU_ITEMS_QUESTIONS,
} from './_util';
import styles from './QuestionItemAddButton.module.scss';

interface QuestionItemAddButtonProps {
    /**
     * When item is added or drag&dropped here, it will have this index set in the items list.
     */
    index: number;
}

interface StateProps {
    isDisabled: boolean;
}

interface DispatchProps {
    addItem: (element: QuestionnaireBuilderElement, index?: number) => void;
}

interface QuestionItemAddButtonState {
    isMenuOpen: boolean;
}

class QuestionItemAddButton extends Component<
    WithTranslation & QuestionItemAddButtonProps & StateProps & DispatchProps,
    QuestionItemAddButtonState
    > {
    private ref = React.createRef<HTMLDivElement>();

    state = {
        isMenuOpen: false
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickDocument);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickDocument);
    }

    render() {
        const { t } = this.props;

        return (
            <div className={styles.container} ref={this.ref}>
                <Dropdown
                    align='n'
                    trigger={this.renderAddButton()}
                    className={styles.dropdown}
                    isDisabled={this.props.isDisabled}>
                    <DropdownHeader>{t('questionnaires.questionnaireForm.addMenu.headerFieldType')}</DropdownHeader>
                    {MENU_ITEMS_QUESTIONS.map(this.renderAddElementMenuOption)}
                    <DropdownHeader>{t('questionnaires.questionnaireForm.addMenu.headerOtherElement')}</DropdownHeader>
                    {MENU_ITEMS_OTHER.map(this.renderAddElementMenuOption)}
                </Dropdown>
            </div>
        );
    }

    private renderAddButton() {
        const { t } = this.props;

        return (
            <div
                className={classNames(
                    styles.button,
                    this.state.isMenuOpen && styles.button__active,
                    this.props.isDisabled && styles.button__disabled
                )}
                onClick={this.onAddButtonClick}>
                <i className='fa fa-plus'></i>
                <span className='ml-2 text-navy'>{t('questionnaires.questionnaireForm.addButtonLabel')}</span>
            </div>
        );
    }

    private renderAddElementMenuOption = ({ type, icon, label }: AddQuestionnaireElementOption) => {
        return (
            <DropdownItem
                key={type}
                label={this.props.t(label)}
                className={classNames('btn dropdown-item')}
                name={type}
                onClick={this.onAddQuestionClick}
                icon={<i className={classNames(`fa fa-${icon}`, styles.listIcon)} />}
            />
        );
    };

    private onAddButtonClick = () => {
        if (this.props.isDisabled) {
            return;
        }

        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    private onClickDocument = (e: MouseEvent) => {
        if (!this.ref.current) {
            return;
        }

        if (!this.ref.current.contains(e.target as HTMLElement)) {
            if (this.state.isMenuOpen) {
                this.setState({ isMenuOpen: false });
            }
        }
    };

    private onAddQuestionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const elementType = e.currentTarget.name as QuestionnaireElementType;
        const item = {
            ...createNewQuestionnaireElement(this.props.t)({ $type: elementType }),
            // dont open editor for structure elements
            isEditing: !Object.values(StructureQuestionnaireElementType).includes(
                elementType as StructureQuestionnaireElementType
            ),
            isAdding: true
        };

        this.props.addItem(item, this.props.index);

        this.setState({
            isMenuOpen: false
        });
    };
}

const mapStateToProps = (state: State): StateProps => ({
    isDisabled: state.questionnaireBuilder.elements.find(el => el.isEditing) !== undefined
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    addItem: (element: QuestionnaireBuilderElement, index?: number) =>
        dispatch(QuestionnaireBuilderActions.addElement(element, index))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuestionItemAddButton));
