import { Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { isInstanceOfRecruiteeListViewDto, RecruiteeListViewDto } from '../../../api/recruitees/recruitees.dto';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import styles from './RecruiteesList.module.scss';
import RecruiteesListItem from './RecruiteesListItem';

interface StateProps {
    items: RecruiteeListViewDto[];
    isLoading: boolean;
    page: number;
    totalCount: number;
}

interface DispatchProps {
    fetchItems: (pageNumber: number) => void;
}

class RecruiteesList extends Component<StateProps & DispatchProps> {
    render() {
        const rows = this.props.items.filter(isInstanceOfRecruiteeListViewDto).map(this.renderRow);

        if (this.props.page === 0 && this.props.isLoading) {
            return <Preloader />;
        }

        return (
            <div className='ibox'>
                <div className='ibox-content p-0'>
                    <InfiniteScroll
                        className='project-list gray-bg'
                        dataLength={this.props.items.length}
                        next={this.loadNextPage}
                        hasMore={this.props.items.length < this.props.totalCount}
                        loader={<Preloader />}>
                        <table className={classNames('table table-hover bg-white', styles.table)}>
                            <tbody>{rows}</tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }

    private renderRow = (recruitee: RecruiteeListViewDto) => {
        return <RecruiteesListItem key={recruitee.id} {...recruitee} />;
    };

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper(state.recruitees.allRecruitees.list, state.recruitees.allRecruitees.items),
    isLoading: state.recruitees.allRecruitees.isLoading,
    page: state.recruitees.allRecruitees.page,
    totalCount: state.recruitees.allRecruitees.totalCount
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (pageNumber: number) => dispatch(RecruiteesThunks.getRecruitees(pageNumber, PAGE_SIZE_DEFAULT))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruiteesList);
