import {
    ErrorContext,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    LabelFieldHorizontal,
    SpinnerCircle,
    TextInput,
} from '@emplo/react-inspinia';
import { Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

import styles from './StageEditorModal.module.scss';

enum FormFieldName {
    title = 'title'
}

export interface FormValues {
    [FormFieldName.title]: string;
}

const formSchema = yup.object().shape({
    [FormFieldName.title]: yup.string().required()
});

interface Props {
    onSubmit: (values: FormValues) => void;
    onEditCancel: () => void;
    isSubmitting: boolean;
    initialValues: FormValues;
    isOpen: boolean;
    onClose: () => void;
    stageTypeName: string;
}

class StageEditorModal extends Component<Props & WithTranslation> {
    render() {
        const props = this.props;
        const { t } = props;

        return (
            <InModal
                isOpen={props.isOpen}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'
                onClose={props.onClose}>
                <Formik
                    onSubmit={props.onSubmit}
                    validationSchema={formSchema}
                    initialValues={props.initialValues}
                    render={(formikProps: FormikProps<FormValues>) => {
                        const { values, errors, touched, handleBlur, handleChange, handleSubmit } = formikProps;

                        return (
                            <ErrorContext.Provider value={{ errors, touched }}>
                                <form
                                    onSubmit={handleSubmit}
                                    className='flex-fill d-flex flex-column align-items-stretch'>
                                    <InModalHeader
                                        titleText={`${t('recruitmentRecruiteeStages.addNew')} - ${props.stageTypeName}`}
                                        onClose={props.onClose}
                                    />
                                    <InModalBody className={styles.modalBody}>
                                        <LabelFieldHorizontal
                                            className='mb-0'
                                            for={FormFieldName.title}
                                            label={t('recruitmentRecruiteeStages.stageName')}>
                                            <TextInput
                                                name={FormFieldName.title}
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={t('recruitmentRecruiteeStages.newStageTitlePlaceholder')}
                                                autoFocus
                                            />
                                        </LabelFieldHorizontal>
                                    </InModalBody>
                                    <InModalFooter>
                                        <button
                                            type='button'
                                            className='btn btn-w-m btn-default font-bold'
                                            onClick={props.onEditCancel}
                                            disabled={props.isSubmitting}>
                                            {t('general.button.cancel')}
                                        </button>
                                        <button
                                            className='btn btn-w-m btn-primary font-bold d-flex justify-content-center align-items-center'
                                            type='submit'
                                            disabled={props.isSubmitting}>
                                            {props.isSubmitting && (
                                                <SpinnerCircle
                                                    spinnerSize='small'
                                                    inline
                                                    className='mt-0 ml-0 mb-0 mr-2'
                                                />
                                            )}
                                            {t('general.button.save')}
                                        </button>
                                    </InModalFooter>
                                </form>
                            </ErrorContext.Provider>
                        );
                    }}
                />
            </InModal>
        );
    }
}

export default withTranslation()(StageEditorModal);
