import { PdfViewer } from '@emplo/react-inspinia';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ResumePanelProps {
    cvUrl?: string;
    cvDownloadUrl?: string;
}

const ResumePane = (props: ResumePanelProps) => {
    const { t } = useTranslation();

    if (!props.cvUrl) {
        return null;
    }

    return (
        <PdfViewer
            pdfUrl={props.cvUrl}
            pdfDownloadUrl={props.cvDownloadUrl}
            labelPrevPage={t('recruiteesResume.previous')}
            labelNextPage={t('recruiteesResume.next')}
            labelZoomIn={t('recruiteesResume.zoomIn')}
            labelZoomOut={t('recruiteesResume.zoomOut')}
            labelPage={t('recruiteesResume.page')}
        />
    );
};

export default ResumePane;
