import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ReactNode } from 'react';
import AppLayout from '../layout/AppLayout';
import Questionnaires from './list/Questionnaires';
import NewQuestionnaire from './new/NewQuestionnaire';
import EditQuestionnaire from './edit/EditQuestionnaire';

export function questionnairesRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <Questionnaires />
        </AppLayout>
    );
}

export function newQuestionnaireRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <NewQuestionnaire />
        </AppLayout>
    );
}

export function editQuestionnaireRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <EditQuestionnaire />
        </AppLayout>
    );
}
