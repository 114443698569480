import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';

export enum AuthActionTypes {
    LOGOUT_STARTED = 'AUTH_LOGOUT_STARTED',
    LOGOUT_FINISHED = 'AUTH_LOGOUT_FINISHED',
    LOGOUT_FAILED = 'AUTH_LOGOUT_FAILED'
}

export const AuthActions = {
    logoutStarted: () => createAction(AuthActionTypes.LOGOUT_STARTED),
    logoutFinished: () => createAction(AuthActionTypes.LOGOUT_FINISHED),
    logoutFailed: (error: ErrorState) => createAction(AuthActionTypes.LOGOUT_FAILED, { error })
};

export type AuthAction = ActionsUnion<typeof AuthActions>;
