import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { convertApiRecruiterUserResultToEditViewModel } from '../../../../api/recruitersBrowser/recruitersBrowser.dto';
import { RecruitersBrowserThunks } from '../../../../api/recruitersBrowser/recruitersBrowser.thunk';
import { ATSUserForRecruitmentEditView } from '../../../../api/recruitments/recruitments.dto';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import RecruiterSelect from './RecruiterSelect';

interface Props {
    name: string;
    value?: ATSUserForRecruitmentEditView;
    setFieldValue: (name: string, value: ATSUserForRecruitmentEditView) => void;
    setFieldTouched: (name: string, touched: boolean) => void;
}

interface StateProps {
    isLoading: boolean;
    error: ErrorState;
    items: ATSUserForRecruitmentEditView[];
}

interface DispatchProps {
    fetchItems: (page?: number, pageSize?: number) => void;
}

class RecruitmentOwnerSelect extends Component<Props & StateProps & DispatchProps> {
    componentDidMount() {
        this.props.fetchItems();
    }

    render() {
        return (
            <RecruiterSelect
                name={this.props.name}
                items={this.props.items}
                value={this.props.value}
                error={this.props.error}
                isLoading={this.props.isLoading}
                fetchItems={this.props.fetchItems}
                setFieldTouched={this.props.setFieldTouched}
                setFieldValue={this.props.setFieldValue}
            />
        );
    }
}

const mapStateToProps = (state: State): StateProps => {
    const foundRecruiters = listMapper(
        state.recruitersBrowser.recruitmentOwner.list,
        state.recruitersBrowser.recruitmentOwner.items
    ).map(convertApiRecruiterUserResultToEditViewModel);

    return {
        isLoading: state.recruitersBrowser.recruitmentOwner.isLoading,
        error: state.recruitersBrowser.recruitmentOwner.error,
        items: foundRecruiters
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number = 1, pageSize: number = 1000) =>
        dispatch(RecruitersBrowserThunks.getUsersListForRecruitmentOwnerChoice(page, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentOwnerSelect);
