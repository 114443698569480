import {
    DatePickerInput,
    ErrorContext,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    LabelFieldHorizontal,
    Select,
    TextInput,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

import { RecruiteePhoneCallType } from '../../../../api/recruitees/recruitees.dto';
import { getCurrentDateFormat } from '../../../../utils/format';
import styles from '../../common/RecruiteeModal.module.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (formData: FormValues) => void;
}

enum FormField {
    callType = 'callType',
    callDate = 'callDate',
    callTime = 'callTime',
    description = 'description'
}

export interface FormValues {
    [FormField.callType]: RecruiteePhoneCallType;
    [FormField.callDate]: string;
    [FormField.callTime]: string;
    [FormField.description]: string;
}

const defaultValues: FormValues = {
    callType: RecruiteePhoneCallType.Outgoing,
    callDate: '',
    callTime: '',
    description: ''
};

class RecruiteeActionLogPhoneCallModal extends Component<Props & WithTranslation> {
    private readonly validationSchema = yup.object().shape({
        [FormField.callType]: yup
            .number()
            .oneOf([RecruiteePhoneCallType.Outgoing, RecruiteePhoneCallType.Incoming])
            .required(),
        [FormField.callDate]: yup.date().required(),
        [FormField.callTime]: yup.string().required(),
        [FormField.description]: yup.string().required()
    });

    render() {
        return (
            <InModal
                isOpen={this.props.isOpen}
                onClose={this.props.closeModal}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'
                size='large'>
                <Formik
                    render={this.renderForm}
                    initialValues={defaultValues}
                    onSubmit={this.props.onSubmit}
                    validationSchema={this.validationSchema}
                />
            </InModal>
        );
    }

    private renderForm = (props: FormikProps<FormValues>) => {
        const { t } = this.props;
        const { errors, touched } = props;

        return (
            <ErrorContext.Provider value={{ errors, touched }}>
                <Form className={styles.container}>
                    <InModalHeader
                        titleText={t('recruiteeView.logPhoneCall')}
                        className='border-bottom-0'
                        onClose={this.props.closeModal}
                    />
                    <InModalBody className='bg-white p-0'>
                        <div className='row ml-0 mr-0'>
                            <div className='col-4'>
                                <LabelFieldHorizontal for={FormField.callDate} label={t('recruiteeView.callDate')}>
                                    <DatePickerInput
                                        name={FormField.callDate}
                                        value={props.values[FormField.callDate]}
                                        selected={
                                            props.values[FormField.callDate]
                                                ? new Date(props.values[FormField.callDate])
                                                : null
                                        }
                                        onChange={props.setFieldValue}
                                        onBlur={props.handleBlur}
                                        dateFormat={getCurrentDateFormat().datePicker}
                                        placeholderText={getCurrentDateFormat().display}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                            <div className='col-4'>
                                <LabelFieldHorizontal for={FormField.callTime} label={t('recruiteeView.callTime')}>
                                    <div className='input-group-prepend'>
                                        <div className='input-group-addon'>
                                            <i className='fa fa-clock-o' />
                                        </div>
                                    </div>
                                    <TextInput
                                        name={FormField.callTime}
                                        value={props.values.callTime}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        placeholder={t('general.timePlaceholder')}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                            <div className='col-4'>
                                <LabelFieldHorizontal for={FormField.callType} label={t('recruiteeView.callType')}>
                                    <Select
                                        name={FormField.callType}
                                        value={props.values.callType}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        className='w-100'>
                                        {this.renderCallTypeOptions()}
                                    </Select>
                                </LabelFieldHorizontal>
                            </div>
                        </div>
                        <LabelFieldHorizontal for={FormField.description} className='mb-0'>
                            <div className={classNames(styles.descriptionWrapper, 'w-100')}>
                                <textarea
                                    className='form-control'
                                    rows={14}
                                    name={FormField.description}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values[FormField.description]}
                                    placeholder={t('recruiteeView.descriptionPlaceholder')}
                                />
                            </div>
                        </LabelFieldHorizontal>
                    </InModalBody>

                    <InModalFooter className='border-top-0'>
                        <button
                            onClick={this.props.closeModal}
                            className={classNames('btn btn-default btn-lg btn-w-m font-bold')}>
                            {t('general.button.close')}
                        </button>
                        <button type='submit' className={classNames('btn btn-primary btn-lg btn-w-m ml-3 font-bold')}>
                            {t('general.button.save')}
                        </button>
                    </InModalFooter>
                </Form>
            </ErrorContext.Provider>
        );
    };

    private renderCallTypeOptions = () => {
        const { t } = this.props;

        return (
            <>
                <option value={RecruiteePhoneCallType.Outgoing}>{t('recruiteeCallType.outgoing')}</option>
                <option value={RecruiteePhoneCallType.Incoming}>{t('recruiteeCallType.incoming')}</option>
            </>
        );
    };
}

export default withTranslation()(RecruiteeActionLogPhoneCallModal);
