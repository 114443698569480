import classNames from 'classnames';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { RecruitmentViewDtoNormalized } from '../../../../api/recruitments/recruitments.schema';
import { State } from '../../../../store/state';
import { TOOLTIP } from '../../../../tooltips';
import styles from '../RecruitmentView.module.scss';

interface StateProps {
    recruitment: RecruitmentViewDtoNormalized;
}

class CopyRecruitmentLinkButtonContainer extends Component<StateProps & WithTranslation> {
    render() {
        return (
            <CopyRecruitmentLinkButton
                handleCopyRecruitmentLinkToClipboard={this.handleCopyRecruitmentLinkToClipboard}
            />
        );
    }

    private handleCopyRecruitmentLinkToClipboard = () => {
        const { t } = this.props;
        const link = this.props.recruitment.jobOfferUrl || '';

        var copyText = document.createElement('textarea');
        document.body.appendChild(copyText);
        copyText.innerText = link;
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile
        document.execCommand('copy');
        document.body.removeChild(copyText);

        // show info message
        toastr.info(link, t('Link copied to clipboard'));
    };
}

interface Props {
    handleCopyRecruitmentLinkToClipboard: () => void;
}

const CopyRecruitmentLinkButton = (props: Props) => {
    const { t } = useTranslation();

    return (
        <button
            onClick={props.handleCopyRecruitmentLinkToClipboard}
            className={classNames(
                'btn btn-lg btn-default p-0 ml-2 d-flex justify-content-center align-items-center',
                styles.toolBtn
            )}
            data-tip={t('recruitmentView.copyLink')}
            data-for={TOOLTIP.TOP}>
            <i className='fa faw fa-link' />
        </button>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    recruitment: state.recruitments.recruitmentDetails.recruitment!
});

export default connect(mapStateToProps)(withTranslation()(CopyRecruitmentLinkButtonContainer));
