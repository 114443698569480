import _ from 'lodash';
import { combineReducers } from 'redux';

import { RecruitersBrowserAction, RecruitersBrowserActionTypes } from './recruitersBrowser.actions';
import { initialState, RecruitersBrowserItem, RecruitersBrowserState } from './recruitersBrowser.state';

const recruitersBrowserRecruitersReducer = (
    state: RecruitersBrowserState['recruitmentTeam'] = initialState['recruitmentTeam'],
    action: RecruitersBrowserAction
): RecruitersBrowserState['recruitmentTeam'] => {
    switch (action.type) {
        case RecruitersBrowserActionTypes.SET_RECRUITERS_BROWSER_FILTERS: {
            return {
                ...state,
                filters: action.filters
            };
        }
        case RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_FINISHED:
            // find selected items and keep them
            const selectedItems = _.pickBy(state.items, item => item.isSelected);
            const selectedIds = _.transform(
                selectedItems,
                (acc: string[], curr) => {
                    acc.push(curr.recruiterATSUserId);
                },
                [] as string[]
            );
            const allItems = {
                ...action.response.items,
                ...selectedItems
            };
            const allSortedIds = _.uniq([...selectedIds, ...action.response.ids]).sort((a, b) => {
                if (allItems[a].lastName === allItems[b].lastName) {
                    return allItems[a].firstName > allItems[b].firstName ? 1 : -1;
                }

                return allItems[a].lastName > allItems[b].lastName ? 1 : -1;
            });

            return {
                ...state,
                list: allSortedIds,
                items: allItems,
                isLoading: false,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_BROWSER_RECRUITERS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitersBrowserActionTypes.SELECT_RECRUITERS_BROWSER_RECRUITER:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.selectedRecruiter.recruiterATSUserId]: {
                        ...state.items[action.selectedRecruiter.recruiterATSUserId],
                        isSelected: true
                    }
                }
            };

        case RecruitersBrowserActionTypes.DESELECT_RECRUITERS_BROWSER_RECRUITER:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.id]: {
                        ...state.items[action.id],
                        isSelected: false
                    }
                }
            };

        case RecruitersBrowserActionTypes.CLEAR_SELECTED_RECRUITERS_BROWSER_RECRUITERS:
            const reducedItems = _.reduce(
                state.items,
                (prev, curr) => {
                    return {
                        ...prev,
                        [curr.recruiterATSUserId]: {
                            ...curr,
                            isSelected: false
                        }
                    };
                },
                {} as Record<string, RecruitersBrowserItem>
            );

            return {
                ...state,
                items: reducedItems
            };

        case RecruitersBrowserActionTypes.PRELOAD_RECRUITERS_BROWSER_RECRUITERS:
            return {
                ...state,
                list: _.uniq([...state.list, ...action.recruiters.map(recruiter => recruiter.recruiterATSUserId)]),
                items: _.reduce(
                    action.recruiters,
                    (prev, curr) => {
                        return {
                            ...prev,
                            [curr.recruiterATSUserId]: curr
                        };
                    },
                    state.items
                )
            };

        default:
            return state;
    }
};

const recruitersHiringManagerReducer = (
    state: RecruitersBrowserState['hiringManager'] = initialState['hiringManager'],
    action: RecruitersBrowserAction
): RecruitersBrowserState['hiringManager'] => {
    switch (action.type) {
        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: {
                    ...action.response.items
                },
                isLoading: false,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_HIRING_MANAGER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruitersRecruitmentOwnerReducer = (
    state: RecruitersBrowserState['recruitmentOwner'] = initialState['recruitmentOwner'],
    action: RecruitersBrowserAction
): RecruitersBrowserState['recruitmentOwner'] => {
    switch (action.type) {
        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: {
                    ...action.response.items
                },
                isLoading: false,
                error: null
            };

        case RecruitersBrowserActionTypes.GET_RECRUITERS_SELECT_RECRUITMENT_OWNER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<RecruitersBrowserState>({
    recruitmentTeam: recruitersBrowserRecruitersReducer,
    hiringManager: recruitersHiringManagerReducer,
    recruitmentOwner: recruitersRecruitmentOwnerReducer
});
