import { ChartjsChart } from '@emplo/react-inspinia';
import moment from 'moment';
import React, { Component } from 'react';

import { NewJobApplicationsByDayDatabaseDto } from '../../api/dashboard/dashboard.dto';
import { date } from '../../utils/format';
import NoChartData from './common/NoChartData';

interface Props {
    data: NewJobApplicationsByDayDatabaseDto[];
}

const CHART_HEIGHT = 300;

export default class NewApplicationsChartChart extends Component<Props> {
    render() {
        if (this.props.data.length === 0) {
            return <NoChartData height={CHART_HEIGHT} />;
        }

        const data = this.prepareChartData();

        const chartOptions = {
            type: 'line',
            data: {
                labels: data.map(this.getLabel),
                datasets: [
                    {
                        backgroundColor: 'rgba(0,86,179,0.1)',
                        borderColor: '#0056B3',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        data: data.map(this.getValue)
                    }
                ]
            },
            options: {
                scales: {
                    xAxes: [
                        {
                            autoSkip: false
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                precision: 0
                            },
                            gridLines: {
                                borderDash: [4, 2]
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                elements: {
                    line: {
                        tension: 0
                    }
                }
            }
        };

        return <ChartjsChart height={CHART_HEIGHT} chartOptions={chartOptions} />;
    }

    private prepareChartData = (): NewJobApplicationsByDayDatabaseDto[] => {
        const result: NewJobApplicationsByDayDatabaseDto[] = [];

        const dateFrom = moment().subtract(7, 'd');

        for (let i = 0; i < 7; ++i) {
            const curDay = dateFrom.add(1, 'd');
            const foundItem = this.props.data.find(item => moment(item.day).isSame(curDay, 'day'));

            result.push({
                day: curDay.format(),
                count: foundItem ? foundItem.count : 0
            });
        }

        return result;
    };

    private getLabel = (item: NewJobApplicationsByDayDatabaseDto) => {
        return date(item.day, 'dateMonthNameDay');
    };

    private getValue = (item: NewJobApplicationsByDayDatabaseDto) => {
        return item.count;
    };
}
