import { Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireListViewDto } from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import styles from './QuestionnairesList.module.scss';
import QuestionnairesListItem from './QuestionnairesListItem';

interface StateProps {
    items: QuestionnaireListViewDto[];
    isLoading: boolean;
    totalCount: number;
    page: number;
}

interface DispatchProps {
    fetchItems: (page: number) => void;
}

class QuestionnairesList extends Component<StateProps & DispatchProps> {
    render() {
        const { items } = this.props;

        return (
            <div className='ibox'>
                <div className='ibox-content p-0'>
                    <InfiniteScroll
                        className='project-list gray-bg'
                        dataLength={this.props.items.length}
                        next={this.loadNextPage}
                        hasMore={this.props.items.length < this.props.totalCount}
                        loader={<Preloader />}>
                        <table className={classNames('table table-hover bg-white', styles.table)}>
                            <tbody>{items.map(this.renderItem)}</tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }

    private renderItem = (item: QuestionnaireListViewDto) => (
        <QuestionnairesListItem {...item} name={item.name || ''} key={item.id}></QuestionnairesListItem>
    );

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper<QuestionnaireListViewDto, undefined, string>(
        state.questionnaires.questionnaires.list,
        state.questionnaires.questionnaires.items as Dictionary<QuestionnaireListViewDto>
    ),
    isLoading: state.questionnaires.questionnaires.isLoading,
    totalCount: state.questionnaires.questionnaires.totalCount,
    page: state.questionnaires.questionnaires.page
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number) => dispatch(QuestionnairesThunks.getQuestionnaires(page, PAGE_SIZE_DEFAULT))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairesList);
