import { ErrorPageLayout } from '@emplo/react-inspinia';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RoutePath } from '../../routes';

interface InternalErrorProps {
    title?: string;
    subtitle?: string;
    description?: string;
}

export default (props: InternalErrorProps) => {
    const { t } = useTranslation();

    return (
        <ErrorPageLayout>
            <h1>{props.title || t('errorPage.internalError.title')}</h1>
            <h3>{props.subtitle || t('errorPage.internalError.subtitle')}</h3>
            <div className='error-desc'>{props.description || t('errorPage.internalError.description')}</div>
            <Link className='btn btn-primary m-t' to={RoutePath.dashboard()}>
                {t('general.button.back')}
            </Link>
        </ErrorPageLayout>
    );
};
