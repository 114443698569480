import { Checkbox, LabelFieldHorizontal } from '@emplo/react-inspinia';
import { FormikProps } from 'formik';
import _ from 'lodash';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
    AnswerableQuestionnaireElement,
    QuestionnaireMultipleChoice,
} from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

type QuestionnaireMultipleChoiceForm = QuestionnaireMultipleChoice & {
    optionsAsText: string;
};

class questionnaireMultipleChoiceForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireMultipleChoiceForm>
    > {
    validationSchema = yup.object().shape({
        optionsAsText: yup.string().required()
    });

    render() {
        return (
            <QuestionForm
                itemData={{
                    ...this.props.itemData,
                    optionsAsText: this.transformOptionsToText(this.props.itemData.options) || ''
                }}
                index={this.props.index}
                onSubmit={this.onSubmit}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                validationSchema={this.validationSchema}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = (formikProps: FormikProps<QuestionnaireMultipleChoiceForm>) => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for='optionsAsText'>
                <textarea
                    rows={formikProps.values.optionsAsText.split('\n').length + 1}
                    className='form-control'
                    name='optionsAsText'
                    value={formikProps.values.optionsAsText}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    placeholder={t(
                        'questionnaires.questionnaireForm.addMenu.field.QuestionnaireMultipleChoice.placeholder'
                    )}
                />
            </LabelFieldHorizontal>
        );
    };

    private onSubmit = (formData: QuestionnaireMultipleChoiceForm) => {
        const transformedData = {
            ...formData,
            options: this.transformTextToOptions(formData.optionsAsText)
        };

        this.props.save(transformedData);
    };

    private transformTextToOptions(optionsAsText: string): QuestionnaireMultipleChoice['options'] {
        return optionsAsText.split(/\r?\n/).reduce((prev, curr, idx) => {
            return {
                ...prev,
                [idx + 1]: curr
            };
        }, {} as QuestionnaireMultipleChoice['options']);
    }

    private transformOptionsToText(options: Record<string | number, string>): string {
        return _.values(options).join("\n");
    }
}

export const QuestionnaireMultipleChoiceForm = withTranslation()(questionnaireMultipleChoiceForm);

// PREVIEW

export const QuestionnaireMultipleChoicePreview = (
    props: QuestionnaireElementPreviewProps<QuestionnaireMultipleChoice>
) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                (props.itemData as QuestionnaireMultipleChoice).question +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <div className='d-flex flex-column'>{_.map(props.itemData.options, renderSingleCheckboxPreview)}</div>
        </LabelFieldHorizontal>
    );
};

const renderSingleCheckboxPreview = (optionText: string, idx: number) => (
    <div key={idx} className='mt-1 mb-2'>
        <Checkbox label={optionText} />
    </div>
);
