import classNames from 'classnames';
import { TFunction } from 'i18next';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import {
    Activity,
    PhoneCallPerformedActivityData,
    RecruiteePhoneCallType,
} from '../../../../../api/recruitees/recruitees.dto';
import { dateTimeDayOfWeek, nl2br } from '../../../../../utils/format';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import { ActivityItem } from './ActivityItem';
import styles from './ActivityItem.module.scss';

export default class PhoneCallPerformedEvent extends ActivityItem {
    constructor(
        protected itemData: Activity,
        protected activityData: PhoneCallPerformedActivityData,
        protected t: TFunction
    ) {
        super(itemData, activityData, t);
    }

    renderNode() {
        return (
            <div className={classNames('bg-primary', styles.nodePhone)}>
                <i className='fa fa-phone' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.activityData.atsUserId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.atsUserFirstName}
                lastName={this.activityData.atsUserLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        return this.t('recruiteeView.activities.phoneCallPerformed.title', { type: this.getPhoneCallTypeString() });
    }

    getSubtitle() {
        return <div className={styles.meetingDate}>{dateTimeDayOfWeek(this.itemData.activityTime)}</div>;
    }

    getContent() {
        return <div>{nl2br(this.activityData.description)}</div>;
    }

    private getPhoneCallTypeString() {
        switch (this.activityData.callType) {
            case RecruiteePhoneCallType.Incoming:
                return this.t('recruiteeCallType.incoming');
            case RecruiteePhoneCallType.Outgoing:
                return this.t('recruiteeCallType.outgoing');
        }
    }
}
