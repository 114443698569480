import { SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruiteesActions } from '../../../api/recruitees/recruitees.actions';
import { RecruiteeListViewDto } from '../../../api/recruitees/recruitees.dto';
import { RecruiteesFilters } from '../../../api/recruitees/recruitees.state';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import ErrorPage from '../../pages/ErrorPage';
import RecruiteesList from './RecruiteesList';
import RecruiteesListFilters from './RecruiteesListFilters';

interface StateProps {
    items: RecruiteeListViewDto[];
    error: ErrorState;
}

interface DispatchProps {
    clearItems: () => void;
    fetchItems: (page: number) => void;
    updateFilters: (filters: RecruiteesFilters) => void;
}

class Recruitees extends Component<WithTranslation & StateProps & DispatchProps> {
    constructor(props: WithTranslation & StateProps & DispatchProps) {
        super(props);

        this.props.updateFilters({
            searchQuery: ''
        });
        this.props.clearItems();
    }

    componentDidMount() {
        this.props.fetchItems(1);
    }

    render() {
        const { t, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent className='animated fadeIn' title={t('general.mainMenu.recruitees')}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <RecruiteesListFilters onFiltersSubmit={this.onFiltersSubmit} />
                        <RecruiteesList />
                    </div>
                </div>
            </SidebarLayoutFixedContent>
        );
    }

    private onFiltersSubmit = () => {
        this.props.clearItems();
        this.props.fetchItems(1);
    };
}

const mapStateToProps = (state: State): StateProps => ({
    error: state.recruitees.allRecruitees.error,
    items: listMapper(
        state.recruitees.allRecruitees.list,
        state.recruitees.allRecruitees.items
    ) as RecruiteeListViewDto[]
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number) => dispatch(RecruiteesThunks.getRecruitees(page, PAGE_SIZE_DEFAULT)),
    clearItems: () => dispatch(RecruiteesActions.clearRecruiteesList()),
    updateFilters: (filters: RecruiteesFilters) => dispatch(RecruiteesActions.setRecruiteesFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Recruitees));
