import classNames from 'classnames';
import { TFunction } from 'i18next';
import { Dictionary } from 'lodash';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import { Activity, StageChangedActivityData } from '../../../../../api/recruitees/recruitees.dto';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import { ActivityItem } from './ActivityItem';
import styles from './ActivityItem.module.scss';

export default class StageChangedEvent extends ActivityItem {
    constructor(
        protected itemData: Activity,
        protected activityData: StageChangedActivityData,
        protected t: TFunction,
        protected stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>
    ) {
        super(itemData, activityData, t);
    }

    renderNode() {
        let bgColorClassName = 'bg-primary';

        // if in the future we will want to check what color to give to this node
        // we can get stage data based on the stageId in activityData
        // this.activityData.newStageId

        return (
            <div className={classNames(styles.nodeStageChanged, bgColorClassName)}>
                <i className='fa fa-comments' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.activityData.atsUserId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.atsUserFirstName}
                lastName={this.activityData.atsUserLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        const stage = this.stages[this.activityData.newStageId];
        const stageName = (stage && stage.name) || '';

        return this.t('recruiteeView.activities.stageChanged.title', { stageName });
    }

    getContent() {
        return null;
    }
}
