import { Api } from '../_commons/http';
import { ThunkAction, ThunkActionResult } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { QuestionnairesActions } from './questionnaires.actions';
import {
    AddQuestionnaireCommand,
    AddQuestionnaireCommandResult,
    QuestionnaireListViewDtoPagedResult,
    QuestionnaireListViewForRecruitmentDtoPagedResult,
    QuestionnaireViewDto,
    UpdateQuestionnaireCommand,
    UpdateQuestionnaireCommandResult,
} from './questionnaires.dto';
import {
    convertApiQuestionnairesForRecruitmentToSchema,
    convertApiQuestionnairesToSchema,
    convertApiQuestionnaireToSchema,
} from './questionnaires.schema';

const DEFAULT_PAGE_SIZE = 10;

export class QuestionnairesThunks {
    static getQuestionnaires = (page: number = 1, pageSize: number = DEFAULT_PAGE_SIZE): ThunkAction => async (
        dispatch,
        getState
    ) => {
        dispatch(QuestionnairesActions.getQuestionnairesStarted());

        const filters = getState().questionnaires.questionnaires.filters;
        const { data, status, error } = await Api().get<QuestionnaireListViewDtoPagedResult>(endpoints.questionnaires, {
            SearchQuery: filters.searchQuery || '',
            pageNumber: page,
            pageSize
        });

        if (data) {
            const parsed = convertApiQuestionnairesToSchema(data.result);

            dispatch(
                QuestionnairesActions.getQuestionnairesFinished({
                    ids: parsed.questionnairesIds,
                    questionnaires: parsed.questionnaires,
                    totalCount: data.totalCount,
                    pageNumber: data.pageNumber
                })
            );
        } else {
            dispatch(QuestionnairesActions.getQuestionnairesFailed({ error: error!, httpStatus: status }));
        }
    };

    static getQuestionnairesForRecruitment = (
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async dispatch => {
        dispatch(QuestionnairesActions.getQuestionnairesForRecruitmentStarted());

        const { data, status, error } = await Api().get<QuestionnaireListViewForRecruitmentDtoPagedResult>(
            endpoints.questionnairesForRecruitment,
            {
                page,
                pageSize
            }
        );

        if (data) {
            const parsed = convertApiQuestionnairesForRecruitmentToSchema(data.result);

            dispatch(
                QuestionnairesActions.getQuestionnairesForRecruitmentFinished({
                    ids: parsed.questionnairesIds,
                    questionnaires: parsed.questionnaires
                })
            );
        } else {
            dispatch(
                QuestionnairesActions.getQuestionnairesForRecruitmentFailed({ error: error!, httpStatus: status })
            );
        }
    };

    static getQuestionnaire = (id: string): ThunkAction => async dispatch => {
        dispatch(QuestionnairesActions.getQuestionnaireStarted());

        const { data, status, error } = await Api().get<QuestionnaireViewDto>(`${endpoints.questionnaires}/${id}`);

        if (data) {
            const parsed = convertApiQuestionnaireToSchema(data);

            dispatch(
                QuestionnairesActions.getQuestionnaireFinished({
                    id: parsed.questionnaireId,
                    questionnaire: parsed.questionnaire
                })
            );
        } else {
            dispatch(QuestionnairesActions.getQuestionnaireFailed({ error: error!, httpStatus: status }));
        }
    };

    static addQuestionnaire = (
        questionnaire: AddQuestionnaireCommand
    ): ThunkAction<ThunkActionResult<string>> => async dispatch => {
        dispatch(QuestionnairesActions.addQuestionnaireStarted());

        const { data, status, error } = await Api().post<AddQuestionnaireCommandResult>(
            `${endpoints.questionnaires}/add`,
            questionnaire
        );

        if (data) {
            dispatch(QuestionnairesActions.addQuestionnaireFinished());
            return {
                data: data.id,
                httpStatus: status
            };
        } else {
            dispatch(QuestionnairesActions.addQuestionnaireFailed({ error: error!, httpStatus: status }));
            return {
                httpStatus: status,
                error
            };
        }
    };

    static updateQuestionnaire = (
        id: string,
        questionnaire: UpdateQuestionnaireCommand
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(QuestionnairesActions.updateQuestionnaireStarted());

        const { status, error } = await Api().post<UpdateQuestionnaireCommandResult>(
            `${endpoints.questionnaires}/edit`,
            questionnaire
        );

        if (status < 300) {
            dispatch(QuestionnairesActions.updateQuestionnaireFinished());
        } else {
            dispatch(QuestionnairesActions.updateQuestionnaireFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error
        };
    };

    static deleteQuestionnaire = (id: string): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(QuestionnairesActions.deleteQuestionnaireStarted());

        const { status, error } = await Api().delete(`${endpoints.questionnaires}`, {
            QuestionnaireId: id
        });

        if (status < 300) {
            dispatch(QuestionnairesActions.deleteQuestionnaireFinished(id));
        } else {
            dispatch(QuestionnairesActions.deleteQuestionnaireFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error
        };
    };
}
