import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { FilledQuestionnaireDto } from './filledQuestionnaire.dto';

export enum FilledQuestionnaireActionTypes {
    GET_FILLED_QUESTIONNAIRE_STARTED = 'FILLED_QUESTIONNAIRE_GET_FILLED_QUESTIONNAIRE_STARTED',
    GET_FILLED_QUESTIONNAIRE_FINISHED = 'FILLED_QUESTIONNAIRE_GET_FILLED_QUESTIONNAIRE_FINISHED',
    GET_FILLED_QUESTIONNAIRE_FAILED = 'FILLED_QUESTIONNAIRE_GET_FILLED_QUESTIONNAIRE_FAILED'
}

export const FilledQuestionnaireActions = {
    getFilledQuestionnaireStarted: () => createAction(FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_STARTED),
    getFilledQuestionnaireFinished: (response: GetFilledQuestionnaireFinishedParams) =>
        createAction(FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_FINISHED, { response }),
    getFilledQuestionnaireFailed: (error: ErrorState) =>
        createAction(FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_FAILED, { error })
};

interface GetFilledQuestionnaireFinishedParams {
    data: FilledQuestionnaireDto;
}

export type FilledQuestionnaireAction = ActionsUnion<typeof FilledQuestionnaireActions>;
