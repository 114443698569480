import { SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruitmentsActions } from '../../../api/recruitments/recruitments.actions';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import ErrorPage from '../../pages/ErrorPage';
import RecruitmentsList from './RecruitmentsList';
import RecruitmentsListFilters from './RecruitmentsListFilters';

interface RecruitmentsProps {
    isWriting: boolean;
    error: ErrorState;
    fetchItems: (page: number) => void;
    clearItems: () => void;
    clearFilters: () => void;
}

class Recruitments extends Component<RecruitmentsProps & WithTranslation> {
    constructor(props: RecruitmentsProps & WithTranslation) {
        super(props);

        this.props.clearFilters();
        this.props.clearItems();
    }

    componentDidMount() {
        this.props.fetchItems(1);
    }

    render() {
        const { t, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('general.mainMenu.recruitments')}
                actions={
                    <Link className='btn btn-primary btn-lg' to={RoutePath.recruitmentsNewRecruitment()}>
                        {t('recruitments.addNew')}
                    </Link>
                }>
                <RecruitmentsListFilters />
                <RecruitmentsList />
            </SidebarLayoutFixedContent>
        );
    }
}

const mapStateToProps = (state: State) => ({
    isWriting: state.recruitments.allRecruitments.isWriting,
    error: state.recruitments.allRecruitments.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    fetchItems: (pageNumber: number) => dispatch(RecruitmentsThunks.getRecruitments(pageNumber, PAGE_SIZE_DEFAULT)),
    clearItems: () => dispatch(RecruitmentsActions.clearRecruitmentsList()),
    clearFilters: () => dispatch(RecruitmentsActions.setRecruitmentsFilters({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Recruitments));
