import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
    convertApiRecruiterUserResultToEditViewModel,
    convertUserRecruiterEditViewModelToStateModel,
    GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult,
} from '../../../api/recruitersBrowser/recruitersBrowser.dto';
import { RecruitersBrowserItem } from '../../../api/recruitersBrowser/recruitersBrowser.state';
import { ATSUserForRecruitmentEditView } from '../../../api/recruitments/recruitments.dto';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import RecruitersBrowserModal from './recruitersBrowser/RecruitersBrowserModal';
import styles from './RecruitersList.module.scss';
import RecruitersListItem from './RecruitersListItem';

interface Props {
    name: string;
    items: ATSUserForRecruitmentEditView[];
    setFieldValue: (name: string, value: ATSUserForRecruitmentEditView[]) => void;
    setFieldTouched: (name: string, touched?: boolean) => void;
}

interface StateProps {
    selectedRecruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[];
}

interface RecruitersListState {
    isAddTeamMemberModalOpen: boolean;
}

class RecruitersList extends Component<Props & StateProps & WithTranslation, RecruitersListState> {
    state: RecruitersListState = {
        isAddTeamMemberModalOpen: false,
    };

    render() {
        const { t, items } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.listContainer}>{items.map(this.renderItem)}</div>
                <button
                    className={classNames('btn btn-primary font-bold', styles.btnAdd)}
                    onClick={this.showAddTeamMemberModal}>
                    {t('recruitmentsNew.recruiters.addTeamMember')}
                </button>
                <RecruitersBrowserModal
                    isOpen={this.state.isAddTeamMemberModalOpen}
                    closeModal={this.closeModals}
                    onSubmit={this.submitAddTeamMember}
                    initialSelected={items.map(convertUserRecruiterEditViewModelToStateModel)}
                />
            </div>
        );
    }

    private renderItem = (item: ATSUserForRecruitmentEditView) => {
        return <RecruitersListItem {...item} onDelete={this.onDelete} key={item.id} />;
    };

    private onDelete = (id: string) => {
        const deleteItemFilter = (item: ATSUserForRecruitmentEditView) => item.id !== id;
        const newItems = this.props.items.filter(deleteItemFilter);

        this.props.setFieldValue(this.props.name, newItems);
    };

    private showAddTeamMemberModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({ isAddTeamMemberModalOpen: true });
    };

    private closeModals = () => {
        this.setState({ isAddTeamMemberModalOpen: false });
        this.props.setFieldTouched(this.props.name, true);
    };

    private submitAddTeamMember = () => {
        this.props.setFieldValue(
            this.props.name,
            this.props.selectedRecruiters.map(convertApiRecruiterUserResultToEditViewModel)
        );

        this.closeModals();
    };
}

const selectedItemsFilter = (item: RecruitersBrowserItem) => item.isSelected;

const mapStateToProps = (state: State): StateProps => ({
    selectedRecruiters: listMapper(
        state.recruitersBrowser.recruitmentTeam.list,
        state.recruitersBrowser.recruitmentTeam.items
    ).filter(selectedItemsFilter),
});

export default connect(mapStateToProps)(withTranslation()(RecruitersList));
