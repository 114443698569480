import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../../_commons/actions.common';
import { ErrorState } from '../../_commons/state.common';
import { KeywordItem } from './keywords.state';

export enum KeywordsActionTypes {
    GET_KEYWORDS_STARTED = 'KEYWORDS_GET_KEYWORDS_STARTED',
    GET_KEYWORDS_FINISHED = 'KEYWORDS_GET_KEYWORDS_FINISHED',
    GET_KEYWORDS_FAILED = 'KEYWORDS_GET_KEYWORDS_FAILED'
}

export const KeywordsActions = {
    getKeywordsStarted: () => createAction(KeywordsActionTypes.GET_KEYWORDS_STARTED),
    getKeywordsFinished: (response: GetKeywordsFinishedParams) =>
        createAction(KeywordsActionTypes.GET_KEYWORDS_FINISHED, { response }),
    getKeywordsFailed: (error: ErrorState) => createAction(KeywordsActionTypes.GET_KEYWORDS_FAILED, { error })
};

interface GetKeywordsFinishedParams {
    ids: string[];
    keywords: Dictionary<KeywordItem>;
}

export type KeywordsAction = ActionsUnion<typeof KeywordsActions>;
