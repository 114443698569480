import { Api } from '../_commons/http';
import { ThunkAction } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { SettingsActions } from './settings.actions';
import { ATSLanguageDto, CurrencyDto, GetAvailableTimezonesQueryResult, GetStageTypesResponse } from './settings.dto';
import {
    convertApiCurrenciesToSchema,
    convertApiLanguagesToSchema,
    convertApiStageTypesToSchema,
    convertApiTimezonesToSchema,
} from './settings.schema';

export class SettingsThunks {
    static getCurrencies = (): ThunkAction => async dispatch => {
        dispatch(SettingsActions.getCurrenciesStarted());

        const { data, status, error } = await Api().get<CurrencyDto[]>(endpoints.settingsCurrencies);

        if (data) {
            const parsed = convertApiCurrenciesToSchema(data);

            dispatch(
                SettingsActions.getCurrenciesFinished({
                    ids: parsed.currenciesIds,
                    currencies: parsed.currencies
                })
            );
        } else {
            dispatch(SettingsActions.getCurrenciesFailed({ error: error!, httpStatus: status }));
        }
    };

    static getStageTypes = (): ThunkAction => async dispatch => {
        dispatch(SettingsActions.getStageTypesStarted());

        const { data, status, error } = await Api().get<GetStageTypesResponse>(endpoints.settingsStageTypes);

        if (data) {
            const parsed = convertApiStageTypesToSchema(data);

            dispatch(
                SettingsActions.getStageTypesFinished({
                    ids: parsed.stageTypesIds,
                    stageTypes: parsed.stageTypes
                })
            );
        } else {
            dispatch(SettingsActions.getStageTypesFailed({ error: error!, httpStatus: status }));
        }
    };

    static getLanguages = (): ThunkAction => async dispatch => {
        dispatch(SettingsActions.getLanguagesStarted());

        const { data, status, error } = await Api().get<ATSLanguageDto[]>(endpoints.settingsLanguages);

        if (data) {
            const parsed = convertApiLanguagesToSchema(data);

            dispatch(
                SettingsActions.getLanguagesFinished({
                    ids: parsed.languagesIds,
                    languages: parsed.languages
                })
            );
        } else {
            dispatch(SettingsActions.getLanguagesFailed({ error: error!, httpStatus: status }));
        }
    };

    static getTimezones = (): ThunkAction => async dispatch => {
        dispatch(SettingsActions.getTimezonesStarted());

        const { data, status, error } = await Api().get<GetAvailableTimezonesQueryResult[]>(
            endpoints.settingsTimezones
        );

        if (data) {
            const parsed = convertApiTimezonesToSchema(data);

            dispatch(
                SettingsActions.getTimezonesFinished({
                    ids: parsed.ids,
                    timezones: parsed.timezones
                })
            );
        } else {
            dispatch(SettingsActions.getTimezonesFailed({ error: error!, httpStatus: status }));
        }
    };
}
