import { Dictionary } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
    QuestionnaireListViewDto,
    QuestionnaireViewDto,
    QuestionnaireListViewForRecruitmentDto
} from './questionnaires.dto';

export const SCHEMA_KEY_QUESTIONNAIRES = 'questionnaires';

export const questionnaireSchema = new schema.Entity(SCHEMA_KEY_QUESTIONNAIRES);

export function convertApiQuestionnairesToSchema(response: QuestionnaireListViewDto[]) {
    const parsed = normalize(response, [questionnaireSchema]);

    return {
        questionnairesIds: parsed.result as string[],
        questionnaires: parsed.entities[SCHEMA_KEY_QUESTIONNAIRES] as Dictionary<QuestionnaireListViewDto>
    };
}

export function convertApiQuestionnairesForRecruitmentToSchema(response: QuestionnaireListViewForRecruitmentDto[]) {
    const parsed = normalize(response, [questionnaireSchema]);

    return {
        questionnairesIds: parsed.result as string[],
        questionnaires: parsed.entities[SCHEMA_KEY_QUESTIONNAIRES] as Dictionary<QuestionnaireListViewForRecruitmentDto>
    };
}

export function convertApiQuestionnaireToSchema(response: QuestionnaireViewDto) {
    const parsed = normalize(response, questionnaireSchema);

    return {
        questionnaireId: parsed.result as string,
        questionnaire: parsed.entities[SCHEMA_KEY_QUESTIONNAIRES][parsed.result] as QuestionnaireViewDto
    };
}
