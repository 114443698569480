/**
 * Get questionnaires list response.
 */
export interface QuestionnaireListViewDtoPagedResult {
    result: QuestionnaireListViewDto[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

/**
 * Get questionnaires list response.
 */
export type QuestionnaireListViewForRecruitmentDtoPagedResult = Omit<QuestionnaireListViewDtoPagedResult, 'result'> & {
    result: QuestionnaireListViewForRecruitmentDto[];
};

export interface QuestionnaireListViewForRecruitmentDto {
    id: string;
    name: string;
}

/**
 * Questionnaires list - single item.
 */
export interface QuestionnaireListViewDto {
    id: string;
    name?: string;
    status: QuestionnaireStatus;
    usageCount: number;
    lastEdited: string;
    createdByListView: CreatedByListViewCreatedByListView;
}

export interface CreatedByListViewCreatedByListView {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string;
}

/**
 * Single questionnaire details.
 */
export interface QuestionnaireViewDto {
    id: string;
    name?: string;
    status: QuestionnaireStatus;
    structure: QuestionnaireStructureDto;
}

/**
 * Create new questionnaire.
 */
export interface AddQuestionnaireCommand {
    questionnaireName: string;
    isDraft: boolean;
    questionnaireStructure: QuestionnaireStructureDto;
}

/**
 * Response for request for creating new questionnaire.
 */
export interface AddQuestionnaireCommandResult {
    id: string;
}

/**
 * Update existing questionnaire.
 */
export type UpdateQuestionnaireCommand = AddQuestionnaireCommand & {
    id: string;
};

/**
 * Response for request for questionnaire update.
 */
export type UpdateQuestionnaireCommandResult = AddQuestionnaireCommandResult;

/**
 * Questionnaires data with questions and other items in `elements`.
 */
export interface QuestionnaireStructureDto {
    elements: QuestionnaireElement[];
}

export enum QuestionnaireStatus {
    DRAFT,
    ACTIVE,
    DELETED
}

/**
 * Questionnaire element `$type`.
 */
export enum QuestionQuestionnaireElementType {
    QuestionnaireAddress = 'QuestionnaireAddress',
    QuestionnaireAttachment = 'QuestionnaireAttachment',
    QuestionnaireCoverLetter = 'QuestionnaireCoverLetter',
    QuestionnaireEducation = 'QuestionnaireEducation',
    QuestionnaireExperience = 'QuestionnaireExperience',
    QuestionnaireHeader = 'QuestionnaireHeader',
    QuestionnaireMultiLineText = 'QuestionnaireMultiLineText',
    QuestionnaireMultipleChoice = 'QuestionnaireMultipleChoice',
    QuestionnairePhoto = 'QuestionnairePhoto',
    QuestionnaireResume = 'QuestionnaireResume',
    QuestionnaireSingleChoice = 'QuestionnaireSingleChoice',
    QuestionnaireSingleLineText = 'QuestionnaireSingleLineText',
    QuestionnaireSummary = 'QuestionnaireSummary',
    QuestionnaireTextParagraph = 'QuestionnaireTextParagraph'
}

export enum StructureQuestionnaireElementType {
    PageBreak = 'PageBreak',
    SectionBreak = 'SectionBreak'
}

export type QuestionnaireElementType = QuestionQuestionnaireElementType | StructureQuestionnaireElementType;

/**
 * `QuestionnaireElement` is a base interface for all element types.
 */
export interface BaseQuestionnaireElement {
    id: string;
    order: number;
    pageNumber: number;
    sectionNumber: number;
    $type: QuestionnaireElementType;
}

/**
 * Elements - questions (can be answered) but the question text is not editable.
 */
export interface AnswerableQuestionnaireElement extends BaseQuestionnaireElement {
    isRequired: boolean;
}

/**
 * Question elements with editable question text.
 */
export interface AnswerableQuestionnaireElementWithQuestion extends AnswerableQuestionnaireElement {
    question?: string;
}

/**
 * All possible questionnaire element types.
 */
export type QuestionnaireElement =
    | QuestionnaireAddress
    | QuestionnaireAttachment
    | QuestionnaireCoverLetter
    | QuestionnaireEducation
    | QuestionnaireExperience
    | QuestionnaireHeader
    | QuestionnaireMultiLineText
    | QuestionnaireMultipleChoice
    | QuestionnairePhoto
    | QuestionnaireResume
    | QuestionnaireSingleChoice
    | QuestionnaireSingleLineText
    | QuestionnaireSummary
    | QuestionnaireTextParagraph
    | QuestionnaireStructureElement;

export type QuestionnaireAddress = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireAddress;
};

export type QuestionnaireAttachment = AnswerableQuestionnaireElementWithQuestion & {
    $type: QuestionQuestionnaireElementType.QuestionnaireAttachment;
};

export type QuestionnaireCoverLetter = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireCoverLetter;
};

export type QuestionnaireEducation = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireEducation;
};

export type QuestionnaireExperience = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireExperience;
};

export type QuestionnaireHeader = BaseQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireHeader;
    text: string;
};

export type QuestionnaireMultiLineText = AnswerableQuestionnaireElementWithQuestion & {
    $type: QuestionQuestionnaireElementType.QuestionnaireMultiLineText;
};

export type QuestionnaireMultipleChoice = AnswerableQuestionnaireElementWithQuestion & {
    options: Record<number, string>;
    $type: QuestionQuestionnaireElementType.QuestionnaireMultipleChoice;
};

export type QuestionnairePhoto = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnairePhoto;
};

export type QuestionnaireResume = BaseQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireResume;
};

export type QuestionnaireSingleChoice = AnswerableQuestionnaireElementWithQuestion & {
    $type: QuestionQuestionnaireElementType.QuestionnaireSingleChoice;
    options: Record<any, string>;
};

export type QuestionnaireSingleLineText = AnswerableQuestionnaireElementWithQuestion & {
    $type: QuestionQuestionnaireElementType.QuestionnaireSingleLineText;
};

export type QuestionnaireSummary = AnswerableQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireSummary;
};

export type QuestionnaireTextParagraph = BaseQuestionnaireElement & {
    $type: QuestionQuestionnaireElementType.QuestionnaireTextParagraph;
    text: string;
};

export type QuestionnaireStructureElement = BaseQuestionnaireElement & {
    $type: StructureQuestionnaireElementType;
};

export interface QuestionnaireAnswersDto {
    firstName?: string;
    lastName?: string;
    prefix?: string;
    phoneNumber?: string;
    email?: string;
    additionalQuestionnaireAnswers: QuestionnaireAnswer[];
}

/**
 * Questionnaire element `$type`.
 */
export enum QuestionQuestionnaireAnswerType {
    QuestionnaireAddressAnswer = 'QuestionnaireAddressAnswer',
    QuestionnaireAttachmentAnswer = 'QuestionnaireAttachmentAnswer',
    QuestionnaireCoverLetterAnswer = 'QuestionnaireCoverLetterAnswer',
    QuestionnaireEducationAnswer = 'QuestionnaireEducationAnswer',
    QuestionnaireExperienceAnswer = 'QuestionnaireExperienceAnswer',
    QuestionnaireMultiLineTextAnswer = 'QuestionnaireMultiLineTextAnswer',
    QuestionnaireMultipleChoiceAnswer = 'QuestionnaireMultipleChoiceAnswer',
    QuestionnairePhotoAnswer = 'QuestionnairePhotoAnswer',
    QuestionnaireResumeAnswer = 'QuestionnaireResumeAnswer',
    QuestionnaireSingleChoiceAnswer = 'QuestionnaireSingleChoiceAnswer',
    QuestionnaireSingleLineTextAnswer = 'QuestionnaireSingleLineTextAnswer',
    QuestionnaireSummaryAnswer = 'QuestionnaireSummaryAnswer'
}

export const questionnaireAnswerTypePerElement = {
    [QuestionQuestionnaireElementType.QuestionnaireAddress]: QuestionQuestionnaireAnswerType.QuestionnaireAddressAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireAttachment]:
        QuestionQuestionnaireAnswerType.QuestionnaireAttachmentAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireCoverLetter]:
        QuestionQuestionnaireAnswerType.QuestionnaireCoverLetterAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireEducation]:
        QuestionQuestionnaireAnswerType.QuestionnaireEducationAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireExperience]:
        QuestionQuestionnaireAnswerType.QuestionnaireExperienceAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireMultiLineText]:
        QuestionQuestionnaireAnswerType.QuestionnaireMultiLineTextAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireMultipleChoice]:
        QuestionQuestionnaireAnswerType.QuestionnaireMultipleChoiceAnswer,
    [QuestionQuestionnaireElementType.QuestionnairePhoto]: QuestionQuestionnaireAnswerType.QuestionnairePhotoAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireResume]: QuestionQuestionnaireAnswerType.QuestionnaireResumeAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireSingleChoice]:
        QuestionQuestionnaireAnswerType.QuestionnaireSingleChoiceAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireSingleLineText]:
        QuestionQuestionnaireAnswerType.QuestionnaireSingleLineTextAnswer,
    [QuestionQuestionnaireElementType.QuestionnaireSummary]: QuestionQuestionnaireAnswerType.QuestionnaireSummaryAnswer
};

export type QuestionnaireAnswer =
    | QuestionnaireAddressAnswer
    | QuestionnaireAttachmentAnswer
    | QuestionnaireCoverLetterAnswer
    | QuestionnaireEducationAnswer
    | QuestionnaireExperienceAnswer
    | QuestionnaireMultiLineTextAnswer
    | QuestionnaireMultipleChoiceAnswer
    | QuestionnairePhotoAnswer
    | QuestionnaireResumeAnswer
    | QuestionnaireSingleChoiceAnswer
    | QuestionnaireSingleLineTextAnswer
    | QuestionnaireSummaryAnswer;

export interface BaseQuestionnaireAnswer {
    questionId: string;
    $type: QuestionQuestionnaireAnswerType;
}

export type QuestionnaireAddressAnswer = QuestionnaireSingleLineTextAnswer;

export interface QuestionnaireAttachmentAnswer extends BaseQuestionnaireAnswer {
    fileIdentifier?: string;
}

export type QuestionnaireCoverLetterAnswer = QuestionnaireAttachmentAnswer;

export interface QuestionnaireEducationAnswer extends BaseQuestionnaireAnswer {
    values: QuestionnaireEducationAnswerEntry[];
}

export interface QuestionnaireExperienceAnswer extends BaseQuestionnaireAnswer {
    values: QuestionnaireExperienceAnswerEntry[];
}

export interface QuestionnaireEducationAnswerEntry {
    school: string;
    degree: string;
    fieldOfStudy: string;
    startDate: string;
    endDate?: string;
    currentlyStudyingHere: boolean;
    description: string;
    order: number;
}

export interface QuestionnaireExperienceAnswerEntry {
    title: string;
    company: string;
    locationDescription: string;
    startDate: string;
    endDate?: string;
    currentlyWorkingInThisRole: boolean;
    description: string;
    order: number;
}

export type QuestionnaireMultiLineTextAnswer = QuestionnaireSingleLineTextAnswer;

export interface QuestionnaireMultipleChoiceAnswer extends BaseQuestionnaireAnswer {
    chosenOptions: number[];
}

export type QuestionnairePhotoAnswer = QuestionnaireAttachmentAnswer;

export type QuestionnaireResumeAnswer = QuestionnaireAttachmentAnswer;

export interface QuestionnaireSingleChoiceAnswer extends BaseQuestionnaireAnswer {
    chosenOption?: number;
}

export interface QuestionnaireSingleLineTextAnswer extends BaseQuestionnaireAnswer {
    value?: string;
}

export type QuestionnaireSummaryAnswer = QuestionnaireSingleLineTextAnswer;
