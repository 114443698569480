import { DropdownHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { IndicatorProps, StylesConfig } from 'react-select';

import styles from './ReactSelect.module.scss';

export const CSS = {
    containerHeight: 53,
    borderColor: '#e5e6e7',
    borderRadius: 1,
    dangerColor: '#ED5565',
    primaryColor: '#1ab394',
    hoverBackgroundColor: 'rgba(26, 179, 148, 0.1)',
    textColor: '#2E4050'
};

export const reactSelectStyles: StylesConfig = {
    control: (provided: CSSProperties) => {
        return {
            ...provided,
            height: CSS.containerHeight,
            borderColor: CSS.borderColor,
            borderRadius: CSS.borderRadius,
            boxShadow: 'none',
            '&:hover': {
                borderColor: CSS.borderColor
            }
        };
    },
    valueContainer: (provided: CSSProperties) => {
        return { ...provided, height: '100%' };
    },
    menu: (provided: CSSProperties) => {
        return {
            ...provided,
            margin: 0,
            borderRadius: CSS.borderRadius,
            border: `solid 1px ${CSS.borderColor}`,
            borderTopColor: 'white',
            boxShadow: 'none',
            padding: 0
        };
    },
    option: (provided: CSSProperties) => {
        return {
            ...provided,
            backgroundColor: 'white',
            color: CSS.textColor,
            '&:active': {
                backgroundColor: CSS.hoverBackgroundColor
            },
            '&:hover': {
                backgroundColor: CSS.hoverBackgroundColor
            }
        };
    },
    clearIndicator: (provided: CSSProperties) => {
        return {
            color: CSS.dangerColor
        };
    }
};

export const DropdownIndicator = () => <i className={classNames('fa fa-caret-down', styles.dropdownIndicator)} />;

export function GroupHeading(props: React.PropsWithChildren<{}>) {
    return <DropdownHeader>{props.children}</DropdownHeader>;
}

export function ClearIndicator<T>(props: IndicatorProps<T>) {
    const {
        innerProps: { ref, ...restInnerProps }
    } = props;

    return <i {...restInnerProps} ref={ref} className={classNames('fa fa-times', styles.clearIndicator)} />;
}
