import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireListViewForRecruitmentDto } from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import {
    EditRecruitmentCommand,
    GetRecruitmentViewForEditQueryResult,
    RecruitmentStatus,
} from '../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { BudgetCurrency } from '../../../api/settings/settings.state';
import { SettingsThunks } from '../../../api/settings/settings.thunk';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import { date } from '../../../utils/format';
import { listMapper } from '../../../utils/listMapper';
import ErrorPage from '../../pages/ErrorPage';
import RecruitmentForm, { FormValues, recruitmentFormValuesToEditRecruitmentPostParams } from '../RecruitmentForm';

interface StateProps {
    recruitment?: GetRecruitmentViewForEditQueryResult;
    currencies: BudgetCurrency[];
    questionnaires: QuestionnaireListViewForRecruitmentDto[];
    isLoading: boolean;
    error: ErrorState;
}

interface DispatchProps {
    fetchRecruitment: () => void;
    saveRecruitment: (recruitmentData: EditRecruitmentCommand) => Promise<ThunkActionResult>;
    fetchCurrencies: () => void;
    fetchQuestionnaires: () => void;
}

interface RouteProps {
    id: string;
}

interface OwnState {
    isSubmitting: boolean;
}

class EditRecruitment extends Component<
    StateProps & DispatchProps & WithTranslation & RouteComponentProps<RouteProps>,
    OwnState
    > {
    state: OwnState = {
        isSubmitting: false
    };

    componentDidMount() {
        this.props.fetchRecruitment();
        this.props.fetchCurrencies();
        this.props.fetchQuestionnaires();
    }

    render() {
        const { t, isLoading, error, recruitment } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        if (isLoading || !recruitment) {
            return <Preloader overlay />;
        }

        const jobDescriptionDraft = htmlToDraft(recruitment.jobDescription);
        const initialValues: FormValues = {
            ...recruitment,
            budgetCurrency: recruitment.budgetCurrency.id,
            deadline: date(recruitment.deadline),
            isDraft: recruitment.status === RecruitmentStatus.DRAFT,
            jobDescription: EditorState.createWithContent(
                ContentState.createFromBlockArray(jobDescriptionDraft.contentBlocks, jobDescriptionDraft.entityMap)
            ),
            newOrExistingPosition: { id: recruitment.positionId, name: recruitment.positionName }
        };

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('recruitments.editRecruitment')}
                breadcrumbs={
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.recruitmentsList()}>{t('general.mainMenu.recruitments')}</Link>
                        </li>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.recruitmentsRecruitmentDetails(recruitment.id)}>
                                {recruitment.title}
                            </Link>
                        </li>
                        <li className='breadcrumb-item active'>
                            <strong>{t('recruitments.editRecruitment')}</strong>
                        </li>
                    </ol>
                }>
                <RecruitmentForm
                    initialValues={initialValues}
                    isInitialValid
                    onSubmit={this.onSubmit}
                    isSubmitting={this.state.isSubmitting}
                    currencies={this.props.currencies}
                    questionnaires={this.props.questionnaires}
                    recruitmentStatus={recruitment.status}
                />
            </SidebarLayoutFixedContent>
        );
    }

    private onSubmit = async (values: FormValues) => {
        const { t } = this.props;
        const recruitmentData = recruitmentFormValuesToEditRecruitmentPostParams(values, this.props.recruitment!.id);

        this.setState({
            isSubmitting: true
        });

        const results = await this.props.saveRecruitment(recruitmentData);
        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentsNew.success.message'), t('recruitmentsNew.success.title'));
            this.props.history.push(RoutePath.recruitmentsList());
        } else {
            toastr.error(t('recruitmentsNew.error.message'), t('recruitmentsNew.error.title'));
            this.setState({
                isSubmitting: false
            });
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    recruitment: state.recruitments.recruitmentEdit.recruitment,
    currencies: listMapper(state.settings.currencies.list, state.settings.currencies.items),
    questionnaires: listMapper<QuestionnaireListViewForRecruitmentDto, undefined, string>(
        state.questionnaires.questionnairesForRecruitment.list,
        state.questionnaires.questionnairesForRecruitment.items as Dictionary<QuestionnaireListViewForRecruitmentDto>
    ),
    isLoading:
        state.recruitments.recruitmentEdit.isLoading ||
        state.settings.currencies.isLoading ||
        state.questionnaires.questionnairesForRecruitment.isLoading,
    error:
        state.recruitments.recruitmentEdit.error ||
        state.settings.currencies.error ||
        state.questionnaires.questionnairesForRecruitment.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: RouteComponentProps<RouteProps>): DispatchProps => ({
    fetchRecruitment: () => dispatch(RecruitmentsThunks.getRecruitmentForEdit(props.match.params.id)),
    saveRecruitment: (recruitmentData: EditRecruitmentCommand) =>
        dispatch(RecruitmentsThunks.editRecruitment(recruitmentData)),
    fetchCurrencies: () => dispatch(SettingsThunks.getCurrencies()),
    fetchQuestionnaires: () => dispatch(QuestionnairesThunks.getQuestionnairesForRecruitment(1, 1000))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditRecruitment)));
