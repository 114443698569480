import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruiteesActions } from '../../../api/recruitees/recruitees.actions';
import { RecruiteeInRecruitmentItem, RecruiteesInRecruitmentFilters } from '../../../api/recruitees/recruitees.state';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import { RecruitmentRecruiteeStagesThunks } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { RecruitmentViewDtoNormalized } from '../../../api/recruitments/recruitments.schema';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import ErrorPage from '../../pages/ErrorPage';
import RecruiteesFilters from './filters/RecruiteesFilters';
import RecruiteesInRecruitmentList from './RecruiteesInRecruitmentList';

interface RecruiteesInRecruitmentStateProps {
    isLoadingList: boolean;
    isLoadingFilters: boolean;
    isWriting: boolean;
    totalCount: number;
    page: number;
    items: RecruiteeInRecruitmentItem[];
    error: ErrorState;
    recruitment?: RecruitmentViewDtoNormalized;
}

interface RecruiteesInRecruitmentDispatchProps {
    clearItems: () => void;
    fetchItems: (page: number) => void;
    fetchRecruitment: () => void;
    fetchRecruitmentStages: () => void;
    updateFilters: (filters: RecruiteesInRecruitmentFilters) => void;
}

interface RecruiteesInRecruitmentRouteProps {
    id: string;
}

class RecruiteesInRecruitment extends Component<
    WithTranslation &
    RecruiteesInRecruitmentStateProps &
    RecruiteesInRecruitmentDispatchProps &
    RouteComponentProps<RecruiteesInRecruitmentRouteProps>
    > {
    constructor(props: any) {
        super(props);

        this.props.clearItems();
    }

    componentDidMount() {
        this.props.fetchItems(1);
        this.props.fetchRecruitment();
        this.props.fetchRecruitmentStages();
    }

    render() {
        const { t, isLoadingList, isLoadingFilters, isWriting, page, error } = this.props;
        const showPreloader = (page === 0 && isLoadingList) || isLoadingFilters;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('recruiteesInRecruitment.breadcrumb', {
                    position: this.props.recruitment ? this.props.recruitment.positionName : ''
                })}
                breadcrumbs={
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.recruitmentsList()}>{t('general.mainMenu.recruitments')}</Link>
                        </li>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.recruitmentsRecruitmentDetails(this.props.match.params.id)}>
                                {t('recruitmentView.recruitmentPreview')}
                            </Link>
                        </li>
                        <li className='breadcrumb-item active'>
                            <strong>
                                {t('recruiteesInRecruitment.breadcrumb', {
                                    position: this.props.recruitment ? this.props.recruitment.positionName : ''
                                })}
                            </strong>
                        </li>
                    </ol>
                }>
                {isWriting && <Preloader overlay />}

                <RecruiteesFilters onFiltersSubmit={this.onFiltersSubmit} />
                {showPreloader && <Preloader overlay />}

                <RecruiteesInRecruitmentList recruitmentId={this.props.match.params.id} />
            </SidebarLayoutFixedContent>
        );
    }

    private onFiltersSubmit = () => {
        this.props.clearItems();
        this.props.fetchItems(1);
    };
}

const mapStateToProps = (state: State): RecruiteesInRecruitmentStateProps => ({
    isLoadingFilters:
        state.recruitmentRecruiteeStages.allStages.isLoading || state.recruitments.recruitmentDetails.isLoading,
    isLoadingList: state.recruitees.recruiteesInRecruitment.isLoading,
    isWriting: state.recruitees.recruiteesInRecruitment.isWriting,
    items: listMapper(state.recruitees.recruiteesInRecruitment.list, state.recruitees.recruiteesInRecruitment.items),
    totalCount: state.recruitees.recruiteesInRecruitment.totalCount,
    page: state.recruitees.recruiteesInRecruitment.page,
    error:
        state.recruitees.recruiteesInRecruitment.error ||
        state.recruitmentRecruiteeStages.allStages.error ||
        state.recruitments.recruitmentDetails.error,
    recruitment: state.recruitments.recruitmentDetails.recruitment
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch,
    props: RouteComponentProps<RecruiteesInRecruitmentRouteProps>
): RecruiteesInRecruitmentDispatchProps => ({
    clearItems: () => dispatch(RecruiteesActions.clearRecruiteesInRecruitmentList()),
    fetchItems: (page: number) =>
        dispatch(RecruiteesThunks.getRecruiteesInRecruitment(props.match.params.id, page, PAGE_SIZE_DEFAULT)),
    fetchRecruitment: () => dispatch(RecruitmentsThunks.getRecruitment(props.match.params.id)),
    fetchRecruitmentStages: () => dispatch(RecruitmentRecruiteeStagesThunks.getStages()),
    updateFilters: (filters: RecruiteesInRecruitmentFilters) =>
        dispatch(RecruiteesActions.setRecruiteesInRecruitmentFilters(filters))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteesInRecruitment)));
