import { InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';

import { RecruitersBrowserItem } from '../../../../api/recruitersBrowser/recruitersBrowser.state';
import { ATSUserForRecruitmentEditView } from '../../../../api/recruitments/recruitments.dto';
import RecruitersBrowser from './RecruitersBrowser';
import styles from './RecruitersBrowserModal.module.scss';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: () => void;
    initialSelected?: RecruitersBrowserItem[];
    recruitmentOwner?: ATSUserForRecruitmentEditView;
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [browserKey] = useState(uuid.v4());

    return (
        <>
            <InModal
                isOpen={props.isOpen}
                onClose={props.closeModal}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'>
                <InModalHeader
                    onClose={props.closeModal}
                    closeTitle={t('general.button.close')}
                    titleText={t('general.recruitmentTeam')}
                />
                <InModalBody className={styles.modalContent} noscroll>
                    <RecruitersBrowser
                        key={browserKey}
                        initialSelected={props.initialSelected}
                        recruitmentOwner={props.recruitmentOwner}
                    />
                </InModalBody>
                <InModalFooter>
                    <button
                        className={classNames('btn btn-default font-bold', styles.button)}
                        onClick={props.closeModal}>
                        {t('general.button.close')}
                    </button>
                    <button className={classNames('btn btn-primary font-bold', styles.button)} onClick={props.onSubmit}>
                        {t('general.button.save')}
                    </button>
                </InModalFooter>
            </InModal>
        </>
    );
};
