import { Preloader } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruiteeViewDtoNormalized } from '../../../../api/recruitees/recruitees.schema';
import { RecruiteeApplicationItem } from '../../../../api/recruitees/recruitees.state';
import { RecruiteesThunks } from '../../../../api/recruitees/recruitees.thunk';
import {
    RecruitmentRecruiteeStagesThunks,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../../constants';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import ErrorPage from '../../../pages/ErrorPage';
import styles from './RecruiteeApplicationsList.module.scss';
import RecruiteeApplicationsListItem from './RecruiteeApplicationsListItem';

interface ListProps {
    recruitee: RecruiteeViewDtoNormalized;
    currentJobApplicationId: string;
}

interface ListStateProps {
    isLoadingItems: boolean;
    isLoadingStages: boolean;
    error: ErrorState;
    applications: RecruiteeApplicationItem[];
    isStagesLoaded: boolean;
    totalCount: number;
    page: number;
}

interface ListDispatchProps {
    fetchItems: (page: number) => void;
    fetchStages: () => void;
}

class RecruiteeApplicationsList extends Component<ListProps & ListStateProps & ListDispatchProps> {
    componentDidMount() {
        this.props.fetchItems(1);

        if (!this.props.isStagesLoaded) {
            this.props.fetchStages();
        }
    }

    render() {
        const { error } = this.props;
        const showPreloader = (this.props.page === 0 && this.props.isLoadingItems) || this.props.isLoadingStages;

        if (showPreloader) {
            return <Preloader />;
        }

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <div className={styles.container}>
                <InfiniteScroll
                    className='project-list gray-bg'
                    dataLength={this.props.applications.length}
                    next={this.loadNextPage}
                    hasMore={this.props.applications.length < this.props.totalCount}
                    loader={<Preloader />}
                    height='60vh'>
                    {this.props.applications.map(this.renderItem)}
                </InfiniteScroll>
            </div>
        );
    }

    private renderItem = (item: RecruiteeApplicationItem) => (
        <RecruiteeApplicationsListItem
            key={item.id}
            {...item}
            recruiteeId={this.props.recruitee.basicData.id}
            isActive={this.props.currentJobApplicationId === item.id}
        />
    );

    private loadNextPage = () => {
        if (!this.props.isLoadingItems) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): ListStateProps => ({
    isLoadingItems: state.recruitees.recruiteeApplications.isLoading,
    isLoadingStages: state.recruitmentRecruiteeStages.allStages.isLoading,
    error: state.recruitees.recruiteeApplications.error || state.recruitmentRecruiteeStages.allStages.error,
    applications: listMapper(state.recruitees.recruiteeApplications.list, state.recruitees.recruiteeApplications.items),
    isStagesLoaded: state.recruitmentRecruiteeStages.allStages.page > 0,
    totalCount: state.recruitees.recruiteeApplications.totalCount,
    page: state.recruitees.recruiteeApplications.page
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: ListProps): ListDispatchProps => ({
    fetchItems: (page: number) =>
        dispatch(RecruiteesThunks.getRecruiteeApplications(props.recruitee.basicData.id, page, PAGE_SIZE_DEFAULT)),
    fetchStages: () => dispatch(RecruitmentRecruiteeStagesThunks.getStages())
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruiteeApplicationsList);
