import { ChartjsChart } from '@emplo/react-inspinia';
import _, { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    RecruitmentRecruiteeStageListViewDto,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { RecruitmentRecruiteesByStage } from '../../../../api/recruitments/recruitments.dto';
import { COLORS } from '../../../../constants';
import { State } from '../../../../store/state';
import { formatRecruitmentStageLabelForChart } from '../../../../utils/format';
import NoChartData from '../../../dashboard/common/NoChartData';

interface Props {
    chartData: RecruitmentRecruiteesByStage[];
}

interface StateProps {
    recruitmentStages: Dictionary<RecruitmentRecruiteeStageListViewDto>;
}

const CHART_HEIGHT = 250;

class RecruitmentCandidatesByStageChart extends Component<Props & StateProps> {
    render() {
        if (this.props.chartData.length === 0) {
            return <NoChartData height={CHART_HEIGHT} />;
        }

        const labels = this.getLabels();
        const values = this.prepareChartData();
        const maxValue = Math.max(...values);

        const chartOptions = {
            type: 'bar',
            data: {
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: this.generateColors(),
                        hoverBackgroundColor: this.generateHoverColors(),
                        barThickness: 32,
                        maxBarThickness: 32
                    }
                ]
            },
            options: {
                scales: {
                    xAxes: [
                        {
                            autoSkip: false,
                            gridLines: {
                                borderDash: [4, 2]
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                precision: 0,
                                suggestedMax: maxValue + Math.ceil(maxValue * 0.05)
                            },
                            gridLines: {
                                borderDash: [4, 2]
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem: any, data: any) => {
                            // don't add commas for multi-line labels
                            if (Array.isArray(tooltipItem.xLabel)) {
                                return (tooltipItem.xLabel as string[]).join(' ');
                            }

                            return tooltipItem.xLabel;
                        },
                        title: () => {
                            // don't render any title in the tooltip
                        }
                    }
                }
            },
            plugins: [
                {
                    beforeInit: function (chart: any) {
                        chart.data.labels.forEach(function (e: string, i: number, a: Array<string[]>) {
                            if (/\n/.test(e)) {
                                a[i] = e.split(/\n/);
                            }
                        });
                    }
                }
            ]
        };

        return (
            <div>
                <ChartjsChart height={CHART_HEIGHT} chartOptions={chartOptions} />
            </div>
        );
    }

    private getLabels = () => {
        return this.getSortedStages().map(this.getItemLabel);
    };

    private getItemLabel = (stage: RecruitmentRecruiteeStageListViewDto) => {
        return formatRecruitmentStageLabelForChart(stage.name || '');
    };

    private prepareChartData = () => {
        const stages = this.getSortedStages();

        return stages.map(this.findChartValueForStage);
    };

    private findChartValueForStage = (stage: RecruitmentRecruiteeStageListViewDto) => {
        const found = this.props.chartData.find(chartItem => chartItem.recruitmentRecruiteeStageId === stage.id);
        return found ? found.count : 0;
    };

    private getSortedStages = () => {
        return _.values(this.props.recruitmentStages)
            .filter(stage => !stage.isDeleted)
            .sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });
    };

    private generateColors = () => this.getSortedStages().map(this.getTransparentColorForItem);

    private generateHoverColors = () => this.getSortedStages().map(this.getColorForItem);

    private getColorForItem = (stage: RecruitmentRecruiteeStageListViewDto): string =>
        COLORS.recruitmentStage[stage.stageType].solid;

    private getTransparentColorForItem = (stage: RecruitmentRecruiteeStageListViewDto): string =>
        COLORS.recruitmentStage[stage.stageType].transparent;
}

const mapStateToProps = (state: State): StateProps => ({
    recruitmentStages: state.recruitmentRecruiteeStages.allStages.items
});

export default connect(mapStateToProps)(RecruitmentCandidatesByStageChart);
