import { Dropdown, DropdownItem } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruitmentStatus } from '../../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../../api/recruitments/recruitments.thunk';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';

interface ContainerProps {
    recruitmentId: string;
    currentStatus: RecruitmentStatus;
}

interface StateProps {
    availableStatuses: RecruitmentStatus[];
}

interface DispatchProps {
    handleChange: (newStatus: RecruitmentStatus) => void;
}

class RecruitmentStatusDropdownContainer extends Component<ContainerProps & StateProps & DispatchProps> {
    render() {
        return (
            <RecruitmentStatusDropdown
                availableStatuses={this.props.availableStatuses}
                handleChange={this.props.handleChange}
                currentStatus={this.props.currentStatus}
            />
        );
    }
}

interface Props {
    currentStatus: RecruitmentStatus;
    availableStatuses: RecruitmentStatus[];
    handleChange: (newStatus: RecruitmentStatus) => void;
}

const RecruitmentStatusDropdown = (props: Props) => {
    const { t } = useTranslation();

    const renderAvailableStatusOption = (status: RecruitmentStatus) => (
        <DropdownItem key={status} label={t(getStatusText(status))} onClick={() => props.handleChange(status)} />
    );

    return (
        <Dropdown
            align='se'
            trigger={
                <button
                    type='button'
                    className={classNames(
                        'btn btn-lg btn-w-m font-weight-bold dropdown-toggle',
                        getBtnClassForStatus(props.currentStatus)
                    )}>
                    {t(getStatusText(props.currentStatus))}
                </button>
            }>
            <DropdownItem label={t(getStatusText(props.currentStatus))} className='font-weight-bold' />
            {props.availableStatuses.map(renderAvailableStatusOption)}
        </Dropdown>
    );
};

const getStatusText = (status: RecruitmentStatus): string => {
    switch (status) {
        case RecruitmentStatus.DRAFT:
            return 'recruitments.filter.draft';
        case RecruitmentStatus.PENDING:
            return 'recruitments.filter.pending';
        case RecruitmentStatus.OPEN:
            return 'recruitments.filter.open';
        case RecruitmentStatus.CLOSED:
            return 'recruitments.filter.closed';
    }
};

const getBtnClassForStatus = (status: RecruitmentStatus): string => {
    switch (status) {
        case RecruitmentStatus.DRAFT:
            return 'btn-warning';
        case RecruitmentStatus.OPEN:
            return 'btn-info';
        case RecruitmentStatus.CLOSED:
            return 'btn-danger';
        case RecruitmentStatus.PENDING:
        default:
            return 'btn-primary';
    }
};

const mapStateToProps = (state: State): StateProps => ({
    availableStatuses: listMapper(state.recruitmentAvailableStatuses.list, state.recruitmentAvailableStatuses.items)
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: ContainerProps): DispatchProps => ({
    handleChange: (newStatus: RecruitmentStatus) =>
        dispatch(RecruitmentsThunks.updateStatusForRecruitment(props.recruitmentId, newStatus))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentStatusDropdownContainer);
