import { Checkbox } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';

import { endpoints } from '../../../../api/endpoints';
import PersonInfoBlock from '../../../common/PersonInfoBlock';
import styles from './RecruitersBrowser.module.scss';

interface Props {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string;
    isChecked?: boolean;
    onChange?: (id: string, isChecked: boolean) => void;
}

export default class RecruitersBrowserItem extends Component<Props> {
    render() {
        const { id, firstName, lastName, isChecked } = this.props;
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(id);
        const isHiringManager = this.props.onChange === undefined;

        return (
            <label className={styles.item}>
                <Checkbox
                    className={classNames('mr-3', isHiringManager && styles.checkboxDisabled)}
                    onChange={this.onItemSelected}
                    checked={isChecked}
                    disabled={isHiringManager}
                />
                <PersonInfoBlock
                    firstName={firstName}
                    lastName={lastName}
                    photoUrl={photoUrl}
                    size='medium'
                    className='flex-fill mb-0 ml-1'
                    personNameClassName={styles.itemPersonName}
                    showStar={isHiringManager}
                />
            </label>
        );
    }

    private onItemSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange && this.props.onChange(this.props.id, e.currentTarget.checked);
    };
}
