import { TextInput } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruiteesActions } from '../../../api/recruitees/recruitees.actions';
import { RecruiteesFilters } from '../../../api/recruitees/recruitees.state';
import { State } from '../../../store/state';

interface Props {
    onFiltersSubmit: () => void;
}

interface StateProps {
    filters: RecruiteesFilters;
}

interface DispatchProps {
    updateFilters: (filters: RecruiteesFilters) => void;
}

class RecruiteesListFilters extends Component<Props & StateProps & DispatchProps & WithTranslation> {
    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.onFormSubmit}>
                <div className='row m-b-md'>
                    <div className='col-12 col-lg-5'>
                        <div className='input-group'>
                            <TextInput
                                placeholder={t('recruiteesInRecruitment.searchCandidate')}
                                value={this.props.filters.searchQuery}
                                onChange={this.onSearchChange}
                            />
                            <span className='input-group-append'>
                                <button type='submit' className='btn btn-default input-group-addon text-default'>
                                    <i className='fa fa-search text-navy' />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    private onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateFilters({
            ...this.props.filters,
            searchQuery: e.currentTarget.value
        });
    };

    private onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.onFiltersSubmit();
    };
}

const mapStateToProps = (state: State): StateProps => ({
    filters: state.recruitees.allRecruitees.filters
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    updateFilters: (filters: RecruiteesFilters) => dispatch(RecruiteesActions.setRecruiteesFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteesListFilters));
