import { Response as NodeFetchResponse } from 'node-fetch';
import { Dispatch } from 'redux';

import { UrlParser } from '../../utils/http';
import { AuthActions } from '../auth/auth.actions';

const DEFAULT_ERROR_MESSAGE = 'error.500';
const HTTP_BAD_REQUEST = 400;
const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;
const HTTP_NOT_FOUND = 404;
const HTTP_TIMEOUT = 408;
const HTTP_INTERNAL_SERVER_ERROR = 500;

const API_ERRORS: Record<number, string> = {
    [HTTP_BAD_REQUEST]: 'error.400',
    [HTTP_UNAUTHORIZED]: 'error.401',
    [HTTP_FORBIDDEN]: 'error.403',
    [HTTP_NOT_FOUND]: 'error.404',
    [HTTP_TIMEOUT]: 'error.408',
    [HTTP_INTERNAL_SERVER_ERROR]: 'error.500'
};

interface ApiCallConfig {
    url: string;
    method: 'get' | 'post' | 'delete';
    params?: ApiCallGetParams;
    data?: ApiCallPostParams;
}

interface ApiCallResult<ResponseType> {
    data?: ResponseType;
    status: number;
    error?: string;
}

type ApiCallGetParams = Record<string | number, string | string[] | number | number[]>;

type ApiCallPostParams = Record<string | number, any>;

export type ApiError<ErrorType = string> = { error: ErrorType; httpStatus: number };

let _API: ApiCaller;

export const Api = (): ApiCaller => {
    return _API;
};

export async function initApi(dispatch: Dispatch) {
    _API = new ApiCaller(dispatch);
}

class ApiCaller {
    private dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }

    get<ResponseType>(url: string, params?: ApiCallGetParams): Promise<ApiCallResult<ResponseType>> {
        return this.apiCall({
            method: 'get',
            url,
            params
        });
    }

    post<ResponseType>(url: string, data?: ApiCallPostParams): Promise<ApiCallResult<ResponseType>> {
        return this.apiCall({ method: 'post', url, data });
    }

    delete<ResponseType>(url: string, params?: ApiCallGetParams): Promise<ApiCallResult<ResponseType>> {
        return this.apiCall({ method: 'delete', url, params });
    }

    downloadFile(url: string): Promise<ApiCallResult<Blob>> {
        return this.apiCall<Blob>({
            method: 'get',
            url
        });
    }

    private async apiCall<ResponseType>({
        method,
        url,
        params,
        data: postData
    }: ApiCallConfig): Promise<ApiCallResult<ResponseType>> {
        try {
            // add query params
            if (params) {
                url = UrlParser.encode(url, params);
            }

            const headers: Record<string, string> = {};

            if (postData) {
                headers['Content-Type'] = 'application/json';
            }

            const req: RequestInit = {
                method,
                mode: 'cors',
                credentials: 'include',
                body: postData && JSON.stringify(postData), // add post data
                headers: headers
            };

            const result = await this.fetch(url, req);

            let data: any;

            // currently we handle only json and blob responses
            if (this.isResponseJson(result)) {
                data = await result.json();
            } else {
                data = await result.blob();
            }

            const status = result.status;

            if (status < 400) {
                return { data, status };
            } else if (status === 401) {
                // unauthenticated - we need to redirect user to the login screen
                this.dispatch(AuthActions.logoutFinished());
                return { status, error: API_ERRORS[status] };
            } else {
                // return error based on the returned http status code
                return { status, error: API_ERRORS[status] || DEFAULT_ERROR_MESSAGE };
            }
        } catch (e) {
            if (!e.response) {
                // handle this as "could not reach the server" error
                console.error(e);
                return { status: 0, error: API_ERRORS[HTTP_TIMEOUT] };
            } else {
                return { status: e.response.status, data: e.response.data };
            }
        }
    }

    private isResponseJson(res: Response | NodeFetchResponse): boolean {
        const contentType = res.headers.get('content-type');
        if (!contentType || !contentType.startsWith('application/json')) {
            return false;
        }

        return true;
    }

    private async fetch(url: string, req: Record<string, any>) {
        let fetch;

        if (typeof window === 'undefined') {
            fetch = (await import('node-fetch')).default;
        } else {
            fetch = window.fetch;
        }

        return fetch(url, req);
    }
}
