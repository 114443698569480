import { GetDashboardQueryResult } from './dashboard.dto';
import { LoadingState, WithErrorState } from '../_commons/state.common';

export const initialState: DashboardState = {
    isLoading: false,
    error: null,
    dashboardData: {
        recruitmentAndRecruiteeAggregates: {
            hiredCount: 0,
            inProcessCount: 0,
            openRecruitmentsCount: 0,
            totalRecruiteesCount: 0
        },
        jobApplicationsGroupedByReferer: [],
        newJobApplicationsGroupedByDay: [],
        recentActivities: [],
        recruitmentRecruiteesGroupedByStage: []
    }
};

export type DashboardState = LoadingState &
    WithErrorState & {
        dashboardData: GetDashboardQueryResult;
    };
