import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { RecruitmentStatus } from '../../../api/recruitments/recruitments.dto';
import { selectRecruitmentDetails } from '../../../api/recruitments/recruitments.selector';
import { RoutePath } from '../../../routes';
import { date } from '../../../utils/format';
import ApplicationsBySourceChart from './components/ApplicationsBySourceChart';
import Budget from './components/Budget';
import CopyRecruitmentLinkButton from './components/CopyRecruitmentLinkButton';
import MoreActionsButton from './components/MoreActionsButton';
import RecruitmentCandidatesByStageChart from './components/RecruitmentCandidatesByStageChart';
import RecruitmentStatusDropdown from './components/RecruitmentStatusDropdown';
import RecruitmentTeamPreview from './components/RecruitmentTeamPreview';
import styles from './RecruitmentView.module.scss';

export default () => {
    const { t } = useTranslation();
    const recruitment = useSelector(selectRecruitmentDetails)!; // here recruitment must exist
    const isCandidatesListNotEmpty = recruitment.candidatesAndApplicants.candidateCount > 0 ||
        recruitment.candidatesAndApplicants.applicantCount > 0;

    useEffect(() => ReactTooltip.rebuild(), []);

    return (
        <>
            <div className='align-items-center ibox-content border-bottom white-bg ibox'>
                <div className='d-flex align-items-center'>
                    <h1 className='font-bold text-dark flex-fill mb-0'>{recruitment.positionName}</h1>
                    <div className={styles.mainTools}>
                        <RecruitmentStatusDropdown currentStatus={recruitment.status} recruitmentId={recruitment.id} />
                        <MoreActionsButton />
                        {recruitment.jobOfferUrl && recruitment.status === RecruitmentStatus.OPEN && (
                            <CopyRecruitmentLinkButton />
                        )}
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-4'>
                        <div>
                            {t('recruitmentView.created')}: {date(recruitment.createDate)}
                        </div>
                        <div>
                            {t('recruitments.deadline')}: {date(recruitment.deadline)}
                        </div>
                    </div>
                    <div className='col-4'>
                        <div>
                            {t('recruitmentView.vacantPosts')}: {recruitment.vacantPosts}
                        </div>
                        <div>
                            {t('recruitmentView.budgetPerPosition')}:{' '}
                            <Budget
                                min={recruitment.budgetPerPositionMin}
                                max={recruitment.budgetPerPositionMax}
                                currencyId={recruitment.budgetCurrency}
                            />
                        </div>
                    </div>
                    <div className='col-4 d-flex flex-column justify-content-end'>
                        <RecruitmentTeamPreview
                            recruitmentId={recruitment.id}
                            recruitmentOwner={recruitment.recruitmentOwner}
                            recruitmentTeamMembers={recruitment.recruitmentTeamMembers}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-8'>
                    <div className='ibox'>
                        <div className={classNames('ibox-title font-bold', styles.title)}>
                            {t('recruitmentView.candidatesByStage')}:
                        </div>
                        <div className='ibox-content'>
                            <RecruitmentCandidatesByStageChart
                                chartData={recruitment.recruitmentRecruiteesByStageList}
                            />
                        </div>
                    </div>

                    <div className='ibox'>
                        <div className={classNames('ibox-title font-bold', styles.title)}>
                            {t('recruitmentView.applicationsBySource')}:
                        </div>
                        <div className='ibox-content'>
                            <ApplicationsBySourceChart data={recruitment.recruitmentJobApplicationsByRefererList} />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div className='white-bg ibox'>
                        <div className={classNames('ibox-title font-bold', styles.title)}>
                            {t('recruitmentView.candidatesAndApplicants')}:
                            {/* <i className={classNames('pl-1 fa fa-question-circle', styles.candidatesIcon)} /> */}
                        </div>
                        <div
                            className={classNames(
                                'ibox-content flex-column justify-content-between',
                                styles.candidatesContent
                            )}>
                            <span className={classNames('font-bold', styles.candidatesCount)}>
                                {recruitment.candidatesAndApplicants.candidateCount} /{' '}
                                {recruitment.candidatesAndApplicants.applicantCount}
                            </span>
                            {isCandidatesListNotEmpty && (
                                <Link
                                    to={RoutePath.recruitmentsRecruitmentRecruiteesList(recruitment.id)}
                                    className={classNames(
                                        'btn btn-primary block m-b js-submit font-bold mt-3',
                                        styles.candidatesBtn
                                    )}>
                                    {t('recruitmentView.goToCandidates')}
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className='white-bg'>
                        <div className={classNames('font-bold ibox-title', styles.title)}>
                            {t('recruitmentView.positionDetails')}
                        </div>
                        <div className='ibox-content'>
                            <h2 className='font-bold text-dark'>{recruitment.jobTitle}</h2>
                            <div>
                                {t('recruitmentView.location')}: {recruitment.locationDescription}
                            </div>
                            {recruitment.keywords.length > 0 && (
                                <div className={styles.keywords}>
                                    {t('recruitmentView.keywords')}:{' '}
                                    {recruitment.keywords.map((keyword) => (
                                        <span key={`recruitment-keyword-${keyword}`} className={styles.keyword}>
                                            {keyword}
                                        </span>
                                    ))}
                                </div>
                            )}
                            {recruitment.jobOfferUrl && recruitment.status === RecruitmentStatus.OPEN && (
                                <a
                                    className='d-block text-navy mt-3'
                                    href={recruitment.jobOfferUrl}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    {t('recruitmentView.viewJobPost')}
                                    <i className='fa fa-external-link ml-2' />
                                </a>
                            )}
                            {/* <Link className='d-block text-navy' to='#'>
                {t('recruitmentView.viewApplicationForm')}
                <i className='fa fa-external-link ml-2' />
            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
