import {
    ListStateWithLoadingState,
    LoadingState,
    WithErrorState,
    WritingState,
    ListFiltersState
} from '../_commons/state.common';
import {
    RecruiteeListViewDto,
    GetRecruiteesInRecruitmentListQueryResult,
    GetJobApplicationsListForRecruiteeQueryResult,
    RecruitmentRecruiteeScoresSummaryRowDto,
    Activity
} from './recruitees.dto';
import { RecruiteeViewDtoNormalized } from './recruitees.schema';

export const initialState: RecruiteesState = {
    allRecruitees: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null,
        filters: {
            searchQuery: ''
        }
    },
    recruiteeInApplication: {
        isLoading: false,
        error: null,
        isWriting: false
    },
    recruiteesInRecruitment: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        isWriting: false,
        error: null,
        filters: {
            searchQuery: '',
            scores: [],
            stageIds: []
        }
    },
    recruiteesInRecruitmentActivities: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    recruiteeApplications: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    recruiteeScoresSummary: {
        items: [],
        isLoading: false,
        error: null
    }
};

export interface RecruiteesState {
    allRecruitees: ListStateWithLoadingState<string, RecruiteeItem> & ListFiltersState<RecruiteesFilters>;
    recruiteeApplications: ListStateWithLoadingState<string, RecruiteeApplicationItem>;
    recruiteeInApplication: RecruiteeInApplicationState;
    recruiteesInRecruitment: ListStateWithLoadingState<string, RecruiteeInRecruitmentItem> &
        WritingState &
        ListFiltersState<RecruiteesInRecruitmentFilters>;
    recruiteesInRecruitmentActivities: RecruitmentRecruiteeActivitiesState;
    recruiteeScoresSummary: RecruiteeScoresSummaryState;
}

export type RecruiteeItem = RecruiteeListViewDto;

export type RecruiteeInRecruitmentItem = GetRecruiteesInRecruitmentListQueryResult;

export type RecruiteeApplicationItem = GetJobApplicationsListForRecruiteeQueryResult;

export type RecruiteeAllScoresSummaryItem = RecruitmentRecruiteeScoresSummaryRowDto;

export type RecruiteeInApplicationState = LoadingState &
    WithErrorState &
    WritingState & {
        recruitee?: RecruiteeViewDtoNormalized;
    };

export type RecruiteeScoresSummaryState = LoadingState &
    WithErrorState & {
        items: RecruiteeAllScoresSummaryItem[];
    };

export type RecruitmentRecruiteeActivitiesState = ListStateWithLoadingState<string, Activity>;

export interface RecruiteesFilters {
    searchQuery: string;
}

export interface RecruiteesInRecruitmentFilters {
    searchQuery: string;
    scores: number[];
    stageIds: string[];
}
