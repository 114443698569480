import classNames from 'classnames';
import React, { Component } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../api/questionnaires/builder/builder.actions';
import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import { State } from '../../../store/state';
import QuestionItemAddButton from './QuestionItemAddButton';
import styles from './QuestionnaireBuilder.module.scss';
import { DraggableEditorType } from './QuestionnaireElement';
import QuestionnaireElementEditor from './QuestionnaireElementEditor';

interface QuestionnaireBuilderProps {
    className?: string;
}

interface StateProps {
    items: QuestionnaireBuilderElement[];
    isEditingQuestionnaireElement: boolean;
}

interface DispatchProps {
    addItem: (element: QuestionnaireBuilderElement, index?: number) => void;
    removeItem: (element: QuestionnaireBuilderElement) => void;
    moveItem: (item: QuestionnaireBuilderElement, newIndex: number) => void;
    updateItem: (formData: QuestionnaireBuilderElement) => void;
}

class QuestionnaireBuilder extends Component<QuestionnaireBuilderProps & StateProps & DispatchProps> {
    static defaultProps = {
        items: []
    };

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className={classNames(styles.container, this.props.className)}>
                    <QuestionItemAddButton index={0} />
                    <Droppable type={DraggableEditorType.DEFAULT} droppableId='questionnaire-builder-droppable'>
                        {(provided, snapshot) => {
                            const items = this.props.items.map(this.renderItem);

                            return (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {items}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </div>
            </DragDropContext>
        );
    }

    private renderItem = (item: QuestionnaireBuilderElement, idx: number) => {
        return <QuestionnaireElementEditor key={item.id} index={idx} itemData={item} />;
    };

    private onDragEnd = (result: DropResult) => {
        if (result.destination && result.source.index !== result.destination.index) {
            let targetIndex = result.destination.index;
            if (result.source.index < result.destination.index) {
                targetIndex += 1;
            }
            this.onReorderItem(result.draggableId, targetIndex);
        }
    };

    private onReorderItem = (itemId: string, newIndex: number) => {
        const item = this.props.items.find(item => item.id === itemId);
        if (item) {
            this.props.moveItem(item, newIndex);
        }
    };
}

const isElementBeingEdited = (el: QuestionnaireBuilderElement) => el.isEditing;

const mapStateToProps = (state: State): StateProps => ({
    items: state.questionnaireBuilder.elements,
    isEditingQuestionnaireElement: !!state.questionnaireBuilder.elements.find(isElementBeingEdited)
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    addItem: (element: QuestionnaireBuilderElement, index?: number) =>
        dispatch(QuestionnaireBuilderActions.addElement(element, index)),
    removeItem: (element: QuestionnaireBuilderElement) => dispatch(QuestionnaireBuilderActions.removeElement(element)),
    moveItem: (element: QuestionnaireBuilderElement, newIndex: number) =>
        dispatch(QuestionnaireBuilderActions.moveElement(element, newIndex)),
    updateItem: (formData: QuestionnaireBuilderElement) => dispatch(QuestionnaireBuilderActions.updateElement(formData))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireBuilder);
