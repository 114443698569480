import { ATSLanguage } from '../../i18n';

export interface RecruitmentRecruiteeStageListViewDto {
    id: string;
    order: number;
    name?: string;
    stageType: StageType;
    isDeleted: boolean;
}

export interface RecruitmentRecruiteeStageListEditsViewDto {
    requiredLanguages: ATSLanguage[];
    recruitmentRecruiteeStageEditViewDtos: RecruitmentRecruiteeStageEditViewDto[];
}

export interface RecruitmentRecruiteeStageEditViewDto {
    id: string;
    stageType: StageType;
    order: number;
    localizations: RecruitmentRecruiteeStageLocalizationEditViewDto[];
}

export interface RecruitmentRecruiteeStageLocalizationEditViewDto {
    language: ATSLanguage;
    stageName?: string;
}

export interface EditRecruitmentRecruiteeStagesCommand {
    recruitmentRecruiteeStages: RecruitmentRecruiteeStageEditDto[];
}

export type RecruitmentRecruiteeStageEditDto = RecruitmentRecruiteeStageEditViewDto;

export type GetRecruitmentRecruiteeStageListForSetQueryResult = Omit<RecruitmentRecruiteeStageListViewDto, 'isDeleted'>;

export enum StageType {
    RejectedApplication,
    RejectedCustom,
    New,
    Custom,
    Hired
}
