import { Preloader } from '@emplo/react-inspinia';
import { Dictionary } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { Activity } from '../../../api/recruitees/recruitees.dto';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { RecruitmentRecruiteeStagesThunks } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { GetAvailableTimezonesQueryResult } from '../../../api/settings/settings.dto';
import { SettingsThunks } from '../../../api/settings/settings.thunk';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import RecruiteeActivities from './activity/RecruiteeActivities';

interface OwnProps {
    recruiteeId: string;
    recruitmentId: string;
}

interface StateProps {
    items: Activity[];
    stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>;
    timezones: Dictionary<GetAvailableTimezonesQueryResult>;
    isLoadingList: boolean;
    isLoadingAdditionalData: boolean;
    page: number;
}

interface DispatchProps {
    fetchStages: () => void;
    fetchTimezones: () => void;
}

const ActivityPane = (props: OwnProps & StateProps & DispatchProps) => {
    useEffect(() => {
        props.fetchStages();
        props.fetchTimezones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showPreloader = (props.page === 0 && props.isLoadingList) || props.isLoadingAdditionalData;

    if (showPreloader) {
        return <Preloader />;
    }

    return (
        <RecruiteeActivities
            stages={props.stages}
            timezones={props.timezones}
            recruiteeId={props.recruiteeId}
            recruitmentId={props.recruitmentId}
        />
    );
};

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper(
        state.recruitees.recruiteesInRecruitmentActivities.list,
        state.recruitees.recruiteesInRecruitmentActivities.items
    ),
    stages: state.recruitmentRecruiteeStages.allStages.items,
    timezones: state.settings.timezones.items,
    page: state.recruitees.recruiteesInRecruitmentActivities.page,
    isLoadingList: state.recruitees.recruiteesInRecruitmentActivities.isLoading,
    isLoadingAdditionalData: state.recruitmentRecruiteeStages.allStages.isLoading || state.settings.timezones.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    fetchStages: () => dispatch(RecruitmentRecruiteeStagesThunks.getStages()),
    fetchTimezones: () => dispatch(SettingsThunks.getTimezones())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPane);
