import { Checkbox } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruiteesActions } from '../../../../api/recruitees/recruitees.actions';
import { RECRUITEE_MAX_SCORE } from '../../../../api/recruitees/recruitees.dto';
import { RecruiteesInRecruitmentFilters } from '../../../../api/recruitees/recruitees.state';
import { State } from '../../../../store/state';
import { DropdownIndicator } from '../../../common/ReactSelect';
import RecruiteeScoreStars from '../../../recruitees/common/RecruiteeScoreStars';
import styles from './RecruiteesFilterScore.module.scss';

interface StateProps {
    filters: RecruiteesInRecruitmentFilters;
}

interface DispatchProps {
    updateFilters: (filters: RecruiteesInRecruitmentFilters) => void;
}

interface OwnProps {
    onFiltersChange: () => void;
}

interface OwnState {
    isMenuOpen?: boolean;
}

interface ScoreOption {
    score?: number;
    isAll?: boolean;
    label?: string;
    hasCheckbox?: boolean;
    hasStars?: boolean;
}

const options: ScoreOption[] = [
    {
        label: 'recruiteesInRecruitment.allScores',
        isAll: true
    },
    {
        score: 5,
        hasCheckbox: true,
        hasStars: true
    },
    {
        score: 4,
        hasCheckbox: true,
        hasStars: true
    },
    {
        score: 3,
        hasCheckbox: true,
        hasStars: true
    },
    {
        score: 2,
        hasCheckbox: true,
        hasStars: true
    },
    {
        score: 1,
        hasCheckbox: true,
        hasStars: true
    },
    {
        label: 'recruiteesInRecruitment.noScore',
        score: 0,
        hasCheckbox: true
    }
];

class RecruiteesFilterScore extends Component<OwnProps & StateProps & DispatchProps & WithTranslation, OwnState> {
    state: OwnState = {};
    private ref = React.createRef<HTMLDivElement>();

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickDocument);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickDocument);
    }

    render() {
        const { t } = this.props;
        let valueLabel;

        if (this.props.filters.scores.length > 0) {
            valueLabel = (
                <>
                    <span>{t('recruiteesInRecruitment.scores')}: </span>
                    {this.props.filters.scores
                        .sort()
                        .reverse()
                        .map(score => <strong>{score === 0 ? t('recruiteesInRecruitment.noScore') : score}</strong>)
                        .reduce((prev, curr) => {
                            return (
                                <>
                                    {prev}, {curr}
                                </>
                            );
                        })}
                </>
            );
        }

        if (this.props.filters.scores.length === 0) {
            valueLabel = t(options[0].label!);
        }

        return (
            <div className={styles.container} ref={this.ref}>
                <div
                    onClick={this.onSelectClick}
                    className={classNames(
                        'form-control d-flex justify-content-between position-relative',
                        styles.select,
                        this.state.isMenuOpen && styles.active
                    )}>
                    <span className={styles.selectLabel}>{valueLabel}</span>
                    <DropdownIndicator />
                </div>
                {this.state.isMenuOpen && <div className={styles.menu}>{options.map(this.renderOption)}</div>}
            </div>
        );
    }

    private renderOption = (option: ScoreOption, idx: number) => {
        const { t } = this.props;

        return (
            <div
                key={idx}
                className={classNames(
                    'd-flex',
                    styles.option,
                    option.label && styles.optionLabel,
                    option.label && idx === 0 && styles.optionAll
                )}
                onClick={() => this.onOptionClick(option)}>
                {option.hasCheckbox && option.score !== undefined && (
                    <Checkbox checked={this.props.filters.scores.includes(option.score)} />
                )}

                {option.label && <span>{t(option.label)}</span>}

                {option.hasStars !== undefined && (
                    <>
                        <span className='font-bold mr-2'>{option.score}</span>
                        <RecruiteeScoreStars
                            starsCount={RECRUITEE_MAX_SCORE}
                            initialScore={option.score || 0}
                            disableHover
                        />
                    </>
                )}
            </div>
        );
    };

    private onSelectClick = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    private onClickDocument = (e: MouseEvent) => {
        if (!this.ref.current) {
            return;
        }

        if (this.state.isMenuOpen && !this.ref.current.contains(e.target as HTMLElement)) {
            this.setState({ isMenuOpen: false });
        }
    };

    private onOptionClick = (option: ScoreOption) => {
        let newScores = this.props.filters.scores;

        if (option.score !== undefined) {
            if (newScores.includes(option.score)) {
                newScores = newScores.filter(score => score !== option.score);
            } else {
                newScores = [...newScores, option.score];
            }
        } else if (option.isAll) {
            newScores = [];
        }

        this.props.updateFilters({
            ...this.props.filters,
            scores: newScores
        });

        // submit filters
        this.props.onFiltersChange();
    };
}

const mapStateToProps = (state: State): StateProps => ({
    filters: state.recruitees.recruiteesInRecruitment.filters
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    updateFilters: (filters: RecruiteesInRecruitmentFilters) =>
        dispatch(RecruiteesActions.setRecruiteesInRecruitmentFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteesFilterScore));
