import { SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnairesActions } from '../../../api/questionnaires/questionnaires.actions';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import ErrorPage from '../../pages/ErrorPage';
import QuestionnairesFilters from './QuestionnairesFilters';
import QuestionnairesList from './QuestionnairesList';

interface StateProps {
    error: ErrorState;
}

interface DispatchProps {
    clearItems: () => void;
    updateFilters: (searchQuery?: string) => void;
    fetchItems: (page: number) => void;
}

class Questionnaires extends Component<WithTranslation & StateProps & DispatchProps> {
    constructor(props: WithTranslation & StateProps & DispatchProps) {
        super(props);

        this.props.clearItems();
    }

    componentDidMount() {
        this.props.updateFilters('');
        this.props.fetchItems(1);
    }

    render() {
        const { t, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('general.mainMenu.questionnaires')}
                actions={
                    <Link to={RoutePath.questionnairesNewQuestionnaire()}>
                        <button className='btn btn-primary btn-lg' type='button'>
                            {t('questionnaires.addNew')}
                        </button>
                    </Link>
                }>
                <div className='row'>
                    <div className='col-lg-12'>
                        <QuestionnairesFilters />
                        <QuestionnairesList />
                    </div>
                </div>
            </SidebarLayoutFixedContent>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    error: state.questionnaires.questionnaires.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number) => dispatch(QuestionnairesThunks.getQuestionnaires(page, PAGE_SIZE_DEFAULT)),
    updateFilters: (searchQuery?: string) =>
        dispatch(
            QuestionnairesActions.setQuestionnairesFilters({
                searchQuery
            })
        ),
    clearItems: () => dispatch(QuestionnairesActions.clearQuestionnairesStarted())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Questionnaires));
