import { generatePath } from 'react-router';

const apiDevUrl = process.env.REACT_APP_API_URL || 'http://10.5.10.59:8080/api';
const publicApiDevUrl = process.env.REACT_APP_API_PUBLIC_URL || 'http://10.5.10.59:8081/api';

export class endpoints {
    static readonly dashboard = `${apiDevUrl}/Dashboard`;

    static readonly questionnaires = `${apiDevUrl}/Questionnaires`;
    static readonly questionnairesForRecruitment = `${apiDevUrl}/Questionnaires/questionnairelistforrecruitment`;

    static readonly recruitments = `${apiDevUrl}/Recruitments`;
    private static readonly getRecruitmentForEdit = `Recruitments/edit?recruitmentId=:id`;
    static readonly editRecruitment = `${apiDevUrl}/Recruitments/edit`;
    private static readonly deleteRecruitment = `Recruitments?recruitmentId=:id`;
    private static readonly nextAvailableRecruitmentStatusList = `${apiDevUrl}/Recruitments/NextAvailableRecruitmentStatusList`;
    static readonly recruitmentSetStatus = `${apiDevUrl}/Recruitments/setstatus`;
    static readonly keywords = `${apiDevUrl}/Recruitments/keywords`;

    static readonly settings = `${apiDevUrl}/Settings`;
    static readonly settingsCurrencies = `${apiDevUrl}/Settings/currencies`;
    static readonly settingsStageTypes = `${apiDevUrl}/Settings/stagetypes`;
    static readonly settingsLanguages = `${apiDevUrl}/Settings/atslanguages`;
    static readonly settingsTimezones = `${apiDevUrl}/Settings/timezones`;
    static readonly recruitmentRecruiteeStages = `${apiDevUrl}/RecruitmentRecruiteeStages`;
    static readonly recruitmentRecruiteeStageEdits = `${apiDevUrl}/RecruitmentRecruiteeStages/edit`;
    static readonly recruitmentRecruiteeStagesSetRecruiteeInRecruitmentStage = `${apiDevUrl}/RecruitmentRecruiteeStages/setstage`;
    static readonly recruitmentRecruiteeStagesForRecruiteeInRecruitment = `${apiDevUrl}/RecruitmentRecruiteeStages/StagesAvailableForSetStage`;

    // Activities
    static readonly recruitmentRecruiteeLogPhoneCall = `${apiDevUrl}/Activities/LogPhoneCall`;
    static readonly recruitmentRecruiteeAddNote = `${apiDevUrl}/Activities/AddNote`;
    static readonly recruitmentRecruiteeScheduleMeeting = `${apiDevUrl}/Activities/ScheduleMeeting`;
    static readonly getRecruitmentRecruiteeActivities = `${apiDevUrl}/Activities/Activities`;

    // RecruitmentRecruitees
    static readonly recruiteesInRecruitment = `${apiDevUrl}/RecruitmentRecruitees`;
    static readonly setScoreForRecruiteeInRecruitment = `${apiDevUrl}/RecruitmentRecruitees/SetScoreForRecruiteeInRecruitment`;
    static readonly getAllScoresSummaryForRecruiteeInRecruitment = `${apiDevUrl}/RecruitmentRecruitees/AllScoresSummaryForRecruiteeInRecruitment`;
    static readonly getScoreForRecruiteeInRecruitment = `${apiDevUrl}/RecruitmentRecruitees/ScoreForRecruiteeInRecruitment`;

    static readonly activities = `${apiDevUrl}/Activities`;
    static readonly recruitees = `${apiDevUrl}/Recruitees`;
    static readonly recruiteeWithJobApplication = `${apiDevUrl}/Recruitees/RecruiteeWithSpecificJobApplication`;
    static readonly recruiteeApplications = `${apiDevUrl}/Recruitees/JobApplicationsListForRecruitee`;
    static readonly users = `${apiDevUrl}/Users`;

    // RecruitmentPositions
    static readonly getRecruitmentPositions = `${apiDevUrl}/RecruitmentPositions`;
    static readonly getRecruitmentPositionsEdit = `${apiDevUrl}/RecruitmentPositions/edit`;
    static readonly updateRecruitmentPositionsEdit = endpoints.getRecruitmentPositionsEdit;
    private static readonly deleteRecruitmentPosition = `/RecruitmentPositions?PositionId=:id`;

    // Recruitments/Recruiters
    static readonly recruitersForRecruitmentTeamMembersChoice = `${apiDevUrl}/Recruitments/ATSUsersListForRecruitmentTeamMembersChoice`;
    static readonly recruitersForHiringManagerChoice = `${apiDevUrl}/Recruitments/ATSUsersListForHiringManagerChoice`;
    static readonly recruitersForRecruitmentOwnerChoice = `${apiDevUrl}/Recruitments/ATSUsersListForRecruitmentOwnerChoice`;
    static readonly updateRecruitmentRecruiters = `${apiDevUrl}/Recruitments/EditRecruitmentRecruiters`;

    // ATSUsers
    static readonly atsUsersCurrent = `${apiDevUrl}/ATSUsers/Current`;
    static readonly atsCurrentUserAvatar = `${apiDevUrl}/ATSUsers/Avatar`;
    private static readonly atsUsersAvatar = `/ATSUsers/:userId/Avatar`;
    private static readonly atsUsersAvatarByFileId = `/ATSUsers/AvatarByFileIdentifier?fileIdentifierId=:fileIdentifierId`;
    private static readonly atsUsersLogin = `${apiDevUrl}/ATSUsers/Login`;
    static readonly atsUsersLogout = `${apiDevUrl}/ATSUsers/Logout`;

    static readonly jobOffers = `${publicApiDevUrl}/JobOffers`;

    private static readonly jobApplicationFile = `${apiDevUrl}/Files/JobApplicationFile`;
    private static readonly recruiteePhotoFile = `${apiDevUrl}/Files/DownloadRecruiteePhoto`;

    private static readonly getFilledQuestionnaireForPdfGeneration = `/pdf/GetFilledQuestionnaireForPdfGeneration?jobApplicationId=:jobApplicationId&atsCustomAuthorizationToken=:atsCustomAuthorizationToken`;
    private static readonly downloadRecruiteeFormAndResumeMerged = `/Files/downloadRecruiteeFormAndResumeMerged/:jobApplicationId`;

    static getJobApplicationFileEndpoint(applicationId: string, fileId: string): string {
        return `${endpoints.jobApplicationFile}?jobApplicationId=${applicationId}&fileIdentifier=${fileId}`;
    }

    static getRecruiteePhotoFileEndpoint(recruiteeId: string): string {
        return `${endpoints.recruiteePhotoFile}/${recruiteeId}`;
    }

    static getRecruitmentForEditEndpoint(recruitmentId: string): string {
        return `${apiDevUrl}/${generatePath(endpoints.getRecruitmentForEdit, { id: recruitmentId })}`;
    }

    static getDeleteRecruitmentEndpoint(recruitmentId: string): string {
        return `${apiDevUrl}/${generatePath(endpoints.deleteRecruitment, { id: recruitmentId })}`;
    }

    static getFilledQuestionnaireForPdfGenerationEndpoint(
        jobApplicationId: string,
        atsCustomAuthorizationToken: string
    ): string {
        const path = generatePath(endpoints.getFilledQuestionnaireForPdfGeneration, {
            jobApplicationId,
            atsCustomAuthorizationToken
        });

        return `${apiDevUrl}${path}`;
    }

    static getDownloadRecruiteeFormAndResumeMergedEndpoint(jobApplicationId: string): string {
        const path = generatePath(endpoints.downloadRecruiteeFormAndResumeMerged, {
            jobApplicationId
        });

        return `${apiDevUrl}${path}`;
    }

    static getLoginEndpoint(redirectPath: string): string {
        return `${endpoints.atsUsersLogin}?atsLoginRedirectUrl=${redirectPath}`;
    }

    static getNextAvailableRecruitmentStatusListEndpoint(recruitmentId: string): string {
        return `${endpoints.nextAvailableRecruitmentStatusList}?RecruitmentId=${recruitmentId}`;
    }

    static getAtsUserAvatarEndpoint(userId: string): string {
        return `${apiDevUrl}${generatePath(endpoints.atsUsersAvatar, { userId })}`;
    }

    static getAtsUserAvatarByFileIdEndpoint(avatarFileId?: string): string {
        if (avatarFileId) {
            return `${apiDevUrl}${generatePath(endpoints.atsUsersAvatarByFileId, { fileIdentifierId: avatarFileId })}`;
        }

        return `${apiDevUrl}${endpoints.atsUsersAvatarByFileId}`;
    }

    static getDeleteRecruitmentPositionEndpoint(id: string): string {
        return `${apiDevUrl}${generatePath(endpoints.deleteRecruitmentPosition, { id: id || '' })}`;
    }
}
