import { PAGE_SIZE_DEFAULT } from '../../constants';
import { Api } from '../_commons/http';
import { ThunkAction, ThunkActionResult } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult } from '../recruitersBrowser/recruitersBrowser.dto';
import { SetRecruitmentStatusCommand } from './availableStatuses/availableStatuses.dto';
import { RecruitmentsActions } from './recruitments.actions';
import {
    AddRecruitmentCommand,
    AddRecruitmentCommandResult,
    EditRecruitmentCommand,
    EditRecruitmentRecruitersCommand,
    GetRecruitmentViewForEditQueryResult,
    RecruitmentListViewDtoPagedResult,
    RecruitmentStatus,
    RecruitmentViewDto,
} from './recruitments.dto';
import { convertApiRecruitmentsToSchema, convertApiRecruitmentToSchema } from './recruitments.schema';

export class RecruitmentsThunks {
    static getRecruitments = (
        pageNumber: number = 1,
        pageSize: number = PAGE_SIZE_DEFAULT,
        clearPreviousRecruitments: boolean = false
    ): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruitmentsActions.getRecruitmentsStarted());

        // collect filters from state
        const searchQuery = getState().recruitments.allRecruitments.filters.searchQuery;
        const statusFilter = getState().recruitments.allRecruitments.filters.status;
        const queryParams: Record<string, string | number> = {
            pageNumber,
            pageSize,
        };

        if (searchQuery !== undefined) {
            queryParams.SearchQuery = searchQuery;
        }
        if (statusFilter !== undefined) {
            queryParams.RecruitmentStatus = statusFilter;
        }

        const { data, status, error } = await Api().get<RecruitmentListViewDtoPagedResult>(
            endpoints.recruitments,
            queryParams
        );

        if (data) {
            if (clearPreviousRecruitments) {
                dispatch(RecruitmentsActions.clearRecruitmentsList());
            }

            const parsed = convertApiRecruitmentsToSchema(data.result);

            dispatch(
                RecruitmentsActions.getRecruitmentsFinished({
                    ids: parsed.recruitmentsIds,
                    recruitments: parsed.recruitments,
                    pageNumber: data.pageNumber,
                    totalCount: data.totalCount,
                })
            );
        } else {
            dispatch(
                RecruitmentsActions.getRecruitmentsFailed({
                    error: error!,
                    httpStatus: status,
                })
            );
        }
    };

    static getRecruitment = (id: string): ThunkAction => async (dispatch) => {
        dispatch(RecruitmentsActions.getRecruitmentStarted());

        const { data, status, error } = await Api().get<RecruitmentViewDto>(`${endpoints.recruitments}/${id}`);

        if (data) {
            const parsed = convertApiRecruitmentToSchema(data);

            dispatch(
                RecruitmentsActions.getRecruitmentFinished({
                    id: parsed.recruitmentId,
                    recruitment: parsed.recruitment,
                    currencies: parsed.currencies,
                })
            );
        } else {
            dispatch(
                RecruitmentsActions.getRecruitmentFailed({
                    error: error!,
                    httpStatus: status,
                })
            );
        }
    };

    static addRecruitment = (recruitment: AddRecruitmentCommand): ThunkAction<ThunkActionResult<string>> => async (
        dispatch
    ) => {
        dispatch(RecruitmentsActions.addRecruitmentStarted());

        const { data, status, error } = await Api().post<AddRecruitmentCommandResult>(
            `${endpoints.recruitments}/add`,
            recruitment
        );

        if (data) {
            dispatch(RecruitmentsActions.addRecruitmentFinished());
            return {
                data: data.id,
                httpStatus: status,
            };
        } else {
            dispatch(RecruitmentsActions.addRecruitmentFailed({ error: error!, httpStatus: status }));
            return {
                httpStatus: status,
                error,
            };
        }
    };

    static updateStatusForRecruitment = (
        recruitmentId: string,
        newStatus: RecruitmentStatus
    ): ThunkAction<ThunkActionResult> => async (dispatch) => {
        const postData: SetRecruitmentStatusCommand = {
            recruitmentId,
            newStatus,
        };

        dispatch(RecruitmentsActions.updateStatusOfRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.recruitmentSetStatus, postData);

        if (status < 300) {
            dispatch(RecruitmentsActions.updateStatusOfRecruitmentFinished(recruitmentId, newStatus));
        } else {
            dispatch(RecruitmentsActions.updateStatusOfRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error: error,
        };
    };

    static updateRecruitersForRecruitment = (
        recruitmentId: string,
        recruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[]
    ): ThunkAction<ThunkActionResult> => async (dispatch) => {
        dispatch(RecruitmentsActions.updateRecruitersOfRecruitmentStarted());

        const postData: EditRecruitmentRecruitersCommand = {
            recruitmentId,
            editedRecruitmentRecruiterIds: recruiters.map((item) => item.recruiterATSUserId),
        };

        const { data, status, error } = await Api().post(endpoints.updateRecruitmentRecruiters, postData);

        if (data) {
            dispatch(RecruitmentsActions.updateRecruitersOfRecruitmentFinished(recruitmentId, recruiters));
        } else {
            dispatch(RecruitmentsActions.updateRecruitersOfRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error,
        };
    };

    static deleteRecruitment = (recruitmentId: string): ThunkAction<ThunkActionResult> => async (dispatch) => {
        dispatch(RecruitmentsActions.deleteRecruitmentStarted());

        const { status, error } = await Api().delete(endpoints.getDeleteRecruitmentEndpoint(recruitmentId));

        if (status < 300) {
            dispatch(RecruitmentsActions.deleteRecruitmentFinished());
        } else {
            dispatch(RecruitmentsActions.deleteRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error,
        };
    };

    static getRecruitmentForEdit = (id: string): ThunkAction => async (dispatch) => {
        dispatch(RecruitmentsActions.getRecruitmentForEditStarted());

        const { data, status, error } = await Api().get<GetRecruitmentViewForEditQueryResult>(
            endpoints.getRecruitmentForEditEndpoint(id)
        );

        if (data) {
            dispatch(RecruitmentsActions.getRecruitmentForEditFinished(data));
        } else {
            dispatch(RecruitmentsActions.getRecruitmentForEditFailed({ error: error!, httpStatus: status }));
        }
    };

    static editRecruitment = (recruitmentData: EditRecruitmentCommand): ThunkAction<ThunkActionResult> => async (
        dispatch
    ) => {
        dispatch(RecruitmentsActions.editRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.editRecruitment, recruitmentData);

        if (status < 300) {
            dispatch(RecruitmentsActions.editRecruitmentFinished());
        } else {
            dispatch(RecruitmentsActions.editRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error,
        };
    };
}
