import { Preloader } from '@emplo/react-inspinia';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { Activity } from '../../../../api/recruitees/recruitees.dto';
import { RecruiteesThunks } from '../../../../api/recruitees/recruitees.thunk';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { GetAvailableTimezonesQueryResult } from '../../../../api/settings/settings.dto';
import { PAGE_SIZE_DEFAULT } from '../../../../constants';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import styles from './RecruiteeActivities.module.scss';
import RecruiteeActivityItem from './RecruiteeActivityItem';

interface OwnProps {
    recruiteeId: string;
    recruitmentId: string;
    stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>;
    timezones: Dictionary<GetAvailableTimezonesQueryResult>;
}

interface StateProps {
    items: Activity[];
    isLoading: boolean;
    totalCount: number;
    page: number;
}

interface DispatchProps {
    fetchItems: (page: number) => void;
}

class RecruiteeActivities extends Component<OwnProps & StateProps & DispatchProps> {
    render() {
        return (
            <InfiniteScroll
                className={styles.listContainer}
                dataLength={this.props.items.length}
                next={this.loadNextPage}
                hasMore={this.props.items.length < this.props.totalCount}
                loader={<Preloader />}>
                {this.props.items.map(this.renderItem)}
            </InfiniteScroll>
        );
    }

    private renderItem = (item: Activity) => (
        <RecruiteeActivityItem item={item} stages={this.props.stages} key={item.id} timezones={this.props.timezones} />
    );

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper(
        state.recruitees.recruiteesInRecruitmentActivities.list,
        state.recruitees.recruiteesInRecruitmentActivities.items
    ),
    isLoading: state.recruitees.recruiteesInRecruitmentActivities.isLoading,
    totalCount: state.recruitees.recruiteesInRecruitmentActivities.totalCount,
    page: state.recruitees.recruiteesInRecruitmentActivities.page
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    fetchItems: (page: number) =>
        dispatch(
            RecruiteesThunks.getRecruitmentRecruiteeActivities({
                recruiteeId: props.recruiteeId,
                recruitmentId: props.recruitmentId,
                PageNumber: page,
                PageSize: PAGE_SIZE_DEFAULT
            })
        )
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruiteeActivities);
