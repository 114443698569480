import { NavHeader as BaseNavHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { endpoints } from '../../../api/endpoints';
import { State } from '../../../store/state';
import LogoutButton from '../LogoutButton';
import styles from './NavHeader.module.scss';

interface StateProps {
    firstName?: string;
    lastName?: string;
}

const NavHeader = (props: StateProps) => {
    const avatarUrl = endpoints.atsCurrentUserAvatar;

    return (
        <BaseNavHeader>
            <div className='dropdown profile-element'>
                <img alt='' className={classNames('rounded-circle', styles.avatar)} src={avatarUrl} />
                <span className={classNames('dropdown-toggle', styles.menu)}>
                    <span className='block m-t-xs font-weight-bold'>
                        {props.firstName} {props.lastName}
                    </span>
                    <LogoutButton></LogoutButton>
                </span>
            </div>
        </BaseNavHeader>
    );
};

const mapStateToProps = (state: State): StateProps => ({
    ...state.user
});

export default connect(mapStateToProps)(NavHeader);
