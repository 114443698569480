import { combineReducers } from 'redux';

import { RecruitmentAvailableStatusesAction } from './availableStatuses/availableStatuses.actions';
import { RecruitmentsAction, RecruitmentsActionTypes } from './recruitments.actions';
import { initialState, RecruitmentsState } from './recruitments.state';

const allRecruitmentsReducer = (
    state: RecruitmentsState['allRecruitments'] = initialState['allRecruitments'],
    action: RecruitmentsAction
): RecruitmentsState['allRecruitments'] => {
    switch (action.type) {
        case RecruitmentsActionTypes.CLEAR_RECRUITMENTS:
            return {
                ...state,
                totalCount: 0,
                page: 0,
                items: {},
                list: []
            };

        case RecruitmentsActionTypes.GET_RECRUITMENTS_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENTS_FINISHED:
            return {
                ...state,
                list: [...state.list, ...action.response.ids],
                items: {
                    ...state.items,
                    ...action.response.recruitments
                },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentsActionTypes.SET_RECRUITMENTS_LIST_FILTERS:
            return {
                ...state,
                filters: action.filters
            };

        case RecruitmentsActionTypes.DELETE_RECRUITMENT_STARTED:
            return {
                ...state,
                isWriting: true,
                error: null
            };

        case RecruitmentsActionTypes.DELETE_RECRUITMENT_FINISHED:
            return {
                ...state,
                isWriting: false,
                error: null
            };

        case RecruitmentsActionTypes.DELETE_RECRUITMENT_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruitmentDetailsReducer = (
    state: RecruitmentsState['recruitmentDetails'] = initialState['recruitmentDetails'],
    action: RecruitmentsAction | RecruitmentAvailableStatusesAction
): RecruitmentsState['recruitmentDetails'] => {
    switch (action.type) {
        case RecruitmentsActionTypes.GET_RECRUITMENT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENT_FINISHED:
            return {
                ...state,
                recruitment: action.response.recruitment,
                isLoading: false,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentsActionTypes.ADD_RECRUITMENT_FINISHED:
            return {
                ...state,
                isLoading: false,
                error: null
            };

        case RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_STARTED:
        case RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_STARTED:
            return {
                ...state,
                isWriting: true,
                error: null
            };

        case RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_FINISHED:
            return {
                ...state,
                isWriting: false,
                recruitment: state.recruitment && {
                    ...state.recruitment,
                    status: action.newStatus
                }
            };

        case RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_FINISHED:
            return {
                ...state,
                isWriting: false,
                recruitment: state.recruitment && {
                    ...state.recruitment,
                    recruitmentTeamMembers: action.recruiters
                }
            };

        case RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_FAILED:
        case RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruitmentEditReducer = (
    state: RecruitmentsState['recruitmentEdit'] = initialState['recruitmentEdit'],
    action: RecruitmentsAction | RecruitmentAvailableStatusesAction
): RecruitmentsState['recruitmentEdit'] => {
    switch (action.type) {
        case RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_FINISHED:
            return {
                ...state,
                recruitment: { ...action.response },
                isLoading: false,
                error: null
            };

        case RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentsActionTypes.EDIT_RECRUITMENT_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case RecruitmentsActionTypes.EDIT_RECRUITMENT_FINISHED:
        case RecruitmentsActionTypes.EDIT_RECRUITMENT_FAILED:
            return {
                ...state,
                isWriting: false
            };

        default:
            return state;
    }
};

export default combineReducers<RecruitmentsState>({
    allRecruitments: allRecruitmentsReducer,
    recruitmentDetails: recruitmentDetailsReducer,
    recruitmentEdit: recruitmentEditReducer
});
