import { Dictionary, NumericDictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import {
    convertApiRecruiterUserResultToEditViewModel,
    GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult,
} from '../recruitersBrowser/recruitersBrowser.dto';
import { BudgetCurrency } from '../settings/settings.state';
import { GetRecruitmentViewForEditQueryResult, RecruitmentListViewDto, RecruitmentStatus } from './recruitments.dto';
import { RecruitmentViewDtoNormalized } from './recruitments.schema';
import { RecruitmentsListFilters } from './recruitments.state';

export enum RecruitmentsActionTypes {
    CLEAR_RECRUITMENTS = 'RECRUITMENTS_CLEAR_RECRUITMENTS',
    GET_RECRUITMENTS_STARTED = 'RECRUITMENTS_GET_RECRUITMENTS_STARTED',
    GET_RECRUITMENTS_FINISHED = 'RECRUITMENTS_GET_RECRUITMENTS_FINISHED',
    GET_RECRUITMENTS_FAILED = 'RECRUITMENTS_GET_RECRUITMENTS_FAILED',

    SET_RECRUITMENTS_LIST_FILTERS = 'RECRUITMENTS_SET_RECRUITMENTS_LIST_FILTERS',

    GET_RECRUITMENT_STARTED = 'RECRUITMENTS_GET_RECRUITMENT_STARTED',
    GET_RECRUITMENT_FINISHED = 'RECRUITMENTS_GET_RECRUITMENT_FINISHED',
    GET_RECRUITMENT_FAILED = 'RECRUITMENTS_GET_RECRUITMENT_FAILED',

    ADD_RECRUITMENT_STARTED = 'RECRUITMENTS_ADD_RECRUITMENT_STARTED',
    ADD_RECRUITMENT_FINISHED = 'RECRUITMENTS_ADD_RECRUITMENT_FINISHED',
    ADD_RECRUITMENT_FAILED = 'RECRUITMENTS_ADD_RECRUITMENT_FAILED',

    GET_RECRUITMENT_FOR_EDIT_STARTED = 'RECRUITMENTS_GET_RECRUITMENT_FOR_EDIT_STARTED',
    GET_RECRUITMENT_FOR_EDIT_FINISHED = 'RECRUITMENTS_GET_RECRUITMENT_FOR_EDIT_FINISHED',
    GET_RECRUITMENT_FOR_EDIT_FAILED = 'RECRUITMENTS_GET_RECRUITMENT_FOR_EDIT_FAILED',

    EDIT_RECRUITMENT_STARTED = 'EDIT_RECRUITMENT_STARTED',
    EDIT_RECRUITMENT_FINISHED = 'EDIT_RECRUITMENT_FINISHED',
    EDIT_RECRUITMENT_FAILED = 'EDIT_RECRUITMENT_FAILED',

    DELETE_RECRUITMENT_STARTED = 'DELETE_RECRUITMENT_STARTED',
    DELETE_RECRUITMENT_FINISHED = 'DELETE_RECRUITMENT_FINISHED',
    DELETE_RECRUITMENT_FAILED = 'DELETE_RECRUITMENT_FAILED',

    UPDATE_STATUS_OF_RECRUITMENT_STARTED = 'RECRUITMENTS_UPDATE_STATUS_OF_RECRUITMENT_STARTED',
    UPDATE_STATUS_OF_RECRUITMENT_FINISHED = 'RECRUITMENTS_UPDATE_STATUS_OF_RECRUITMENT_FINISHED',
    UPDATE_STATUS_OF_RECRUITMENT_FAILED = 'RECRUITMENTS_UPDATE_STATUS_OF_RECRUITMENT_FAILED',

    UPDATE_RECRUITERS_OF_RECRUITMENT_STARTED = 'UPDATE_RECRUITERS_OF_RECRUITMENT_STARTED',
    UPDATE_RECRUITERS_OF_RECRUITMENT_FINISHED = 'UPDATE_RECRUITERS_OF_RECRUITMENT_FINISHED',
    UPDATE_RECRUITERS_OF_RECRUITMENT_FAILED = 'UPDATE_RECRUITERS_OF_RECRUITMENT_FAILED'
}

export const RecruitmentsActions = {
    clearRecruitmentsList: () => createAction(RecruitmentsActionTypes.CLEAR_RECRUITMENTS),
    getRecruitmentsStarted: () => createAction(RecruitmentsActionTypes.GET_RECRUITMENTS_STARTED),
    getRecruitmentsFinished: (response: GetRecruitmentsFinishedParams) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENTS_FINISHED, { response }),
    getRecruitmentsFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENTS_FAILED, { error }),

    setRecruitmentsFilters: (filters: RecruitmentsListFilters) =>
        createAction(RecruitmentsActionTypes.SET_RECRUITMENTS_LIST_FILTERS, { filters }),

    getRecruitmentStarted: () => createAction(RecruitmentsActionTypes.GET_RECRUITMENT_STARTED),
    getRecruitmentFinished: (response: GetRecruitmentFinishedParams) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENT_FINISHED, { response }),
    getRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENT_FAILED, { error }),

    addRecruitmentStarted: () => createAction(RecruitmentsActionTypes.ADD_RECRUITMENT_STARTED),
    addRecruitmentFinished: () => createAction(RecruitmentsActionTypes.ADD_RECRUITMENT_FINISHED),
    addRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.ADD_RECRUITMENT_FAILED, { error }),

    getRecruitmentForEditStarted: () => createAction(RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_STARTED),
    getRecruitmentForEditFinished: (response: GetRecruitmentViewForEditQueryResult) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_FINISHED, { response }),
    getRecruitmentForEditFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.GET_RECRUITMENT_FOR_EDIT_FAILED, { error }),

    editRecruitmentStarted: () => createAction(RecruitmentsActionTypes.EDIT_RECRUITMENT_STARTED),
    editRecruitmentFinished: () => createAction(RecruitmentsActionTypes.EDIT_RECRUITMENT_FINISHED),
    editRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.EDIT_RECRUITMENT_FAILED, { error }),

    deleteRecruitmentStarted: () => createAction(RecruitmentsActionTypes.DELETE_RECRUITMENT_STARTED),
    deleteRecruitmentFinished: () => createAction(RecruitmentsActionTypes.DELETE_RECRUITMENT_FINISHED),
    deleteRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.DELETE_RECRUITMENT_FAILED, { error }),

    updateStatusOfRecruitmentStarted: () => createAction(RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_STARTED),
    updateStatusOfRecruitmentFinished: (recruitmentId: string, newStatus: RecruitmentStatus) =>
        createAction(RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_FINISHED, {
            recruitmentId,
            newStatus
        }),
    updateStatusOfRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.UPDATE_STATUS_OF_RECRUITMENT_FAILED, { error }),

    updateRecruitersOfRecruitmentStarted: () =>
        createAction(RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_STARTED),
    updateRecruitersOfRecruitmentFinished: (
        recruitmentId: string,
        recruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[]
    ) => {
        return createAction(RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_FINISHED, {
            recruitmentId,
            recruiters: recruiters.map(convertApiRecruiterUserResultToEditViewModel)
        });
    },
    updateRecruitersOfRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentsActionTypes.UPDATE_RECRUITERS_OF_RECRUITMENT_FAILED, { error })
};

interface GetRecruitmentsFinishedParams {
    ids: string[];
    recruitments: Dictionary<RecruitmentListViewDto>;
    pageNumber: number;
    totalCount: number;
}

interface GetRecruitmentFinishedParams {
    id: string;
    recruitment: RecruitmentViewDtoNormalized;
    currencies: NumericDictionary<BudgetCurrency>;
}

export type RecruitmentsAction = ActionsUnion<typeof RecruitmentsActions>;
