import { combineReducers } from 'redux';

import {
    RecruitmentRecruiteeStagesAction,
    RecruitmentRecruiteeStagesActionTypes,
} from '../recruitmentRecruiteeStages/recruitmentRecruiteeStages.actions';
import { RecruiteesAction, RecruiteesActionTypes } from './recruitees.actions';
import { initialState, RecruiteesState } from './recruitees.state';

const allRecruiteesReducer = (
    state: RecruiteesState['allRecruitees'] = initialState.allRecruitees,
    action: RecruiteesAction
) => {
    switch (action.type) {
        case RecruiteesActionTypes.CLEAR_RECRUITEES:
            return {
                ...state,
                totalCount: 0,
                page: 0,
                items: {},
                list: []
            };

        case RecruiteesActionTypes.GET_RECRUITEES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEES_FINISHED:
            return {
                ...state,
                list: [...state.list, ...action.response.ids],
                items: {
                    ...state.items,
                    ...action.response.recruitees
                },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruiteesActionTypes.SET_RECRUITEES_FILTERS: {
            return {
                ...state,
                filters: {
                    ...action.filters
                }
            };
        }

        default:
            return state;
    }
};

const recruiteeInApplicationReducer = (
    state: RecruiteesState['recruiteeInApplication'] = initialState.recruiteeInApplication,
    action: RecruiteesAction | RecruitmentRecruiteeStagesAction
): RecruiteesState['recruiteeInApplication'] => {
    switch (action.type) {
        case RecruiteesActionTypes.GET_RECRUITEE_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_FINISHED:
            return {
                ...state,
                recruitee: action.recruitee,
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
        case RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
        case RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
        case RecruiteesActionTypes.SCHEDULE_MEETING_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED:
        case RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED:
        case RecruiteesActionTypes.SCHEDULE_MEETING_FINISHED:
            return {
                ...state,
                isWriting: false
            };

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED:
            if (state.recruitee) {
                const recruitee = state.recruitee;

                return {
                    ...state,
                    isWriting: false,
                    recruitee: {
                        ...recruitee,
                        authorizedData: {
                            ...recruitee.authorizedData!,
                            scoreSetByMe: action.score
                        }
                    }
                };
            }

            return { ...state };

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
        case RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
        case RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
        case RecruiteesActionTypes.SCHEDULE_MEETING_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        // update average score after setting new score
        case RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED: {
            return {
                ...state,
                recruitee: state.recruitee
                    ? {
                          ...state.recruitee,
                          authorizedData: state.recruitee.authorizedData
                              ? {
                                    ...state.recruitee.authorizedData,
                                    averageScore: action.response.averageScore,
                                    scoreSetByMe: action.response.scoreSetByMe,
                                    reviewCount: action.response.reviewCount
                                }
                              : undefined
                      }
                    : state.recruitee
            };
        }

        // update stage for recruitee in recruitment
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_STARTED: {
            return {
                ...state,
                isWriting: true
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FINISHED: {
            return {
                ...state,
                isWriting: false,
                recruitee: state.recruitee
                    ? {
                          ...state.recruitee,
                          authorizedData: state.recruitee.authorizedData
                              ? {
                                    ...state.recruitee.authorizedData,
                                    recruitmentRecruiteeStageId: action.newStageId
                                }
                              : undefined
                      }
                    : state.recruitee
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FAILED: {
            return {
                ...state,
                isWriting: false,
                error: action.error
            };
        }

        default:
            return state;
    }
};

const recruiteesInRecruitmentReducer = (
    state: RecruiteesState['recruiteesInRecruitment'] = initialState.recruiteesInRecruitment,
    action: RecruiteesAction | RecruitmentRecruiteeStagesAction
): RecruiteesState['recruiteesInRecruitment'] => {
    switch (action.type) {
        case RecruiteesActionTypes.CLEAR_RECRUITEES_IN_RECRUITMENT:
            return {
                ...state,
                totalCount: 0,
                page: 0,
                items: {},
                list: []
            };

        case RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_STARTED:
        case RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_FINISHED:
            return {
                ...state,
                list: [...state.list, ...action.response.ids],
                items: {
                    ...state.items,
                    ...action.response.recruitees
                },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_FAILED:
        case RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FINISHED: {
            const recruitee = state.items[action.recruiteeId];

            return {
                ...state,
                isWriting: false,
                items: {
                    ...state.items,
                    [action.recruiteeId]: {
                        ...recruitee,
                        recruitmentRecruiteeStageId: action.newStageId
                    }
                }
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED: {
            return {
                ...state,
                isWriting: false,
                items: {
                    ...state.items,
                    [action.recruiteeId]: {
                        ...state.items[action.recruiteeId],
                        scoreSetByMe: action.score
                    }
                }
            };
        }

        case RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        // update average score after setting new score
        case RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED: {
            const recruitee = state.items[action.response.recruiteeId];

            return {
                ...state,
                items: {
                    ...state.items,
                    [recruitee.id]: {
                        ...state.items[recruitee.id],
                        ...action.response
                    }
                },
                isLoading: false
            };
        }

        case RecruiteesActionTypes.SET_RECRUITEES_IN_RECRUITMENT_FILTERS: {
            return {
                ...state,
                filters: {
                    ...action.filters
                }
            };
        }

        default:
            return state;
    }
};

const recruiteesInRecruitmentActivitiesReducer = (
    state: RecruiteesState['recruiteesInRecruitmentActivities'] = initialState.recruiteesInRecruitmentActivities,
    action: RecruiteesAction
) => {
    switch (action.type) {
        case RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_STARTED:
            return {
                ...state,
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FINISHED:
            return {
                ...state,
                list: [...(action.response.pageNumber === 1 ? [] : state.list), ...action.response.ids],
                items: {
                    ...(action.response.pageNumber === 1 ? {} : state.items),
                    ...action.response.items
                },
                page: action.response.pageNumber,
                totalCount: action.response.totalCount,
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruiteeApplicationsReducer = (
    state: RecruiteesState['recruiteeApplications'] = initialState.recruiteeApplications,
    action: RecruiteesAction
) => {
    switch (action.type) {
        case RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_STARTED:
            return {
                ...state,
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_FINISHED:
            return {
                ...state,
                list: [...(action.response.pageNumber === 1 ? [] : state.list), ...action.response.ids],
                items: {
                    ...(action.response.pageNumber === 1 ? {} : state.items),
                    ...action.response.applications
                },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruiteeScoresSummaryReducer = (
    state: RecruiteesState['recruiteeScoresSummary'] = initialState.recruiteeScoresSummary,
    action: RecruiteesAction
) => {
    switch (action.type) {
        case RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED:
            return {
                ...state,
                items: [...action.response.scores],
                isLoading: false,
                error: null
            };

        case RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<RecruiteesState>({
    allRecruitees: allRecruiteesReducer,
    recruiteeApplications: recruiteeApplicationsReducer,
    recruiteeInApplication: recruiteeInApplicationReducer,
    recruiteesInRecruitment: recruiteesInRecruitmentReducer,
    recruiteesInRecruitmentActivities: recruiteesInRecruitmentActivitiesReducer,
    recruiteeScoresSummary: recruiteeScoresSummaryReducer
});
