import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { Activity } from './recruitees.dto';
import { RecruiteeViewDtoNormalized } from './recruitees.schema';
import {
    RecruiteeAllScoresSummaryItem,
    RecruiteeApplicationItem,
    RecruiteeInRecruitmentItem,
    RecruiteeItem,
    RecruiteesFilters,
    RecruiteesInRecruitmentFilters,
} from './recruitees.state';

export enum RecruiteesActionTypes {
    CLEAR_RECRUITEES = 'RECRUITEES_CLEAR_RECRUITEES',
    GET_RECRUITEES_STARTED = 'RECRUITEES_GET_RECRUITEES_STARTED',
    GET_RECRUITEES_FINISHED = 'RECRUITEES_GET_RECRUITEES_FINISHED',
    GET_RECRUITEES_FAILED = 'RECRUITEES_GET_RECRUITEES_FAILED',

    SET_RECRUITEES_FILTERS = 'RECRUITEES_SET_RECRUITEES_FILTERS',

    CLEAR_RECRUITEES_IN_RECRUITMENT = 'RECRUITEES_CLEAR_RECRUITEES_IN_RECRUITMENT',
    GET_RECRUITEES_IN_RECRUITMENT_STARTED = 'RECRUITEES_GET_RECRUITEES_IN_RECRUITMENT_STARTED',
    GET_RECRUITEES_IN_RECRUITMENT_FINISHED = 'RECRUITEES_GET_RECRUITEES_IN_RECRUITMENT_FINISHED',
    GET_RECRUITEES_IN_RECRUITMENT_FAILED = 'RECRUITEES_GET_RECRUITEES_IN_RECRUITMENT_FAILED',

    SET_RECRUITEES_IN_RECRUITMENT_FILTERS = 'RECRUITEES_SET_RECRUITEES_IN_RECRUITMENT_FILTERS',

    GET_RECRUITEE_STARTED = 'RECRUITEES_GET_RECRUITEE_STARTED',
    GET_RECRUITEE_FINISHED = 'RECRUITEES_GET_RECRUITEE_FINISHED',
    GET_RECRUITEE_FAILED = 'RECRUITEES_GET_RECRUITEE_FAILED',

    GET_RECRUITEE_APPLICATIONS_STARTED = 'RECRUITEES_GET_RECRUITEE_APPLICATIONS_STARTED',
    GET_RECRUITEE_APPLICATIONS_FINISHED = 'RECRUITEES_GET_RECRUITEE_APPLICATIONS_FINISHED',
    GET_RECRUITEE_APPLICATIONS_FAILED = 'RECRUITEES_GET_RECRUITEE_APPLICATIONS_FAILED',

    SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITEES_SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITEES_SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITEES_SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITEES_GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITEES_GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITEES_GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITEES_GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITEES_GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITEES_GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITEES_LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITEES_LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITEES_LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITEES_ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITEES_ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITEES_ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    SCHEDULE_MEETING_STARTED = 'RECRUITEES_SCHEDULE_MEETING_STARTED',
    SCHEDULE_MEETING_FINISHED = 'RECRUITEES_SCHEDULE_MEETING_FINISHED',
    SCHEDULE_MEETING_FAILED = 'RECRUITEES_SCHEDULE_MEETING_FAILED',

    GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_STARTED = 'RECRUITEES_GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_STARTED',
    GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FINISHED = 'RECRUITEES_GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FINISHED',
    GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FAILED = 'RECRUITEES_GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FAILED'
}

export const RecruiteesActions = {
    clearRecruiteesList: () => createAction(RecruiteesActionTypes.CLEAR_RECRUITEES),
    getRecruiteesStarted: () => createAction(RecruiteesActionTypes.GET_RECRUITEES_STARTED),
    getRecruiteesFinished: (response: GetRecruiteesFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEES_FINISHED, { response }),
    getRecruiteesFailed: (error: ErrorState) => createAction(RecruiteesActionTypes.GET_RECRUITEES_FAILED, { error }),

    setRecruiteesFilters: (filters: RecruiteesFilters) =>
        createAction(RecruiteesActionTypes.SET_RECRUITEES_FILTERS, { filters }),

    clearRecruiteesInRecruitmentList: () => createAction(RecruiteesActionTypes.CLEAR_RECRUITEES_IN_RECRUITMENT),
    getRecruiteesInRecruitmentStarted: () => createAction(RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_STARTED),
    getRecruiteesInRecruitmentFinished: (response: GetRecruiteesInRecruitmentFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_FINISHED, { response }),
    getRecruiteesInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEES_IN_RECRUITMENT_FAILED, { error }),

    setRecruiteesInRecruitmentFilters: (filters: RecruiteesInRecruitmentFilters) =>
        createAction(RecruiteesActionTypes.SET_RECRUITEES_IN_RECRUITMENT_FILTERS, { filters }),

    getRecruiteeStarted: () => createAction(RecruiteesActionTypes.GET_RECRUITEE_STARTED),
    getRecruiteeFinished: (recruitee: RecruiteeViewDtoNormalized) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_FINISHED, { recruitee }),
    getRecruiteeFailed: (error: ErrorState) => createAction(RecruiteesActionTypes.GET_RECRUITEE_FAILED, { error }),

    getRecruiteeApplicationStarted: () => createAction(RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_STARTED),
    getRecruiteeApplicationsFinished: (response: GetRecruiteeApplicationsFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_FINISHED, { response }),
    getRecruiteeApplicationsFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_APPLICATIONS_FAILED, { error }),

    setScoreForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    setScoreForRecruiteeInRecruitmentFinished: (
        recruiteeId: string,
        recruitmentId: string,
        score: number,
        comment: string
    ) =>
        createAction(RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED, {
            recruiteeId,
            recruitmentId,
            score,
            comment
        }),
    setScoreForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.SET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, { error }),

    getAllScoresSummaryForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    getAllScoresSummaryForRecruiteeInRecruitmentFinished: (response: GetRecruiteeAllScoresSummaryFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED, {
            response
        }),
    getAllScoresSummaryForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.GET_ALL_SCORES_SUMMARY_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, { error }),

    getScoreForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    getScoreForRecruiteeInRecruitmentFinished: (response: GetScoreForRecruiteeInRecruitmentFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED, {
            response
        }),
    getScoreForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.GET_SCORE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, { error }),

    logPhoneCallForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    logPhoneCallForRecruiteeInRecruitmentFinished: () =>
        createAction(RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED),
    logPhoneCallForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.LOG_PHONE_CALL_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, { error }),

    addNoteForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    addNoteForRecruiteeInRecruitmentFinished: () =>
        createAction(RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED),
    addNoteForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.ADD_NOTE_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, { error }),

    scheduleMeetingStarted: () => createAction(RecruiteesActionTypes.SCHEDULE_MEETING_STARTED),
    scheduleMeetingFinished: () => createAction(RecruiteesActionTypes.SCHEDULE_MEETING_FINISHED),
    scheduleMeetingFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.SCHEDULE_MEETING_FAILED, { error }),

    getRecruiteeInRecruitmentActivitiesStarted: () =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_STARTED),
    getRecruiteeInRecruitmentActivitiesFinished: (response: GetRecruiteeInRecruitmentActivitiesFinishedParams) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FINISHED, {
            response
        }),
    getRecruiteeInRecruitmentActivitiesFailed: (error: ErrorState) =>
        createAction(RecruiteesActionTypes.GET_RECRUITEE_IN_RECRUITMENT_ACTIVITIES_FAILED, { error })
};

interface GetRecruiteesFinishedParams {
    ids: string[];
    recruitees: Dictionary<RecruiteeItem>;
    pageNumber: number;
    totalCount: number;
}

interface GetRecruiteesInRecruitmentFinishedParams {
    ids: string[];
    recruitees: Dictionary<RecruiteeInRecruitmentItem>;
    pageNumber: number;
    totalCount: number;
}

interface GetRecruiteeApplicationsFinishedParams {
    ids: string[];
    applications: Dictionary<RecruiteeApplicationItem>;
    pageNumber: number;
    totalCount: number;
}

interface GetRecruiteeAllScoresSummaryFinishedParams {
    scores: RecruiteeAllScoresSummaryItem[];
}

interface GetScoreForRecruiteeInRecruitmentFinishedParams {
    recruiteeId: string;
    scoreSetByMe: number;
    averageScore: number;
    reviewCount: number;
}

interface GetRecruiteeInRecruitmentActivitiesFinishedParams {
    ids: string[];
    items: Dictionary<Activity>;
    pageNumber: number;
    totalCount: number;
}

export type RecruiteesAction = ActionsUnion<typeof RecruiteesActions>;
