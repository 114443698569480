import { TextInput } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnairesActions } from '../../../api/questionnaires/questionnaires.actions';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { State } from '../../../store/state';

interface StateProps {
    searchQuery: string;
}

interface DispatchProps {
    fetchItems: (page?: number, pageSize?: number) => void;
    updateFilters: (searchQuery?: string) => void;
}

class QuestionnairesFilters extends Component<StateProps & DispatchProps & WithTranslation> {
    render() {
        const { t } = this.props;

        return (
            <div className='row m-b-md'>
                <div className='col-6'>
                    <form onSubmit={this.onSearchSubmit}>
                        <div className='input-group'>
                            <TextInput
                                placeholder={t('questionnaires.search')}
                                value={this.props.searchQuery}
                                onChange={this.onSearchChange}
                            />
                            <span className='input-group-append'>
                                <button type='submit' className='btn btn-default input-group-addon text-default'>
                                    <i className='fa fa-search text-navy' />
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    private onSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.fetchItems();
    };

    private onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateFilters(e.currentTarget.value);
    };
}

const mapStateToProps = (state: State) => ({
    isLoading: state.questionnaires.questionnaires.isLoading,
    error: state.questionnaires.questionnaires.error,
    searchQuery: state.questionnaires.questionnaires.filters.searchQuery || ''
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    fetchItems: (page?: number, pageSize?: number) => dispatch(QuestionnairesThunks.getQuestionnaires(page, pageSize)),
    updateFilters: (searchQuery?: string) => dispatch(QuestionnairesActions.setQuestionnairesFilters({ searchQuery }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuestionnairesFilters));
