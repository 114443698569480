import { InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import {
    RecruitmentRecruiteeStageEditViewDto,
    RecruitmentRecruiteeStageLocalizationEditViewDto,
    StageType,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import {
    RecruitmentRecruiteeStageEditViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import {
    RecruitmentRecruiteeStagesThunks,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { StageType as StageTypeEntity } from '../../../../api/settings/settings.state';
import { getCurrentLangId } from '../../../../i18n';
import { State } from '../../../../store/state';
import { TOOLTIP } from '../../../../tooltips';
import DragHandle from '../../../common/DragHandle';
import { isStageTypeEditable } from '../_utils';
import StageEditor, { FormValues } from './edit/StageEditor';
import styles from './RecruitmentRecruiteeStagesList.module.scss';
import RecruitmentRecruiteeStageTypeIcon from './RecruitmentRecruiteeStageTypeIcon';

export type OwnProps = RecruitmentRecruiteeStageEditViewDto & {
    index: number;
};

interface StateProps {
    stageTypeEntity: StageTypeEntity;
}

interface DispatchProps {
    doUpdateItem: (data: RecruitmentRecruiteeStageEditViewDtoNormalized) => Promise<ThunkActionResult>;
    doReloadItems: () => void;
    doDeleteItem: () => Promise<ThunkActionResult>;
}

type RecruitmentRecruiteeStagesListItemProps = StateProps & DispatchProps & OwnProps & WithTranslation;

interface RecruitmentRecruiteeStagesListItemState {
    isDeleteModalOpen: boolean;
    isEditing: boolean;
    isSubmitting: boolean;
}

class RecruitmentRecruiteeStagesListItem extends Component<
    RecruitmentRecruiteeStagesListItemProps,
    RecruitmentRecruiteeStagesListItemState
    > {
    state: RecruitmentRecruiteeStagesListItemState = {
        isDeleteModalOpen: false,
        isEditing: false,
        isSubmitting: false
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const { t, stageTypeEntity } = this.props;
        const stageName = this.getName();
        const isEditable = isStageTypeEditable(this.props.stageType);

        return (
            <Draggable
                type={`${stageTypeEntity.id}`}
                draggableId={this.props.id}
                index={this.props.index}
                isDragDisabled={!isStageTypeEditable(stageTypeEntity.id)}>
                {(provided, snapshot) => (
                    <>
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames(
                                styles.row,
                                snapshot.isDragging && styles.dragging,
                                isEditable && styles.editable,
                                this.state.isEditing && styles.editing
                            )}>
                            {isEditable && <DragHandle className={styles.dragHandle} />}
                            <div className={classNames(styles.col, styles.colStageType)}>
                                {stageTypeEntity && (
                                    <RecruitmentRecruiteeStageTypeIcon type={stageTypeEntity.id as StageType} />
                                )}
                            </div>
                            <div className={classNames(styles.col, styles.colName)}>
                                {!this.state.isEditing && <span className={styles.stageName}>{stageName}</span>}
                                {this.state.isEditing && this.renderEditor()}
                            </div>
                            <div className={classNames(styles.col, styles.colActions)}>
                                {!this.state.isEditing && stageTypeEntity && isStageTypeEditable(stageTypeEntity.id) && (
                                    <>
                                        <button
                                            className={classNames('btn btn-default', styles.toolsButton)}
                                            onClick={this.onEditStage}
                                            data-tip={t('general.button.edit')}
                                            data-for={TOOLTIP.TOP}>
                                            <i className='fa fa-pencil-square-o' />
                                        </button>
                                        <button
                                            className={classNames('btn btn-default', styles.toolsButton)}
                                            onClick={this.onDeleteStage}
                                            data-tip={t('general.button.delete')}
                                            data-for={TOOLTIP.TOP}>
                                            <i className='fa fa-trash-o' />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        <InModal
                            isOpen={this.state.isDeleteModalOpen}
                            onClose={this.onCloseModal}
                            className='animated a500 fadeInDown'
                            backdropClassNames='animated a500 backdropFadeIn'>
                            <InModalHeader titleText={t('recruitmentRecruiteeStages.deleteItemTitle')} />
                            <InModalBody>
                                <span>
                                    <Trans
                                        i18nKey='recruitmentRecruiteeStages.deleteItemMessage'
                                        values={{ stageName }}>
                                        <strong>{{ stageName }}</strong>
                                    </Trans>
                                </span>
                            </InModalBody>
                            <InModalFooter>
                                <button
                                    type='button'
                                    className='btn btn-default font-bold btn-w-m'
                                    onClick={this.onCloseModal}>
                                    {t('general.button.cancel')}
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-danger font-bold btn-w-m'
                                    onClick={this.onDeleteStageConfirm}>
                                    {t('general.button.delete')}
                                </button>
                            </InModalFooter>
                        </InModal>
                    </>
                )}
            </Draggable>
        );
    }

    private renderEditor() {
        const initialValues = {
            title: this.getName()
        };

        return (
            <StageEditor
                onSubmit={this.onEditStageSubmit}
                onEditCancel={this.onEditStageCancel}
                initialValues={initialValues}
                isSubmitting={this.state.isSubmitting}
            />
        );
    }

    private getName(): string {
        const localization = this.props.localizations.find(this.findLocalizationForCurrentLang);
        return localization ? localization.stageName || '' : '';
    }

    private findLocalizationForCurrentLang(localizationItem: RecruitmentRecruiteeStageLocalizationEditViewDto) {
        return localizationItem.language === getCurrentLangId();
    }

    private onEditStage = () => {
        this.setState({
            isEditing: true
        });
    };

    private onEditStageSubmit = async (values: FormValues) => {
        const { t } = this.props;
        const localizations: RecruitmentRecruiteeStageLocalizationEditViewDto[] = [...this.props.localizations];
        localizations.find(this.findLocalizationForCurrentLang)!.stageName = values.title;

        this.setState({
            isSubmitting: true
        });

        const results = await this.props.doUpdateItem({
            id: this.props.id,
            stageType: this.props.stageType,
            order: this.props.order,
            localizations: localizations
        });

        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentRecruiteeStages.success.editMessage'), t('general.success'));
        } else {
            toastr.error(t('recruitmentRecruiteeStages.error.editMessage'), t('general.error'));
        }
        this.setState({
            isSubmitting: false,
            isEditing: false
        });
    };

    private onEditStageCancel = () => {
        this.setState({
            isEditing: false
        });
    };

    private onDeleteStage = () => {
        this.setState({
            isDeleteModalOpen: true
        });
    };

    private onCloseModal = () => {
        this.setState({
            isDeleteModalOpen: false
        });
    };

    private onDeleteStageConfirm = async () => {
        const { t, doDeleteItem, doReloadItems } = this.props;

        const results = await doDeleteItem();
        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentRecruiteeStages.success.deletedMessage'), t('general.success'));
        } else {
            doReloadItems();
            toastr.error(t('recruitmentRecruiteeStages.error.deleted.Message'), t('general.error'));
        }
    };
}

const mapStateToProps = (state: State, props: OwnProps) => ({
    stageTypeEntity: state.settings.stageTypes.items[props.stageType]
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps) => ({
    doUpdateItem: (data: RecruitmentRecruiteeStageEditViewDtoNormalized) =>
        dispatch(RecruitmentRecruiteeStagesThunks.updateStageEdit(data)),
    doReloadItems: () => dispatch(RecruitmentRecruiteeStagesThunks.getStageEdits()),
    doDeleteItem: () => dispatch(RecruitmentRecruiteeStagesThunks.deleteStageEdit(props.id))
});

export interface DraggedItemSourceData {
    itemId: string;
    currentIdx: number;
    stageType: StageType;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentRecruiteeStagesListItem));
