import { Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruiteeInRecruitmentItem } from '../../../api/recruitees/recruitees.state';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import styles from './RecruiteesInRecruitmentList.module.scss';
import RecruiteesInRecruitmentListItem from './RecruiteesInRecruitmentListItem';

interface OwnProps {
    recruitmentId: string;
}

interface StateProps {
    items: RecruiteeInRecruitmentItem[];
    isLoading: boolean;
    page: number;
    totalCount: number;
}

interface DispatchProps {
    fetchItems: (pageNumber: number) => void;
}

class RecruiteesInRecruitmentList extends Component<OwnProps & StateProps & DispatchProps> {
    render() {
        const rows = this.props.items.map(this.renderRow);

        return (
            <div className='ibox'>
                <div className='ibox-content p-0'>
                    <InfiniteScroll
                        className='project-list gray-bg'
                        dataLength={this.props.items.length}
                        next={this.loadNextPage}
                        hasMore={this.props.items.length < this.props.totalCount}
                        loader={<Preloader />}>
                        <table className={classNames('table table-hover bg-white', styles.table)}>
                            <tbody>{rows}</tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }

    private renderRow = (recruitee: RecruiteeInRecruitmentItem) => {
        return (
            <RecruiteesInRecruitmentListItem
                key={recruitee.id}
                {...recruitee}
                recruitmentId={this.props.recruitmentId}
            />
        );
    };

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper(state.recruitees.recruiteesInRecruitment.list, state.recruitees.recruiteesInRecruitment.items),
    isLoading: state.recruitees.recruiteesInRecruitment.isLoading,
    page: state.recruitees.recruiteesInRecruitment.page,
    totalCount: state.recruitees.recruiteesInRecruitment.totalCount
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    fetchItems: (pageNumber: number) =>
        dispatch(RecruiteesThunks.getRecruiteesInRecruitment(props.recruitmentId, pageNumber, PAGE_SIZE_DEFAULT))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruiteesInRecruitmentList);
