import { ListStateWithLoadingState, WritingState } from '../_commons/state.common';
import { RecruitmentPositionForEditViewDto, GetRecruitmentPositionsListQueryResult } from './recruitmentPositions.dto';

export const initialState: RecruitmentPositionsState = {
    recruitmentPositionEdits: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        isWriting: false,
        error: null
    },
    recruitmentPositions: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    }
};

export interface RecruitmentPositionsState {
    recruitmentPositions: ListStateWithLoadingState<string, GetRecruitmentPositionsListQueryResult>;
    recruitmentPositionEdits: ListStateWithLoadingState<string, RecruitmentPositionForEditViewDto> & WritingState;
}
