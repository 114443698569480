import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { QuestionnaireElementType } from '../../../api/questionnaires/questionnaires.dto';
import { TOOLTIP } from '../../../tooltips';
import { isComponentTypeDeletable, isComponentTypeEditable, isComponentTypeUnique } from './_util';
import styles from './QuestionnaireElementEditor.module.scss';

interface Props {
    type: QuestionnaireElementType;
    onEdit: () => void;
    onDuplicate: () => void;
    onDelete: () => void;
}

export default (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <div className={styles.fieldTools}>
            {!isComponentTypeUnique(props.type) && (
                <button
                    className={classNames('btn btn-default', styles.toolButton)}
                    onClick={props.onDuplicate}
                    data-tip={t('questionnaires.questionnaireForm.questionEditor.tools.clone')}
                    data-for={TOOLTIP.TOP}>
                    <i className='fa fa-files-o'></i>
                </button>
            )}
            {isComponentTypeEditable(props.type) && (
                <button
                    className={classNames('btn btn-default', styles.toolButton)}
                    onClick={props.onEdit}
                    data-tip={t('questionnaires.questionnaireForm.questionEditor.tools.edit')}
                    data-for={TOOLTIP.TOP}>
                    <i className='fa fa-pencil-square-o'></i>
                </button>
            )}
            {isComponentTypeDeletable(props.type) && (
                <button
                    className={classNames('btn btn-default', styles.toolButton)}
                    onClick={props.onDelete}
                    data-tip={t('questionnaires.questionnaireForm.questionEditor.tools.delete')}
                    data-for={TOOLTIP.TOP}>
                    <i className='fa fa-trash-o'></i>
                </button>
            )}
        </div>
    );
};
