import { LabelFieldHorizontal } from '@emplo/react-inspinia';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnswerableQuestionnaireElement, QuestionnaireSummary } from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementPreviewProps } from '../QuestionnaireElement';

// PREVIEW

export const QuestionnaireSummaryPreview = (props: QuestionnaireElementPreviewProps<QuestionnaireSummary>) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                t('questionnaires.questionnaireForm.question.summary.label') +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <textarea className='form-control' />
        </LabelFieldHorizontal>
    );
};
