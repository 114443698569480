import { Hr } from '@emplo/react-inspinia';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { endpoints } from '../../../../api/endpoints';
import {
    QuestionnaireAnswer,
    QuestionnaireAttachmentAnswer,
    QuestionnaireCoverLetterAnswer,
    QuestionnaireEducationAnswer,
    QuestionnaireElement,
    QuestionnaireElementType,
    QuestionnaireExperienceAnswer,
    QuestionnaireMultiLineTextAnswer,
    QuestionnaireMultipleChoiceAnswer,
    QuestionnairePhotoAnswer,
    QuestionnaireResumeAnswer,
    QuestionnaireSingleChoiceAnswer,
    QuestionnaireSingleLineTextAnswer,
    QuestionnaireSummaryAnswer,
    QuestionQuestionnaireElementType,
} from '../../../../api/questionnaires/questionnaires.dto';
import { nl2br } from '../../../../utils/format';
import ApplicationFormAnswerEducationItem from './ApplicationFormAnswerEducationItem';
import ApplicationFormAnswerExperienceItem from './ApplicationFormAnswerExperienceItem';
import ApplicationFormAnswerUploadedFile from './ApplicationFormAnswerUploadedFile';
import styles from './ApplicationFormPane.module.scss';

export const Step = (props: { step: number }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.step, 'font-bold mt-4')}>
            <div className={styles.stepLine} />
            <div className={styles.stepLabel}>{t('recruiteesForm.step', { step: props.step })}</div>
            <div className={styles.stepLine} />
        </div>
    );
};

export const Section = () => <Hr type='solid' />;

export const Header = (props: { text: string }) => <h3 className='mt-5'>{props.text}</h3>;

export const Text = (props: { text: string }) => <p className='mt-0'>{props.text}</p>;

export const PropertyElement = (props: { label: React.ReactNode; value: React.ReactNode }) => (
    <div className='mt-4'>
        <div className='font-bold'>{props.label}</div>
        <div className='mt-2'>{props.value}</div>
    </div>
);

export interface ApplicationFormQuestionnaireElement {
    element: QuestionnaireElement;
    answer?: QuestionnaireAnswer;
}

export const getAnswer = ($type: QuestionnaireElementType, answer?: QuestionnaireAnswer): string => {
    switch ($type) {
        case QuestionQuestionnaireElementType.QuestionnaireSingleLineText:
            return (answer as QuestionnaireSingleLineTextAnswer).value || '';
        default:
            return '';
    }
};

export const renderQuestionnaireAnswerElement = (t: TFunction) => (
    jobApplicationId: string,
    element: ApplicationFormQuestionnaireElement,
    label?: string
) => {
    switch (element.element.$type) {
        case QuestionQuestionnaireElementType.QuestionnaireHeader:
            return <Header text={element.element.text || ''} key={element.element.id} />;
        case QuestionQuestionnaireElementType.QuestionnaireTextParagraph:
            return <Text text={(element.element.text as string) || ''} key={element.element.id} />;

        case QuestionQuestionnaireElementType.QuestionnaireAttachment:
        case QuestionQuestionnaireElementType.QuestionnaireCoverLetter:
        case QuestionQuestionnaireElementType.QuestionnairePhoto:
        case QuestionQuestionnaireElementType.QuestionnaireResume: {
            const fileId =
                element.answer &&
                (element.answer as
                    | QuestionnaireAttachmentAnswer
                    | QuestionnaireCoverLetterAnswer
                    | QuestionnairePhotoAnswer
                    | QuestionnaireResumeAnswer).fileIdentifier;
            const fileLink = fileId ? endpoints.getJobApplicationFileEndpoint(jobApplicationId, fileId) : '';

            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={
                        <ApplicationFormAnswerUploadedFile url={fileLink}>
                            {t('general.button.downloadFile')}
                        </ApplicationFormAnswerUploadedFile>
                    }
                />
            );
        }
        case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
            const availableOptions = element.element.options;
            const optionsValues: number[] = element.answer
                ? (element.answer as QuestionnaireMultipleChoiceAnswer).chosenOptions
                : [];
            const optionsText = optionsValues.reduce((textResult, selectedOption, idx) => {
                return textResult + (idx > 0 ? ', ' : '') + availableOptions[selectedOption];
            }, '');

            return <PropertyElement key={element.element.id} label={label} value={optionsText || '-'} />;
        case QuestionQuestionnaireElementType.QuestionnaireMultiLineText:
            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={element.answer && nl2br((element.answer as QuestionnaireMultiLineTextAnswer).value || '')}
                />
            );
        case QuestionQuestionnaireElementType.QuestionnaireSingleChoice: {
            const availableOptions = element.element.options;
            const chosenOption = element.answer && (element.answer as QuestionnaireSingleChoiceAnswer).chosenOption;
            const chosenOptionText = chosenOption ? availableOptions[chosenOption] : '-';

            return <PropertyElement key={element.element.id} label={label} value={chosenOptionText} />;
        }
        case QuestionQuestionnaireElementType.QuestionnaireSummary: {
            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={nl2br((element.answer && (element.answer as QuestionnaireSummaryAnswer).value) || '')}
                />
            );
        }
        case QuestionQuestionnaireElementType.QuestionnaireEducation: {
            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={(element.answer as QuestionnaireEducationAnswer).values.map((entry, idx) => (
                        <React.Fragment key={idx}>
                            <ApplicationFormAnswerEducationItem data={entry} />
                            <Hr type='solid' />
                        </React.Fragment>
                    ))}
                />
            );
        }
        case QuestionQuestionnaireElementType.QuestionnaireExperience: {
            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={(element.answer as QuestionnaireExperienceAnswer).values.map((entry, idx) => (
                        <React.Fragment key={idx}>
                            <ApplicationFormAnswerExperienceItem data={entry} />
                            <Hr type='solid' />
                        </React.Fragment>
                    ))}
                />
            );
        }
        default:
            return (
                <PropertyElement
                    key={element.element.id}
                    label={label}
                    value={element.answer && (element.answer as QuestionnaireSingleLineTextAnswer).value}
                />
            );
    }
};
