import { Preloader } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruitmentPositionForEditViewDto } from '../../../../api/recruitmentPositions/recruitmentPositions.dto';
import { RecruitmentPositionsThunks } from '../../../../api/recruitmentPositions/recruitmentPositions.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../../constants';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import styles from './RecruitmentPositionsList.module.scss';
import RecruitmentPositionsListItem from './RecruitmentPositionsListItem';

interface OwnProps {
    doDeleteItem: (id: string) => void;
}

interface StateProps {
    items: RecruitmentPositionForEditViewDto[];
    isLoading: boolean;
    totalCount: number;
    page: number;
}

interface DispatchProps {
    fetchItems: (page: number) => void;
}

class RecruitmentPositionsList extends Component<OwnProps & StateProps & DispatchProps> {
    render() {
        if (this.props.page === 0 && this.props.isLoading) {
            return <Preloader />;
        }

        return (
            <div className={styles.listContainer}>
                <InfiniteScroll
                    className='project-list gray-bg'
                    dataLength={this.props.items.length}
                    next={this.loadNextPage}
                    hasMore={this.props.items.length < this.props.totalCount}
                    loader={<Preloader />}>
                    <table className='table table-hover bg-white mb-0'>
                        <tbody>{this.props.items.map(this.renderItem)}</tbody>
                    </table>
                </InfiniteScroll>
            </div>
        );
    }

    private renderItem = (item: RecruitmentPositionForEditViewDto) => {
        return <RecruitmentPositionsListItem {...item} key={item.id} doDeleteItem={this.props.doDeleteItem} />;
    };

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper(
        state.recruitmentPositions.recruitmentPositionEdits.list,
        state.recruitmentPositions.recruitmentPositionEdits.items
    ),
    isLoading: state.recruitmentPositions.recruitmentPositionEdits.isLoading,
    totalCount: state.recruitmentPositions.recruitmentPositionEdits.totalCount,
    page: state.recruitmentPositions.recruitmentPositionEdits.page
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number) =>
        dispatch(RecruitmentPositionsThunks.getRecruitmentPositionsEdit(page, PAGE_SIZE_DEFAULT))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentPositionsList);
