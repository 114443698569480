import React, { Component } from 'react';
import ReactSelect from 'react-select';

import { ErrorState } from '../../../../api/_commons/state.common';
import { endpoints } from '../../../../api/endpoints';
import PersonInfoBlock from '../../../common/PersonInfoBlock';
import { ClearIndicator, DropdownIndicator, reactSelectStyles } from '../../../common/ReactSelect';

interface Props<T> {
    name: string;
    value?: T;
    isLoading: boolean;
    error: ErrorState;
    items: T[];
    isClearable?: boolean;
    fetchItems: (page?: number, pageSize?: number) => void;
    setFieldValue: (name: string, value: T) => void;
    setFieldTouched: (name: string, touched: boolean) => void;
}

export default class RecruiterSelect<T> extends Component<Props<T>> {
    render() {
        return (
            <ReactSelect
                name={this.props.name}
                isLoading={this.props.isLoading}
                options={this.props.items}
                value={this.props.value}
                onChange={this.onChange}
                formatOptionLabel={this.formatOptionLabel}
                placeholder=''
                isSearchable={false}
                onBlur={this.onBlur}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    ClearIndicator
                }}
                isClearable={this.props.isClearable}
                styles={reactSelectStyles}
                menuPlacement='auto'
            />
        );
    }

    private formatOptionLabel = (option: any) => {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(option.id);

        return (
            <PersonInfoBlock
                firstName={option.firstName}
                lastName={option.lastName}
                photoUrl={photoUrl}
                size='small'
                className='mb-0'
            />
        );
    };

    private onChange = (value: any) => {
        this.props.setFieldValue(this.props.name, value);
    };

    private onBlur = () => {
        this.props.setFieldTouched(this.props.name, true);
    };
}
