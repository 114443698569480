import { ActionsUnion, createAction } from '../../_commons/actions.common';
import { QuestionnaireBuilderElement } from './builder.state';

export enum QuestionnaireBuilderActionTypes {
    LOAD_QUESTIONNAIRE_STRUCTURE = 'QUESTIONNAIRE_BUILDER_LOAD_QUESTIONNAIRE_STRUCTURE',
    ADD_ELEMENT = 'QUESTIONNAIRE_BUILDER_ADD_ELEMENT',
    REMOVE_ELEMENT = 'QUESTIONNAIRE_BUILDER_REMOVE_ELEMENT',
    MOVE_ELEMENT = 'QUESTIONNAIRE_BUILDER_MOVE_ELEMENT',
    UPDATE_ELEMENT = 'QUESTIONNAIRE_BUILDER_UPDATE_ELEMENT',
    EDIT_ELEMENT_START = 'QUESTIONNAIRE_BUILDER_EDIT_ELEMENT_START',
    EDIT_ELEMENT_END = 'QUESTIONNAIRE_BUILDER_EDIT_ELEMENT_END'
}

export const QuestionnaireBuilderActions = {
    loadQuestionnaireStructure: (elements: QuestionnaireBuilderElement[]) =>
        createAction(QuestionnaireBuilderActionTypes.LOAD_QUESTIONNAIRE_STRUCTURE, { elements }),
    addElement: (element: QuestionnaireBuilderElement, index?: number) =>
        createAction(QuestionnaireBuilderActionTypes.ADD_ELEMENT, { element, index }),
    removeElement: (element: QuestionnaireBuilderElement) =>
        createAction(QuestionnaireBuilderActionTypes.REMOVE_ELEMENT, { element }),
    moveElement: (element: QuestionnaireBuilderElement, newIndex: number) =>
        createAction(QuestionnaireBuilderActionTypes.MOVE_ELEMENT, { element, newIndex }),
    updateElement: (formData: QuestionnaireBuilderElement) =>
        createAction(QuestionnaireBuilderActionTypes.UPDATE_ELEMENT, { formData }),
    editElementStart: (elementId: string) =>
        createAction(QuestionnaireBuilderActionTypes.EDIT_ELEMENT_START, { elementId }),
    editElementEnd: (elementId: string) => createAction(QuestionnaireBuilderActionTypes.EDIT_ELEMENT_END, { elementId })
};

export type QuestionnaireBuilderAction = ActionsUnion<typeof QuestionnaireBuilderActions>;
