import { ErrorContext, Hr, LabelFieldHorizontal, SpinnerCircle, TextInput } from '@emplo/react-inspinia';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object as yupObject, string as yupString } from 'yup';

enum FormFieldName {
    positionName = 'positionName'
}

export interface FormValues {
    [FormFieldName.positionName]: string;
}

const formSchema = yupObject().shape({
    [FormFieldName.positionName]: yupString().required()
});

interface FormProps {
    onSubmit: (values: FormValues) => void;
    onEditCancel: () => void;
    isSubmitting: boolean;
    initialValues: FormValues;
}

export default (props: FormProps) => {
    const { t } = useTranslation();

    return (
        <Formik
            onSubmit={props.onSubmit}
            validationSchema={formSchema}
            initialValues={props.initialValues}
            render={(formikProps: FormikProps<FormValues>) => {
                const { values, errors, isValid, touched, handleBlur, handleChange, handleSubmit } = formikProps;

                return (
                    <ErrorContext.Provider value={{ errors, touched }}>
                        <form onSubmit={handleSubmit}>
                            <LabelFieldHorizontal for={FormFieldName.positionName} label={t('positionNames.name')}>
                                <TextInput
                                    name={FormFieldName.positionName}
                                    value={values.positionName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </LabelFieldHorizontal>

                            <Hr type='solid' />

                            <div className='row justify-content-between'>
                                <div className='col-auto'></div>
                                <div className='col-auto d-flex justify-content-end'>
                                    <button
                                        className='btn btn-w-m btn-default font-bold'
                                        onClick={props.onEditCancel}
                                        disabled={props.isSubmitting}>
                                        {t('general.button.cancel')}
                                    </button>
                                    <button
                                        className='btn btn-w-m m-l-md btn-primary font-bold d-flex justify-content-center align-items-center'
                                        type='submit'
                                        disabled={!isValid || props.isSubmitting}>
                                        {props.isSubmitting && (
                                            <SpinnerCircle spinnerSize='small' inline className='mt-0 ml-0 mb-0 mr-2' />
                                        )}
                                        {t('general.button.save')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ErrorContext.Provider>
                );
            }}
        />
    );
};
