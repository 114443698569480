import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import toastr from 'toastr';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../api/questionnaires/builder/builder.actions';
import {
    convertApiQuestionnaireStructureToBuilderStructure,
    convertQuestionnaireElementToBuilderElement,
    questionnaireFormValuesToUpdateQuestionnairePostParams,
} from '../../../api/questionnaires/builder/builder.dto';
import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import {
    QuestionnaireStatus,
    QuestionnaireViewDto,
    UpdateQuestionnaireCommand,
} from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import ErrorPage from '../../pages/ErrorPage';
import QuestionnaireForm, { FormValues } from '../QuestionnaireForm';

interface EditQuestionnaireProps {
    questionnaire?: QuestionnaireViewDto;
    isLoading: boolean;
    error: ErrorState;
}

interface DispatchProps {
    loadQuestionnaireStructure: (elements: QuestionnaireBuilderElement[]) => void;
    doUpdateQuestionnaire: (id: string, questionnaire: UpdateQuestionnaireCommand) => Promise<ThunkActionResult>;
    fetchQuestionnaire: (id: string) => Promise<void>;
}

interface EditQuestionnaireRouteProps {
    /**
     * Questionnaire `id` in the route (/questionnaires/:id).
     */
    id: string;
}

class EditQuestionnaire extends Component<
    WithTranslation & RouteComponentProps<EditQuestionnaireRouteProps> & EditQuestionnaireProps & DispatchProps
    > {
    async componentDidMount() {
        await this.props.fetchQuestionnaire(this.getQuestionnaireIdFromRoute());

        if (this.props.questionnaire) {
            this.props.loadQuestionnaireStructure(
                convertApiQuestionnaireStructureToBuilderStructure(this.props.questionnaire.structure.elements)
            );
        }
    }

    render() {
        const { t, questionnaire, isLoading, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        const questionnaireFormInitialValues = questionnaire && {
            ...questionnaire,
            questionnaireName: questionnaire.name || '',
            isDraft: questionnaire.status === QuestionnaireStatus.DRAFT,
            elements: questionnaire.structure.elements.map(convertQuestionnaireElementToBuilderElement)
        };

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('general.mainMenu.questionnaires')}
                breadcrumbs={
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.questionnairesList()}>{t('general.mainMenu.questionnaires')}</Link>
                        </li>
                        <li className='breadcrumb-item active'>
                            <strong>
                                {t('questionnairesEdit.title')}
                                {questionnaire && `: ${questionnaire.name}`}
                            </strong>
                        </li>
                    </ol>
                }>
                {isLoading && <Preloader />}

                {!isLoading && questionnaireFormInitialValues && (
                    <QuestionnaireForm
                        initialValues={questionnaireFormInitialValues}
                        onSubmit={this.onSubmit}
                        prefixes={this.getPrefixes()}
                        questionnaire={this.props.questionnaire}
                    />
                )}
            </SidebarLayoutFixedContent>
        );
    }

    private getPrefixes() {
        // TODO: from API or hardcoded enum?
        return ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'prefer not to say'];
    }

    private getQuestionnaireIdFromRoute() {
        return this.props.match.params.id;
    }

    private onSubmit = async (values: FormValues) => {
        const { t, match } = this.props;
        const questionnaireData = questionnaireFormValuesToUpdateQuestionnairePostParams(match.params.id, values);
        const questionnaireId = match.params.id;

        const results = await this.props.doUpdateQuestionnaire(questionnaireId, questionnaireData);
        if (results.httpStatus < 300) {
            toastr.success(t('questionnairesEdit.success.message'), t('questionnairesEdit.success.title'));
            this.props.history.push(RoutePath.questionnairesList());
        } else {
            toastr.error(t('questionnairesEdit.error.message'), t('questionnairesEdit.error.title'));
        }
    };
}

const mapStateToProps = (state: State, props: RouteComponentProps<EditQuestionnaireRouteProps>) => ({
    questionnaire: state.questionnaires.questionnaireDetails.questionnaire,
    isLoading: state.questionnaires.questionnaireDetails.isLoading,
    error: state.questionnaires.questionnaireDetails.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchQuestionnaire: (id: string) => dispatch(QuestionnairesThunks.getQuestionnaire(id)),
    doUpdateQuestionnaire: (id: string, questionnaireData: UpdateQuestionnaireCommand) =>
        dispatch(QuestionnairesThunks.updateQuestionnaire(id, questionnaireData)),
    loadQuestionnaireStructure: (elements: QuestionnaireBuilderElement[]) =>
        dispatch(QuestionnaireBuilderActions.loadQuestionnaireStructure(elements))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditQuestionnaire)));
