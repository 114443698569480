import { Checkbox, Hr, LabelFieldHorizontal, Select, TextInput } from '@emplo/react-inspinia';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionnaireExperience } from '../../../../api/questionnaires/questionnaires.dto';
import { QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import styles from './QuestionnaireExperience.module.scss';

// PREVIEW

export const QuestionnaireExperiencePreview = (props: QuestionnaireElementPreviewProps<QuestionnaireExperience>) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.experience.label')}>
            <ExperiencePreview />
        </LabelFieldHorizontal>
    );
};

const ExperiencePreview = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.experience.titleLabel')}>
                <TextInput />
            </LabelFieldHorizontal>
            <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.experience.companyLabel')}>
                <TextInput />
            </LabelFieldHorizontal>
            <LabelFieldHorizontal
                for=''
                label={t('questionnaires.questionnaireForm.question.experience.locationLabel')}>
                <TextInput
                    placeholder={t('questionnaires.questionnaireForm.question.experience.locationPlaceholder')}
                />
            </LabelFieldHorizontal>
            <div className='row'>
                <div className='col-6'>
                    <LabelFieldHorizontal
                        for=''
                        label={t('questionnaires.questionnaireForm.question.experience.startDateLabel')}>
                        <div className='row flex-fill'>
                            <div className='col-7'>
                                <Select>
                                    <option>
                                        {t('questionnaires.questionnaireForm.question.experience.dateMonth')}
                                    </option>
                                </Select>
                            </div>
                            <div className='col-5'>
                                <Select>
                                    <option>
                                        {t('questionnaires.questionnaireForm.question.experience.dateYear')}
                                    </option>
                                </Select>
                            </div>
                        </div>
                    </LabelFieldHorizontal>
                </div>
                <div className='col-6'>
                    <LabelFieldHorizontal
                        for=''
                        label={t('questionnaires.questionnaireForm.question.experience.endDateLabel')}>
                        <div className='row flex-fill'>
                            <div className='col-7'>
                                <Select>
                                    <option>
                                        {t('questionnaires.questionnaireForm.question.experience.dateMonth')}
                                    </option>
                                </Select>
                            </div>
                            <div className='col-5'>
                                <Select>
                                    <option>
                                        {t('questionnaires.questionnaireForm.question.experience.dateYear')}
                                    </option>
                                </Select>
                            </div>
                        </div>
                    </LabelFieldHorizontal>
                </div>
            </div>
            <LabelFieldHorizontal for=''>
                <Checkbox label={t('questionnaires.questionnaireForm.question.experience.currentlyWorkingLabel')} />
            </LabelFieldHorizontal>
            <LabelFieldHorizontal
                for=''
                label={t('questionnaires.questionnaireForm.question.experience.descriptionLabel')}>
                <textarea className='form-control' rows={4} />
            </LabelFieldHorizontal>
            <Hr type='solid' />
            <button type='button' className='btn text-navy btn-link'>
                + {t('questionnaires.questionnaireForm.question.experience.addNewButton')}
            </button>
        </div>
    );
};
