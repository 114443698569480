import { TFunction } from 'i18next';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import { Activity, JobApplicationSentActivityData } from '../../../../../api/recruitees/recruitees.dto';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import { ActivityItem } from './ActivityItem';

export default class JobApplicationSentEvent extends ActivityItem {
    constructor(
        protected itemData: Activity,
        protected activityData: JobApplicationSentActivityData,
        protected t: TFunction
    ) {
        super(itemData, activityData, t);
    }

    renderNode() {
        return (
            <div className='bg-info'>
                <i className='fa fa-file-text' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getRecruiteePhotoFileEndpoint(this.activityData.recruiteeId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.recruiteeFirstName}
                lastName={this.activityData.recruiteeLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        return this.t('recruiteeView.activities.applicationSent.title');
    }

    getContent() {
        return <div>{''}</div>;
    }
}
