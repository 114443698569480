import React from 'react';
import { useTranslation } from 'react-i18next';

import { RecruitmentAndRecruiteeAggregates } from '../../api/dashboard/dashboard.dto';

type Props = RecruitmentAndRecruiteeAggregates;

export default (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className='row'>
            <div className='col-3'>
                <strong>{t('dashboard.recruitmentsSummary.openRecruitments')}</strong>
                <div className='h2 mt-2 mb-0'>{props.openRecruitmentsCount}</div>
            </div>
            <div className='col-3'>
                <strong>{t('dashboard.recruitmentsSummary.totalApplicants')}</strong>
                <div className='h2 mt-2 mb-0'>{props.totalRecruiteesCount}</div>
            </div>
            <div className='col-3'>
                <strong>{t('dashboard.recruitmentsSummary.inProcess')}</strong>
                <div className='h2 mt-2 mb-0'>{props.inProcessCount}</div>
            </div>
            <div className='col-3'>
                <strong>{t('dashboard.recruitmentsSummary.hired')}</strong>
                <div className='h2 mt-2 mb-0'>{props.hiredCount}</div>
            </div>
        </div>
    );
};
