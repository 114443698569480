import { BaseQuestionnaireElement, QuestionnaireElementType } from '../../../api/questionnaires/questionnaires.dto';

/**
 * Interface which must be implemented by props type of all item editors.
 */
export interface QuestionnaireElementFormProps<
    ElementType extends BaseQuestionnaireElement = BaseQuestionnaireElement
> {
    itemData: ElementType;
    elementType: QuestionnaireElementType;
    index: number;
    exitEditing: () => void;
    save: (formData: ElementType) => void;
    handleTypeChange: (newType: QuestionnaireElementType) => void;
}

/**
 * Interface which must be implemented by props type of all item preview components.
 */
export interface QuestionnaireElementPreviewProps<
    ElementType extends BaseQuestionnaireElement = BaseQuestionnaireElement
> {
    itemData: ElementType;
}

export enum DraggableEditorType {
    DEFAULT = 'default'
}
