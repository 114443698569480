import { MainNavItem, MainNavItemLabel, Navigation, SidebarLayout } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { matchPath, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';

import { getRouteConfig, RoutePath, RoutePathEnum } from '../../routes';
import NavHeader from './navigation/NavHeader';

interface AppLayoutProps {
    background?: 'gray' | 'white';
}

class AppLayout extends Component<AppLayoutProps & RouteComponentProps<any> & WithTranslation> {
    render() {
        return (
            <SidebarLayout navigation={this.renderNavigation()} background={this.props.background} isFixed>
                {this.props.children}
            </SidebarLayout>
        );
    }

    /**
     * Checks given `navItemPath` if it matches the current `location.pathname`.
     * Check can be `exact` or not. Not-exact match is when only part of the path matches (used for sub-menu).
     */
    isNavItemActive = (navItemPath: string, matchExact: boolean): boolean => {
        const match = matchPath(this.props.location.pathname, navItemPath);
        if (match) {
            // if this item requires exact match - check if it's exactly that
            return !matchExact || match.isExact;
        }

        return false;
    };

    private renderNavigation() {
        const { t } = this.props;

        return (
            <Navigation location={window.location} className='fixed'>
                <NavHeader></NavHeader>
                <MainNavItem
                    path={RoutePath.dashboard()}
                    isActiveTester={this.isNavItemActive}
                    matchExactPath={getRouteConfig(RoutePathEnum.DASHBOARD)!.isExact}
                    link={this.renderNavLink(RoutePath.dashboard(), t('general.mainMenu.dashboard'), 'th-large')}
                />
                <MainNavItem
                    path={RoutePath.recruitmentsList()}
                    isActiveTester={this.isNavItemActive}
                    matchExactPath={getRouteConfig(RoutePathEnum.RECRUITMENTS_LIST)!.isExact}
                    link={this.renderNavLink(
                        RoutePath.recruitmentsList(),
                        t('general.mainMenu.recruitments'),
                        'search'
                    )}
                />
                <MainNavItem
                    path={RoutePath.recruiteesList()}
                    isActiveTester={this.isNavItemActive}
                    matchExactPath={getRouteConfig(RoutePathEnum.RECRUITEES_LIST)!.isExact}
                    link={this.renderNavLink(RoutePath.recruiteesList(), t('general.mainMenu.recruitees'), 'user')}
                />
                <MainNavItem
                    path={RoutePath.questionnairesList()}
                    isActiveTester={this.isNavItemActive}
                    matchExactPath={getRouteConfig(RoutePathEnum.QUESTIONNAIRES_LIST)!.isExact}
                    link={this.renderNavLink(
                        RoutePath.questionnairesList(),
                        t('general.mainMenu.questionnaires'),
                        'file-text'
                    )}
                />
                <MainNavItem
                    isActiveTester={this.isNavItemActive}
                    matchExactPath={getRouteConfig(RoutePathEnum.SETTINGS)!.isExact}
                    link={this.renderNavLink('#', t('general.mainMenu.settings'), 'cog', true)}>
                    <MainNavItem
                        path={RoutePath.settingsRecruitmentStagesList()}
                        isActiveTester={this.isNavItemActive}
                        matchExactPath={getRouteConfig(RoutePathEnum.SETTINGS_RECRUITMENT_STAGES_LIST)!.isExact}
                        link={this.renderNavLink(
                            RoutePath.settingsRecruitmentStagesList(),
                            t('general.mainMenu.recruitmentRecruiteeStages')
                        )}
                    />
                    <MainNavItem
                        path={RoutePath.settingsRecruitmentPositionsList()}
                        isActiveTester={this.isNavItemActive}
                        matchExactPath={getRouteConfig(RoutePathEnum.SETTINGS_RECRUITMENT_POSITIONS_LIST)!.isExact}
                        link={this.renderNavLink(
                            RoutePath.settingsRecruitmentPositionsList(),
                            t('general.mainMenu.recruitmentPositions')
                        )}
                    />
                    {/* <MainNavItem
                        path={RoutePath.SETTINGS_EMAIL_TEMPLATES_LIST}
                        isActiveTester={this.isNavItemActive}
                        matchExactPath={getRouteConfig(RoutePath.DASHBOARD)!.isExact}
                        link={this.renderNavLink(
                            RoutePath.SETTINGS_EMAIL_TEMPLATES_LIST,
                            t('general.mainMenu.emailTemplates')
                        )}
                    /> */}
                </MainNavItem>
            </Navigation>
        );
    }

    private renderNavLink(path: string, text: string, icon?: string, isExpandable: boolean = false) {
        return (
            <NavLink to={path}>
                <MainNavItemLabel title={text} icon={icon} />
                {isExpandable && <span className='fa arrow'></span>}
            </NavLink>
        );
    }
}

export default withTranslation()(AppLayout);
