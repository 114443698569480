import { StageType } from './api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';

export const COLORS = {
    source: {
        unknown: {
            transparent: 'rgba(163, 225, 212, 0.6)',
            solid: '#A3E1D4'
        },
        linkedIn: {
            transparent: 'rgba(38, 151, 207, 0.6)',
            solid: '#2697CF'
        },
        pracujPl: {
            transparent: 'rgba(24, 64, 123, 0.6)',
            solid: '#18407B'
        },
        other: {
            transparent: 'rgba(199, 199, 199, 0.6)',
            solid: '#C7C7C7'
        }
    },
    recruitmentStage: {
        [StageType.New]: {
            transparent: 'rgba(35, 198, 200, 0.6)',
            solid: '#23C6C8'
        },
        [StageType.Custom]: {
            transparent: 'rgba(248, 172, 89, 0.6)',
            solid: '#F8AC59'
        },
        [StageType.RejectedApplication]: {
            transparent: 'rgba(237, 85, 100, 0.6)',
            solid: '#ED5564'
        },
        [StageType.RejectedCustom]: {
            transparent: 'rgba(237, 85, 100, 0.6)',
            solid: '#ED5564'
        },
        [StageType.Hired]: {
            transparent: 'rgba(26, 179, 148, 0.6)',
            solid: '#1AB394'
        }
    }
};

// by default all paged lists should display this many items per page
export const PAGE_SIZE_DEFAULT = 20;
