import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { GetRecruitmentRecruiteeStageListForSetQueryResult } from './recruitmentRecruiteeStages.dto';
import {
    RecruitmentRecruiteeStageEditViewDtoNormalized,
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from './recruitmentRecruiteeStages.schema';

export enum RecruitmentRecruiteeStagesActionTypes {
    GET_RECRUITMENT_STAGES_STARTED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_STARTED',
    GET_RECRUITMENT_STAGES_FINISHED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_FINISHED',
    GET_RECRUITMENT_STAGES_FAILED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_FAILED',

    GET_RECRUITMENT_STAGE_EDITS_STARTED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGE_EDITS_STARTED',
    GET_RECRUITMENT_STAGE_EDITS_FINISHED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGE_EDITS_FINISHED',
    GET_RECRUITMENT_STAGE_EDITS_FAILED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGE_EDITS_FAILED',

    GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_STARTED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_STARTED',
    GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED',
    GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FAILED = 'RECRUITMENT_STAGES_GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FAILED',

    UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_STARTED = 'RECRUITMENT_STAGES_UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_STARTED',
    UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FINISHED = 'RECRUITMENT_STAGES_UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FINISHED',
    UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FAILED = 'RECRUITMENT_STAGES_UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FAILED',

    UPDATE_RECRUITMENT_STAGE_EDITS_STARTED = 'RECRUITMENT_STAGES_UPDATE_RECRUITMENT_STAGE_EDITS_STARTED',
    UPDATE_RECRUITMENT_STAGE_EDITS_FINISHED = 'RECRUITMENT_STAGES_UPDATE_RECRUITMENT_STAGE_EDITS_FINISHED',
    UPDATE_RECRUITMENT_STAGE_EDITS_FAILED = 'RECRUITMENT_STAGES_UPDATE_RECRUITMENT_STAGE_EDITS_FAILED',

    REORDER_RECRUITMENT_STAGE_EDITS = 'RECRUITMENT_STAGES_REORDER_RECRUITMENT_STAGE_EDITS',
    DELETE_RECRUITMENT_STAGE_EDIT = 'RECRUITMENT_STAGES_DELETE_RECRUITMENT_STAGE_EDIT',
    UPDATE_RECRUITMENT_STAGE_EDIT = 'RECRUITMENT_STAGES_UPDATE_RECRUITMENT_STAGE_EDIT',
    CREATE_RECRUITMENT_STAGE_EDIT = 'RECRUITMENT_STAGES_CREATE_RECRUITMENT_STAGE_EDIT'
}

export const RecruitmentRecruiteeStagesActions = {
    getRecruitmentRecruiteeStagesStarted: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_STARTED),
    getRecruitmentRecruiteeStagesFinished: (response: GetRecruitmentRecruiteeStagesFinishedParams) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FINISHED, { response }),
    getRecruitmentRecruiteeStagesFailed: (error: ErrorState) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FAILED, { error }),

    getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentStarted: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_STARTED),
    getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFinished: (
        response: GetRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFinishedParams
    ) =>
        createAction(
            RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED,
            { response }
        ),
    getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FAILED, {
            error
        }),

    updateRecruiteeStageInRecruitmentStarted: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_STARTED),
    updateRecruiteeStageInRecruitmentFinished: (newStageId: string, recruiteeId: string, recruitmentId: string) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FINISHED, {
            newStageId,
            recruiteeId,
            recruitmentId
        }),
    updateRecruiteeStageInRecruitmentFailed: (error: ErrorState) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITEE_IN_RECRUITMENT_STAGE_FAILED, {
            error
        }),

    getRecruitmentRecruiteeStageEditsStarted: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_STARTED),
    getRecruitmentRecruiteeStageEditsFinished: (response: GetRecruitmentRecruiteeStageEditsFinishedParams) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_FINISHED, { response }),
    getRecruitmentRecruiteeStageEditsFailed: (error: ErrorState) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_FAILED, { error }),

    updateRecruitmentRecruiteeStageEditStarted: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_STARTED),
    updateRecruitmentRecruiteeStageEditFinished: () =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_FINISHED),
    updateRecruitmentRecruiteeStageEditFailed: (error: ErrorState) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_FAILED, { error }),

    reorderRecruitmentRecruiteeStageEdits: (stageId: string, newIndex: number) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.REORDER_RECRUITMENT_STAGE_EDITS, { stageId, newIndex }),
    deleteRecruitmentRecruiteeStageEdit: (stageId: string) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.DELETE_RECRUITMENT_STAGE_EDIT, { stageId }),
    updateRecruitmentRecruiteeStageEdit: (data: RecruitmentRecruiteeStageEditViewDtoNormalized) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDIT, { data }),
    createRecruitmentRecruiteeStageEdit: (data: CreateRecruitmentRecruiteeStageEditParams) =>
        createAction(RecruitmentRecruiteeStagesActionTypes.CREATE_RECRUITMENT_STAGE_EDIT, { data })
};

interface GetRecruitmentRecruiteeStagesFinishedParams {
    ids: string[];
    stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>;
    totalCount: number;
    page: number;
}

interface GetRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFinishedParams {
    ids: string[];
    stages: Dictionary<GetRecruitmentRecruiteeStageListForSetQueryResult>;
}

interface GetRecruitmentRecruiteeStageEditsFinishedParams {
    ids: string[];
    stages: Dictionary<RecruitmentRecruiteeStageEditViewDtoNormalized>;
}

export type CreateRecruitmentRecruiteeStageEditParams = Omit<RecruitmentRecruiteeStageEditViewDtoNormalized, 'order'>;

export type RecruitmentRecruiteeStagesAction = ActionsUnion<typeof RecruitmentRecruiteeStagesActions>;
