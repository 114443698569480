import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTrans from './translations/en.json';
import plTrans from './translations/pl.json';

export enum ATSLanguage {
    EN,
    PL
}

export const ATSLanguageCode = {
    [ATSLanguage.EN]: 'en',
    [ATSLanguage.PL]: 'pl'
};

/**
 * Returns currently selected language code (e.g. `en`).
 */
export function getCurrentLangCode(): string {
    // NYI: return english as default until languages switcher is implemented
    return ATSLanguageCode[ATSLanguage.EN];
}

/**
 * Returns currently selected language ID.
 */
export function getCurrentLangId(): ATSLanguage {
    // NYI: return english as default until languages switcher is implemented
    return ATSLanguage.EN;
}

i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: getCurrentLangCode(),
        fallbackLng: 'en',
        debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
            pl: { translation: plTrans },
            en: { translation: enTrans }
        }
    });
