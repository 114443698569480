import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ReactNode } from 'react';
import AppLayout from '../layout/AppLayout';
import Recruitees from './list/Recruitees';
import RecruiteeView from "./view/RecruiteeView";

export function recruiteesRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <Recruitees />
        </AppLayout>
    );
}

export function showRecruiteeRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <RecruiteeView/>
        </AppLayout>
    );
}

