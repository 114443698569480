import _ from 'lodash';

import { Api } from '../../_commons/http';
import { ThunkAction } from '../../_commons/thunks.common';
import { endpoints } from '../../endpoints';
import { KeywordsActions } from './keywords.actions';
import { StringPagedResult } from './keywords.dto';
import { KeywordItem } from './keywords.state';

const DEFAULT_PAGE_SIZE = 10;

export class KeywordsThunks {
    static getKeywords = (
        startingWith: string,
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async dispatch => {
        dispatch(KeywordsActions.getKeywordsStarted());

        const { data, status, error } = await Api().get<StringPagedResult>(endpoints.keywords, {
            startingWith,
            page,
            pageSize
        });

        if (data) {
            const results = data.result;
            const items = _.mapValues(
                _.keyBy(results, keyword => keyword),
                (keyword): KeywordItem => ({ keyword: keyword })
            );

            dispatch(
                KeywordsActions.getKeywordsFinished({
                    ids: results,
                    keywords: items
                })
            );
        } else {
            dispatch(KeywordsActions.getKeywordsFailed({ error: error!, httpStatus: status }));
        }
    };
}
