import { LabelFieldHorizontal } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

import {
    AnswerableQuestionnaireElement,
    QuestionnaireMultiLineText,
} from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireMultiLineTextForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireMultiLineText>
    > {
    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = () => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for=''>
                <textarea
                    disabled
                    className='form-control'
                    rows={4}
                    value={
                        t(
                            'questionnaires.questionnaireForm.addMenu.field.QuestionnaireMultiLineText.placeholder'
                        ) as string
                    }
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireMultiLineTextForm = withTranslation()(questionnaireMultiLineTextForm);

// PREVIEW

export const QuestionnaireMultiLineTextPreview = (
    props: QuestionnaireElementPreviewProps<QuestionnaireMultiLineText>
) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                (props.itemData as QuestionnaireMultiLineText).question +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <textarea className='form-control' />
        </LabelFieldHorizontal>
    );
};
