import { InModal, InModalBody, InModalFooter, InModalHeader, Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import { RecruiteeViewDtoNormalized } from '../../../api/recruitees/recruitees.schema';
import { RecruiteesThunks } from '../../../api/recruitees/recruitees.thunk';
import { State } from '../../../store/state';
import PersonInfoBlock from '../../common/PersonInfoBlock';
import RecruiteeApplicationForm from '../../recruitments/recruiteesInRecruitment/RecruiteeApplicationForm';
import { filterOutAddress } from '../view/applicationForm/ApplicationFormPane';
import styles from './RecruiteeModal.module.scss';

interface OwnProps {
    isOpen: boolean;
    closeModal: () => void;
    recruiteeId: string;
    jobApplicationId: string;
}

interface OwnState {
    currentStep: number;
    lastStep: number;
}

interface StateProps {
    recruitee?: RecruiteeViewDtoNormalized;
    isLoading: boolean;
    error: ErrorState;
}

interface DispatchProps {
    fetchRecruitee: () => Promise<void>;
}

class RecruiteeAppFormModal extends Component<OwnProps & StateProps & DispatchProps & WithTranslation, OwnState> {
    state: OwnState = {
        currentStep: 1,
        lastStep: 0
    };

    async componentDidMount() {
        await this.props.fetchRecruitee();

        if (this.props.recruitee && this.props.recruitee.authorizedData) {
            // create list of question/answers to display (ApplicationFormQuestionnaireElement)
            const questionnaireElements = this.props.recruitee.authorizedData.filledQuestionnaireStructure.elements.filter(
                filterOutAddress
            );

            // find out what max pageNumber is
            const pageNumbers = questionnaireElements.map<number>(element => element.pageNumber);
            this.setState({
                lastStep: Math.max(...pageNumbers)
            });
        }
    }

    render() {
        const { t, recruitee, isLoading, error } = this.props;

        if (isLoading) {
            return <Preloader overlay />;
        }

        if (error || !recruitee || !recruitee.authorizedData) {
            return (
                <div className='middle-box text-center animated fadeIn'>
                    <h3>{t('errorPage.internalError.subtitle')}</h3>
                    <div className='error-desc'>{t('errorPage.internalError.description')}</div>
                </div>
            );
        }

        return (
            <InModal
                isOpen={this.props.isOpen}
                onClose={this.props.closeModal}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'
                size='large'>
                <InModalHeader titleText={t('recruitees.applicationForm')} onClose={this.props.closeModal} />
                <InModalBody className={styles.modalContentAppForm}>
                    <div className={styles.recruiteePersonalInfoContainer}>
                        <PersonInfoBlock
                            size='large'
                            photoUrl={endpoints.getRecruiteePhotoFileEndpoint(this.props.recruiteeId)}
                            firstName={recruitee.basicData.firstName || ''}
                            lastName={recruitee.basicData.lastName || ''}>
                            <div>{recruitee.basicData.phoneNumber}</div>
                            <div>{recruitee.basicData.email}</div>
                        </PersonInfoBlock>
                    </div>
                    <RecruiteeApplicationForm
                        filledQuestionnaireStructure={recruitee.authorizedData.filledQuestionnaireStructure}
                        filledQuestionnaireAnswers={recruitee.authorizedData.filledQuestionnaireAnswers}
                        currentStep={this.state.currentStep}
                        jobApplicationId={this.props.jobApplicationId}
                    />
                </InModalBody>
                <InModalFooter>{this.renderFooter()}</InModalFooter>
            </InModal>
        );
    }

    private prevStep = () => {
        this.setState(state => ({
            currentStep: state.currentStep - 1
        }));
    };

    private nextStep = () => {
        this.setState(state => ({
            currentStep: state.currentStep + 1
        }));
    };

    private closeModal = () => {
        this.props.closeModal();
        // we need to reset the state because this component is not destroyed on close
        this.setState({
            currentStep: 1
        });
    };

    private renderFooter = () => {
        const { t } = this.props;

        return (
            <div className='flex-fill d-flex justify-content-between'>
                <div>
                    {/* prev step button */}
                    {this.state.currentStep > 1 && (
                        <button
                            onClick={this.prevStep}
                            className={classNames('btn btn-default btn-w-m font-bold justify-self-start')}>
                            {t('general.button.previousPage')}
                        </button>
                    )}
                </div>
                <div className='d-flex justify-content-end'>
                    {/* next step button */}
                    {this.state.currentStep < this.state.lastStep && (
                        <button
                            onClick={this.nextStep}
                            className={classNames('btn btn-primary btn-w-m font-bold justify-self-end')}>
                            {t('general.button.nextPage')}
                        </button>
                    )}
                    {/* close button */}
                    {this.state.currentStep === this.state.lastStep && (
                        <button onClick={this.closeModal} className={classNames('btn btn-primary btn-w-m font-bold')}>
                            {t('general.button.close')}
                        </button>
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state: State): StateProps => {
    return {
        recruitee: state.recruitees.recruiteeInApplication.recruitee,
        isLoading: state.recruitees.recruiteeInApplication.isLoading,
        error: state.recruitees.recruiteeInApplication.error
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    fetchRecruitee: () => dispatch(RecruiteesThunks.getRecruiteeWithJobApplication(props.jobApplicationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteeAppFormModal));
