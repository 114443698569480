import { Dropdown, DropdownItem, Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toastr from 'toastr';
import uuid from 'uuid';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import {
    CreateRecruitmentRecruiteeStageEditParams,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.actions';
import { StageType as StageTypes } from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import {
    RecruitmentRecruiteeStagesThunks,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { Language, StageType } from '../../../../api/settings/settings.state';
import { SettingsThunks } from '../../../../api/settings/settings.thunk';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import ErrorPage from '../../../pages/ErrorPage';
import { isStageTypeEditable } from '../_utils';
import StageEditorModal, { FormValues } from './edit/StageEditorModal';
import RecruitmentRecruiteeStagesList from './RecruitmentRecruiteeStagesList';
import RecruitmentRecruiteeStageTypeIcon from './RecruitmentRecruiteeStageTypeIcon';

interface RecruitmentRecruiteeStagesProps {
    isLoading: boolean;
    error?: ErrorState;
    fetchLanguages: () => Promise<void>;
    fetchItems: () => Promise<void>;
    fetchStageTypes: () => Promise<void>;
    doAddStage: (data: CreateRecruitmentRecruiteeStageEditParams) => Promise<ThunkActionResult>;
    stageTypes: StageType[];
    languages: Language[];
}

interface RecruitmentRecruiteeStagesState {
    stageTypeBeingAdded?: StageType;
    isSubmitting: boolean;
}

class RecruitmentRecruiteeStages extends Component<
    WithTranslation & RecruitmentRecruiteeStagesProps,
    RecruitmentRecruiteeStagesState
> {
    state: RecruitmentRecruiteeStagesState = {
        stageTypeBeingAdded: undefined,
        isSubmitting: false,
    };

    async componentDidMount() {
        await this.props.fetchStageTypes();
        await this.props.fetchLanguages();
        this.props.fetchItems();
    }

    render() {
        const { t, isLoading, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <>
                <SidebarLayoutFixedContent
                    className='animated fadeIn'
                    title={t('general.mainMenu.recruitmentRecruiteeStages')}
                    actions={
                        <Dropdown trigger={this.renderAddButton()} align='se'>
                            {this.props.stageTypes.map(this.renderStageTypeOption)}
                        </Dropdown>
                    }>
                    {isLoading && <Preloader overlay />}

                    <div className='ibox'>
                        <div className='ibox-content p-0 bg-transparent border-top-0'>
                            <RecruitmentRecruiteeStagesList />
                        </div>
                    </div>
                </SidebarLayoutFixedContent>

                <StageEditorModal
                    isOpen={this.state.stageTypeBeingAdded !== undefined}
                    onClose={this.closeAddNewStageModal}
                    onSubmit={this.onAddNewStageSubmit}
                    onEditCancel={this.closeAddNewStageModal}
                    isSubmitting={this.state.isSubmitting}
                    initialValues={{ title: '' }}
                    stageTypeName={this.state.stageTypeBeingAdded ? this.state.stageTypeBeingAdded.name : ''}
                />
            </>
        );
    }

    private renderAddButton() {
        return (
            <button className='btn btn-primary btn-lg dropdown-toggle'>
                {this.props.t('recruitmentRecruiteeStages.addNew')}
            </button>
        );
    }

    private renderStageTypeOption = (stageType: StageType) => {
        return (
            <DropdownItem
                key={`stage-type-${stageType.id}`}
                name={stageType.name}
                onClick={this.onAddOptionClick}
                icon={<RecruitmentRecruiteeStageTypeIcon type={stageType.id as StageTypes} />}
                label={stageType.name}
            />
        );
    };

    private onAddOptionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const name = e.currentTarget.name;
        const stageType = this.props.stageTypes.find((st) => st.name === name);

        if (!stageType) {
            return;
        }

        this.setState({
            stageTypeBeingAdded: stageType,
        });
    };

    private onAddNewStageSubmit = async (values: FormValues) => {
        const { t } = this.props;

        const localizations = this.props.languages.map((language) => ({
            language: language.id,
            stageName: values.title,
        }));

        this.setState({
            isSubmitting: true,
        });

        const results = await this.props.doAddStage({
            id: uuid.v4(),
            stageType: this.state.stageTypeBeingAdded!.id,
            localizations,
        });
        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentRecruiteeStages.success.addedMessage'), t('general.success'));
        } else {
            toastr.error(t('recruitmentRecruiteeStages.error.addedMessage'), t('general.error'));
        }

        this.setState({
            isSubmitting: false,
        });
        this.closeAddNewStageModal();
    };

    private closeAddNewStageModal = () => {
        this.setState({
            stageTypeBeingAdded: undefined,
        });
    };
}

const mapStateToProps = (state: State) => ({
    isLoading:
        state.settings.stageTypes.isLoading ||
        state.settings.languages.isLoading ||
        state.recruitmentRecruiteeStages.editStages.isLoading,
    error:
        state.settings.stageTypes.error ||
        state.settings.languages.error ||
        state.recruitmentRecruiteeStages.editStages.error,
    stageTypes: listMapper(state.settings.stageTypes.list, state.settings.stageTypes.items).filter((item) => {
        return isStageTypeEditable(item.id as StageTypes);
    }),
    languages: listMapper(state.settings.languages.list, state.settings.languages.items),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    fetchLanguages: () => dispatch(SettingsThunks.getLanguages()),
    fetchItems: () => dispatch(RecruitmentRecruiteeStagesThunks.getStageEdits()),
    fetchStageTypes: () => dispatch(SettingsThunks.getStageTypes()),
    doAddStage: (data: CreateRecruitmentRecruiteeStageEditParams) =>
        dispatch(RecruitmentRecruiteeStagesThunks.createStageEdit(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentRecruiteeStages));
