import { RecruitmentStatus } from '../recruitments.dto';
import { RecruitmentAvailableStatusesAction, RecruitmentAvailableStatusesActionTypes } from './availableStatuses.actions';
import { initialState, RecruitmentAvailableStatusesState } from './availableStatuses.state';

export default (
    state: RecruitmentAvailableStatusesState = initialState,
    action: RecruitmentAvailableStatusesAction
): RecruitmentAvailableStatusesState => {
    switch (action.type) {
        case RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_FINISHED:
            return {
                ...state,
                list: [...action.response.availableStatuses],
                items: action.response.availableStatuses.reduce(
                    statusesReducer,
                    {} as Record<number, RecruitmentStatus>
                ),
                isLoading: false,
                error: null
            };

        case RecruitmentAvailableStatusesActionTypes.GET_AVAILABLE_STATUSES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const statusesReducer = (prev: Record<number, RecruitmentStatus>, curr: RecruitmentStatus) => ({
    ...prev,
    [curr as number]: curr
});
