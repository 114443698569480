import { PAGE_SIZE_DEFAULT } from '../../constants';
import { Api } from '../_commons/http';
import { ThunkAction, ThunkActionResult } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { RecruiteesActions } from './recruitees.actions';
import {
    AddRecruitmentRecruiteeNoteCommand,
    GetAllScoresSummaryForRecruiteeInRecruitmentQueryResult,
    GetJobApplicationsListForRecruiteeQueryResultPagedResult,
    GetRecruiteesInRecruitmentListQueryResultPagedResult,
    GetRecruitmentRecruiteeActivityListQuery,
    GetRecruitmentRecruiteeActivityListQueryResultPagedResult,
    GetScoreForRecruiteeInRecruitmentQueryResult,
    LogRecruitmentRecruiteePhoneCallCommand,
    RecruiteeListViewDtoPagedResult,
    RecruiteeViewDto,
    ScheduleMeetingCommand,
} from './recruitees.dto';
import {
    convertApiAllScoresForRecruiteeSummaryToSchema,
    convertApiRecruiteeApplicationsToSchema,
    convertApiRecruiteesInRecruitmentToSchema,
    convertApiRecruiteesToSchema,
    convertApiRecruiteeToSchema,
    convertApiRecruitmentRecruiteeEventHistoryToSchema,
} from './recruitees.schema';

export class RecruiteesThunks {
    static getRecruitees = (page: number = 1, pageSize: number): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruiteesActions.getRecruiteesStarted());

        const { data, status, error } = await Api().get<RecruiteeListViewDtoPagedResult>(endpoints.recruitees, {
            pageNumber: page,
            pageSize,
            SearchQuery: getState().recruitees.allRecruitees.filters.searchQuery
        });

        if (data) {
            const parsed = convertApiRecruiteesToSchema(data.result);

            dispatch(
                RecruiteesActions.getRecruiteesFinished({
                    ids: parsed.recruiteesIds,
                    recruitees: parsed.recruitees,
                    pageNumber: data.pageNumber,
                    totalCount: data.totalCount
                })
            );
        } else {
            dispatch(RecruiteesActions.getRecruiteesFailed({ error: error!, httpStatus: status }));
        }
    };

    static getRecruiteeWithJobApplication = (jobApplicationId: string): ThunkAction => async dispatch => {
        dispatch(RecruiteesActions.getRecruiteeStarted());

        const { data, status, error } = await Api().get<RecruiteeViewDto>(endpoints.recruiteeWithJobApplication, {
            jobApplicationId
        });

        if (data) {
            const parsed = convertApiRecruiteeToSchema(data);

            dispatch(RecruiteesActions.getRecruiteeFinished(parsed.recruitee));
        } else {
            dispatch(RecruiteesActions.getRecruiteeFailed({ error: error!, httpStatus: status }));
        }
    };

    static getRecruiteesInRecruitment = (
        recruitmentId: string,
        page: number = 1,
        pageSize: number
    ): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruiteesActions.getRecruiteesInRecruitmentStarted());

        const filters = getState().recruitees.recruiteesInRecruitment.filters;

        const queryParams: Record<string, any> = {
            recruitmentId,
            PageNumber: page,
            PageSize: pageSize,
            SearchQuery: filters.searchQuery,
            RecruitmentRecruiteeScores: filters.scores,
            RecruitmentStageIds: filters.stageIds
        };

        const { data, status, error } = await Api().get<GetRecruiteesInRecruitmentListQueryResultPagedResult>(
            endpoints.recruiteesInRecruitment,
            queryParams
        );

        if (data) {
            const parsed = convertApiRecruiteesInRecruitmentToSchema(data.result);

            dispatch(
                RecruiteesActions.getRecruiteesInRecruitmentFinished({
                    ids: parsed.recruiteesIds,
                    recruitees: parsed.recruitees,
                    totalCount: data.totalCount,
                    pageNumber: data.pageNumber
                })
            );
        } else {
            dispatch(RecruiteesActions.getRecruiteesInRecruitmentFailed({ error: error!, httpStatus: status }));
        }
    };

    static getRecruiteeApplications = (
        recruiteeId: string,
        page: number = 1,
        pageSize: number = PAGE_SIZE_DEFAULT
    ): ThunkAction => async dispatch => {
        dispatch(RecruiteesActions.getRecruiteeApplicationStarted());

        const { data, status, error } = await Api().get<GetJobApplicationsListForRecruiteeQueryResultPagedResult>(
            endpoints.recruiteeApplications,
            {
                recruiteeId,
                PageNumber: page,
                PageSize: pageSize
            }
        );

        if (data) {
            const parsed = convertApiRecruiteeApplicationsToSchema(data.result);

            dispatch(
                RecruiteesActions.getRecruiteeApplicationsFinished({
                    ids: parsed.applicationsIds,
                    applications: parsed.applications,
                    totalCount: data.totalCount,
                    pageNumber: data.pageNumber
                })
            );
        } else {
            dispatch(RecruiteesActions.getRecruiteeApplicationsFailed({ error: error!, httpStatus: status }));
        }
    };

    static setScoreForRecruiteeInRecruitment = (
        recruiteeId: string,
        recruitmentId: string,
        score: number,
        comment: string
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruiteesActions.setScoreForRecruiteeInRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.setScoreForRecruiteeInRecruitment, {
            recruiteeId,
            recruitmentId,
            score,
            comment
        });

        if (status === 200) {
            dispatch(
                RecruiteesActions.setScoreForRecruiteeInRecruitmentFinished(recruiteeId, recruitmentId, score, comment)
            );
        } else {
            dispatch(RecruiteesActions.setScoreForRecruiteeInRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error
        };
    };

    static getAllScoresSummaryForRecruiteeInRecruitment = (
        recruiteeId: string,
        recruitmentId: string,
        page: number = 1,
        pageSize: number
    ): ThunkAction => async dispatch => {
        dispatch(RecruiteesActions.getAllScoresSummaryForRecruiteeInRecruitmentStarted());

        const { data, status, error } = await Api().get<GetAllScoresSummaryForRecruiteeInRecruitmentQueryResult>(
            endpoints.getAllScoresSummaryForRecruiteeInRecruitment,
            {
                recruiteeId,
                recruitmentId,
                PageNumber: page,
                PageSize: pageSize
            }
        );

        if (data) {
            const parsed = convertApiAllScoresForRecruiteeSummaryToSchema(data);

            dispatch(
                RecruiteesActions.getAllScoresSummaryForRecruiteeInRecruitmentFinished({
                    scores: parsed.scores
                })
            );
        } else {
            dispatch(
                RecruiteesActions.getAllScoresSummaryForRecruiteeInRecruitmentFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }
    };

    static getScoreForRecruiteeInRecruitment = (
        recruiteeId: string,
        recruitmentId: string
    ): ThunkAction => async dispatch => {
        dispatch(RecruiteesActions.getScoreForRecruiteeInRecruitmentStarted());

        const { data, status, error } = await Api().get<GetScoreForRecruiteeInRecruitmentQueryResult>(
            endpoints.getScoreForRecruiteeInRecruitment,
            {
                recruiteeId,
                recruitmentId
            }
        );

        if (data) {
            dispatch(
                RecruiteesActions.getScoreForRecruiteeInRecruitmentFinished({
                    ...data,
                    recruiteeId
                })
            );
        } else {
            dispatch(RecruiteesActions.getScoreForRecruiteeInRecruitmentFailed({ error: error!, httpStatus: status }));
        }
    };

    static logPhoneCallForRecruiteeInRecruitment = (
        command: LogRecruitmentRecruiteePhoneCallCommand
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruiteesActions.logPhoneCallForRecruiteeInRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.recruitmentRecruiteeLogPhoneCall, command);

        if (status === 200) {
            dispatch(RecruiteesActions.logPhoneCallForRecruiteeInRecruitmentFinished());
        } else {
            dispatch(
                RecruiteesActions.logPhoneCallForRecruiteeInRecruitmentFailed({ error: error!, httpStatus: status })
            );
        }

        return {
            httpStatus: status,
            error
        };
    };

    static addNoteForRecruiteeInRecruitment = (
        command: AddRecruitmentRecruiteeNoteCommand
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruiteesActions.addNoteForRecruiteeInRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.recruitmentRecruiteeAddNote, command);

        if (status === 200) {
            dispatch(RecruiteesActions.addNoteForRecruiteeInRecruitmentFinished());
        } else {
            dispatch(RecruiteesActions.addNoteForRecruiteeInRecruitmentFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error
        };
    };

    static scheduleMeeting = (command: ScheduleMeetingCommand): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruiteesActions.scheduleMeetingStarted());

        const { status, error } = await Api().post(endpoints.recruitmentRecruiteeScheduleMeeting, command);

        if (status === 200) {
            dispatch(RecruiteesActions.scheduleMeetingFinished());
        } else {
            dispatch(RecruiteesActions.scheduleMeetingFailed({ error: error!, httpStatus: status }));
        }

        return {
            httpStatus: status,
            error
        };
    };

    static getRecruitmentRecruiteeActivities = (
        query: GetRecruitmentRecruiteeActivityListQuery
    ): ThunkAction => async dispatch => {
        dispatch(RecruiteesActions.getRecruiteeInRecruitmentActivitiesStarted());

        const { data, status, error } = await Api().get<GetRecruitmentRecruiteeActivityListQueryResultPagedResult>(
            endpoints.getRecruitmentRecruiteeActivities,
            {
                ...query
            }
        );

        if (data) {
            const parsed = convertApiRecruitmentRecruiteeEventHistoryToSchema(data.result);

            dispatch(
                RecruiteesActions.getRecruiteeInRecruitmentActivitiesFinished({
                    ...parsed,
                    totalCount: data.totalCount,
                    pageNumber: data.pageNumber
                })
            );
        } else {
            dispatch(
                RecruiteesActions.getRecruiteeInRecruitmentActivitiesFailed({ error: error!, httpStatus: status })
            );
        }
    };
}
