import { RecruitmentStatus } from '../recruitments.dto';
import { ListStateWithLoadingState } from '../../_commons/state.common';

export const initialState: RecruitmentAvailableStatusesState = {
    list: [],
    items: {},
    totalCount: 0,
    page: 0,
    isLoading: false,
    error: null
};

export type RecruitmentAvailableStatusesState = ListStateWithLoadingState<number, RecruitmentStatus>;
