import { Dictionary, NumericDictionary } from 'lodash';
import { normalize, schema } from 'normalizr';
import { currencySchema, SCHEMA_KEY_CURRENCIES } from '../settings/settings.schema';
import { RecruitmentListViewDto, RecruitmentViewDto } from './recruitments.dto';
import { CurrencyDto } from '../settings/settings.dto';

const RECRUITMENTS_KEY = 'recruitments';

export const recruitmentSchema = new schema.Entity(RECRUITMENTS_KEY, {
    budgetCurrency: currencySchema
});

export function convertApiRecruitmentsToSchema(response: RecruitmentListViewDto[]) {
    const parsed = normalize(response, [recruitmentSchema]);

    return {
        recruitmentsIds: parsed.result as string[],
        recruitments: parsed.entities[RECRUITMENTS_KEY] as Dictionary<RecruitmentListViewDto>
    };
}

export function convertApiRecruitmentToSchema(response: RecruitmentViewDto) {
    const parsed = normalize(response, recruitmentSchema);

    return {
        recruitmentId: parsed.result as string,
        recruitment: parsed.entities[RECRUITMENTS_KEY][parsed.result] as RecruitmentViewDtoNormalized,
        currencies: parsed.entities[SCHEMA_KEY_CURRENCIES] as NumericDictionary<CurrencyDto>
    };
}

export type RecruitmentViewDtoNormalized = Omit<RecruitmentViewDto, 'budgetCurrency'> & { budgetCurrency: number };
