import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { RECRUITEE_MAX_SCORE } from '../../../../api/recruitees/recruitees.dto';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { RoutePath } from '../../../../routes';
import { State } from '../../../../store/state';
import { date } from '../../../../utils/format';
import RecruiteeStageCircle, {
    getColorForStageType,
} from '../../../recruitments/recruiteesInRecruitment/RecruiteeStageCircle';
import RecruiteeScoreStars from '../../common/RecruiteeScoreStars';
import styles from './RecruiteeApplicationsList.module.scss';

interface ItemProps {
    id: string;
    recruitmentTitle: string;
    createDate: string;
    recruitmentRecruiteeStageId: string;
    recruitmentRecruiteeMyScore: number;
    recruiteeId: string;
    isActive: boolean;
}

interface ItemStateProps {
    stage?: RecruitmentRecruiteeStageListViewDtoNormalized;
}

const RecruiteeApplicationsListItem = (props: ItemProps & ItemStateProps) => {
    const { t } = useTranslation();

    return (
        <Link
            to={RoutePath.recruiteesRecruiteeDetails(props.id)}
            className={classNames(styles.item, props.isActive && styles.itemActive)}>
            <div className={styles.titleRow}>
                <h3 className='mb-1'>{props.recruitmentTitle}</h3>
                <div className={styles.score}>
                    <RecruiteeScoreStars
                        disableHover
                        initialScore={props.recruitmentRecruiteeMyScore}
                        starsCount={RECRUITEE_MAX_SCORE}
                    />
                </div>
            </div>
            <div className={styles.dateStageRow}>
                <span className={styles.applicationDate}>
                    {t('recruiteeView.applicationDate')}: {date(props.createDate)}
                </span>
                <div className={classNames('d-flex align-items-center', styles.stage)}>
                    {props.stage && (
                        <>
                            <RecruiteeStageCircle color={getColorForStageType(props.stage.stageType)} />{' '}
                            <span>{props.stage.name}</span>
                        </>
                    )}
                </div>
            </div>
        </Link>
    );
};

const mapStateToProps = (state: State, props: ItemProps): ItemStateProps => ({
    stage: state.recruitmentRecruiteeStages.allStages.items[
        props.recruitmentRecruiteeStageId
    ] as RecruitmentRecruiteeStageListViewDtoNormalized
});

export default connect(mapStateToProps)(RecruiteeApplicationsListItem);
