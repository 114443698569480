import { ListStateWithLoadingState } from '../_commons/state.common';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
    RecruitmentRecruiteeStageEditViewDtoNormalized
} from './recruitmentRecruiteeStages.schema';
import { GetRecruitmentRecruiteeStageListForSetQueryResult } from './recruitmentRecruiteeStages.dto';

export const initialState: RecruitmentRecruiteeStagesState = {
    allStages: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    editStages: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    currentRecruiteeAvailableStages: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    }
};

export interface RecruitmentRecruiteeStagesState {
    allStages: ListStateWithLoadingState<string, RecruitmentRecruiteeStageListViewDtoNormalized>;
    editStages: ListStateWithLoadingState<string, RecruitmentRecruiteeStageEditViewDtoNormalized>;
    currentRecruiteeAvailableStages: ListStateWithLoadingState<
        string,
        GetRecruitmentRecruiteeStageListForSetQueryResult
    >;
}
