import { Checkbox, Radio } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';

import styles from './ListChoice.module.scss';

interface ListChoiceProps {
    className?: string;
}

export const ListChoice = (props: React.PropsWithChildren<ListChoiceProps>) => (
    <div className={classNames(styles.container, props.className)}>{props.children}</div>
);

interface ListChoiceItemProps<T> {
    multi: boolean;
    name?: string;
    value: T;
    label: string;
    checked?: boolean;
    onChange: (val: T, isChecked: boolean) => void;
}

export class ListChoiceItem<T> extends Component<React.PropsWithChildren<ListChoiceItemProps<T>>> {
    render() {
        const choiceInput = this.props.multi ? (
            <Checkbox label={this.props.label} onChange={this.onChange} checked={this.props.checked} />
        ) : (
                <Radio
                    label={this.props.label}
                    onChange={this.onChange}
                    name={this.props.name}
                    checked={this.props.checked}
                />
            );

        return (
            <div className={classNames(styles.item, 'form-group')}>
                <div className={styles.checkbox}>{choiceInput}</div>
                <div className={styles.itemContent}>{this.props.children}</div>
            </div>
        );
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.currentTarget.checked;
        this.props.onChange(this.props.value, isChecked);
    };
}
