import _ from 'lodash';
import { AnyAction, combineReducers } from 'redux';

import { RecruitmentsAction } from '../recruitments/recruitments.actions';
import { SettingsAction, SettingsActionTypes } from './settings.actions';
import { initialState, SettingsState } from './settings.state';

export const currenciesReducer = (
    state: SettingsState['currencies'] = initialState.currencies,
    action: SettingsAction | RecruitmentsAction
): SettingsState['currencies'] => {
    switch (action.type) {
        // FIXME: What was this for?
        // case RecruitmentsActionTypes.GET_RECRUITMENT_FINISHED:
        //     return {
        //         ...state,
        //         list: _.keys(action.response.currencies).map(Number),
        //         items: action.response.currencies,
        //         totalCount: _.size(action.response.currencies)
        //     };
        case SettingsActionTypes.GET_CURRENCIES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case SettingsActionTypes.GET_CURRENCIES_FINISHED:
            return {
                ...state,
                list: action.response.ids,
                items: action.response.currencies,
                totalCount: _.size(action.response.ids),
                isLoading: false,
                error: null
            };

        case SettingsActionTypes.GET_CURRENCIES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export const stageTypesReducer = (
    state: SettingsState['stageTypes'] = initialState.stageTypes,
    action: AnyAction
): SettingsState['stageTypes'] => {
    switch (action.type) {
        case SettingsActionTypes.GET_STAGE_TYPES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case SettingsActionTypes.GET_STAGE_TYPES_FINISHED:
            return {
                ...state,
                list: _.keys(action.response.stageTypes).map(Number),
                items: action.response.stageTypes,
                totalCount: _.size(action.response.stageTypes),
                isLoading: false,
                error: null
            };

        case SettingsActionTypes.GET_STAGE_TYPES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export const languagesReducer = (
    state: SettingsState['languages'] = initialState.languages,
    action: AnyAction
): SettingsState['languages'] => {
    switch (action.type) {
        case SettingsActionTypes.GET_LANGUAGES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case SettingsActionTypes.GET_LANGUAGES_FINISHED:
            return {
                ...state,
                list: action.response.ids,
                items: action.response.languages,
                totalCount: _.size(action.response.ids),
                isLoading: false,
                error: null
            };

        case SettingsActionTypes.GET_LANGUAGES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export const timezonesReducer = (
    state: SettingsState['timezones'] = initialState.timezones,
    action: AnyAction
): SettingsState['timezones'] => {
    switch (action.type) {
        case SettingsActionTypes.GET_TIMEZONES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case SettingsActionTypes.GET_TIMEZONES_FINISHED:
            return {
                ...state,
                list: action.response.ids,
                items: action.response.timezones,
                totalCount: _.size(action.response.ids),
                isLoading: false,
                error: null
            };

        case SettingsActionTypes.GET_TIMEZONES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<SettingsState>({
    currencies: currenciesReducer,
    stageTypes: stageTypesReducer,
    languages: languagesReducer,
    timezones: timezonesReducer
});
