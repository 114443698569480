import { InModal, InModalBody, InModalFooter, InModalHeader, PdfViewer, Preloader } from '@emplo/react-inspinia';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Api } from '../../../api/_commons/http';
import styles from './RecruiteeModal.module.scss';

interface RecruiteeCvModalProps {
    cvUrl: string;
    isOpen: boolean;
    closeModal: () => void;
}

export default (props: RecruiteeCvModalProps) => {
    const { t } = useTranslation();
    const [downloadedCvFile, setDownloadedCvFile] = useState<string | undefined>(undefined);

    const downloadResumeFile = async (cvUrl: string) => {
        const downloadResult = await Api().downloadFile(cvUrl);
        if (downloadResult.status === 200) {
            setDownloadedCvFile(URL.createObjectURL(downloadResult.data));
        }
    };

    useEffect(() => {
        downloadResumeFile(props.cvUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!downloadedCvFile) {
        return <Preloader overlay />;
    }

    return (
        <InModal
            isOpen={props.isOpen}
            onClose={props.closeModal}
            className='animated a500 fadeInDown'
            backdropClassNames='animated a500 backdropFadeIn'
            size='large'>
            <InModalHeader titleText={t('recruitees.resume')} onClose={props.closeModal} />
            <InModalBody className={styles.modalContentCv}>
                <PdfViewer
                    pdfUrl={downloadedCvFile}
                    pdfDownloadUrl={props.cvUrl}
                    labelPrevPage={t('recruiteesResume.previous')}
                    labelNextPage={t('recruiteesResume.next')}
                    labelZoomIn={t('recruiteesResume.zoomIn')}
                    labelZoomOut={t('recruiteesResume.zoomOut')}
                    labelPage={t('recruiteesResume.page')}
                />
            </InModalBody>
            <InModalFooter>
                <button onClick={props.closeModal} className='btn btn-default font-bold'>
                    {t('general.button.close')}
                </button>
            </InModalFooter>
        </InModal>
    );
};
