import { combineReducers } from 'redux';

import { RecruitmentPositionsAction, RecruitmentPositionsActionTypes } from './recruitmentPositions.actions';
import { initialState, RecruitmentPositionsState } from './recruitmentPositions.state';

const recruitmentPositionEditsReducer = (
    state: RecruitmentPositionsState['recruitmentPositionEdits'] = initialState.recruitmentPositionEdits,
    action: RecruitmentPositionsAction
) => {
    switch (action.type) {
        case RecruitmentPositionsActionTypes.CLEAR_RECRUITMENT_POSITIONS_EDIT:
            return {
                ...state,
                list: [],
                items: {}
            };

        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_FINISHED:
            return {
                ...state,
                list: [...(action.response.pageNumber === 0 ? [] : state.list), ...action.response.ids],
                items: {
                    ...(action.response.pageNumber === 0 ? {} : state.items),
                    ...action.response.recruitmentPositions
                },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_STARTED:
        case RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_FINISHED:
            const newItems = { ...state.items };
            delete newItems[action.id];

            return {
                ...state,
                isWriting: false,
                list: state.list.filter(itemId => itemId !== action.id),
                items: newItems
            };

        case RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_FAILED:
        case RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        case RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_FINISHED:
            return {
                ...state,
                isWriting: false,
                items: {
                    ...state.items,
                    [action.command.positionId]: {
                        ...state.items[action.command.positionId],
                        localizations: action.command.localizations
                    }
                }
            };

        default:
            return state;
    }
};

const recruitmentPositionsReducer = (
    state: RecruitmentPositionsState['recruitmentPositions'] = initialState.recruitmentPositions,
    action: RecruitmentPositionsAction
) => {
    switch (action.type) {
        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: {
                    ...action.response.recruitmentPositions
                },
                isLoading: false,
                error: null
            };

        case RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<RecruitmentPositionsState>({
    recruitmentPositionEdits: recruitmentPositionEditsReducer,
    recruitmentPositions: recruitmentPositionsReducer
});
