import React, { HTMLProps } from 'react';
import styles from './DragHandle.module.scss';
import classNames from 'classnames';

export default (props: HTMLProps<HTMLDivElement>) => (
    <div {...props} className={classNames(styles.dragHandle, props.className)}>
        <i className='fa fa-ellipsis-v'></i>
        <i className='fa fa-ellipsis-v'></i>
    </div>
);
