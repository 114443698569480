import classNames from 'classnames';
import React from 'react';

import { StageType } from '../../../api/recruitees/recruitees.dto';
import styles from './RecruiteeStageCircle.module.scss';

interface StageCircleProps {
    color: 'red' | 'gray' | 'green' | 'greenGlass' | 'blue';
    className?: string;
}

export default (props: StageCircleProps) => (
    <div className={classNames(styles.stageIndicator, styles[props.color], props.className)}></div>
);

export const getColorForStageType = (stageType: StageType) => {
    switch (stageType) {
        case StageType.RejectedApplication:
            return 'gray';
        case StageType.RejectedCustom:
            return 'red';
        case StageType.New:
            return 'greenGlass';
        case StageType.Hired:
            return 'blue';
        case StageType.Custom:
        default:
            return 'green';
    }
};
