import {
    ErrorContext,
    LabelFieldHorizontal,
    PropertyRow,
    Select,
    SpinnerCircle,
    Switch,
    TextInput,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import { Formik, FormikProps, withFormik } from 'formik';
import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import toastr from 'toastr';
import uuid from 'uuid';
import { boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

import { ThunkDispatch } from '../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../api/questionnaires/builder/builder.actions';
import { QuestionnaireBuilderElement } from '../../api/questionnaires/builder/builder.state';
import {
    QuestionnaireElementType,
    QuestionnaireStatus,
    QuestionnaireViewDto,
    QuestionQuestionnaireElementType,
} from '../../api/questionnaires/questionnaires.dto';
import { RoutePath } from '../../routes';
import { State } from '../../store/state';
import QuestionnaireBuilder from './builder/QuestionnaireBuilder';
import styles from './QuestionnaireForm.module.scss';

enum FormFieldName {
    QUESTIONNAIRE_NAME = 'questionnaireName',
    IS_DRAFT = 'isDraft',
    ELEMENTS = 'elements'
}

export interface FormValues {
    [FormFieldName.QUESTIONNAIRE_NAME]: string;
    [FormFieldName.IS_DRAFT]: boolean;
    [FormFieldName.ELEMENTS]: QuestionnaireBuilderElement[];
}

interface QuestionnaireNameFormValues {
    [FormFieldName.QUESTIONNAIRE_NAME]: string;
}

enum SwitchName {
    ADDRESS = 'address',
    COVER_LETTER = 'coverLetter',
    EDUCATION = 'education',
    EXPERIENCE = 'experience',
    PHOTO = 'photo',
    SUMMARY = 'summary'
}

interface InnerFormOwnProps {
    onSubmit: (values: FormValues) => void;
    prefixes: string[];
    questionnaire?: QuestionnaireViewDto;
}

interface StateProps {
    items: QuestionnaireBuilderElement[];
    isEditingQuestionnaireElement: boolean;
}

interface DispatchProps {
    addItem: (element: QuestionnaireBuilderElement, index?: number) => void;
    removeItem: (element: QuestionnaireBuilderElement) => void;
}

type InnerFormProps = WithTranslation & FormikProps<FormValues> & InnerFormOwnProps & StateProps & DispatchProps;

interface InnerFormState {
    isEditingQuestionnaireName: boolean;
    submittingAs?: 'draft' | 'active';
    isCancelling: boolean;
}

class InnerForm extends Component<InnerFormProps, InnerFormState> {
    private titleFocusRef = React.createRef<HTMLInputElement>();
    private leftPanelRef = React.createRef<HTMLDivElement>();

    state: InnerFormState = {
        isEditingQuestionnaireName: false,
        isCancelling: false
    };

    componentDidMount() {
        window.addEventListener('scroll', this.updateLeftPanelPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateLeftPanelPosition);
    }

    private updateLeftPanelPosition = () => {
        if (window && this.leftPanelRef.current) {
            this.leftPanelRef.current.style.top = `${Math.round(window.scrollY)}px`;
        }
    };

    componentDidUpdate(prevProps: InnerFormProps, prevState: InnerFormState) {
        if (
            !prevState.isEditingQuestionnaireName &&
            this.state.isEditingQuestionnaireName &&
            this.titleFocusRef.current
        ) {
            this.titleFocusRef.current.focus();
            this.titleFocusRef.current.select();
        }
    }

    render() {
        const { t, values, errors, touched, prefixes } = this.props;
        const questionnaireNameFormInitialValues = { [FormFieldName.QUESTIONNAIRE_NAME]: values.questionnaireName };
        const isQuestionnaireActive =
            this.props.questionnaire && this.props.questionnaire.status === QuestionnaireStatus.ACTIVE;
        const isQuestionnaireDeleted =
            this.props.questionnaire && this.props.questionnaire.status === QuestionnaireStatus.DELETED;

        return (
            <>
                {this.state.isCancelling && <Redirect to={RoutePath.questionnairesList()} push />}

                <ErrorContext.Provider value={{ errors, touched }}>
                    <div className={classNames(styles.container, 'row')}>
                        <div className='col-md-12 col-lg-4'>
                            <div ref={this.leftPanelRef} className={classNames('ibox', styles.leftPanel)}>
                                <div className='ibox-content'>
                                    <h3>{t('questionnaires.questionnaireForm.leftPanel.title')}</h3>
                                    <p>{t('questionnaires.questionnaireForm.leftPanel.description')}</p>

                                    <h5 className='font-weight-bold text-uppercase'>
                                        {t('questionnaires.questionnaireForm.leftPanel.personalInfo')}
                                    </h5>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.address')}>
                                        <Switch
                                            name={SwitchName.ADDRESS}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnaireAddress
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.photo')}>
                                        <Switch
                                            name={SwitchName.PHOTO}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnairePhoto
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>

                                    <h5
                                        className={classNames(
                                            'font-weight-bold text-uppercase',
                                            styles.leftPanelCandidateProfileTitle
                                        )}>
                                        {t('questionnaires.questionnaireForm.leftPanel.candidateProfile')}
                                    </h5>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.education')}>
                                        <Switch
                                            name={SwitchName.EDUCATION}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnaireEducation
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.experience')}>
                                        <Switch
                                            name={SwitchName.EXPERIENCE}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnaireExperience
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.summary')}>
                                        <Switch
                                            name={SwitchName.SUMMARY}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnaireSummary
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>
                                    <PropertyRow label={t('questionnaires.questionnaireForm.leftPanel.coverLetter')}>
                                        <Switch
                                            name={SwitchName.COVER_LETTER}
                                            checked={this.hasItemType(
                                                QuestionQuestionnaireElementType.QuestionnaireCoverLetter
                                            )}
                                            onChange={this.onSwitchChange}
                                            disabled={this.props.isEditingQuestionnaireElement}
                                        />
                                    </PropertyRow>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-8'>
                            {!this.state.isEditingQuestionnaireName && (
                                <h2 className={styles.questionnaireName}>
                                    <span>{values.questionnaireName}</span>
                                    <button className='btn btn-link' onClick={this.toggleEditingQuestionnaireName}>
                                        <i className='fa fa-pencil' />
                                    </button>
                                </h2>
                            )}

                            {this.state.isEditingQuestionnaireName && (
                                <div className='ibox'>
                                    <div className='ibox-content ats-p-h-md ats-p-v-md ats-tag-panel shadow-sm'>
                                        <Formik
                                            onSubmit={this.onSaveQuestionnaireName}
                                            initialValues={questionnaireNameFormInitialValues}
                                            isInitialValid
                                            render={this.renderQuestionnaireNameForm}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='ibox'>
                                <div className={classNames('ibox-content', styles.formContainer)}>
                                    <div className={styles.formHeaderFieldsContainer}>
                                        <LabelFieldHorizontal
                                            for=''
                                            label={t('questionnaires.questionnaireForm.question.fullName.label')}>
                                            <div className='row flex-fill'>
                                                <div className='col-6'>
                                                    <TextInput
                                                        readOnly
                                                        placeholder={t(
                                                            'questionnaires.questionnaireForm.question.fullName.firstNamePlaceholder'
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-6'>
                                                    <TextInput
                                                        readOnly
                                                        placeholder={t(
                                                            'questionnaires.questionnaireForm.question.fullName.lastNamePlaceholder'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </LabelFieldHorizontal>

                                        <div className='row flex-fill'>
                                            <div className='col-6'>
                                                <LabelFieldHorizontal
                                                    for=''
                                                    label={t('questionnaires.questionnaireForm.question.prefix.label')}>
                                                    <Select readOnly>
                                                        <option value=''>
                                                            {t(
                                                                'questionnaires.questionnaireForm.question.prefix.chooseLabel'
                                                            )}
                                                        </option>
                                                        {prefixes.map(this.renderPrefixOption)}
                                                    </Select>
                                                </LabelFieldHorizontal>
                                            </div>
                                            <div className='col-6'>
                                                <LabelFieldHorizontal
                                                    for=''
                                                    label={t('questionnaires.questionnaireForm.question.phone.label')}>
                                                    <TextInput readOnly />
                                                </LabelFieldHorizontal>
                                            </div>
                                        </div>

                                        <LabelFieldHorizontal
                                            for=''
                                            label={t('questionnaires.questionnaireForm.question.email.label')}>
                                            <TextInput readOnly type='email' />
                                        </LabelFieldHorizontal>
                                    </div>

                                    <QuestionnaireBuilder className={styles.builderContainer} />
                                </div>
                            </div>

                            <div className='row justify-content-between'>
                                <div className='col-auto'>
                                    {/* <div className='btn btn-primary js-preview-job'>Preview questionnaire</div> */}
                                    {!isQuestionnaireActive && (
                                        <button
                                            type='button'
                                            className='btn btn-default btn-w-m font-bold'
                                            onClick={this.onCancel}
                                            disabled={
                                                this.props.isEditingQuestionnaireElement ||
                                                this.state.isEditingQuestionnaireName
                                            }>
                                            {t('general.button.cancel')}
                                        </button>
                                    )}
                                </div>
                                <div className='col-auto d-flex justify-content-end'>
                                    {!isQuestionnaireActive && (
                                        <button
                                            className='btn btn-w-m btn-default font-bold d-flex justify-content-center align-items-center'
                                            disabled={
                                                this.props.isEditingQuestionnaireElement ||
                                                this.state.isEditingQuestionnaireName ||
                                                !this.props.isValid ||
                                                this.props.isSubmitting
                                            }
                                            onClick={this.onSaveAsDraft}>
                                            {this.props.isSubmitting && this.state.submittingAs === 'draft' && (
                                                <SpinnerCircle
                                                    spinnerSize='small'
                                                    inline
                                                    className='mt-0 ml-0 mb-0 mr-2'
                                                />
                                            )}
                                            {t('questionnairesNew.saveDraftBtn')}
                                        </button>
                                    )}
                                    {isQuestionnaireActive && (
                                        <button
                                            type='button'
                                            className='btn btn-default btn-w-m font-bold'
                                            onClick={this.onCancel}
                                            disabled={
                                                this.props.isEditingQuestionnaireElement ||
                                                this.state.isEditingQuestionnaireName
                                            }>
                                            {t('general.button.cancel')}
                                        </button>
                                    )}
                                    {!isQuestionnaireDeleted && (
                                        <button
                                            className='btn btn-w-m btn-primary m-l-md font-bold d-flex justify-content-center align-items-center'
                                            disabled={
                                                this.props.isEditingQuestionnaireElement ||
                                                this.state.isEditingQuestionnaireName ||
                                                !this.props.isValid ||
                                                this.props.isSubmitting
                                            }
                                            onClick={this.onSubmit}>
                                            {this.props.isSubmitting && this.state.submittingAs === 'active' && (
                                                <SpinnerCircle
                                                    spinnerSize='small'
                                                    inline
                                                    className='mt-0 ml-0 mb-0 mr-2'
                                                />
                                            )}
                                            {t(
                                                isQuestionnaireActive
                                                    ? 'questionnairesEdit.saveBtn'
                                                    : 'questionnairesEdit.saveAndActivateBtn'
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ErrorContext.Provider>
            </>
        );
    }

    private hasItemType(searchedType: QuestionnaireElementType): boolean {
        return this.props.items.find(item => item.$type === searchedType) !== undefined;
    }

    private renderPrefixOption(prefix: string) {
        return (
            <option key={prefix} value={prefix}>
                {prefix}
            </option>
        );
    }

    /**
     * Collects data from the form, applies changes like setting proper `order` values and returns the result data
     * ready for submit.
     */
    private getFormDataForSubmit(): FormValues {
        // prepare a deep copy of the items for further manipulation
        const items = this.props.items.map(item => ({ ...item }));

        // prepare form data to submit
        const formValues = {
            ...this.props.values,
            elements: items
        };

        return formValues;
    }

    private renderQuestionnaireNameForm = (formikProps: FormikProps<QuestionnaireNameFormValues>) => {
        const { t } = this.props;

        return (
            <form onSubmit={formikProps.handleSubmit}>
                <LabelFieldHorizontal
                    for={FormFieldName.QUESTIONNAIRE_NAME}
                    label={t('questionnaires.questionnaireForm.questionnaireName.label')}
                    className='mb-0'>
                    <TextInput
                        name={FormFieldName.QUESTIONNAIRE_NAME}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.questionnaireName}
                        inputRef={this.titleFocusRef}
                    />
                    <button
                        type='button'
                        className='btn btn-w-m btn-default font-bold m-l-sm'
                        onClick={this.toggleEditingQuestionnaireName}>
                        {t('general.button.cancel')}
                    </button>
                    <button
                        type='submit'
                        className='btn btn-w-m btn-primary font-bold m-l-sm'
                        disabled={!formikProps.isValid}>
                        {t('general.button.save')}
                    </button>
                </LabelFieldHorizontal>
            </form>
        );
    };

    private onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { t } = this.props;
        const isOn = e.target.checked;
        let componentType: QuestionnaireElementType;
        let label: string;

        switch (e.target.name) {
            case SwitchName.ADDRESS:
                componentType = QuestionQuestionnaireElementType.QuestionnaireAddress;
                label = t('questionnaires.questionnaireForm.question.address.label');
                break;
            case SwitchName.COVER_LETTER:
                componentType = QuestionQuestionnaireElementType.QuestionnaireCoverLetter;
                label = t('questionnaires.questionnaireForm.question.coverLetter.label');
                break;
            case SwitchName.EDUCATION:
                componentType = QuestionQuestionnaireElementType.QuestionnaireEducation;
                label = t('questionnaires.questionnaireForm.question.education.label');
                break;
            case SwitchName.EXPERIENCE:
                componentType = QuestionQuestionnaireElementType.QuestionnaireExperience;
                label = t('questionnaires.questionnaireForm.question.experience.label');
                break;
            case SwitchName.PHOTO:
                componentType = QuestionQuestionnaireElementType.QuestionnairePhoto;
                label = t('questionnaires.questionnaireForm.question.photo.label');
                break;
            case SwitchName.SUMMARY:
                componentType = QuestionQuestionnaireElementType.QuestionnaireSummary;
                label = t('questionnaires.questionnaireForm.question.summary.label');
                break;
            default:
                throw new Error(`Unrecognized switch name ${e.target.name}`);
        }

        if (isOn) {
            // add new element of the type
            const newItem = {
                id: uuid.v4(),
                isRequired: false,
                question: label,
                $type: componentType,
                order: 0,
                pageNumber: 0,
                sectionNumber: 0,
                isEditing: false
            };
            this.props.addItem(newItem as QuestionnaireBuilderElement);
            toastr.success(t('questionnaires.questionnaireForm.addMenu.addedMessage', { elementLabel: label }));
        } else {
            // remove existing element of the type
            const item = this.props.items.find(item => item.$type === componentType);
            if (item) {
                this.props.removeItem(item);
                toastr.success(t('questionnaires.questionnaireForm.addMenu.removedMessage', { elementLabel: label }));
            }
        }
    };

    private toggleEditingQuestionnaireName = () => {
        this.setState(state => ({
            isEditingQuestionnaireName: !state.isEditingQuestionnaireName
        }));
    };

    private onSaveQuestionnaireName = (formValues: QuestionnaireNameFormValues) => {
        this.props.setFieldValue(FormFieldName.QUESTIONNAIRE_NAME, formValues.questionnaireName);
        this.toggleEditingQuestionnaireName();
    };

    private onCancel = () => {
        this.setState({
            isCancelling: true
        });
    };

    private onSaveAsDraft = () => {
        const formValues = {
            ...this.getFormDataForSubmit(),
            isDraft: true
        };

        this.setState({
            submittingAs: 'draft'
        });

        this.props.setSubmitting(true);
        this.props.onSubmit(formValues);
    };

    private onSubmit = () => {
        const formValues = {
            ...this.getFormDataForSubmit(),
            isDraft: false
        };

        this.setState({
            submittingAs: 'active'
        });

        this.props.setSubmitting(true);
        this.props.onSubmit(formValues);
    };
}

interface QuestionnaireFormProps {
    questionnaire?: QuestionnaireViewDto;
    initialValues?: FormValues;
    onSubmit: (values: FormValues) => void;
}

const mapPropsToValues = (props: QuestionnaireFormProps & InnerFormOwnProps): FormValues => ({
    questionnaireName: (props.initialValues && props.initialValues.questionnaireName) || '',
    isDraft: (props.initialValues && props.initialValues.isDraft) || true,
    elements: (props.initialValues && props.initialValues.elements) || []
});

// validation
const recruitmentSchema = yupObject().shape({
    questionnaireName: yupString().required(),
    isDraft: yupBoolean().required()
});

// convertApiQuestionnaireStructureToBuilderStructure(elements)

const isElementBeingEdited = (el: QuestionnaireBuilderElement) => el.isEditing;

const mapStateToProps = (state: State): StateProps => ({
    items: state.questionnaireBuilder.elements,
    isEditingQuestionnaireElement: !!state.questionnaireBuilder.elements.find(isElementBeingEdited)
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    addItem: (element: QuestionnaireBuilderElement, index?: number) =>
        dispatch(QuestionnaireBuilderActions.addElement(element, index)),
    removeItem: (element: QuestionnaireBuilderElement) => dispatch(QuestionnaireBuilderActions.removeElement(element))
});

export default withFormik<QuestionnaireFormProps & InnerFormOwnProps, FormValues>({
    mapPropsToValues,
    validationSchema: recruitmentSchema,
    isInitialValid: true,
    handleSubmit: (values: FormValues, { props }) => {
        props.onSubmit(values);
    }
})(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InnerForm)));
