import { Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';

import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruitmentListViewDto } from '../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import styles from './RecruitmentsList.module.scss';
import RecruitmentsListItem from './RecruitmentsListItem';

interface RecruitmentsListProps {
    items: RecruitmentListViewDto[];
    isLoading: boolean;
    page: number;
    totalCount: number;
}

interface DispatchProps {
    deleteRecruitment: (id: string) => Promise<ThunkActionResult>;
    fetchItems: (pageNumber: number, clearPreviousRecruitments?: boolean) => void;
}

class RecruitmentsList extends Component<RecruitmentsListProps & DispatchProps & WithTranslation> {
    render() {
        const { items, isLoading, page } = this.props;

        if (page === 0 && isLoading) {
            return <Preloader />;
        }

        return (
            <div className='ibox'>
                <div className='ibox-content p-0'>
                    <InfiniteScroll
                        className='project-list gray-bg'
                        dataLength={this.props.items.length}
                        next={this.loadNextPage}
                        hasMore={this.props.items.length < this.props.totalCount}
                        loader={<Preloader />}>
                        <table className={classNames('table table-hover bg-white mb-0', styles.table)}>
                            <tbody>{items.map(this.renderItem)}</tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }

    private renderItem = (item: RecruitmentListViewDto) => (
        <RecruitmentsListItem
            {...item}
            title={item.title || ''}
            positionName={item.positionName || ''}
            key={item.id}
            doDeleteRecruitment={this.props.deleteRecruitment}
            reloadRecruitments={this.reloadList}></RecruitmentsListItem>
    );

    private reloadList = () => {
        this.props.fetchItems(1, true);
    };

    private loadNextPage = () => {
        if (!this.props.isLoading) {
            this.props.fetchItems(this.props.page + 1);
        }
    };
}

const mapStateToProps = (state: State) => ({
    items: listMapper(state.recruitments.allRecruitments.list, state.recruitments.allRecruitments.items),
    isLoading: state.recruitments.allRecruitments.isLoading,
    page: state.recruitments.allRecruitments.page,
    totalCount: state.recruitments.allRecruitments.totalCount,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (pageNumber: number, clearPreviousRecruitments?: boolean) =>
        dispatch(RecruitmentsThunks.getRecruitments(pageNumber, PAGE_SIZE_DEFAULT, clearPreviousRecruitments)),
    deleteRecruitment: (id: string) => dispatch(RecruitmentsThunks.deleteRecruitment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentsList));
