import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrency } from '../../../../api/settings/settings.selector';
import { BudgetCurrency } from '../../../../api/settings/settings.state';
import { getCurrentLangCode } from '../../../../i18n';
import { State } from '../../../../store/state';

interface Props {
    min: number;
    max: number;
    currencyId: number;
}

export default (props: Props) => {
    const currency = useSelector<State, BudgetCurrency | undefined>(state => selectCurrency(state, props.currencyId));

    return (
        <>
            {props.min.toLocaleString(getCurrentLangCode())}-{props.max.toLocaleString(getCurrentLangCode())}{' '}
            {currency && currency.name}
        </>
    );
};
