import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import {
    QuestionnaireListViewDto,
    QuestionnaireListViewForRecruitmentDto,
    QuestionnaireViewDto,
} from './questionnaires.dto';
import { QuestionnairesListFilters } from './questionnaires.state';

export enum QuestionnairesActionTypes {
    CLEAR_QUESTIONNAIRES = 'QUESTIONNAIRES_CLEAR_QUESTIONNAIRES',
    GET_QUESTIONNAIRES_STARTED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_STARTED',
    GET_QUESTIONNAIRES_FINISHED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_FINISHED',
    GET_QUESTIONNAIRES_FAILED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_FAILED',

    GET_QUESTIONNAIRES_FOR_RECRUITMENT_STARTED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_FOR_RECRUITMENT_STARTED',
    GET_QUESTIONNAIRES_FOR_RECRUITMENT_FINISHED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_FOR_RECRUITMENT_FINISHED',
    GET_QUESTIONNAIRES_FOR_RECRUITMENT_FAILED = 'QUESTIONNAIRES_GET_QUESTIONNAIRES_FOR_RECRUITMENT_FAILED',

    GET_QUESTIONNAIRE_STARTED = 'QUESTIONNAIRES_GET_QUESTIONNAIRE_STARTED',
    GET_QUESTIONNAIRE_FINISHED = 'QUESTIONNAIRES_GET_QUESTIONNAIRE_FINISHED',
    GET_QUESTIONNAIRE_FAILED = 'QUESTIONNAIRES_GET_QUESTIONNAIRE_FAILED',

    ADD_QUESTIONNAIRE_STARTED = 'QUESTIONNAIRES_ADD_QUESTIONNAIRE_STARTED',
    ADD_QUESTIONNAIRE_FINISHED = 'QUESTIONNAIRES_ADD_QUESTIONNAIRE_FINISHED',
    ADD_QUESTIONNAIRE_FAILED = 'QUESTIONNAIRES_ADD_QUESTIONNAIRE_FAILED',

    UPDATE_QUESTIONNAIRE_STARTED = 'QUESTIONNAIRES_UPDATE_QUESTIONNAIRE_STARTED',
    UPDATE_QUESTIONNAIRE_FINISHED = 'QUESTIONNAIRES_UPDATE_QUESTIONNAIRE_FINISHED',
    UPDATE_QUESTIONNAIRE_FAILED = 'QUESTIONNAIRES_UPDATE_QUESTIONNAIRE_FAILED',

    DELETE_QUESTIONNAIRE_STARTED = 'QUESTIONNAIRES_DELETE_QUESTIONNAIRE_STARTED',
    DELETE_QUESTIONNAIRE_FINISHED = 'QUESTIONNAIRES_DELETE_QUESTIONNAIRE_FINISHED',
    DELETE_QUESTIONNAIRE_FAILED = 'QUESTIONNAIRES_DELETE_QUESTIONNAIRE_FAILED',

    SET_QUESTIONNAIRES_LIST_FILTERS = 'QUESTIONNAIRES_SET_QUESTIONNAIRES_LIST_FILTERS'
}

export const QuestionnairesActions = {
    clearQuestionnairesStarted: () => createAction(QuestionnairesActionTypes.CLEAR_QUESTIONNAIRES),
    getQuestionnairesStarted: () => createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_STARTED),
    getQuestionnairesFinished: (response: GetQuestionnairesFinishedParams) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_FINISHED, { response }),
    getQuestionnairesFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_FAILED, { error }),

    getQuestionnairesForRecruitmentStarted: () =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_STARTED),
    getQuestionnairesForRecruitmentFinished: (response: GetQuestionnairesForRecruitmentFinishedParams) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_FINISHED, { response }),
    getQuestionnairesForRecruitmentFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_FAILED, { error }),

    setQuestionnairesFilters: (filters: QuestionnairesListFilters) =>
        createAction(QuestionnairesActionTypes.SET_QUESTIONNAIRES_LIST_FILTERS, { filters }),

    getQuestionnaireStarted: () => createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRE_STARTED),
    getQuestionnaireFinished: (response: GetQuestionnaireFinishedParams) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRE_FINISHED, { response }),
    getQuestionnaireFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.GET_QUESTIONNAIRE_FAILED, { error }),

    addQuestionnaireStarted: () => createAction(QuestionnairesActionTypes.ADD_QUESTIONNAIRE_STARTED),
    addQuestionnaireFinished: () => createAction(QuestionnairesActionTypes.ADD_QUESTIONNAIRE_FINISHED),
    addQuestionnaireFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.ADD_QUESTIONNAIRE_FAILED, { error }),

    updateQuestionnaireStarted: () => createAction(QuestionnairesActionTypes.UPDATE_QUESTIONNAIRE_STARTED),
    updateQuestionnaireFinished: () => createAction(QuestionnairesActionTypes.UPDATE_QUESTIONNAIRE_FINISHED),
    updateQuestionnaireFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.UPDATE_QUESTIONNAIRE_FAILED, { error }),

    deleteQuestionnaireStarted: () => createAction(QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_STARTED),
    deleteQuestionnaireFinished: (id: string) =>
        createAction(QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_FINISHED, { id }),
    deleteQuestionnaireFailed: (error: ErrorState) =>
        createAction(QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_FAILED, { error })
};

interface GetQuestionnairesFinishedParams {
    ids: string[];
    questionnaires: Dictionary<QuestionnaireListViewDto>;
    totalCount: number;
    pageNumber: number;
}

interface GetQuestionnairesForRecruitmentFinishedParams {
    ids: string[];
    questionnaires: Dictionary<QuestionnaireListViewForRecruitmentDto>;
}

interface GetQuestionnaireFinishedParams {
    id: string;
    questionnaire: QuestionnaireViewDto;
}

export type QuestionnairesAction = ActionsUnion<typeof QuestionnairesActions>;
