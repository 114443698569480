import { Api } from '../_commons/http';
import { ThunkAction } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { DashboardActions } from './dashboard.actions';
import { GetDashboardQueryResult } from './dashboard.dto';

export class DashboardThunks {
    static getDashboardData = (): ThunkAction => async dispatch => {
        dispatch(DashboardActions.getDashboardDataStarted());

        const { data, status, error } = await Api().get<GetDashboardQueryResult>(endpoints.dashboard);

        if (data) {
            dispatch(DashboardActions.getDashboardDataFinished(data));
        } else {
            dispatch(DashboardActions.getDashboardDataFailed({ error: error!, httpStatus: status }));
        }
    };
}
