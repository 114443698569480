import { LabelFieldHorizontal } from '@emplo/react-inspinia';
import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

import { QuestionnaireTextParagraph } from '../../../../api/questionnaires/questionnaires.dto';
import { nl2br } from '../../../../utils/format';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireTextParagraphForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireTextParagraph>
    > {
    validationSchema = yup.object().shape({
        text: yup.string().required()
    });

    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                isRequiredEditable={false}
                isQuestionEditable={false}
                validationSchema={this.validationSchema}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = (formikProps: FormikProps<QuestionnaireTextParagraph>) => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal
                for='text'
                label={t('questionnaires.questionnaireForm.addMenu.field.QuestionnaireTextParagraph.placeholder')}>
                <textarea
                    className='form-control'
                    name='text'
                    value={formikProps.values.text}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireTextParagraphForm = withTranslation()(questionnaireTextParagraphForm);

// PREVIEW

export function QuestionnaireTextParagraphPreview<T extends QuestionnaireTextParagraph>(
    props: QuestionnaireElementPreviewProps<T>
) {
    return <p className='mb-4'>{nl2br(props.itemData.text)}</p>;
}
