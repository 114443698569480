import { PageLayout } from '@emplo/react-inspinia';
import React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import ForgotPassword from './ForgotPassword';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';

export function errorPageNotFoundRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <PageLayout {...props}>
            <NotFound />
        </PageLayout>
    );
}

export function errorPageUnauthorizedRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <PageLayout {...props}>
            <Unauthorized />
        </PageLayout>
    );
}

export function forgotPasswordRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <PageLayout {...props}>
            <ForgotPassword />
        </PageLayout>
    );
}
