import React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import AppLayout from '../layout/AppLayout';
import EditRecruitment from './edit/EditRecruitment';
import Recruitments from './list/Recruitments';
import NewRecruitment from './new/NewRecruitment';
import RecruiteesInRecruitment from './recruiteesInRecruitment/RecruiteesInRecruitment';
import RecruitmentView from './view/RecruitmentView';

export function newRecruitmentRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props} background='white'>
            <NewRecruitment />
        </AppLayout>
    );
}

export function editRecruitmentRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props} background='white'>
            <EditRecruitment />
        </AppLayout>
    );
}

export function recruitmentsRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <Recruitments />
        </AppLayout>
    );
}

export function showRecruitmentRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <RecruitmentView />
        </AppLayout>
    );
}

export function recruiteesInRecruitmentRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <RecruiteesInRecruitment />
        </AppLayout>
    );
}
