import { ATSUserForRecruitmentEditView } from '../recruitments/recruitments.dto';
import { RecruitersBrowserItem } from './recruitersBrowser.state';

export interface GetATSUsersListForRecruitmentTeamMembersChoiceQueryResultPagedResult {
    result: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export interface GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult {
    recruiterATSUserId: string;
    firstName: string;
    lastName: string;
    avatar?: string;
}

export const convertApiRecruiterUserResultToEditViewModel = (
    data: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult
): ATSUserForRecruitmentEditView => {
    return {
        ...data,
        id: data.recruiterATSUserId
    };
};

export const convertUserRecruiterEditViewModelToApiResult = (
    data: ATSUserForRecruitmentEditView
): GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult => {
    return {
        ...data,
        recruiterATSUserId: data.id
    };
};

export const convertUserRecruiterEditViewModelToStateModel = (
    data: ATSUserForRecruitmentEditView
): RecruitersBrowserItem => {
    return {
        ...data,
        recruiterATSUserId: data.id,
        isSelected: true
    };
};
