import {
    ListStateWithLoadingState,
    ListFiltersState,
    LoadingState,
    WithErrorState,
    WritingState
} from '../_commons/state.common';
import { RecruitmentListViewDto, RecruitmentStatus, GetRecruitmentViewForEditQueryResult } from './recruitments.dto';
import { RecruitmentViewDtoNormalized } from './recruitments.schema';

export const initialState: RecruitmentsState = {
    allRecruitments: {
        list: [],
        items: {},
        filters: {},
        page: 0,
        totalCount: 0,
        isLoading: false,
        error: null,
        isWriting: false
    },
    recruitmentDetails: {
        isLoading: false,
        isWriting: false,
        error: null
    },
    recruitmentEdit: {
        isLoading: false,
        isWriting: false,
        error: null
    }
};

export interface RecruitmentsListFilters {
    status?: RecruitmentStatus;
    searchQuery?: string;
}

export type RecruitmentsListState = ListStateWithLoadingState<string, RecruitmentListViewDto> &
    ListFiltersState<RecruitmentsListFilters> &
    WritingState;

export type RecruitmentDetailsState = LoadingState &
    WritingState &
    WithErrorState & {
        recruitment?: RecruitmentViewDtoNormalized;
    };

export type RecruitmentEditState = LoadingState &
    WritingState &
    WithErrorState & {
        recruitment?: GetRecruitmentViewForEditQueryResult;
    };

export interface RecruitmentsState {
    allRecruitments: RecruitmentsListState;
    recruitmentDetails: RecruitmentDetailsState;
    recruitmentEdit: RecruitmentEditState;
}
