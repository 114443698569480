import { ChartjsChart } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
    RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer,
} from '../../../../api/recruitments/recruitments.dto';
import { COLORS } from '../../../../constants';
import NoChartData from '../../../dashboard/common/NoChartData';
import styles from './ApplicationsBySourceChart.module.scss';

interface Props {
    data: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer[];
}

interface State {
    legendHtml: string;
}

const CHART_HEIGHT = 160;

class ApplicationsBySourceChart extends Component<Props & WithTranslation, State> {
    state: State = {
        legendHtml: ''
    };

    render() {
        if (this.props.data.length === 0) {
            return <NoChartData height={CHART_HEIGHT} />;
        }

        const labels = this.props.data.map(this.getLabel);
        const values = this.props.data.map(this.getValue);

        const chartOptions = {
            type: 'doughnut',
            data: {
                labels,
                datasets: [
                    {
                        backgroundColor: this.generateColors,
                        hoverBackgroundColor: this.generateHoverColors,
                        data: values
                    }
                ]
            },
            options: {
                responsive: false,
                legend: {
                    display: false
                },
                legendCallback: () => {
                    return (
                        <div className={styles.legend}>
                            {this.props.data.sort(this.sortData).map(this.renderLegendItem)}
                        </div>
                    );
                }
            }
        };

        return (
            <div className={styles.container}>
                <ChartjsChart
                    height={CHART_HEIGHT}
                    width={CHART_HEIGHT}
                    chartOptions={chartOptions}
                    className={styles.chart}
                    renderLegend={this.setLegend}
                />
                <div dangerouslySetInnerHTML={{ __html: this.state.legendHtml }}></div>
            </div>
        );
    }

    private sortData = (
        a: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer,
        b: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer
    ) => (a.count > b.count ? -1 : 1);

    private setLegend = (html: JSX.Element) => {
        this.setState({
            legendHtml: ReactDOMServer.renderToStaticMarkup(html)
        });
    };

    private renderLegendItem = (
        item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer,
        idx: number
    ) => {
        return (
            <div className={styles.legendItem} key={idx}>
                <div className={styles.legendItemColor} style={{ backgroundColor: this.getColorForItem(item) }} />
                <div className={styles.legendItemName}>{this.getLabelWithCount(item)}</div>
            </div>
        );
    };

    private getLabel = (item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer) =>
        item.referer ? item.referer : this.props.t('dashboard.candidatesBySource.directLinks');

    private getLabelWithCount = (item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer) =>
        `${item.referer ? item.referer : this.props.t('dashboard.candidatesBySource.directLinks')} (${item.count})`;

    private getValue = (item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer) => item.count;

    private generateColors = () => {
        let result: string[] = [];

        if (this.props.data.length > 0) {
            result = this.props.data.map(this.getTransparentColorForItem);
        }

        return result;
    };

    private generateHoverColors = () => {
        let result: string[] = [];

        if (this.props.data.length > 0) {
            result = this.props.data.map(this.getColorForItem);
        }

        return result;
    };

    private getColorForItem = (
        item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer
    ): string => {
        switch (item.referer) {
            case null:
                return COLORS.source.unknown.solid;
            case 'linkedin.com':
                return COLORS.source.linkedIn.solid;
            case 'pracuj.pl':
                return COLORS.source.pracujPl.solid;
            default:
                return COLORS.source.other.solid;
        }
    };

    private getTransparentColorForItem = (
        item: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer
    ): string => {
        switch (item.referer) {
            case null:
                return COLORS.source.unknown.transparent;
            case 'linkedin.com':
                return COLORS.source.linkedIn.transparent;
            case 'pracuj.pl':
                return COLORS.source.pracujPl.transparent;
            default:
                return COLORS.source.other.transparent;
        }
    };
}

export default withTranslation()(ApplicationsBySourceChart);
