import React from 'react';
import styles from './ApplicationFormAnswerUploadedFile.module.scss';
import classNames from 'classnames';

interface Props {
    url: string;
    className?: string;
}

export default (props: React.PropsWithChildren<Props>) => {
    if (!props.url.length) {
        return <span>-</span>;
    }

    return (
        <a href={props.url} className={classNames(props.className, styles.link)}>
            <i className='fa fa-file' /> {props.children}
        </a>
    );
};
