import { Checkbox } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruiteesActions } from '../../../../api/recruitees/recruitees.actions';
import { RecruiteesInRecruitmentFilters } from '../../../../api/recruitees/recruitees.state';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import { DropdownIndicator } from '../../../common/ReactSelect';
import styles from './RecruiteesFilterScore.module.scss';

interface StateProps {
    isLoading: boolean;
    filters: RecruiteesInRecruitmentFilters;
    stages: StageOption[];
}

interface DispatchProps {
    updateFilters: (filters: RecruiteesInRecruitmentFilters) => void;
}

interface OwnProps {
    onFiltersChange: () => void;
}

interface OwnState {
    isMenuOpen?: boolean;
}

interface StageOption {
    stageId?: string;
    isAll?: boolean;
    label?: string;
    hasCheckbox?: boolean;
}

const optionAll: StageOption = {
    label: 'recruiteesInRecruitment.allStages',
    isAll: true
};

class RecruiteesFilterScore extends Component<OwnProps & StateProps & DispatchProps & WithTranslation, OwnState> {
    state: OwnState = {};
    private ref = React.createRef<HTMLDivElement>();

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickDocument);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickDocument);
    }

    render() {
        const { t } = this.props;
        let valueLabel;

        if (this.props.filters.stageIds.length > 0) {
            /* this doesn't fit
            valueLabel = (
                <>
                    <span>{t('recruiteesInRecruitment.stages')}: </span>
                    {this.props.filters.stageIds
                        .sort()
                        .reverse()
                        .map(stageId => {
                            const stage = this.props.stages.find(stage => stage.stageId === stageId);
                            if (stage) {
                                return <strong>{stage.label}</strong>;
                            }
                            return null;
                        })
                        .reduce((prev, curr) => {
                            return (
                                <>
                                    {prev}, {curr}
                                </>
                            );
                        })}
                </>
            );*/

            valueLabel = t('recruiteesInRecruitment.stagesSelected');
        }

        if (this.props.filters.stageIds.length === 0) {
            valueLabel = t(optionAll.label!);
        }

        return (
            <div className={styles.container} ref={this.ref}>
                <div
                    onClick={this.onSelectClick}
                    className={classNames(
                        'form-control d-flex justify-content-between position-relative',
                        styles.select,
                        this.state.isMenuOpen && styles.active
                    )}>
                    <span className={styles.selectLabel}>{valueLabel}</span>
                    <DropdownIndicator />
                </div>
                {this.state.isMenuOpen && <div className={styles.menu}>{this.props.stages.map(this.renderOption)}</div>}
            </div>
        );
    }

    private renderOption = (option: StageOption, idx: number) => {
        const { t } = this.props;

        return (
            <div
                key={idx}
                className={classNames(
                    'd-flex',
                    styles.option,
                    option.label && styles.optionLabel,
                    option.label && idx === 0 && styles.optionAll
                )}
                onClick={() => this.onOptionClick(option)}>
                {option.hasCheckbox && option.stageId !== undefined && (
                    <Checkbox checked={this.props.filters.stageIds.includes(option.stageId)} />
                )}

                {option.label && <span>{t(option.label)}</span>}
            </div>
        );
    };

    private onSelectClick = () => {
        this.setState(state => ({
            isMenuOpen: !state.isMenuOpen
        }));
    };

    private onClickDocument = (e: MouseEvent) => {
        if (!this.ref.current) {
            return;
        }

        if (this.state.isMenuOpen && !this.ref.current.contains(e.target as HTMLElement)) {
            this.setState({ isMenuOpen: false });
        }
    };

    private onOptionClick = (option: StageOption) => {
        let newStages = this.props.filters.stageIds;

        if (option.stageId !== undefined) {
            if (newStages.includes(option.stageId)) {
                newStages = newStages.filter(score => score !== option.stageId);
            } else {
                newStages = [...newStages, option.stageId];
            }
        } else if (option.isAll) {
            newStages = [];
        }

        this.props.updateFilters({
            ...this.props.filters,
            stageIds: newStages
        });

        // submit filters
        this.props.onFiltersChange();
    };
}

const convertStageToOption = (stage: RecruitmentRecruiteeStageListViewDtoNormalized): StageOption => ({
    hasCheckbox: true,
    label: stage.name,
    stageId: stage.id
});

const filterOutDeletedStage = (stage: RecruitmentRecruiteeStageListViewDtoNormalized) => !stage.isDeleted;

const mapStateToProps = (state: State): StateProps => {
    const stages = listMapper(
        state.recruitmentRecruiteeStages.allStages.list,
        state.recruitmentRecruiteeStages.allStages.items
    );

    return {
        isLoading: state.recruitmentRecruiteeStages.allStages.isLoading,
        filters: state.recruitees.recruiteesInRecruitment.filters,
        stages: [optionAll, ...stages.filter(filterOutDeletedStage).map(convertStageToOption)]
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    updateFilters: (filters: RecruiteesInRecruitmentFilters) =>
        dispatch(RecruiteesActions.setRecruiteesInRecruitmentFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteesFilterScore));
