import { InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import {
    EditRecruitmentPositionCommand,
    RecruitmentPositionForEditViewDto,
    RecruitmentPositionLocalizationEditViewDto,
} from '../../../../api/recruitmentPositions/recruitmentPositions.dto';
import { RecruitmentPositionsThunks } from '../../../../api/recruitmentPositions/recruitmentPositions.thunk';
import { getCurrentLangId } from '../../../../i18n';
import { TOOLTIP } from '../../../../tooltips';
import PositionNameEditor, { FormValues } from '../edit/PositionNameEditor';
import styles from './RecruitmentPositionsList.module.scss';

type Props = RecruitmentPositionForEditViewDto & {
    doDeleteItem: (id: string) => void;
};

interface DispatchProps {
    doUpdateItem: (command: EditRecruitmentPositionCommand) => Promise<ThunkActionResult>;
}

interface RecruitmentPositionsListItemState {
    isDeleteModalOpen: boolean;
    isEditing: boolean;
    isSubmitting: boolean;
}

class RecruitmentPositionsListItem extends Component<
    Props & DispatchProps & WithTranslation,
    RecruitmentPositionsListItemState
    > {
    state: RecruitmentPositionsListItemState = {
        isDeleteModalOpen: false,
        isEditing: false,
        isSubmitting: false
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const { t } = this.props;
        const mainLangLocalization = this.getMainLocalization();

        const onDeleteConfirm = () => {
            this.closeDeleteModal();
            this.props.doDeleteItem(this.props.id);
        };
        const positionName = '';

        return (
            <>
                <tr className={styles.item}>
                    <td className={classNames(styles.col, styles.colName)}>
                        {this.state.isEditing && this.renderEditor()}

                        {!this.state.isEditing && (
                            <>{mainLangLocalization && this.renderLocalizedName(mainLangLocalization)}</>
                        )}
                    </td>
                    <td className={styles.col}>
                        {!this.state.isEditing && (
                            <div className={styles.toolsContainer}>
                                <button
                                    className='btn btn-default'
                                    data-tip={t('general.button.edit')}
                                    data-for={TOOLTIP.TOP}
                                    onClick={this.startEditing}>
                                    <i className='fa fa-fw fa-pencil' />
                                </button>
                                <button
                                    className='btn btn-default'
                                    data-tip={t('general.button.delete')}
                                    data-for={TOOLTIP.TOP}
                                    onClick={this.openDeleteModal}>
                                    <i className='fa fa-fw fa-trash-o' />
                                </button>
                            </div>
                        )}
                    </td>
                </tr>

                {ReactDOM.createPortal(
                    <InModal
                        isOpen={this.state.isDeleteModalOpen}
                        onClose={this.closeDeleteModal}
                        className='animated a500 fadeInDown'
                        backdropClassNames='animated a500 backdropFadeIn'>
                        <InModalHeader titleText={t('positionNames.deletePopupTitle')} />
                        <InModalBody>
                            <span>
                                <Trans
                                    i18nKey='positionNames.deletePopupMessage'
                                    tOptions={{
                                        positionName: mainLangLocalization
                                            ? mainLangLocalization.positionName
                                            : positionName
                                    }}>
                                    Are you sure you want to delete position <strong>{{ positionName }}</strong>?
                                    Selecting this position won’t be possible in future recruitment processes. After
                                    deleting, the position will be still visible in existing recruitment processes.
                                </Trans>
                            </span>
                        </InModalBody>
                        <InModalFooter>
                            <button
                                type='button'
                                className='btn btn-default font-bold btn-w-m'
                                onClick={this.closeDeleteModal}>
                                {t('general.button.cancel')}
                            </button>
                            <button
                                type='button'
                                className='btn btn-danger font-bold btn-w-m'
                                onClick={onDeleteConfirm}>
                                {t('general.button.delete')}
                            </button>
                        </InModalFooter>
                    </InModal>,
                    document.body
                )}
            </>
        );
    }

    private renderEditor = () => {
        const mainLangLocalization = this.getMainLocalization();
        const initialValues = {
            positionName: mainLangLocalization ? mainLangLocalization.positionName : ''
        };

        return (
            <PositionNameEditor
                onSubmit={this.onEditSubmit}
                onEditCancel={this.stopEditing}
                initialValues={initialValues}
                isSubmitting={this.state.isSubmitting}
            />
        );
    };

    private openDeleteModal = () => this.setState({ isDeleteModalOpen: true });
    private closeDeleteModal = () => this.setState({ isDeleteModalOpen: false });

    private startEditing = () => this.setState({ isEditing: true });
    private stopEditing = () => this.setState({ isEditing: false });

    private renderLocalizedName = (localization: RecruitmentPositionLocalizationEditViewDto) => {
        return (
            <div className={classNames(styles.colNameRow, localization.language === getCurrentLangId() && 'font-bold')}>
                {localization.positionName}
            </div>
        );
    };

    private onEditSubmit = async (values: FormValues) => {
        const { t } = this.props;
        const localizations: RecruitmentPositionLocalizationEditViewDto[] = [...this.props.localizations];
        localizations.find(this.findLocalizationForCurrentLang)!.positionName = values.positionName;

        this.setState({
            isSubmitting: true
        });

        const results = await this.props.doUpdateItem({
            positionId: this.props.id,
            localizations: localizations
        });

        if (results.httpStatus < 300) {
            toastr.success(t('positionNames.editSuccessMessage'), t('general.success'));
        } else {
            toastr.error(t('positionNames.editErrorMessage'), t('general.error'));
        }

        this.setState({
            isEditing: false,
            isSubmitting: false
        });
    };

    private findLocalizationForCurrentLang(localizationItem: RecruitmentPositionLocalizationEditViewDto) {
        return localizationItem.language === getCurrentLangId();
    }

    private getMainLocalization = () =>
        this.props.localizations.find(localization => localization.language === getCurrentLangId());
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    doUpdateItem: (command: EditRecruitmentPositionCommand) =>
        dispatch(RecruitmentPositionsThunks.updateRecruitmentPositionEdit(command))
});

export default connect(null, mapDispatchToProps)(withTranslation()(RecruitmentPositionsListItem));
