import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import uuid from 'uuid';

import { StageType } from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import styles from './RecruitmentRecruiteeStagesList.module.scss';

interface RecruitmentRecruiteeStageTypeIconProps {
    type: StageType;
}

class RecruitmentRecruiteeStageTypeIcon extends Component<RecruitmentRecruiteeStageTypeIconProps & WithTranslation> {
    private tooltipId = uuid.v4();

    render() {
        const { t } = this.props;
        let colorClass = styles.stageType1;
        let iconClass = 'fa-ban';

        switch (this.props.type) {
            case StageType.RejectedApplication:
                colorClass = styles.stageTypeColorDanger;
                iconClass = 'fa-file-text-o';
                break;
            case StageType.RejectedCustom:
                colorClass = styles.stageTypeColorDanger;
                iconClass = 'fa-ban';
                break;
            case StageType.New:
                colorClass = styles.stageTypeColorInfo;
                iconClass = 'fa-plus-circle';
                break;
            case StageType.Custom:
                colorClass = styles.stageTypeColorWarning;
                iconClass = 'fa-pencil-square-o';
                break;
            case StageType.Hired:
                colorClass = styles.stageTypeColorPrimary;
                iconClass = 'fa-check';
                break;
        }

        return (
            <i
                className={classNames('fa fa-fw', iconClass, colorClass)}
                data-tip={t(`recruitmentRecruiteeStages.stageType.${this.props.type}`)}
                data-for={this.tooltipId}
            />
        );
    }
}

export default withTranslation()(RecruitmentRecruiteeStageTypeIcon);
