import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'general.validation.required',
        default: 'general.validation.invalid',
        notType: 'general.validation.invalid'
    },
    number: {
        min: ({ min }) => ({ key: 'general.validation.min', values: { min } }),
        max: ({ max }) => ({ key: 'general.validation.max', values: { max } })
    },
    date: {
        min: ({ min }) => ({ key: 'general.validation.dateMin', values: { min } }),
        max: ({ max }) => ({ key: 'general.validation.dateMax', values: { max } })
    }
});
