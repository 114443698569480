import { QuestionnaireStructureDto, QuestionnaireAnswersDto } from '../questionnaires/questionnaires.dto';

export const RECRUITEE_MAX_SCORE = 5;

/**
 * Get recruitees list response.
 */
export interface RecruiteeListViewDtoPagedResult {
    result: RecruiteeListViewDto[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

/**
 * Recruitees list - single item.
 */
export interface RecruiteeListViewDto {
    id: string;
    newestJobApplicationId: string;
    newestAccessibleJobApplicationId?: string;
    firstName?: string;
    lastName?: string;
    newestResumeFileIdentifier?: string;
    phoneNumber?: string;
    email?: string;
    newestJobApplicationDate: string;
    recruiteeStageIdInNewestRecruitment: string;
    recruiteeStageTypeInNewestRecruitment: StageType;
    recruiteeStageNameInNewestRecruitment?: string;
}

export const isInstanceOfRecruiteeListViewDto = (object: any): object is RecruiteeListViewDto => {
    return 'newestJobApplicationId' in object;
};

/**
 * Single recruitee details.
 */
export interface RecruiteeViewDto {
    permissionsSufficientToViewAuthorizedData: boolean;
    basicData: RecruiteeViewBasicDataDto;
    authorizedData?: RecruiteeViewAuthorizedDataDto;
}

export interface RecruiteeViewBasicDataDto {
    id: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    address?: string;
}

export interface RecruiteeViewAuthorizedDataDto {
    jobApplicationId: string;
    recruitmentId: string;
    recruitmentRecruiteeStageId: string;
    appliedForPositionName?: string;
    jobApplicationDate: string;
    filledQuestionnaireStructure: QuestionnaireStructureDto;
    filledQuestionnaireAnswers: QuestionnaireAnswersDto;
    scoreSetByMe: number;
    averageScore: number;
    averageScoreStarCountToDisplay: number;
    reviewCount: number;
}

export const isInstanceOfRecruiteeViewDto = (object: any): object is RecruiteeViewDto => {
    return 'filledQuestionnaireStructure' in object;
};

export enum StageType {
    RejectedApplication,
    RejectedCustom,
    New,
    Custom,
    Hired
}

export interface GetRecruiteesInRecruitmentListQueryResultPagedResult {
    result: GetRecruiteesInRecruitmentListQueryResult[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export interface GetRecruiteesInRecruitmentListQueryResult {
    id: string;
    firstName: string;
    lastName: string;
    newestJobApplicationId: string;
    newestJobApplicationDate: string;
    newestResumeFileIdentifier: string;
    recruitmentRecruiteeStageId: string;
    scoreSetByMe: number;
    averageScore: number;
    averageScoreStarCountToDisplay: number;
    reviewCount: number;
}

export interface GetJobApplicationsListForRecruiteeQueryResultPagedResult {
    result: GetJobApplicationsListForRecruiteeQueryResult[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export interface GetJobApplicationsListForRecruiteeQueryResult {
    id: string;
    createDate: string;
    recruitmentId: string;
    recruitmentTitle: string;
    recruitmentRecruiteeStageId: string;
    recruitmentRecruiteeMyScore: number;
}

export interface GetAllScoresSummaryForRecruiteeInRecruitmentQueryResult {
    averageScoreStarCountToDisplay: number;
    averageScore: number;
    reviewCount: number;
    scoreSetByMe: number;
    recruitmentRecruiteeScoresSummaryRows: RecruitmentRecruiteeScoresSummaryRowDtoPagedResult;
}

export interface RecruitmentRecruiteeScoresSummaryRowDtoPagedResult {
    result: RecruitmentRecruiteeScoresSummaryRowDto[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export interface RecruitmentRecruiteeScoresSummaryRowDto {
    scoringATSUserId: string;
    scoringATSUserFirstName: string;
    scoringATSUserLastName: string;
    scoringATSUserAvatar?: string;
    scoringTime: string;
    score: number;
}

export enum RecruiteePhoneCallType {
    Incoming = 0,
    Outgoing
}

export interface LogRecruitmentRecruiteePhoneCallCommand {
    recruitmentId: string;
    recruiteeId: string;
    description: string;
    callType: RecruiteePhoneCallType;
    callTime: string;
}

export interface AddRecruitmentRecruiteeNoteCommand {
    recruitmentId: string;
    recruiteeId: string;
    text: string;
}

export interface ScheduleMeetingCommand {
    recruitmentId: string;
    recruiteeId: string;
    meetingName: string;
    invitedPeople: string[];
    meetingDateTime: string;
    timeZoneId: string;
    durationInMinutes: number;
    messageContent: string;
}

export interface GetScoreForRecruiteeInRecruitmentQueryResult {
    scoreSetByMe: number;
    averageScore: number;
    reviewCount: number;
}

/**
 * Recruitment Recruitee activities
 */

export interface GetRecruitmentRecruiteeActivityListQuery {
    recruitmentId: string;
    recruiteeId: string;
    PageNumber: number;
    PageSize: number;
}

export interface GetRecruitmentRecruiteeActivityListQueryResultPagedResult {
    result: [];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export interface GetRecruitmentRecruiteeActivityListQueryResult {
    id: string;
    recruitmentId: string;
    recruiteeId: string;
    creationTimestamp: string;
    activityTime: string;
    activityType: ActivityType;
    activityData: ActivityData;
}

export enum ActivityType {
    JobApplicationSent,
    ScoreChanged,
    PhoneCallPerformed,
    MessageSent,
    NoteAdded,
    StageChanged,
    MeetingScheduled
}

interface ActivityDataWithAtsUserData {
    atsUserId: string;
    atsUserFirstName: string;
    atsUserLastName: string;
    atsUserAvatar: string;
}

// Activity data types

export type ActivityData =
    | JobApplicationSentActivityData
    | ScoreChangedActivityData
    | PhoneCallPerformedActivityData
    | MessageSentActivityData
    | NoteAddedActivityData
    | StageChangedActivityData
    | MeetingScheduledActivityData;

export interface JobApplicationSentActivityData {
    jobApplicationId: string;
    appliedForPositionId: string;
    applicationTime: string;
    positionName: string;
    recruiteeId: string;
    recruiteeFirstName: string;
    recruiteeLastName: string;
    recruiteePhoto: string;
}

export interface ScoreChangedActivityData extends ActivityDataWithAtsUserData {
    oldScore: number;
    newScore: number;
    comment: string;
}

export interface PhoneCallPerformedActivityData extends ActivityDataWithAtsUserData {
    description: string;
    callType: RecruiteePhoneCallType;
}

export interface MessageSentActivityData extends ActivityDataWithAtsUserData {
    text: string;
}

export interface NoteAddedActivityData extends ActivityDataWithAtsUserData {
    text: string;
}

export interface StageChangedActivityData extends ActivityDataWithAtsUserData {
    oldStageId: string;
    newStageId: string;
}

export interface MeetingScheduledActivityData extends ActivityDataWithAtsUserData {
    meetingName: string;
    invitedPeople: string[];
    meetingDateTime: string;
    timeZoneId: string;
    durationInMinutes: string;
    messageContent: string;
}

// Activity types

export interface JobApplicationSentActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.JobApplicationSent;
    activityData: JobApplicationSentActivityData;
}

export interface ScoreChangedActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.ScoreChanged;
    activityData: ScoreChangedActivityData;
}

export interface PhoneCallPerformedActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.PhoneCallPerformed;
    activityData: PhoneCallPerformedActivityData;
}

export interface MessageSentActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.MessageSent;
    activityData: MessageSentActivityData;
}

export interface NoteAddedActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.NoteAdded;
    activityData: NoteAddedActivityData;
}

export interface StageChangedActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.StageChanged;
    activityData: StageChangedActivityData;
}

export interface MeetingScheduledActivity extends GetRecruitmentRecruiteeActivityListQueryResult {
    activityType: ActivityType.MeetingScheduled;
    activityData: MeetingScheduledActivityData;
}

export type Activity =
    | JobApplicationSentActivity
    | ScoreChangedActivity
    | PhoneCallPerformedActivity
    | MessageSentActivity
    | NoteAddedActivity
    | StageChangedActivity
    | MeetingScheduledActivity;
