import { Api } from '../_commons/http';
import { ThunkAction } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { RecruitersBrowserActions } from './recruitersBrowser.actions';
import { GetATSUsersListForRecruitmentTeamMembersChoiceQueryResultPagedResult } from './recruitersBrowser.dto';
import { convertApiRecruitersBrowserRecruitersToSchema } from './recruitersBrowser.schema';

const DEFAULT_PAGE_SIZE = 10;

export class RecruitersBrowserThunks {
    static getUsersListForRecruitmentTeamMembersChoice = (
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruitersBrowserActions.getRecruitersBrowserRecruitersStarted());

        // collect filters from state
        const searchQuery = getState().recruitersBrowser.recruitmentTeam.filters.searchQuery;
        const skipUserIds = getState().recruitersBrowser.recruitmentTeam.filters.skipUserIds;

        const queryParams: Record<string, string | number | string[]> = {
            page,
            pageSize,
            ATSUserIdsToSkip: skipUserIds
        };

        if (searchQuery) {
            queryParams.SearchQuery = searchQuery;
        }

        const { data, status, error } = await Api().get<
            GetATSUsersListForRecruitmentTeamMembersChoiceQueryResultPagedResult
        >(endpoints.recruitersForRecruitmentTeamMembersChoice, queryParams);

        if (data) {
            const parsed = convertApiRecruitersBrowserRecruitersToSchema(data.result);

            dispatch(RecruitersBrowserActions.getRecruitersBrowserRecruitersFinished(parsed));
        } else {
            dispatch(
                RecruitersBrowserActions.getRecruitersBrowserRecruitersFailed({ error: error!, httpStatus: status })
            );
        }
    };

    static getUsersListForHiringManagerChoice = (
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruitersBrowserActions.getRecruitersHiringManagerStarted());

        // collect filters from state
        const searchQuery = getState().recruitersBrowser.recruitmentTeam.filters.searchQuery;
        const skipUserIds = getState().recruitersBrowser.recruitmentTeam.filters.skipUserIds;

        const queryParams: Record<string, string | number | string[]> = {
            page,
            pageSize,
            ATSUserIdsToSkip: skipUserIds
        };

        if (searchQuery !== undefined) {
            queryParams.SearchQuery = searchQuery;
        }

        const { data, status, error } = await Api().get<
            GetATSUsersListForRecruitmentTeamMembersChoiceQueryResultPagedResult
        >(endpoints.recruitersForHiringManagerChoice, queryParams);

        if (data) {
            const parsed = convertApiRecruitersBrowserRecruitersToSchema(data.result);

            dispatch(RecruitersBrowserActions.getRecruitersHiringManagerFinished(parsed));
        } else {
            dispatch(RecruitersBrowserActions.getRecruitersHiringManagerFailed({ error: error!, httpStatus: status }));
        }
    };

    static getUsersListForRecruitmentOwnerChoice = (
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async (dispatch, getState) => {
        dispatch(RecruitersBrowserActions.getRecruitersRecruitmentOwnerStarted());

        // collect filters from state
        const searchQuery = getState().recruitersBrowser.recruitmentTeam.filters.searchQuery;
        const skipUserIds = getState().recruitersBrowser.recruitmentTeam.filters.skipUserIds;

        const queryParams: Record<string, string | number | string[]> = {
            page,
            pageSize,
            ATSUserIdsToSkip: skipUserIds
        };

        if (searchQuery !== undefined) {
            queryParams.SearchQuery = searchQuery;
        }

        const { data, status, error } = await Api().get<
            GetATSUsersListForRecruitmentTeamMembersChoiceQueryResultPagedResult
        >(endpoints.recruitersForRecruitmentOwnerChoice, queryParams);

        if (data) {
            const parsed = convertApiRecruitersBrowserRecruitersToSchema(data.result);

            dispatch(RecruitersBrowserActions.getRecruitersRecruitmentOwnerFinished(parsed));
        } else {
            dispatch(
                RecruitersBrowserActions.getRecruitersRecruitmentOwnerFailed({ error: error!, httpStatus: status })
            );
        }
    };
}
