import { InModal, InModalBody, InModalFooter, InModalHeader, Wysiwyg } from '@emplo/react-inspinia';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RECRUITEE_MAX_SCORE } from '../../../api/recruitees/recruitees.dto';
import styles from './RecruiteeGiveScoreModal.module.scss';
import RecruiteeScoreStars from './RecruiteeScoreStars';

interface RecruiteeGiveScoreModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (score: number, comment: string) => void;
    initialScore?: number;
}

export default (props: RecruiteeGiveScoreModalProps) => {
    const { t } = useTranslation();
    const [score, setScore] = useState(props.initialScore || 0);
    const [comment, setComment] = useState<EditorState>(EditorState.createEmpty());

    const onScoreChange = (newScore: number) => setScore(newScore);

    const onSubmit = () => {
        props.onSubmit(score, draftToHtml(convertToRaw(comment.getCurrentContent())));
    };

    return (
        <InModal
            isOpen={props.isOpen}
            onClose={props.closeModal}
            className='animated a500 fadeInDown'
            backdropClassNames='animated a500 backdropFadeIn'>
            <InModalHeader titleText={t('recruiteeView.changeScore')} onClose={props.closeModal} />
            <InModalBody className='bg-white p-0'>
                <div className='d-flex flex-column align-items-center'>
                    <RecruiteeScoreStars
                        initialScore={score}
                        starsCount={RECRUITEE_MAX_SCORE}
                        onScoreChange={onScoreChange}
                        className={styles.score}
                    />
                    <Wysiwyg
                        name='comment'
                        editorState={comment}
                        setFieldValue={(name: string, value: EditorState) => setComment(value)}
                        placeholder={t('recruiteeView.changeScorePlaceholder')}
                        wrapperStyle={{ height: 200 }}
                    />
                </div>
            </InModalBody>
            <InModalFooter className='border-top-0'>
                <button onClick={props.closeModal} className='btn btn-default font-bold btn-w-m'>
                    {t('general.button.close')}
                </button>
                <button onClick={onSubmit} className='btn btn-primary font-bold btn-w-m'>
                    {t('general.button.save')}
                </button>
            </InModalFooter>
        </InModal>
    );
};
