import { Api } from '../_commons/http';
import { ThunkAction } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { UserActions } from '../user/user.actions';
import { GetCurrentATSUserDataQueryResult } from '../user/user.dto';
import { AuthActions } from './auth.actions';

export class AuthThunks {
    /**
     * This action is a test API call to check the response. If the response is 401 Unauthenticated,
     * then the user is not logged in yet and the app will redirect the user to the login screen.
     * If the response is 2xx then the user is logged in and the app can continue working as if nothing happenned.
     */
    static checkAuthState = (): ThunkAction => async dispatch => {
        dispatch(UserActions.getProfileStarted());

        const { data, status, error } = await Api().get<GetCurrentATSUserDataQueryResult>(endpoints.atsUsersCurrent);

        if (status === 401) {
            dispatch(UserActions.getProfileFailed({ error: error!, httpStatus: status }));
        } else if (data) {
            dispatch(UserActions.getProfileFinished(data));
        }
    };

    static logout = (): ThunkAction => async dispatch => {
        dispatch(AuthActions.logoutStarted());

        const { error, status } = await Api().get(endpoints.atsUsersLogout);

        if (status === 200) {
            dispatch(AuthActions.logoutFinished());
        } else {
            dispatch(
                AuthActions.logoutFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }
    };
}
