import {
    ListStateWithLoadingState,
    ListFiltersState,
    LoadingState,
    WritingState,
    WithErrorState
} from '../_commons/state.common';
import {
    QuestionnaireListViewDto,
    QuestionnaireViewDto,
    QuestionnaireListViewForRecruitmentDto
} from './questionnaires.dto';

export const initialState: QuestionnairesState = {
    questionnaires: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        isWriting: false,
        error: null,
        filters: {}
    },
    questionnaireDetails: {
        isLoading: false,
        isWriting: false,
        error: null
    },
    questionnairesForRecruitment: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    }
};

export interface QuestionnairesListFilters {
    searchQuery?: string;
}

export type QuestionnaireDetailsState = LoadingState &
    WritingState &
    WithErrorState & {
        questionnaire?: QuestionnaireViewDto;
    };

export type QuestionnairesState = {
    questionnaires: ListStateWithLoadingState<string, QuestionnaireListViewDto> &
        ListFiltersState<QuestionnairesListFilters> &
        WritingState;
    questionnairesForRecruitment: ListStateWithLoadingState<string, QuestionnaireListViewForRecruitmentDto>;
    questionnaireDetails: QuestionnaireDetailsState;
};
