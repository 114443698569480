import { Preloader } from '@emplo/react-inspinia';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruiteeAllScoresSummaryItem } from '../../../../api/recruitees/recruitees.state';
import { RecruiteesThunks } from '../../../../api/recruitees/recruitees.thunk';
import { State } from '../../../../store/state';
import ErrorPage from '../../../pages/ErrorPage';
import styles from './RecruiteeReviewsList.module.scss';
import RecruiteeReviewsListItem from './RecruiteeReviewsListItem';

interface OwnProps {
    recruiteeId: string;
    recruitmentId: string;
}

interface StateProps {
    items: RecruiteeAllScoresSummaryItem[];
    isLoading: boolean;
    error: ErrorState;
}

interface DispatchProps {
    fetchItems: (page?: number, pageSize?: number) => Promise<void>;
}

const RecruiteeReviewsList = (props: StateProps & DispatchProps) => {
    useEffect(() => {
        props.fetchItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.isLoading) {
        return <Preloader overlay />;
    }

    if (props.error) {
        return <ErrorPage error={props.error} />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.listContainer}>{props.items.map(renderListItem)}</div>
        </div>
    );
};

const renderListItem = (item: RecruiteeAllScoresSummaryItem, idx: number) => (
    <RecruiteeReviewsListItem {...item} key={idx} />
);

const mapStateToProps = (state: State): StateProps => ({
    isLoading: state.recruitees.recruiteeScoresSummary.isLoading,
    error: state.recruitees.recruiteeScoresSummary.error,
    items: state.recruitees.recruiteeScoresSummary.items
});

const mapDispatchToProps = (dispatch: ThunkDispatch, props: OwnProps): DispatchProps => ({
    fetchItems: (page: number = 1, pageSize: number = 1000) =>
        dispatch(
            RecruiteesThunks.getAllScoresSummaryForRecruiteeInRecruitment(
                props.recruiteeId,
                props.recruitmentId,
                page,
                pageSize
            )
        )
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruiteeReviewsList);
