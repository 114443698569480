import { Currency, CurrencyDto } from '../settings/settings.dto';

/**
 * New recruitment's data.
 */
export interface AddRecruitmentCommand {
    title: string;
    deadline: string;
    vacantPosts: number;
    budgetPerPositionMin: number;
    budgetPerPositionMax: number;
    budgetCurrency: Currency;
    newOrExistingPosition: string;
    jobTitle: string;
    jobDescription: string;
    locationDescription: string;
    locationLatitude: number;
    locationLongitude: number;
    remoteWorkIsPossible: boolean;
    keywords: string[];
    questionnaireId: string;
    isDraft: boolean;
    recruitmentOwnerId: string;
    hiringManagerId: string;
    recruiterIds: string[];
}

export interface ATSUserForRecruitmentEditView {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string;
}

/**
 * Response for newly created recruitment.
 */
export interface AddRecruitmentCommandResult {
    id: string;
}

export interface EditRecruitmentCommand extends AddRecruitmentCommand {
    id: string;
}

/**
 * Get recruitments list response.
 */
export interface RecruitmentListViewDtoPagedResult {
    result: RecruitmentListViewDto[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

/**
 * Get recruitments list - single item.
 */
export interface RecruitmentListViewDto {
    id: string;
    title?: string;
    positionName?: string;
    deadline: string;
    recruitmentStatus: RecruitmentStatus;
    candidatesCount: number;
    applicantsCount: number;
    newRecruiteesCount: number;
    hiringManager?: HiringManagerListViewHiringManagerListView;
    recruitmentOwner: RecruitmentOwnerListViewRecruitmentOwnerListView;
}

export interface RecruitmentOwnerListViewRecruitmentOwnerListView {
    id: string;
    firstName: string;
    lastName: string;
    avatar?: string;
}

export type HiringManagerListViewHiringManagerListView = RecruitmentOwnerListViewRecruitmentOwnerListView;

/**
 * Single recruitment details.
 */
export interface RecruitmentViewDto {
    id: string;
    title: string;
    createDate: string;
    deadline: string;
    vacantPosts: number;
    budgetPerPositionMin: number;
    budgetPerPositionMax: number;
    budgetCurrency: CurrencyDto;
    status: RecruitmentStatus;
    positionId: string;
    positionName: string;
    jobTitle: string;
    jobDescription: string;
    locationDescription: string;
    locationLatitude: number;
    locationLongitude: number;
    remoteWorkIsPossible: boolean;
    keywords: string[];
    questionnaireId: string;
    jobOfferUrl?: string;
    hiringManager: ATSUserForRecruitmentEditView;
    recruitmentOwner: ATSUserForRecruitmentEditView;
    recruitmentTeamMembers: ATSUserForRecruitmentEditView[];
    candidatesAndApplicants: {
        candidateCount: number;
        applicantCount: number;
    };
    recruitmentRecruiteesByStageList: RecruitmentRecruiteesByStage[];
    recruitmentJobApplicationsByRefererList: RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer[];
}

/**
 * Recruitment editing view.
 */
export interface GetRecruitmentViewForEditQueryResult {
    id: string;
    title: string;
    createDate: string;
    deadline: string;
    vacantPosts: number;
    budgetPerPositionMin: number;
    budgetPerPositionMax: number;
    budgetCurrency: CurrencyDto;
    status: RecruitmentStatus;
    positionId: string;
    positionName: string;
    jobTitle: string;
    jobDescription: string;
    locationLatitude: number;
    locationLongitude: number;
    locationDescription: string;
    remoteWorkIsPossible: boolean;
    keywords: string[];
    questionnaireId: string;
    recruitmentOwner: ATSUserForRecruitmentEditView;
    hiringManager: ATSUserForRecruitmentEditView;
    recruitmentTeamMembers: ATSUserForRecruitmentEditView[];
}

export interface RecruitmentJobApplicationsByRefererRecruitmentJobApplicationsByReferer {
    referer: string | null;
    count: number;
}

export interface RecruitmentRecruiteesByStage {
    recruitmentRecruiteeStageId: string;
    count: number;
}

export enum RecruitmentStatus {
    DRAFT,
    PENDING,
    OPEN,
    CLOSED
}

export interface EditRecruitmentRecruitersCommand {
    recruitmentId: string;
    editedRecruitmentRecruiterIds: string[];
}
