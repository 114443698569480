import _, { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import {
    RecruitmentRecruiteeStageEditViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import {
    RecruitmentRecruiteeStagesThunks,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import styles from './RecruitmentRecruiteeStagesList.module.scss';
import RecruitmentRecruiteeStagesListItem from './RecruitmentRecruiteeStagesListItem';

interface StateProps {
    items: RecruitmentRecruiteeStageEditViewDtoNormalized[];
}

interface DispatchProps {
    doReorderItem: (itemId: string, newIndex: number) => Promise<ThunkActionResult>;
    doReloadItems: () => void;
}

type StagesByStageTypeGroup = RecruitmentRecruiteeStageEditViewDtoNormalized[];

class RecruitmentRecruiteeStagesList extends Component<StateProps & DispatchProps & WithTranslation> {
    render() {
        const itemsGroupedByType = _.reduce(
            this.props.items,
            this.groupItemsByStageTypeReducer,
            [] as StagesByStageTypeGroup[]
        );

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className={styles.listContainer}>{itemsGroupedByType.map(this.renderItemsByGroup)}</div>
            </DragDropContext>
        );
    }

    private groupItemsByStageTypeReducer = (
        prev: StagesByStageTypeGroup[],
        curr: RecruitmentRecruiteeStageEditViewDtoNormalized
    ) => {
        const result = [...prev];
        const lastType = prev.length ? prev[prev.length - 1][0].stageType : null;

        if (lastType === null || curr.stageType !== lastType) {
            result.push([curr]);
        } else {
            result[result.length - 1].push(curr);
        }

        return result;
    };

    private renderItemsByGroup = (group: StagesByStageTypeGroup) => {
        const items = group.map(this.renderItem);

        return this.wrapInDroppable(items, group[0].stageType);
    };

    private renderItem = (item: RecruitmentRecruiteeStageEditViewDtoNormalized) => {
        return <RecruitmentRecruiteeStagesListItem {...item} index={item.order - 1} key={item.id} />;
    };

    private wrapInDroppable = (children: React.ReactNode, type: number) => {
        return (
            <Droppable type={`${type}`} droppableId={`${type}`} key={type}>
                {(provided, snapshot) => {
                    return (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {children}
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        );
    };

    private onDragEnd = (result: DropResult) => {
        if (result.type !== result.source.droppableId) {
            return;
        }

        if (result.destination && result.source.index !== result.destination.index) {
            let targetIndex = result.destination.index;
            if (result.source.index < result.destination.index) {
                targetIndex += 1;
            }
            this.onReorderItem(result.draggableId, targetIndex);
        }
    };

    private onReorderItem = async (itemId: string, newIndex: number) => {
        const { t } = this.props;

        // do reorder items

        const results = await this.props.doReorderItem(itemId, newIndex);
        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentRecruiteeStages.success.reorderMessage'), t('general.success'));
        } else {
            this.props.doReloadItems();
            toastr.error(t('recruitmentRecruiteeStages.error.reorderMessage'), t('general.error'));
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    items: listMapper<RecruitmentRecruiteeStageEditViewDtoNormalized, undefined, string>(
        state.recruitmentRecruiteeStages.editStages.list,
        state.recruitmentRecruiteeStages.editStages.items as Dictionary<RecruitmentRecruiteeStageEditViewDtoNormalized>
    )
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    doReloadItems: () => dispatch(RecruitmentRecruiteeStagesThunks.getStageEdits()),
    doReorderItem: (itemId: string, newIndex: number) =>
        dispatch(RecruitmentRecruiteeStagesThunks.reorderStageEdits(itemId, newIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentRecruiteeStagesList));
