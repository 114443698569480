import { TFunction } from 'i18next';
import { Dictionary } from 'lodash';

import { Activity, ActivityType } from '../../../../../api/recruitees/recruitees.dto';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { GetAvailableTimezonesQueryResult } from '../../../../../api/settings/settings.dto';
import { ActivityItem } from './ActivityItem';
import JobApplicationSentEvent from './JobApplicationSentEvent';
import MeetingScheduledEvent from './MeetingScheduledEvent';
import MessageSentEvent from './MessageSentEvent';
import NoteAddedEvent from './NoteAddedEvent';
import PhoneCallPerformedEvent from './PhoneCallPerformedEvent';
import ScoreChangedEvent from './ScoreChangedEvent';
import StageChangedEvent from './StageChangedEvent';

/**
 * Factory function for creating instances of ActivityItem based on the eventType value.
 *
 * @param event Event history item.
 * @param t TFunction for translating texts in the item.
 */
export const createActivityItem = (
    event: Activity,
    t: TFunction,
    stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>,
    timezones: Dictionary<GetAvailableTimezonesQueryResult>
): ActivityItem => {
    switch (event.activityType) {
        case ActivityType.JobApplicationSent: {
            return new JobApplicationSentEvent(event, event.activityData, t);
        }
        case ActivityType.MessageSent: {
            return new MessageSentEvent(event, event.activityData, t);
        }
        case ActivityType.NoteAdded: {
            return new NoteAddedEvent(event, event.activityData, t);
        }
        case ActivityType.PhoneCallPerformed: {
            return new PhoneCallPerformedEvent(event, event.activityData, t);
        }
        case ActivityType.ScoreChanged: {
            return new ScoreChangedEvent(event, event.activityData, t);
        }
        case ActivityType.StageChanged: {
            return new StageChangedEvent(event, event.activityData, t, stages);
        }
        case ActivityType.MeetingScheduled: {
            return new MeetingScheduledEvent(event, event.activityData, t, timezones);
        }
    }
};
