import { FileUpload, LabelFieldHorizontal } from '@emplo/react-inspinia';
import _ from 'lodash';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

import { AnswerableQuestionnaireElement, QuestionnaireAttachment } from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireAttachmentForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireAttachment>
    > {
    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                isQuestionEditable={true}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = () => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for=''>
                <FileUpload
                    disabled
                    labelButton={t('questionnaires.questionnaireForm.question.attachment.button')}
                    placeholder={t('questionnaires.questionnaireForm.question.attachment.placeholder')}
                    name=''
                    setFieldValue={_.noop}
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireAttachmentForm = withTranslation()(questionnaireAttachmentForm);

export const QuestionnaireAttachmentPreview = (props: QuestionnaireElementPreviewProps<QuestionnaireAttachment>) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                (props.itemData as QuestionnaireAttachment).question +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <FileUpload
                labelButton={t('questionnaires.questionnaireForm.question.attachment.button')}
                placeholder={t('questionnaires.questionnaireForm.question.attachment.placeholder')}
                name=''
                setFieldValue={_.noop}
            />
        </LabelFieldHorizontal>
    );
};
