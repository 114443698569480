import { Preloader } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { ThunkDispatch } from './api/_commons/thunks.common';
import { AuthThunks } from './api/auth/auth.thunks';
import { loginRouteRenderer } from './components/login/routes';
import { errorPageNotFoundRouteRenderer } from './components/pages/routes';
import { RouteConfig } from './routes';
import { State } from './store/state';
import { TOOLTIP } from './tooltips';

interface AppProps {
    routes: RouteConfig[];
    isLoggedIn: boolean;
    isLoggingOut: boolean;
    isCheckingAuthState: boolean;
    checkAuthState: () => void;
}

class App extends Component<AppProps> {
    componentDidMount() {
        this.props.checkAuthState();
    }

    render() {
        const routes = this.renderRoutes();

        if (this.props.isCheckingAuthState) {
            return <Preloader overlay />;
        }

        return (
            <>
                {/* app routes */}
                <Switch>
                    {routes}

                    {/* not found or login redirect */}
                    <Route render={this.props.isLoggedIn ? errorPageNotFoundRouteRenderer : loginRouteRenderer} />
                </Switch>

                {this.props.isLoggingOut && <Preloader overlay />}

                {ReactDOM.createPortal(
                    <>
                        <ReactTooltip effect='solid' place='bottom' id={TOOLTIP.BOTTOM} globalEventOff='click' />
                        <ReactTooltip effect='solid' place='top' id={TOOLTIP.TOP} globalEventOff='click' />
                    </>,
                    document.body
                )}
            </>
        );
    }

    /**
     * Returns list of `Route`s for the app.
     */
    private renderRoutes = () => {
        let routes = this.props.routes;

        // filter routes to not add secure ones when the user is not logged in
        if (!this.props.isLoggedIn) {
            routes = routes.filter(routeCfg => !routeCfg.isSecure);
        }

        return routes.map(routeConfig => (
            <Route
                exact={routeConfig.isExact}
                key={routeConfig.path}
                path={routeConfig.path}
                render={routeConfig.renderer}
            />
        ));
    };
}

const mapStateToProps = (state: State) => ({
    isLoggedIn: state.auth.isLogged,
    isLoggingOut: state.auth.isLoggingOut,
    isCheckingAuthState: state.auth.isCheckingCredentials
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    checkAuthState: () => dispatch(AuthThunks.checkAuthState())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
