import _ from 'lodash';
import { combineReducers } from 'redux';

import {
    RecruitmentRecruiteeStagesAction,
    RecruitmentRecruiteeStagesActionTypes,
} from './recruitmentRecruiteeStages.actions';
import { RecruitmentRecruiteeStageEditViewDtoNormalized } from './recruitmentRecruiteeStages.schema';
import { initialState, RecruitmentRecruiteeStagesState } from './recruitmentRecruiteeStages.state';

const recruitmentRecruiteeStagesAllStagesReducer = (
    state: RecruitmentRecruiteeStagesState['allStages'] = initialState['allStages'],
    action: RecruitmentRecruiteeStagesAction
): RecruitmentRecruiteeStagesState['allStages'] => {
    switch (action.type) {
        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: action.response.stages,
                isLoading: false,
                totalCount: action.response.totalCount,
                page: action.response.page,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const recruitmentRecruiteeStagesEditStagesReducer = (
    state: RecruitmentRecruiteeStagesState['editStages'] = initialState['editStages'],
    action: RecruitmentRecruiteeStagesAction
): RecruitmentRecruiteeStagesState['editStages'] => {
    switch (action.type) {
        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_STARTED:
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: action.response.stages,
                isLoading: false,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_FINISHED:
            return {
                ...state,
                isLoading: false,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGE_EDITS_FAILED:
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDITS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case RecruitmentRecruiteeStagesActionTypes.REORDER_RECRUITMENT_STAGE_EDITS: {
            const newItems: Record<string, RecruitmentRecruiteeStageEditViewDtoNormalized> = { ...state.items };
            const newList = [...state.list];
            const currentIndex = state.list.findIndex(itemId => action.stageId === itemId);
            const newIndex = action.newIndex > currentIndex ? action.newIndex - 1 : action.newIndex;

            newList.splice(currentIndex, 1);
            newList.splice(newIndex, 0, action.stageId);

            _.forEach(newList, (itemId, idx) => {
                newItems[itemId].order = idx + 1;
            });

            return {
                ...state,
                list: newList,
                items: newItems
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.DELETE_RECRUITMENT_STAGE_EDIT: {
            const newItems: Record<string, RecruitmentRecruiteeStageEditViewDtoNormalized> = { ...state.items };
            const newList = [...state.list];
            const index = state.list.findIndex(itemId => action.stageId === itemId);

            // delete the item from the list
            newList.splice(index, 1);

            // delete the item from the items map
            delete newItems[action.stageId];

            // update order after removal
            _.forEach(newList, (itemId, idx) => {
                newItems[itemId].order = idx + 1;
            });

            return {
                ...state,
                list: newList,
                items: newItems
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.UPDATE_RECRUITMENT_STAGE_EDIT: {
            const currentData = state.items[action.data.id];

            return {
                ...state,
                items: {
                    ...state.items,
                    [action.data.id]: {
                        ...action.data,
                        order: currentData.order // don't change order here because it shouldn't be changed by this action
                    }
                }
            };
        }
        case RecruitmentRecruiteeStagesActionTypes.CREATE_RECRUITMENT_STAGE_EDIT: {
            // sort items by order
            // find last stage of the same type
            // set new stage's order to order+1
            // all following stages order+1

            const newItem = { ...action.data, order: 0 };

            const sortedItemsList = _.values(state.items).sort((a, b) =>
                a.order === b.order ? 0 : a.order > b.order ? 1 : -1
            );

            let insertAtIndex = 0;
            _.forEach(sortedItemsList, (item, idx) => {
                if (item.stageType === action.data.stageType) {
                    newItem.order = item.order + 1;
                    insertAtIndex = idx + 1;
                } else if (newItem.order > 0) {
                    // this is not the stage type of the new item but new item has order increased,
                    // so we passed that stage type -> now we increment order of the following items
                    item.order += 1;
                }
            });

            // insert new item in the list at correct index
            const newList = [...state.list];
            newList.splice(insertAtIndex, 0, newItem.id);

            // add new item to the items map
            const newItems = { ...state.items, [newItem.id]: newItem };

            return {
                ...state,
                list: newList,
                items: newItems
            };
        }
        default:
            return state;
    }
};

const recruitmentRecruiteeStagescurrentRecruiteeAvailableStagesReducer = (
    state: RecruitmentRecruiteeStagesState['currentRecruiteeAvailableStages'] = initialState[
        'currentRecruiteeAvailableStages'
    ],
    action: RecruitmentRecruiteeStagesAction
): RecruitmentRecruiteeStagesState['currentRecruiteeAvailableStages'] => {
    switch (action.type) {
        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: action.response.stages,
                isLoading: false,
                error: null
            };

        case RecruitmentRecruiteeStagesActionTypes.GET_RECRUITMENT_STAGES_FOR_RECRUITEE_IN_RECRUITMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<RecruitmentRecruiteeStagesState>({
    allStages: recruitmentRecruiteeStagesAllStagesReducer,
    editStages: recruitmentRecruiteeStagesEditStagesReducer,
    currentRecruiteeAvailableStages: recruitmentRecruiteeStagescurrentRecruiteeAvailableStagesReducer
});
