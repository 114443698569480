import React, { Component } from 'react';
import styles from './ActivitiesList.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface ActivitiesListItemProps {
    avatar: string;
    content: JSX.Element;
    when: string;
    link?: string;
}

class ActivitiesListItem extends Component<ActivitiesListItemProps> {
    render() {
        if (this.props.link) {
            return (
                <Link to={this.props.link} className={classNames('feed-element', styles.item)}>
                    {this.renderContent()}
                </Link>
            );
        }

        return <div className='feed-element'>{this.renderContent()}</div>;
    }

    private renderContent = () => {
        return (
            <>
                <div className={styles.photoWrapper} style={{ backgroundImage: `url(${this.props.avatar})` }}>
                    <img alt='Avatar' src={this.props.avatar} />
                </div>
                <div className='media-body'>
                    <div>{this.props.content}</div>
                    <span className='text-small'>{this.props.when}</span>
                </div>
            </>
        );
    };
}

export default ActivitiesListItem;
