import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { GetCurrentATSUserDataQueryResult } from './user.dto';

export enum UserActionTypes {
    GET_PROFILE_STARTED = 'USER_GET_PROFILE_STARTED',
    GET_PROFILE_FINISHED = 'USER_GET_PROFILE_FINISHED',
    GET_PROFILE_FAILED = 'USER_GET_PROFILE_FAILED'
}

export const UserActions = {
    getProfileStarted: () => createAction(UserActionTypes.GET_PROFILE_STARTED),
    getProfileFinished: (response: GetCurrentATSUserDataQueryResult) =>
        createAction(UserActionTypes.GET_PROFILE_FINISHED, { response }),
    getProfileFailed: (error: ErrorState) => createAction(UserActionTypes.GET_PROFILE_FAILED, { error })
};

export type UserAction = ActionsUnion<typeof UserActions>;
