import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../api/_commons/thunks.common';
import { AuthThunks } from '../../api/auth/auth.thunks';
import styles from './LogoutButton.module.scss';

interface LogoutButtonProps {
    doLogout: () => void;
}

class LogoutButton extends Component<LogoutButtonProps & WithTranslation> {
    render() {
        const { t } = this.props;

        return (
            <div onClick={this.onClick} className={styles.btn}>
                {t('general.logout')}
            </div>
        );
    }

    private onClick = () => {
        // TODO: some confirmation dialog would be useful here
        this.props.doLogout();
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    doLogout: () => dispatch(AuthThunks.logout())
});

export default connect(null, mapDispatchToProps)(withTranslation()(LogoutButton));
