import { ReactNode } from 'react';
import { generatePath, RouteComponentProps } from 'react-router';

import { dashboardRouteRenderer } from './components/dashboard/routes';
import { loginRouteRenderer } from './components/login/routes';
import {
    errorPageNotFoundRouteRenderer,
    errorPageUnauthorizedRouteRenderer,
    forgotPasswordRouteRenderer,
} from './components/pages/routes';
import {
    editQuestionnaireRouteRenderer,
    newQuestionnaireRouteRenderer,
    questionnairesRouteRenderer,
} from './components/questionnaires/routes';
import { recruiteesRouteRenderer, showRecruiteeRouteRenderer } from './components/recruitees/routes';
import {
    editRecruitmentRouteRenderer,
    newRecruitmentRouteRenderer,
    recruiteesInRecruitmentRouteRenderer,
    recruitmentsRouteRenderer,
    showRecruitmentRouteRenderer,
} from './components/recruitments/routes';
import { settingsRouteRenderer } from './components/settings/routes';

/**
 * Enum defining all existing routes.
 */
export enum RoutePathEnum {
    // public
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    FILLED_QUESTIONNAIRE_HTML_FOR_PDF = '/pdf/get-filled-questionnaire-html/:jobApplicationId/:atsCustomAuthorizationToken',
    ERROR_404_NOT_FOUND = '/error/not-found',
    ERROR_401_UNAUTHORIZED = '/error/unauthorized',
    // secure
    DASHBOARD = '/',
    RECRUITMENTS_LIST = '/recruitments',
    RECRUITMENTS_NEW = '/recruitments/new',
    RECRUITMENTS_SHOW_RECRUITMENT = '/recruitments/:id',
    RECRUITMENTS_RECRUITMENT_RECRUITEES_LIST = '/recruitments/:id/candidates',
    RECRUITMENTS_EDIT_RECRUITMENT = '/recruitments/:id/edit',
    RECRUITEES_LIST = '/applicants',
    RECRUITEES_SHOW_RECRUITEE = '/applicants/application/:jobApplicationId',
    QUESTIONNAIRES_LIST = '/questionnaires',
    QUESTIONNAIRES_NEW = '/questionnaires/new',
    QUESTIONNAIRES_EDIT = '/questionnaires/:id/edit',
    SETTINGS = '/settings/:page',
    SETTINGS_EMAIL_TEMPLATES_LIST = '/settings/email-templates',
    SETTINGS_RECRUITMENT_STAGES_LIST = '/settings/recruitment-stages',
    SETTINGS_RECRUITMENT_POSITIONS_LIST = '/settings/recruitment-positions'
}

export class RoutePath {
    /**
     * Public paths.
     */
    static login(): string {
        return generatePath(RoutePathEnum.LOGIN);
    }

    static forgotPassword(): string {
        return generatePath(RoutePathEnum.FORGOT_PASSWORD);
    }

    static filledQuestionnaireHtmlForPdf(jobApplicationId: string, atsCustomAuthorizationToken: string): string {
        return generatePath(RoutePathEnum.FILLED_QUESTIONNAIRE_HTML_FOR_PDF, {
            jobApplicationId,
            atsCustomAuthorizationToken
        });
    }

    static errorNotFound(): string {
        return generatePath(RoutePathEnum.ERROR_404_NOT_FOUND);
    }

    static errorUnauthorized(): string {
        return generatePath(RoutePathEnum.ERROR_401_UNAUTHORIZED);
    }

    /**
     * Secure paths.
     */

    // DASHBOARD
    static dashboard(): string {
        return generatePath(RoutePathEnum.DASHBOARD);
    }

    // RECRUITMENTS
    static recruitmentsList(): string {
        return generatePath(RoutePathEnum.RECRUITMENTS_LIST);
    }

    static recruitmentsNewRecruitment(): string {
        return generatePath(RoutePathEnum.RECRUITMENTS_NEW);
    }

    static recruitmentsEditRecruitment(id: string): string {
        return generatePath(RoutePathEnum.RECRUITMENTS_EDIT_RECRUITMENT, { id });
    }

    static recruitmentsRecruitmentDetails(id: string): string {
        return generatePath(RoutePathEnum.RECRUITMENTS_SHOW_RECRUITMENT, { id });
    }

    static recruitmentsRecruitmentRecruiteesList(recruitmentId: string): string {
        return generatePath(RoutePathEnum.RECRUITMENTS_RECRUITMENT_RECRUITEES_LIST, { id: recruitmentId });
    }

    // RECRUITEES
    static recruiteesList(): string {
        return generatePath(RoutePathEnum.RECRUITEES_LIST);
    }

    static recruiteesRecruiteeDetails(jobApplicationId: string): string {
        return generatePath(RoutePathEnum.RECRUITEES_SHOW_RECRUITEE, { jobApplicationId });
    }

    // QUESTIONNAIRES
    static questionnairesList(): string {
        return generatePath(RoutePathEnum.QUESTIONNAIRES_LIST);
    }

    static questionnairesNewQuestionnaire(): string {
        return generatePath(RoutePathEnum.QUESTIONNAIRES_NEW);
    }

    static questionnairesEditQuestionnaire(id: string): string {
        return generatePath(RoutePathEnum.QUESTIONNAIRES_EDIT, { id });
    }

    static settingsSubpage(page: string): string {
        return generatePath(RoutePathEnum.SETTINGS, { page });
    }

    static settingsRecruitmentStagesList(): string {
        return generatePath(RoutePathEnum.SETTINGS_RECRUITMENT_STAGES_LIST);
    }

    static settingsRecruitmentPositionsList(): string {
        return generatePath(RoutePathEnum.SETTINGS_RECRUITMENT_POSITIONS_LIST);
    }
}

/**
 * Parameters for a `SETTINGS` route.
 */
export interface SettingsRouteParams {
    page: SettingsPage;
}

/**
 * Possible `page` parameter values for `SettingsRouteParams`.
 */
export enum SettingsPage {
    EMAIL_TEMPLATES_LIST = 'email-templates',
    RECRUITMENT_STAGES_LIST = 'recruitment-stages',
    RECRUITMENT_POSITIONS_LIST = 'recruitment-positions'
}

export interface RouteConfig {
    path: string;
    renderer: (props: RouteComponentProps<any>) => ReactNode;
    isExact: boolean;
    isSecure: boolean;
}

export const appRoutes: RouteConfig[] = [
    // public routes
    {
        path: RoutePathEnum.LOGIN,
        renderer: loginRouteRenderer,
        isExact: true,
        isSecure: false
    },
    {
        path: RoutePathEnum.FORGOT_PASSWORD,
        renderer: forgotPasswordRouteRenderer,
        isExact: true,
        isSecure: false
    },
    // public error pages
    {
        path: RoutePathEnum.ERROR_401_UNAUTHORIZED,
        renderer: errorPageUnauthorizedRouteRenderer,
        isExact: true,
        isSecure: false
    },
    {
        path: RoutePathEnum.ERROR_404_NOT_FOUND,
        renderer: errorPageNotFoundRouteRenderer,
        isExact: true,
        isSecure: false
    },
    // secure routes
    {
        path: RoutePathEnum.DASHBOARD,
        renderer: dashboardRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITMENTS_NEW,
        renderer: newRecruitmentRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITMENTS_EDIT_RECRUITMENT,
        renderer: editRecruitmentRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITMENTS_RECRUITMENT_RECRUITEES_LIST,
        renderer: recruiteesInRecruitmentRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITMENTS_SHOW_RECRUITMENT,
        renderer: showRecruitmentRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITMENTS_LIST,
        renderer: recruitmentsRouteRenderer,
        isExact: false,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITEES_SHOW_RECRUITEE,
        renderer: showRecruiteeRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.RECRUITEES_LIST,
        renderer: recruiteesRouteRenderer,
        isExact: false,
        isSecure: true
    },
    {
        path: RoutePathEnum.QUESTIONNAIRES_NEW,
        renderer: newQuestionnaireRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.QUESTIONNAIRES_EDIT,
        renderer: editQuestionnaireRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.QUESTIONNAIRES_LIST,
        renderer: questionnairesRouteRenderer,
        isExact: false,
        isSecure: true
    },
    {
        path: RoutePathEnum.SETTINGS,
        renderer: settingsRouteRenderer,
        isExact: false,
        isSecure: true
    },
    {
        path: RoutePathEnum.SETTINGS_EMAIL_TEMPLATES_LIST,
        renderer: settingsRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.SETTINGS_RECRUITMENT_STAGES_LIST,
        renderer: settingsRouteRenderer,
        isExact: true,
        isSecure: true
    },
    {
        path: RoutePathEnum.SETTINGS_RECRUITMENT_POSITIONS_LIST,
        renderer: settingsRouteRenderer,
        isExact: true,
        isSecure: true
    }
];

export const applicationFormRoutes: RouteConfig[] = [
    {
        path: RoutePathEnum.FILLED_QUESTIONNAIRE_HTML_FOR_PDF,
        renderer: () => null,
        isExact: true,
        isSecure: false
    }
];

/**
 * Returns `RouteConfig` by given `RoutePath` or `undefined` if such config is not available.
 */
export function getRouteConfig(path: RoutePath): RouteConfig | undefined {
    return appRoutes.find(routeConfig => routeConfig.path === path);
}
