import { ErrorState } from '../_commons/state.common';

export const initialState: AuthState = {
    isCheckingCredentials: false,
    isLoggingOut: false,
    isLogged: false,
    error: null
};

export interface AuthState {
    isCheckingCredentials: boolean;
    isLoggingOut: boolean;
    isLogged: boolean;
    error: ErrorState;
}
