import { Dictionary } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
    RecruitmentPositionForEditViewDto,
    GetRecruitmentPositionsListQueryResult
} from '../recruitmentPositions/recruitmentPositions.dto';

export const SCHEMA_KEY_RECRUITMENT_POSITION_EDITS = 'recruitment_position_edits';
export const SCHEMA_KEY_RECRUITMENT_POSITIONS = 'recruitment_positions';

export const recruitmentPositionSchema = new schema.Entity(SCHEMA_KEY_RECRUITMENT_POSITIONS);
export const recruitmentPositionEditSchema = new schema.Entity(SCHEMA_KEY_RECRUITMENT_POSITION_EDITS);

export function convertApiRecruitmentPositionEditsToSchema(response: RecruitmentPositionForEditViewDto[]) {
    const parsed = normalize(response, [recruitmentPositionEditSchema]);

    return {
        ids: parsed.result as string[],
        recruitmentPositions: parsed.entities[SCHEMA_KEY_RECRUITMENT_POSITION_EDITS] as Dictionary<
            RecruitmentPositionForEditViewDto
        >
    };
}

export function convertApiRecruitmentPositionsToSchema(response: GetRecruitmentPositionsListQueryResult[]) {
    const parsed = normalize(response, [recruitmentPositionSchema]);

    return {
        ids: parsed.result as string[],
        recruitmentPositions: parsed.entities[SCHEMA_KEY_RECRUITMENT_POSITIONS] as Dictionary<
            GetRecruitmentPositionsListQueryResult
        >
    };
}
