import {
    Checkbox,
    DatePickerInput,
    ErrorContext,
    FieldWithHelp,
    formValueToString,
    GeoLocation,
    InputGroupAddon,
    LabelFieldHorizontal,
    NumberInput,
    Select,
    SpinnerCircle,
    stringToNumber,
    TextInput,
    WheeledNumberInput,
    Wysiwyg,
} from '@emplo/react-inspinia';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { QuestionnaireListViewForRecruitmentDto } from '../../api/questionnaires/questionnaires.dto';
import {
    AddRecruitmentCommand,
    ATSUserForRecruitmentEditView,
    EditRecruitmentCommand,
    RecruitmentStatus,
} from '../../api/recruitments/recruitments.dto';
import { BudgetCurrency } from '../../api/settings/settings.state';
import { getCurrentDateFormat } from '../../utils/format';
import Keywords from './common/Keywords';
import PositionName, { PositionNameValue } from './common/PositionName';
import HiringManagerSelect from './common/recruiterSelect/HiringManagerSelect';
import RecruitmentOwnerSelect from './common/recruiterSelect/RecruitmentOwnerSelect';
import RecruitersList from './common/RecruitersList';

// min value for vacantPosts number field
export const VACANT_POSTS_MIN_VAL = 1;
export const VACANT_POSTS_MAX_VAL = 1000;
export const BUDGET_MIN_VAL = 0;

enum FormFieldName {
    title = 'title',
    deadline = 'deadline',
    vacantPosts = 'vacantPosts',
    budgetPerPositionMin = 'budgetPerPositionMin',
    budgetPerPositionMax = 'budgetPerPositionMax',
    budgetCurrency = 'budgetCurrency',
    newOrExistingPosition = 'newOrExistingPosition',
    questionnaireId = 'questionnaireId',
    jobTitle = 'jobTitle',
    jobDescription = 'jobDescription',
    locationDescription = 'locationDescription',
    locationLatitude = 'locationLatitude',
    locationLongitude = 'locationLongitude',
    remoteWorkIsPossible = 'remoteWorkIsPossible',
    keywords = 'keywords',
    isDraft = 'isDraft',
    recruitmentOwner = 'recruitmentOwner',
    hiringManager = 'hiringManager',
    recruitmentTeamMembers = 'recruitmentTeamMembers',
}

export interface FormValues {
    [FormFieldName.title]: string;
    [FormFieldName.deadline]: string;
    [FormFieldName.vacantPosts]: number | string;
    [FormFieldName.budgetPerPositionMin]?: number | string;
    [FormFieldName.budgetPerPositionMax]?: number | string;
    [FormFieldName.budgetCurrency]?: number | string;
    [FormFieldName.newOrExistingPosition]: PositionNameValue;
    [FormFieldName.questionnaireId]?: string;
    [FormFieldName.jobTitle]: string;
    [FormFieldName.jobDescription]: EditorState;
    [FormFieldName.locationDescription]?: string;
    [FormFieldName.locationLatitude]?: number;
    [FormFieldName.locationLongitude]?: number;
    [FormFieldName.remoteWorkIsPossible]: boolean;
    [FormFieldName.keywords]: string[];
    [FormFieldName.isDraft]: boolean;
    [FormFieldName.recruitmentOwner]?: ATSUserForRecruitmentEditView;
    [FormFieldName.hiringManager]?: ATSUserForRecruitmentEditView;
    [FormFieldName.recruitmentTeamMembers]: ATSUserForRecruitmentEditView[];
}

// validation
const recruitmentSchema = yup.object().shape({
    title: yup.string().required(),
    newOrExistingPosition: yup.object().shape({
        id: yup.string(),
        name: yup.string().required(),
    }),
    deadline: yup.date().min(moment().format(getCurrentDateFormat().display)).required(),
    vacantPosts: yup.number().min(VACANT_POSTS_MIN_VAL).max(VACANT_POSTS_MAX_VAL).required(),
    budgetPerPositionMin: yup.number().min(BUDGET_MIN_VAL),
    budgetPerPositionMax: yup
        .number()
        .when('budgetPerPositionMin', (budgetPerPositionMin: number, schema: yup.NumberSchema) =>
            schema.min(budgetPerPositionMin, 'general.validation.maxHigherThanMin')
        ),
    questionnaireId: yup.string().required(),
    jobTitle: yup.string().required(),
    jobDescription: yup.string().required(),
    locationDescription: yup.string().required(),
    locationLatitude: yup.number().required(),
    locationLongitude: yup.number().required(),
    [FormFieldName.recruitmentOwner]: yup.object().required(),
});

const recruitmentDraftSchema = yup.object().shape({
    title: yup.string().required(),
});

export function recruitmentFormValuesToAddRecruitmentPostParams(values: FormValues): AddRecruitmentCommand {
    const recruitmentData: AddRecruitmentCommand = {
        ...values,
        jobDescription: draftToHtml(convertToRaw(values.jobDescription.getCurrentContent())),
        vacantPosts: stringToNumber(formValueToString(values.vacantPosts)) || 0,
        budgetCurrency: stringToNumber(formValueToString(values.budgetCurrency)) || 0,
        questionnaireId: values.questionnaireId || '',
        budgetPerPositionMin: stringToNumber(formValueToString(values.budgetPerPositionMin)) || 0,
        budgetPerPositionMax: stringToNumber(formValueToString(values.budgetPerPositionMax)) || 0,
        locationDescription: values.locationDescription || '',
        locationLatitude: values.locationLatitude || 0,
        locationLongitude: values.locationLongitude || 0,
        recruiterIds: values.recruitmentTeamMembers.map((recruiter) => recruiter.id),
        hiringManagerId: values.hiringManager ? values.hiringManager.id : '',
        recruitmentOwnerId: values.recruitmentOwner ? values.recruitmentOwner.id : '',
        newOrExistingPosition: values.newOrExistingPosition.id || values.newOrExistingPosition.name,
    };

    return recruitmentData;
}

export function recruitmentFormValuesToEditRecruitmentPostParams(
    values: FormValues,
    id: string
): EditRecruitmentCommand {
    const recruitmentData: EditRecruitmentCommand = {
        ...recruitmentFormValuesToAddRecruitmentPostParams(values),
        id,
        newOrExistingPosition: values.newOrExistingPosition.id || values.newOrExistingPosition.name,
    };

    return recruitmentData;
}

interface InnerFormProps {
    recruitmentStatus?: RecruitmentStatus;
    isInitialValid?: boolean;
    initialValues?: FormValues;
    onSubmit: (values: FormValues, isDraft: boolean) => Promise<void>;
    isSubmitting: boolean;
    currencies: BudgetCurrency[];
    questionnaires: QuestionnaireListViewForRecruitmentDto[];
}

const renderCurrencyOption = (currency: BudgetCurrency) => {
    return (
        <option key={`currency-option-${currency.id}`} value={currency.id}>
            {currency.name}
        </option>
    );
};

const renderQuestionnaireOption = (questionnaire: QuestionnaireListViewForRecruitmentDto) => {
    return (
        <option key={`questionnaire-option-${questionnaire.id}`} value={questionnaire.id}>
            {questionnaire.name}
        </option>
    );
};

const Form = (props: InnerFormProps) => {
    const { t } = useTranslation();
    const { currencies, questionnaires } = props;
    const canSaveAsDraft = props.recruitmentStatus === undefined || props.recruitmentStatus === RecruitmentStatus.DRAFT;
    const isQuestionnaireDisabled = props.recruitmentStatus === RecruitmentStatus.OPEN;
    const [usedValidationSchema, setUsedValidationSchema] = useState<any>(recruitmentSchema);

    const onFormSubmit = (values: FormValues) => {
        return props.onSubmit(values, false);
    };

    const renderForm = (formikProps: FormikProps<FormValues>) => {
        const { touched, values, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formikProps;

        const onSave = () => {
            setFieldValue(FormFieldName.isDraft, false);
            setUsedValidationSchema(recruitmentSchema);
        };

        const onSaveDraft = () => {
            setFieldValue(FormFieldName.isDraft, true);
            setUsedValidationSchema(recruitmentDraftSchema);
        };

        return (
            <ErrorContext.Provider value={{ errors, touched }}>
                <form className='js-form-recruitment-add recruitment-add' onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='ibox'>
                                <div className='ibox-content gray-bg'>
                                    <div className='h4 font-bold text-dark'>
                                        {t('recruitmentsNew.section.basicInfo.title')}
                                    </div>
                                    <FieldWithHelp for='' helpText={t('recruitmentsNew.title.help')}>
                                        <LabelFieldHorizontal
                                            for={FormFieldName.title}
                                            label={t('recruitmentsNew.title.label')}>
                                            <TextInput
                                                name={FormFieldName.title}
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={t('recruitmentsNew.title.placeholder')}
                                            />
                                        </LabelFieldHorizontal>
                                        <LabelFieldHorizontal
                                            for={FormFieldName.newOrExistingPosition}
                                            label={t('recruitmentsNew.positionName.label')}>
                                            <PositionName
                                                name={FormFieldName.newOrExistingPosition}
                                                setFieldValue={formikProps.setFieldValue}
                                                value={values.newOrExistingPosition}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={t('recruitmentsNew.positionName.placeholder')}
                                                newSelectionPrefix={t('recruitmentsNew.positionName.new')}
                                            />
                                        </LabelFieldHorizontal>
                                    </FieldWithHelp>

                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-7 col-md-9 col-lg-9 col-xl-2'>
                                            <LabelFieldHorizontal
                                                for={FormFieldName.deadline}
                                                label={t('recruitmentsNew.deadline.label')}>
                                                <DatePickerInput
                                                    name={FormFieldName.deadline}
                                                    value={values.deadline}
                                                    dateFormat={getCurrentDateFormat().datePicker}
                                                    onChange={formikProps.setFieldValue}
                                                    onBlur={handleBlur}
                                                    selected={values.deadline ? new Date(values.deadline) : null}
                                                    placeholderText={getCurrentDateFormat().display}
                                                />
                                            </LabelFieldHorizontal>
                                        </div>
                                        <div className='col-xs-12 col-sm-7 col-md-9 col-lg-9 col-xl-2'>
                                            <LabelFieldHorizontal
                                                label={t('recruitmentsNew.vacantPosts.label')}
                                                for={{
                                                    [FormFieldName.vacantPosts]: { min: VACANT_POSTS_MIN_VAL },
                                                }}>
                                                <WheeledNumberInput
                                                    name={FormFieldName.vacantPosts}
                                                    value={values.vacantPosts}
                                                    setFieldValue={formikProps.setFieldValue}
                                                    onBlur={handleBlur}
                                                />
                                            </LabelFieldHorizontal>
                                        </div>
                                        <div className='col-xs-12 col-sm-7 col-md-9 col-lg-9 col-xl-3'>
                                            <LabelFieldHorizontal
                                                label={t('recruitmentsNew.budgetPerPosition.label')}
                                                for={{
                                                    [FormFieldName.budgetPerPositionMin]: {},
                                                    [FormFieldName.budgetPerPositionMax]: {},
                                                }}>
                                                <NumberInput
                                                    name={FormFieldName.budgetPerPositionMin}
                                                    value={values.budgetPerPositionMin}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={t('recruitmentsNew.budgetPerPosition.placeholderMin')}
                                                />
                                                <InputGroupAddon>
                                                    {t('recruitmentsNew.budgetPerPosition.separator')}
                                                </InputGroupAddon>
                                                <NumberInput
                                                    name={FormFieldName.budgetPerPositionMax}
                                                    value={values.budgetPerPositionMax}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder={t('recruitmentsNew.budgetPerPosition.placeholderMax')}
                                                />
                                            </LabelFieldHorizontal>
                                        </div>
                                        <div className='col-xs-12 col-sm-7 col-md-9 col-lg-9 col-xl-2'>
                                            <LabelFieldHorizontal
                                                label={t('recruitmentsNew.currency.label')}
                                                for={FormFieldName.budgetCurrency}>
                                                <Select
                                                    name={FormFieldName.budgetCurrency}
                                                    value={values.budgetCurrency}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}>
                                                    {currencies.map(renderCurrencyOption)}
                                                </Select>
                                            </LabelFieldHorizontal>
                                        </div>
                                    </div>

                                    <FieldWithHelp
                                        label={t('recruitmentsNew.hiringManager.label')}
                                        for={FormFieldName.hiringManager}
                                        helpText=''>
                                        <HiringManagerSelect
                                            name={FormFieldName.hiringManager}
                                            value={values.hiringManager}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={formikProps.setFieldTouched}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp
                                        label={t('recruitmentsNew.recruitmentOwner.label')}
                                        for={FormFieldName.recruitmentOwner}
                                        helpText=''>
                                        <RecruitmentOwnerSelect
                                            name={FormFieldName.recruitmentOwner}
                                            value={values.recruitmentOwner}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={formikProps.setFieldTouched}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp
                                        label={t('recruitmentsNew.recruiters.label')}
                                        for={FormFieldName.recruitmentTeamMembers}
                                        helpText=''>
                                        <RecruitersList
                                            name={FormFieldName.recruitmentTeamMembers}
                                            items={values.recruitmentTeamMembers}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={formikProps.setFieldTouched}
                                        />
                                    </FieldWithHelp>
                                </div>
                            </div>

                            <div className='ibox'>
                                <div className='ibox-content gray-bg'>
                                    <div className='h4 font-bold text-dark'>
                                        {t('recruitmentsNew.section.questionnaire.title')}
                                    </div>
                                    <FieldWithHelp
                                        for={FormFieldName.questionnaireId}
                                        label={t('recruitmentsNew.questionnaireId.label')}
                                        helpText={t('recruitmentsNew.questionnaireId.help')}>
                                        <Select
                                            name={FormFieldName.questionnaireId}
                                            value={values.questionnaireId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={isQuestionnaireDisabled}>
                                            <option value=''>{t('recruitmentsNew.questionnaireId.chooseLabel')}</option>
                                            {questionnaires.map(renderQuestionnaireOption)}
                                        </Select>
                                    </FieldWithHelp>
                                </div>
                            </div>

                            <div className='ibox'>
                                <div className='ibox-content gray-bg'>
                                    <div className='h4 font-bold text-dark'>
                                        {t('recruitmentsNew.section.positionDetails.title')}
                                    </div>
                                    <FieldWithHelp
                                        for={FormFieldName.jobTitle}
                                        label={t('recruitmentsNew.jobTitle.label')}
                                        helpText={t('recruitmentsNew.jobTitle.help')}>
                                        <TextInput
                                            name={FormFieldName.jobTitle}
                                            value={values.jobTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp
                                        for={FormFieldName.jobDescription}
                                        label={t('recruitmentsNew.jobDescription.label')}
                                        helpText={t('recruitmentsNew.jobDescription.help')}>
                                        <Wysiwyg
                                            name={FormFieldName.jobDescription}
                                            editorState={values.jobDescription}
                                            setFieldValue={formikProps.setFieldValue}
                                            wrapperStyle={{ maxHeight: '70vh' }}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp
                                        for={{
                                            [FormFieldName.locationLatitude]: {},
                                            [FormFieldName.locationLongitude]: {},
                                        }}
                                        label={t('recruitmentsNew.location.label')}
                                        helpText={t('recruitmentsNew.location.help')}>
                                        <GeoLocation
                                            nameLat={FormFieldName.locationLatitude}
                                            nameLng={FormFieldName.locationLongitude}
                                            nameAddress={FormFieldName.locationDescription}
                                            valueLat={values.locationLatitude}
                                            valueLng={values.locationLongitude}
                                            valueAddress={values.locationDescription}
                                            setFieldValue={formikProps.setFieldValue}
                                            placeholder={t('recruitmentsNew.location.placeholder')}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp for={FormFieldName.remoteWorkIsPossible} helpText=''>
                                        <Checkbox
                                            label={t('recruitmentsNew.remoteWorkIsPossible.label')}
                                            name={FormFieldName.remoteWorkIsPossible}
                                            checked={values.remoteWorkIsPossible}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FieldWithHelp>

                                    <FieldWithHelp
                                        label={t('recruitmentsNew.keywords.label')}
                                        for={FormFieldName.keywords}
                                        helpText=''>
                                        <Keywords
                                            name={FormFieldName.keywords}
                                            setFieldValue={formikProps.setFieldValue}
                                            value={values.keywords}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={t('recruitmentsNew.keywords.placeholder')}
                                            newSelectionPrefix={t('recruitmentsNew.keywords.new')}
                                            placement='bottom'
                                        />
                                    </FieldWithHelp>
                                </div>
                            </div>

                            <div className='d-flex justify-content-end'>
                                {canSaveAsDraft && (
                                    <button
                                        className='btn btn-w-m btn-default font-bold d-flex justify-content-center align-items-center'
                                        type='submit'
                                        onClick={onSaveDraft}>
                                        {props.isSubmitting && (
                                            <SpinnerCircle spinnerSize='small' inline className='mt-0 ml-0 mb-0 mr-2' />
                                        )}
                                        {t(props.isSubmitting ? 'general.button.saving' : 'general.button.saveAsDraft')}
                                    </button>
                                )}
                                <button
                                    className='btn btn-w-m btn-primary m-l-md font-bold d-flex justify-content-center align-items-center'
                                    type='submit'
                                    onClick={onSave}>
                                    {props.isSubmitting && (
                                        <SpinnerCircle spinnerSize='small' inline className='mt-0 ml-0 mb-0 mr-2' />
                                    )}
                                    {t(props.isSubmitting ? 'general.button.saving' : 'general.button.save')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ErrorContext.Provider>
        );
    };

    return (
        <Formik
            onSubmit={onFormSubmit}
            initialValues={{
                title: (props.initialValues && props.initialValues.title) || '',
                deadline: (props.initialValues && props.initialValues.deadline) || '',
                newOrExistingPosition: (props.initialValues && props.initialValues.newOrExistingPosition) || {
                    name: '',
                },
                vacantPosts: (props.initialValues && props.initialValues.vacantPosts) || VACANT_POSTS_MIN_VAL,
                budgetCurrency: props.initialValues
                    ? props.initialValues.budgetCurrency !== undefined
                        ? props.initialValues.budgetCurrency
                        : undefined
                    : undefined,
                budgetPerPositionMin: (props.initialValues && props.initialValues.budgetPerPositionMin) || undefined,
                budgetPerPositionMax: (props.initialValues && props.initialValues.budgetPerPositionMax) || undefined,
                jobTitle: (props.initialValues && props.initialValues.jobTitle) || '',
                jobDescription:
                    (props.initialValues && props.initialValues.jobDescription) || EditorState.createEmpty(),
                locationDescription: props.initialValues && props.initialValues.locationDescription,
                locationLatitude: props.initialValues && props.initialValues.locationLatitude,
                locationLongitude: props.initialValues && props.initialValues.locationLongitude,
                remoteWorkIsPossible: (props.initialValues && props.initialValues.remoteWorkIsPossible) || false,
                keywords: (props.initialValues && props.initialValues.keywords) || [],
                isDraft: false,
                recruitmentOwner: props.initialValues && props.initialValues.recruitmentOwner,
                hiringManager: props.initialValues && props.initialValues.hiringManager,
                recruitmentTeamMembers: (props.initialValues && props.initialValues.recruitmentTeamMembers) || [],
                questionnaireId: props.initialValues && props.initialValues.questionnaireId,
            }}
            validationSchema={usedValidationSchema}
            isInitialValid={props.isInitialValid}
            render={renderForm}></Formik>
    );
};

export default Form;
