import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import { SettingsPage, SettingsRouteParams } from '../../routes';
import AppLayout from '../layout/AppLayout';
import EmailTemplates from './EmailTemplates';
import RecruitmentPositions from './recruitmentPositions/list/RecruitmentPositions';
import RecruitmentRecruiteeStages from './recruitmentRecruiteeStages/list/RecruitmentRecruiteeStages';

export function settingsRouteRenderer(props: RouteComponentProps<SettingsRouteParams>): ReactNode {
    switch (props.match.params.page) {
        case SettingsPage.EMAIL_TEMPLATES_LIST:
            return (
                <AppLayout {...props}>
                    <EmailTemplates />
                </AppLayout>
            );
        case SettingsPage.RECRUITMENT_STAGES_LIST:
            return (
                <AppLayout {...props}>
                    <RecruitmentRecruiteeStages />
                </AppLayout>
            );
        case SettingsPage.RECRUITMENT_POSITIONS_LIST:
            return (
                <AppLayout {...props}>
                    <RecruitmentPositions />
                </AppLayout>
            );
        default:
            // TODO: 404 error page
            return <div>Not found</div>;
    }
}
