import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import momentTz from 'moment-timezone';

export enum DateFormat {
    DEFAULT = 'DEFAULT',
    US = 'US'
}

/**
 * Date formats per usage/library.
 */
export interface DateFormats {
    display: string;
    moment: string;
    datePicker: string;
    dateTime: string;
    dateTimeDayOfWeek: string;
    dateMonthNameDay: string;
    dateMonthNameYear: string;
}

export const DATE_FORMATS: Record<DateFormat, DateFormats> = {
    [DateFormat.DEFAULT]: {
        display: 'DD.MM.YYYY',
        moment: 'DD.MM.YYYY',
        datePicker: 'dd.MM.yyyy',
        dateTime: 'DD.MM.YYYY HH:mm',
        dateTimeDayOfWeek: 'dddd h:mm, DD.MM.YYYY',
        dateMonthNameDay: 'MMM DD',
        dateMonthNameYear: 'MMM YYYY'
    },
    [DateFormat.US]: {
        display: 'MM/DD/YYYY',
        moment: 'MM/DD/YYYY',
        datePicker: 'MM/dd/yyyy',
        dateTime: 'MM/DD/YYYY HH:mm',
        dateTimeDayOfWeek: 'dddd h:mm a, MM/DD/YYYY',
        dateMonthNameDay: 'MMM DD',
        dateMonthNameYear: 'MMM YYYY'
    }
};

export function getCurrentDateFormat(): DateFormats {
    return i18next.language.startsWith('en') ? DATE_FORMATS.US : DATE_FORMATS.DEFAULT;
}

export function getCurrentDateTimeFormat(): DateFormats {
    return i18next.language.startsWith('en') ? DATE_FORMATS.US : DATE_FORMATS.DEFAULT;
}

/**
 * Formats the date to the format dependant on the currently set language.
 * Possible formats:
 * - `MM/DD/YYYY` for `en` language
 * - `DD.MM.YYYY` for other languages
 */
export function date(date: string, format: keyof DateFormats = 'display'): string {
    return moment(date).format(getCurrentDateFormat()[format]);
}

/**
 * Formats the date and time to the datetime format dependant on the currently set language.
 */
export function datetime(dateTime: string): string;
export function datetime(date: string, time?: string): string;

export function datetime(date: string, time?: string): string {
    let stringToParse = date;

    if (time) {
        stringToParse += ` ${time}`;
    }

    return moment(stringToParse).format(getCurrentDateFormat().dateTime);
}

/**
 * Returns either `moment(date).fromNow()` result or empty string if the provided date is not lower than `now`.
 */
export function dateAgo(date: string): string {
    return moment(date) < moment() ? moment(date).fromNow() : '';
}

/**
 * Days/hours/minutes ago.
 */
export function dateTimeAgo(date: string): string {
    return moment(date).fromNow();
}

/**
 * Returns date and time in format like: Monday at H:MM, DD-MM-YYYY
 */
export function dateTimeDayOfWeek(datetime: string): string {
    return moment(datetime).format(getCurrentDateFormat().dateTimeDayOfWeek);
}

/**
 * @param datetime DateTime in UTC
 * @param timezone Timezone (e.g. "Europe/Warsaw") to convert the datetime to
 */
export function dateTimeDayOfWeekWithTimezone(datetime: string, timezone: string = ''): string {
    return momentTz.tz(datetime, timezone).format(getCurrentDateFormat().dateTimeDayOfWeek);
}

export function dateTimeDayOfWeekLocalTimezone(datetime: string): string {
    return momentTz(datetime)
        .tz(getLocalTimezone())
        .format(getCurrentDateFormat().dateTimeDayOfWeek);
}

export function getLocalTimezone(): string {
    return momentTz.tz.guess();
}

/**
 * Removes `\r` characters and splits input string by `\n`, putting texts (lines) in <span> with <br> at the end of text.
 */
export function nl2br(text: string): React.ReactNode[] {
    return text
        .replace(/\r/g, '')
        .split('\n')
        .map(wrapTextLine);
}

const wrapTextLine = (textLine: string, idx: number): React.ReactNode => (
    <span key={idx}>
        {textLine}
        <br />
    </span>
);

export function formatRecruitmentStageLabelForChart(stageName: string): string | string[] {
    if (stageName.length > 12) {
        return stageName
            .replace(' - ', '-')
            .replace('by ', 'by$')
            .replace(' ', '\n')
            .replace('-', ' - ')
            .replace('by$', 'by ');
    }
    return stageName;
}
