import { FilledQuestionnaireDto } from './filledQuestionnaire.dto';
import { ErrorState } from '../_commons/state.common';

export const initialState: FilledQuestionnaireState = {
    isLoading: false,
    error: null,
    data: null
};

export interface FilledQuestionnaireState {
    isLoading: boolean;
    error: ErrorState;
    data: FilledQuestionnaireDto | null;
}

export type FilledQuestionnaireItem = FilledQuestionnaireDto;
