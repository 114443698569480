import { ListStateWithLoadingState } from '../../_commons/state.common';

export const initialState: KeywordsState = {
    list: [],
    items: {},
    totalCount: 0,
    page: 0,
    isLoading: false,
    error: null
};

export interface KeywordsState extends ListStateWithLoadingState<string, KeywordItem> {}

export interface KeywordItem {
    keyword: string;
}
