import { QuestionnaireElement } from '../questionnaires.dto';

export const initialState: QuestionnaireBuilderState = {
    elements: []
};

export type QuestionnaireBuilderElement = QuestionnaireElement & {
    isEditing: boolean;
    isAdding?: boolean;
};

export interface QuestionnaireBuilderState {
    elements: QuestionnaireBuilderElement[];
}
