import { LabelFieldHorizontal, TextInput } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

import {
    AnswerableQuestionnaireElement,
    QuestionnaireSingleLineText,
} from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireSingleLineTextForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireSingleLineText>
    > {
    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = () => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for=''>
                <TextInput
                    disabled
                    value={
                        t(
                            'questionnaires.questionnaireForm.addMenu.field.QuestionnaireSingleLineText.placeholder'
                        ) as string
                    }
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireSingleLineTextForm = withTranslation()(questionnaireSingleLineTextForm);

// PREVIEW

export const QuestionnaireSingleLineTextPreview = (
    props: QuestionnaireElementPreviewProps<QuestionnaireSingleLineText>
) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                (props.itemData as QuestionnaireSingleLineText).question +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <TextInput />
        </LabelFieldHorizontal>
    );
};
