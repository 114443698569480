import { LabelFieldHorizontal, TextInput } from '@emplo/react-inspinia';
import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

import { QuestionnaireHeader } from '../../../../api/questionnaires/questionnaires.dto';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireHeaderForm extends Component<WithTranslation & QuestionnaireElementFormProps<QuestionnaireHeader>> {
    validationSchema = yup.object().shape({
        text: yup.string().required()
    });

    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                isRequiredEditable={false}
                isQuestionEditable={false}
                validationSchema={this.validationSchema}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = (formikProps: FormikProps<QuestionnaireHeader>) => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal
                for='text'
                label={t('questionnaires.questionnaireForm.addMenu.field.QuestionnaireHeader.placeholder')}>
                <TextInput
                    name='text'
                    value={formikProps.values.text}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireHeaderForm = withTranslation()(questionnaireHeaderForm);

// PREVIEW

export function QuestionnaireHeaderPreview<T extends QuestionnaireHeader>(props: QuestionnaireElementPreviewProps<T>) {
    return <h3 className='font-weight-bold text-dark mb-4'>{(props.itemData as QuestionnaireHeader).text}</h3>;
}
