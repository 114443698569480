import { DashboardAction, DashboardActionTypes } from './dashboard.actions';
import { DashboardState, initialState } from './dashboard.state';

export default (state: DashboardState = initialState, action: DashboardAction) => {
    switch (action.type) {
        case DashboardActionTypes.GET_DASHBOARD_DATA_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case DashboardActionTypes.GET_DASHBOARD_DATA_FINISHED:
            return {
                ...state,
                dashboardData: {
                    ...action.response
                },
                isLoading: false,
                error: null
            };

        case DashboardActionTypes.GET_DASHBOARD_DATA_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};
