import classNames from 'classnames';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Activity } from '../../../../api/recruitees/recruitees.dto';
import {
    RecruitmentRecruiteeStageListViewDtoNormalized,
} from '../../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.schema';
import { GetAvailableTimezonesQueryResult } from '../../../../api/settings/settings.dto';
import { createActivityItem } from './item/_utils';
import { ActivityItem } from './item/ActivityItem';
import styles from './RecruiteeActivities.module.scss';

interface Props {
    item: Activity;
    stages: Dictionary<RecruitmentRecruiteeStageListViewDtoNormalized>;
    timezones: Dictionary<GetAvailableTimezonesQueryResult>;
}

class RecruiteeActivityItem extends Component<Props & WithTranslation> {
    private itemRenderer: ActivityItem = createActivityItem(
        this.props.item,
        this.props.t,
        this.props.stages,
        this.props.timezones
    );

    render() {
        const item = this.itemRenderer;
        const subtitle = item.getSubtitle();

        return (
            <div className={styles.item}>
                <div className={styles.timelineContainer}>
                    <div className={styles.timelineNode}>{item.renderNode()}</div>
                    <div className={styles.timelinePath}></div>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.cardBubble}></div>
                    <div className={styles.card}>
                        <h4 className={classNames(styles.cardTitle, !!subtitle && 'mb-0')}>{item.getTitle()}</h4>
                        {subtitle}
                        {item.getContent()}
                        {item.getUserInfo()}
                        <span className={styles.cardDate}>{item.getDate()}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(RecruiteeActivityItem);
