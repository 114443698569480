import { NumericDictionary, Dictionary } from 'lodash';
import { normalize, schema } from 'normalizr';
import { CurrencyDto, StageTypeDto, ATSLanguageDto, GetAvailableTimezonesQueryResult } from './settings.dto';
import { BudgetCurrency, StageType, Language } from './settings.state';

export const SCHEMA_KEY_CURRENCIES = 'currencies';
export const SCHEMA_KEY_STAGE_TYPES = 'stageTypes';
export const SCHEMA_KEY_LANGUAGES = 'languages';
export const SCHEMA_KEY_TIMEZONES = 'timezones';

export const currencySchema = new schema.Entity(SCHEMA_KEY_CURRENCIES);
export const stageTypeSchema = new schema.Entity(SCHEMA_KEY_STAGE_TYPES);
export const languageSchema = new schema.Entity(SCHEMA_KEY_LANGUAGES);
export const timezoneSchema = new schema.Entity(SCHEMA_KEY_TIMEZONES);

export function convertApiCurrenciesToSchema(response: CurrencyDto[]) {
    const parsed = normalize(response, [currencySchema]);

    return {
        currenciesIds: parsed.result as number[],
        currencies: parsed.entities[SCHEMA_KEY_CURRENCIES] as NumericDictionary<BudgetCurrency>
    };
}

export function convertApiStageTypesToSchema(response: StageTypeDto[]) {
    const parsed = normalize(response, [stageTypeSchema]);

    return {
        stageTypesIds: parsed.result as number[],
        stageTypes: parsed.entities[SCHEMA_KEY_STAGE_TYPES] as NumericDictionary<StageType>
    };
}

export function convertApiLanguagesToSchema(response: ATSLanguageDto[]) {
    const parsed = normalize(response, [languageSchema]);

    return {
        languagesIds: parsed.result as number[],
        languages: parsed.entities[SCHEMA_KEY_LANGUAGES] as NumericDictionary<Language>
    };
}

export function convertApiTimezonesToSchema(response: GetAvailableTimezonesQueryResult[]) {
    const parsed = normalize(response, [timezoneSchema]);

    return {
        ids: parsed.result as string[],
        timezones: parsed.entities[SCHEMA_KEY_TIMEZONES] as Dictionary<GetAvailableTimezonesQueryResult>
    };
}
