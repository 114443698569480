import { FileUpload, LabelFieldHorizontal } from '@emplo/react-inspinia';
import _ from 'lodash';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

import { AnswerableQuestionnaireElement, QuestionnairePhoto } from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnairePhotoForm extends Component<WithTranslation & QuestionnaireElementFormProps<QuestionnairePhoto>> {
    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                isQuestionEditable={false}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = () => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.photo.label')}>
                <FileUpload
                    disabled
                    labelButton={t('questionnaires.questionnaireForm.question.photo.button')}
                    placeholder={t('questionnaires.questionnaireForm.question.photo.placeholder')}
                    name=''
                    setFieldValue={_.noop}
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnairePhotoForm = withTranslation()(questionnairePhotoForm);

export const QuestionnairePhotoPreview = (props: QuestionnaireElementPreviewProps<QuestionnairePhoto>) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                t('questionnaires.questionnaireForm.question.photo.label') +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <FileUpload
                labelButton={t('questionnaires.questionnaireForm.question.photo.button')}
                placeholder={t('questionnaires.questionnaireForm.question.photo.placeholder')}
                name=''
                setFieldValue={_.noop}
            />
        </LabelFieldHorizontal>
    );
};
