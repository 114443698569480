import { Api } from '../../_commons/http';
import { ThunkAction } from '../../_commons/thunks.common';
import { endpoints } from '../../endpoints';
import { RecruitmentStatus } from '../recruitments.dto';
import { RecruitmentAvailableStatusesActions } from './availableStatuses.actions';

export class RecruitmentAvailableStatusesThunks {
    static getAvailableStatusesForRecruitment = (recruitmentId: string): ThunkAction => async dispatch => {
        dispatch(RecruitmentAvailableStatusesActions.getAvailableStatusesStarted());

        const { data, status, error } = await Api().get<RecruitmentStatus[]>(
            endpoints.getNextAvailableRecruitmentStatusListEndpoint(recruitmentId)
        );

        if (data) {
            dispatch(
                dispatch(RecruitmentAvailableStatusesActions.getAvailableStatusesFinished({ availableStatuses: data }))
            );
        } else {
            dispatch(
                RecruitmentAvailableStatusesActions.getAvailableStatusesFailed({ error: error!, httpStatus: status })
            );
        }
    };
}
