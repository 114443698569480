import { ChartjsChart } from '@emplo/react-inspinia';
import _, { Dictionary } from 'lodash';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';

import { RecruitmentRecruiteesByStageDatabaseDto } from '../../api/dashboard/dashboard.dto';
import { StageType } from '../../api/recruitees/recruitees.dto';
import { RecruitmentRecruiteeStageListViewDto } from '../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { COLORS } from '../../constants';
import styles from './CandidatesByStageChart.module.scss';
import NoChartData from './common/NoChartData';

interface Props {
    recruitmentStages: Dictionary<RecruitmentRecruiteeStageListViewDto>;
    data: RecruitmentRecruiteesByStageDatabaseDto[];
}

interface State {
    legendHtml: string;
}

const CHART_HEIGHT = 160;

export default class CandidatesByStageChart extends Component<Props, State> {
    state: State = {
        legendHtml: ''
    };

    render() {
        if (this.props.data.length === 0) {
            return <NoChartData height={CHART_HEIGHT} />;
        }

        const labels = this.getLabels();
        const values = this.prepareChartData();

        const chartOptions = {
            type: 'doughnut',
            data: {
                labels,
                datasets: [
                    {
                        backgroundColor: this.generateColors,
                        hoverBackgroundColor: this.generateHoverColors,
                        data: values
                    }
                ]
            },
            options: {
                responsive: false,
                legend: {
                    display: false
                },
                legendCallback: () => {
                    return <div className={styles.legend}>{this.getSortedStages().map(this.renderLegendItem)}</div>;
                }
            }
        };

        return (
            <>
                <ChartjsChart
                    height={CHART_HEIGHT}
                    width={CHART_HEIGHT}
                    chartOptions={chartOptions}
                    className={styles.chart}
                    renderLegend={this.setLegend}
                />
                <div dangerouslySetInnerHTML={{ __html: this.state.legendHtml }}></div>
            </>
        );
    }

    private getSortedStages = () => {
        return _.values(this.props.recruitmentStages)
            .filter(stage => !stage.isDeleted)
            .sort((a, b) => {
                return a.order > b.order ? 1 : -1;
            });
    };

    private setLegend = (html: JSX.Element) => {
        this.setState({
            legendHtml: ReactDOMServer.renderToStaticMarkup(html)
        });
    };

    private renderLegendItem = (stage: RecruitmentRecruiteeStageListViewDto, idx: number) => {
        const item = this.props.data.find(item => item.recruitmentRecruiteeStageId === stage.id);

        if (!item) {
            return null;
        }

        return (
            <div className={styles.legendItem} key={idx}>
                <div className={styles.legendItemColor} style={{ backgroundColor: this.getColorForItem(item) }} />
                <div className={styles.legendItemName}>
                    {this.getItemLabel(item)} ({item.count})
                </div>
            </div>
        );
    };

    private getItemLabel = (item: RecruitmentRecruiteesByStageDatabaseDto) => {
        const stage = this.props.recruitmentStages[item.recruitmentRecruiteeStageId];
        return stage ? stage.name || '' : '';
    };

    private getLabels = () => {
        return this.props.data.map(this.getItemLabel);
    };

    private prepareChartData = () => {
        return this.props.data.map(this.getItemValue);
    };

    private getItemValue = (item: RecruitmentRecruiteesByStageDatabaseDto) => item.count;

    private generateColors = () => this.props.data.map(this.getTransparentColorForItem);

    private generateHoverColors = () => this.props.data.map(this.getColorForItem);

    private getColorForItem = (item: RecruitmentRecruiteesByStageDatabaseDto): string => {
        const stage = this.props.recruitmentStages[item.recruitmentRecruiteeStageId];
        const stageType = stage ? stage.stageType : StageType.Custom;
        return COLORS.recruitmentStage[stageType].solid;
    };

    private getTransparentColorForItem = (item: RecruitmentRecruiteesByStageDatabaseDto): string => {
        const stage = this.props.recruitmentStages[item.recruitmentRecruiteeStageId];
        const stageType = stage ? stage.stageType : StageType.Custom;
        return COLORS.recruitmentStage[stageType].transparent;
    };
}
