import { ATSLanguage } from '../../i18n';

export const initialState: UserState = {};

export interface UserState {
    userId?: string;
    language?: ATSLanguage;
    firstName?: string;
    lastName?: string;
    /**
     * File ID.
     */
    avatar?: string;
    accessToken?: string;
}
