import classNames from 'classnames';
import { TFunction } from 'i18next';
import { Dictionary } from 'lodash';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import { Activity, MeetingScheduledActivityData } from '../../../../../api/recruitees/recruitees.dto';
import { GetAvailableTimezonesQueryResult } from '../../../../../api/settings/settings.dto';
import { dateTimeDayOfWeekWithTimezone } from '../../../../../utils/format';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import { ActivityItem } from './ActivityItem';
import styles from './ActivityItem.module.scss';

export default class MeetingScheduledEvent extends ActivityItem {
    constructor(
        protected itemData: Activity,
        protected activityData: MeetingScheduledActivityData,
        protected t: TFunction,
        protected timezones: Dictionary<GetAvailableTimezonesQueryResult>
    ) {
        super(itemData, activityData, t);
    }

    renderNode() {
        return (
            <div className={classNames('bg-success', styles.nodePhone)}>
                <i className='fa fa-calendar' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.activityData.atsUserId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.atsUserFirstName}
                lastName={this.activityData.atsUserLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        return this.t('recruiteeView.activities.meetingScheduled.title');
    }

    getContent() {
        const timezone = this.timezones[this.activityData.timeZoneId];
        const timezoneId = timezone ? timezone.id : '';

        return (
            <>
                <div className={styles.meetingName}>{this.activityData.meetingName}</div>
                <div className={styles.meetingDate}>
                    {dateTimeDayOfWeekWithTimezone(this.activityData.meetingDateTime, timezoneId)}{' '}
                    {timezone && timezone.displayName}
                </div>
                {/* <div className={styles.meetingDate}>
                    {dateTimeDayOfWeekLocalTimezone(this.activityData.meetingDateTime)} {getLocalTimezone()}
                </div> */}
                {this.activityData.invitedPeople.length > 0 && (
                    <div className={styles.meetingInvitedPeople}>
                        {this.t('recruiteeView.activities.meetingScheduled.invitedPeople')}:{' '}
                        {this.activityData.invitedPeople.join(', ')}
                    </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: this.activityData.messageContent }} />
            </>
        );
    }
}
