import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { endpoints } from '../../../../api/endpoints';
import {
    convertUserRecruiterEditViewModelToStateModel,
    GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult,
} from '../../../../api/recruitersBrowser/recruitersBrowser.dto';
import { RecruitersBrowserItem } from '../../../../api/recruitersBrowser/recruitersBrowser.state';
import { ATSUserForRecruitmentEditView } from '../../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../../api/recruitments/recruitments.thunk';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import HiringManagerStar from '../../common/HiringManagerStar';
import RecruitersBrowserModal from '../../common/recruitersBrowser/RecruitersBrowserModal';
import styles from './RecruitmentTeamPreview.module.scss';

interface Props {
    recruitmentId: string;
    recruitmentOwner: ATSUserForRecruitmentEditView;
    recruitmentTeamMembers: ATSUserForRecruitmentEditView[];
}

interface StateProps {
    selectedRecruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[];
}

interface DispatchProps {
    updateRecruiters: (
        recruitmentId: string,
        recruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[]
    ) => Promise<ThunkActionResult>;
}

interface OwnState {
    isRecruitersBrowserModalOpen: boolean;
}

const OVERLAP_SIZE = 8;

class RecruitmentTeamPreview extends Component<Props & StateProps & DispatchProps & WithTranslation, OwnState> {
    state: OwnState = {
        isRecruitersBrowserModalOpen: false
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.itemsContainer} onClick={this.openRecruitersBrowserModal}>
                    <div className={classNames(styles.item, styles.btn, styles.btnAdd)}>
                        <i className='fa fa-plus' />
                    </div>
                    {this.props.recruitmentTeamMembers.map(this.renderTeamMember)}
                    {this.renderRecruitmentOwner()}
                </div>

                <RecruitersBrowserModal
                    isOpen={this.state.isRecruitersBrowserModalOpen}
                    closeModal={this.closeModals}
                    onSubmit={this.submitRecruitersBrowser}
                    initialSelected={this.props.recruitmentTeamMembers.map(
                        convertUserRecruiterEditViewModelToStateModel
                    )}
                    recruitmentOwner={this.props.recruitmentOwner}
                />
            </div>
        );
    }

    private renderTeamMember = (item: ATSUserForRecruitmentEditView, idx: number) => {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(item.id);

        return (
            <div
                key={item.id}
                className={classNames(styles.item, styles.itemMember)}
                style={{ backgroundImage: `url(${photoUrl})`, right: (idx + 1) * -1 * OVERLAP_SIZE }}
            />
        );
    };

    private renderRecruitmentOwner = () => {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.props.recruitmentOwner.id || '');

        return (
            <div
                className={classNames(styles.item, styles.itemMember)}
                style={{
                    backgroundImage: `url(${photoUrl})`,
                    right: (this.props.recruitmentTeamMembers.length + 1) * -1 * OVERLAP_SIZE
                }}>
                <HiringManagerStar />
            </div>
        );
    };

    private openRecruitersBrowserModal = () => this.setState({ isRecruitersBrowserModalOpen: true });
    private closeModals = () => this.setState({ isRecruitersBrowserModalOpen: false });

    private submitRecruitersBrowser = async () => {
        const { t } = this.props;

        if (this.props.selectedRecruiters.length) {
            this.closeModals();

            const results = await this.props.updateRecruiters(this.props.recruitmentId, this.props.selectedRecruiters);
            if (results.httpStatus < 300) {
                toastr.success(t('recruitmentView.recruitmentTeamChanged'), t('general.success'));
            } else {
                toastr.success(t('recruitmentView.recruitmentTeamChangeError'), t('general.error'));
            }
        }
    };
}

const selectedItemsFilter = (item: RecruitersBrowserItem) => item.isSelected;

const mapStateToProps = (state: State): StateProps => ({
    selectedRecruiters: listMapper(
        state.recruitersBrowser.recruitmentTeam.list,
        state.recruitersBrowser.recruitmentTeam.items
    ).filter(selectedItemsFilter)
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    updateRecruiters: (
        recruitmentId: string,
        recruiters: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[]
    ) => dispatch(RecruitmentsThunks.updateRecruitersForRecruitment(recruitmentId, recruiters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentTeamPreview));
