import { LabelFieldHorizontal, Radio } from '@emplo/react-inspinia';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    AnswerableQuestionnaireElement,
    QuestionnaireSingleChoice,
} from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import { QuestionnaireMultipleChoiceForm } from './QuestionnaireMultipleChoice';

// FORM is exactly the same as with checkboxes
export const QuestionnaireSingleChoiceForm = QuestionnaireMultipleChoiceForm;

// PREVIEW

export const QuestionnaireSingleChoicePreview = (
    props: QuestionnaireElementPreviewProps<QuestionnaireSingleChoice>
) => {
    const { t } = useTranslation();

    const renderSingleCheckboxPreview = (optionText: string, idx: number) => (
        <div key={idx} className='mt-1 mb-2'>
            <Radio label={optionText} name={props.itemData.id} />
        </div>
    );

    return (
        <LabelFieldHorizontal
            for=''
            label={
                (props.itemData as QuestionnaireSingleChoice).question +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <div className='d-flex flex-column'>{_.map(props.itemData.options, renderSingleCheckboxPreview)}</div>
        </LabelFieldHorizontal>
    );
};
