import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ReactNode } from 'react';
import AppLayout from '../layout/AppLayout';
import Dashboard from './Dashboard';

export function dashboardRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <AppLayout {...props}>
            <Dashboard />
        </AppLayout>
    );
}
