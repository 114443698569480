import { InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RECRUITEE_MAX_SCORE } from '../../../api/recruitees/recruitees.dto';
import RecruiteeViewScore from '../view/RecruiteeViewScore';
import styles from './RecruiteeReviewsModal.module.scss';
import RecruiteeReviewsList from './reviews/RecruiteeReviewsList';

interface RecruiteeReviewsModalProps {
    recruiteeId: string;
    recruitmentId: string;
    isOpen: boolean;
    closeModal: () => void;
    scoreSetByMe: number;
    averageScore: number;
    reviewCount: number;
}

export default (props: RecruiteeReviewsModalProps) => {
    const { t } = useTranslation();

    return (
        <InModal
            isOpen={props.isOpen}
            onClose={props.closeModal}
            className='animated a500 fadeInDown'
            backdropClassNames='animated a500 backdropFadeIn'>
            <InModalHeader titleText={t('recruiteeView.allScores')} onClose={props.closeModal} />
            <InModalBody className='bg-white p-0'>
                <div className='d-flex flex-column align-items-center'>
                    <h3 className='mt-3 mb-0'>{t('recruiteesInRecruitment.myScore')}</h3>
                    <RecruiteeViewScore
                        id={props.recruiteeId}
                        avgScore={props.averageScore}
                        score={props.scoreSetByMe}
                        reviewsCount={props.reviewCount}
                        starsCount={RECRUITEE_MAX_SCORE}
                        displayScoreNumber
                        disableHover
                        className={classNames(styles.score, 'd-flex flex-column align-items-center mb-3')}
                    />
                    <RecruiteeReviewsList recruiteeId={props.recruiteeId} recruitmentId={props.recruitmentId} />
                </div>
            </InModalBody>
            <InModalFooter>
                <button onClick={props.closeModal} className='btn btn-default font-bold'>
                    {t('general.button.close')}
                </button>
            </InModalFooter>
        </InModal>
    );
};
