import { UserAction, UserActionTypes } from '../user/user.actions';
import { AuthAction, AuthActionTypes } from './auth.actions';
import { AuthState, initialState } from './auth.state';

export default (state: AuthState = initialState, action: AuthAction | UserAction): AuthState => {
    switch (action.type) {
        case UserActionTypes.GET_PROFILE_STARTED:
            return {
                ...state,
                isCheckingCredentials: true
            };
        case UserActionTypes.GET_PROFILE_FINISHED:
            return {
                ...state,
                isCheckingCredentials: false,
                isLogged: true
            };

        case UserActionTypes.GET_PROFILE_FAILED:
            return {
                ...state,
                isCheckingCredentials: false,
                isLogged: false
            };
        case AuthActionTypes.LOGOUT_STARTED:
            return {
                ...state,
                isLoggingOut: true
            };
        case AuthActionTypes.LOGOUT_FINISHED:
            return {
                ...state,
                isLoggingOut: false,
                isLogged: false
            };

        case AuthActionTypes.LOGOUT_FAILED:
            return {
                ...state,
                isLoggingOut: false,
                error: action.error
            };
        default:
            return state;
    }
};
