import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NoChartData.module.scss';

interface Props {
    height: number;
}

export default (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.noData} style={{ height: props.height }}>
            <span>{t('dashboard.newApplications.noData')}</span>
        </div>
    );
};
