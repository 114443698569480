import React from 'react';
import classNames from 'classnames';
import styles from './RecruiteeScoreStar.module.scss';

interface Props {
    isFilled: boolean;
    index: number;
    onMouseOver?: (idx: number) => void;
    onMouseOut?: () => void;
    onClick?: () => void;
}

export default (props: Props) => {
    return (
        <div
            className={styles.star}
            onMouseOver={() => props.onMouseOver && props.onMouseOver(props.index)}
            onMouseOut={props.onMouseOut}
            onClick={props.onClick}>
            <i className={classNames('fa', props.isFilled ? 'fa-star' : 'fa-star-o')} />
        </div>
    );
};
