import { SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import uuid from 'uuid';

import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../api/questionnaires/builder/builder.actions';
import { questionnaireFormValuesToAddQuestionnairePostParams } from '../../../api/questionnaires/builder/builder.dto';
import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import {
    AddQuestionnaireCommand,
    QuestionnaireResume,
    QuestionQuestionnaireElementType,
} from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { RoutePath } from '../../../routes';
import QuestionnaireForm, { FormValues } from '../QuestionnaireForm';

interface DispatchProps {
    doCreateQuestionnaire: (questionnaire: AddQuestionnaireCommand) => Promise<ThunkActionResult<string>>;
    loadQuestionnaireStructure: (elements: QuestionnaireBuilderElement[]) => void;
}

class NewQuestionnaire extends Component<WithTranslation & RouteComponentProps & DispatchProps> {
    componentDidMount() {
        this.props.loadQuestionnaireStructure(this.getDefaultQuestionnaireElements());
    }

    render() {
        const { t } = this.props;
        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('general.mainMenu.questionnaires')}
                breadcrumbs={
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.questionnairesList()}>{t('general.mainMenu.questionnaires')}</Link>
                        </li>
                        <li className='breadcrumb-item active'>
                            <strong>{t('questionnairesNew.title')}</strong>
                        </li>
                    </ol>
                }>
                <QuestionnaireForm
                    initialValues={{
                        questionnaireName: t('questionnaires.questionnaireForm.questionnaireName.defaultName'),
                        isDraft: true,
                        elements: []
                    }}
                    onSubmit={this.onSubmit}
                    prefixes={this.getPrefixes()}
                />
            </SidebarLayoutFixedContent>
        );
    }

    private getDefaultQuestionnaireElements = () => [
        {
            ...({
                id: uuid.v4(),
                $type: QuestionQuestionnaireElementType.QuestionnaireResume,
                question: this.props.t('questionnaires.questionnaireForm.question.resume.label'),
                order: 0,
                pageNumber: 0,
                sectionNumber: 0
            } as QuestionnaireResume),
            isEditing: false
        }
    ];

    private getPrefixes() {
        // TODO: from API or hardcoded enum?
        return ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'prefer not to say'];
    }

    private onSubmit = async (values: FormValues) => {
        const { t } = this.props;
        const questionnaireData = questionnaireFormValuesToAddQuestionnairePostParams(values);

        const results = await this.props.doCreateQuestionnaire(questionnaireData);
        if (results.httpStatus < 300) {
            toastr.success(t('questionnairesNew.success.message'), t('questionnairesNew.success.title'));
            this.props.history.push(RoutePath.questionnairesList());
        } else {
            toastr.error(t('questionnairesNew.error.message'), t('questionnairesNew.error.title'));
        }
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    doCreateQuestionnaire: (questionnaireData: AddQuestionnaireCommand) =>
        dispatch(QuestionnairesThunks.addQuestionnaire(questionnaireData)),
    loadQuestionnaireStructure: (elements: QuestionnaireBuilderElement[]) =>
        dispatch(QuestionnaireBuilderActions.loadQuestionnaireStructure(elements))
});

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(NewQuestionnaire)));
