import { State } from '../../store/state';
import { listMapper } from '../../utils/listMapper';
import { Api } from '../_commons/http';
import { ThunkAction, ThunkActionResult, ThunkDispatch } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import {
    CreateRecruitmentRecruiteeStageEditParams,
    RecruitmentRecruiteeStagesActions,
} from './recruitmentRecruiteeStages.actions';
import {
    EditRecruitmentRecruiteeStagesCommand,
    GetRecruitmentRecruiteeStageListForSetQueryResult,
    RecruitmentRecruiteeStageListEditsViewDto,
    RecruitmentRecruiteeStageListViewDto,
} from './recruitmentRecruiteeStages.dto';
import {
    convertApiRecruitmentRecruiteeStageEditsToSchema,
    convertApiRecruitmentRecruiteeStagesForRecruiteeInRecruitmentToSchema,
    convertApiRecruitmentRecruiteeStagesToSchema,
    RecruitmentRecruiteeStageEditViewDtoNormalized,
} from './recruitmentRecruiteeStages.schema';

export class RecruitmentRecruiteeStagesThunks {
    static getStages = (): ThunkAction => async dispatch => {
        dispatch(RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesStarted());

        const { data, status, error } = await Api().get<RecruitmentRecruiteeStageListViewDto[]>(
            endpoints.recruitmentRecruiteeStages
        );

        if (data) {
            const parsed = convertApiRecruitmentRecruiteeStagesToSchema(data);

            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesFinished({
                    ids: parsed.stagesIds,
                    stages: parsed.stages,
                    totalCount: data.length,
                    page: 1
                })
            );
        } else {
            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }
    };

    static getStagesForRecruiteeInRecruitment = (
        recruiteeId: string,
        recruitmentId: string
    ): ThunkAction => async dispatch => {
        dispatch(RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentStarted());

        const { data, status, error } = await Api().get<GetRecruitmentRecruiteeStageListForSetQueryResult[]>(
            endpoints.recruitmentRecruiteeStagesForRecruiteeInRecruitment,
            {
                RecruiteeId: recruiteeId,
                RecruitmentId: recruitmentId
            }
        );

        if (data) {
            const parsed = convertApiRecruitmentRecruiteeStagesForRecruiteeInRecruitmentToSchema(data);

            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFinished({
                    ids: parsed.stagesIds,
                    stages: parsed.stages
                })
            );
        } else {
            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStagesForRecruiteeInRecruitmentFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }
    };

    static setStageForRecruiteeInRecruitment = (
        recruitmentRecruiteeStageId: string,
        recruiteeId: string,
        recruitmentId: string
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruitmentRecruiteeStagesActions.updateRecruiteeStageInRecruitmentStarted());

        const { status, error } = await Api().post(endpoints.recruitmentRecruiteeStagesSetRecruiteeInRecruitmentStage, {
            recruiteeId,
            recruitmentId,
            recruitmentRecruiteeStageId
        });

        if (status < 300) {
            dispatch(
                RecruitmentRecruiteeStagesActions.updateRecruiteeStageInRecruitmentFinished(
                    recruitmentRecruiteeStageId,
                    recruiteeId,
                    recruitmentId
                )
            );
        } else {
            dispatch(
                RecruitmentRecruiteeStagesActions.updateRecruiteeStageInRecruitmentFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }

        return {
            httpStatus: status,
            error
        };
    };

    static getStageEdits = (): ThunkAction => async dispatch => {
        dispatch(RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStageEditsStarted());

        const { data, status, error } = await Api().get<RecruitmentRecruiteeStageListEditsViewDto>(
            endpoints.recruitmentRecruiteeStageEdits
        );

        if (data) {
            const parsed = convertApiRecruitmentRecruiteeStageEditsToSchema(data.recruitmentRecruiteeStageEditViewDtos);

            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStageEditsFinished({
                    ids: parsed.stagesIds,
                    stages: parsed.stages
                })
            );
        } else {
            dispatch(
                RecruitmentRecruiteeStagesActions.getRecruitmentRecruiteeStageEditsFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }
    };

    static updateStageEdits = async (dispatch: ThunkDispatch, getState: () => State): Promise<ThunkActionResult> => {
        dispatch(RecruitmentRecruiteeStagesActions.updateRecruitmentRecruiteeStageEditStarted());

        const updateData: EditRecruitmentRecruiteeStagesCommand = {
            recruitmentRecruiteeStages: listMapper(
                getState().recruitmentRecruiteeStages.editStages.list,
                getState().recruitmentRecruiteeStages.editStages.items as Record<
                    string,
                    RecruitmentRecruiteeStageEditViewDtoNormalized
                >
            )
        };

        const { error, status } = await Api().post<string>(endpoints.recruitmentRecruiteeStageEdits, updateData);

        if (!error) {
            dispatch(RecruitmentRecruiteeStagesActions.updateRecruitmentRecruiteeStageEditFinished());
        } else {
            dispatch(
                RecruitmentRecruiteeStagesActions.updateRecruitmentRecruiteeStageEditFailed({
                    error: error!,
                    httpStatus: status
                })
            );
        }

        return {
            httpStatus: status,
            error
        };
    };

    static reorderStageEdits = (stageId: string, newIndex: number): ThunkAction<ThunkActionResult> => async (
        dispatch,
        getState
    ) => {
        // update state data
        dispatch(RecruitmentRecruiteeStagesActions.reorderRecruitmentRecruiteeStageEdits(stageId, newIndex));

        // api request
        return RecruitmentRecruiteeStagesThunks.updateStageEdits(dispatch, getState);
    };

    static deleteStageEdit = (stageId: string): ThunkAction<ThunkActionResult> => async (dispatch, getState) => {
        // update state data
        dispatch(RecruitmentRecruiteeStagesActions.deleteRecruitmentRecruiteeStageEdit(stageId));

        // api request
        return RecruitmentRecruiteeStagesThunks.updateStageEdits(dispatch, getState);
    };

    static updateStageEdit = (
        data: RecruitmentRecruiteeStageEditViewDtoNormalized
    ): ThunkAction<ThunkActionResult> => async (dispatch, getState) => {
        // update state data
        dispatch(RecruitmentRecruiteeStagesActions.updateRecruitmentRecruiteeStageEdit(data));

        // api request
        return RecruitmentRecruiteeStagesThunks.updateStageEdits(dispatch, getState);
    };

    static createStageEdit = (
        data: CreateRecruitmentRecruiteeStageEditParams
    ): ThunkAction<ThunkActionResult> => async (dispatch, getState) => {
        // update state data
        dispatch(RecruitmentRecruiteeStagesActions.createRecruitmentRecruiteeStageEdit(data));

        // api request
        return RecruitmentRecruiteeStagesThunks.updateStageEdits(dispatch, getState);
    };
}
