import { InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RecruiteeViewDtoNormalized } from '../../../api/recruitees/recruitees.schema';
import RecruiteeApplicationsList from './applicationsList/RecruiteeApplicationsList';

interface RecruitmentsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    recruitee: RecruiteeViewDtoNormalized;
    currentJobApplicationId: string;
}

export default (props: RecruitmentsModalProps) => {
    const { t } = useTranslation();

    return (
        <InModal
            isOpen={props.isOpen}
            onClose={props.closeModal}
            className='animated a500 fadeInDown'
            backdropClassNames='animated a500 backdropFadeIn'>
            <InModalHeader titleText={t('recruiteeView.otherApplications')} onClose={props.closeModal} />
            <InModalBody className='p-0' noscroll>
                <RecruiteeApplicationsList
                    recruitee={props.recruitee}
                    currentJobApplicationId={props.currentJobApplicationId}
                />
            </InModalBody>
            <InModalFooter>
                <button onClick={props.closeModal} className='btn btn-default font-bold'>
                    {t('general.button.close')}
                </button>
            </InModalFooter>
        </InModal>
    );
};
