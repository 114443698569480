import { combineReducers } from 'redux';
import authReducer from '../api/auth/auth.reducer';
import recruiteesReducer from '../api/recruitees/recruitees.reducer';
import dashboardReducer from '../api/dashboard/dashboard.reducer';
import questionnairesReducer from '../api/questionnaires/questionnaires.reducer';
import recruitmentsReducer from '../api/recruitments/recruitments.reducer';
import settingsReducer from '../api/settings/settings.reducer';
import userReducer from '../api/user/user.reducer';
import { State } from './state';
import keywordsReducer from '../api/recruitments/keywords/keywords.reducer';
import recruitmentRecruiteeStagesReducer from '../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.reducer';
import recruitmentAvailableStatusesReducer from '../api/recruitments/availableStatuses/availableStatuses.reducer';
import filledQuestionnaireReducer from '../api/filledQuestionnaire/filledQuestionnaire.reducer';
import recruitmentPositionsReducer from '../api/recruitmentPositions/recruitmentPositions.reducer';
import recruitersBrowserReducer from '../api/recruitersBrowser/recruitersBrowser.reducer';
import questionnaireBuilderReducer from '../api/questionnaires/builder/builder.reducer';

export default () =>
    combineReducers<State, any>({
        auth: authReducer,
        dashboard: dashboardReducer,
        filledQuestionnaire: filledQuestionnaireReducer,
        questionnaires: questionnairesReducer,
        questionnaireBuilder: questionnaireBuilderReducer,
        recruitees: recruiteesReducer,
        recruitersBrowser: recruitersBrowserReducer,
        recruitmentRecruiteeStages: recruitmentRecruiteeStagesReducer,
        recruitments: recruitmentsReducer,
        recruitmentPositions: recruitmentPositionsReducer,
        recruitmentAvailableStatuses: recruitmentAvailableStatusesReducer,
        keywords: keywordsReducer,
        settings: settingsReducer,
        user: userReducer
    });
