import { InModal, InModalBody, InModalFooter, InModalHeader, Preloader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import toastr from 'toastr';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import { GetRecruiteesInRecruitmentListQueryResult } from '../../../api/recruitees/recruitees.dto';
import {
    GetRecruitmentRecruiteeStageListForSetQueryResult,
    RecruitmentRecruiteeStageListViewDto,
} from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { RecruitmentRecruiteeStagesThunks } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import ButtonWithPreloader from '../../common/ButtonWithPreloader';
import { ListChoice, ListChoiceItem } from '../../common/ListChoice';
import PersonInfoBlock from '../../common/PersonInfoBlock';
import styles from './RecruiteeInRecruitmentStageEditor.module.scss';
import RecruiteeStageCircle, { getColorForStageType } from './RecruiteeStageCircle';

interface RecruiteeInRecruitmentStageEditorProps {
    stage: RecruitmentRecruiteeStageListViewDto;
    recruitee: GetRecruiteesInRecruitmentListQueryResult;
}

interface RecruiteeInRecruitmentStageEditorStateProps {
    availableStages: GetRecruitmentRecruiteeStageListForSetQueryResult[];
    isStagesLoading: boolean;
    stagesError: ErrorState;
}

interface RecruiteeInRecruitmentStageEditorDispatchProps {
    fetchStagesForRecruitee: () => void;
    updateRecruiteeInRecruitmentStage: (newStageId: string) => Promise<ThunkActionResult>;
}

interface RecruiteeInRecruitmentStageEditorRouteProps {
    id: string;
}

interface RecruiteeInRecruitmentStageEditorState {
    isModalOpen: boolean;
    isModalSubmitting: boolean;
    selectedNewStage?: GetRecruitmentRecruiteeStageListForSetQueryResult;
}

class RecruiteeInRecruitmentStageEditor extends Component<
    WithTranslation &
    RecruiteeInRecruitmentStageEditorProps &
    RecruiteeInRecruitmentStageEditorStateProps &
    RecruiteeInRecruitmentStageEditorDispatchProps &
    RouteComponentProps<RecruiteeInRecruitmentStageEditorRouteProps>,
    RecruiteeInRecruitmentStageEditorState
    > {
    state: RecruiteeInRecruitmentStageEditorState = {
        isModalOpen: false,
        isModalSubmitting: false,
        selectedNewStage: this.props.stage
    };

    render() {
        const { t, stage } = this.props;

        return (
            <>
                <div className={styles.container}>
                    <RecruiteeStageCircle
                        color={getColorForStageType(stage.stageType)}
                        className={styles.stageIndicator}
                    />
                    <div className={styles.stageName}>{stage && stage.name}</div>
                    <button
                        onClick={this.onChangeClick}
                        className={classNames('btn btn-link', styles.btnChangeStage)}
                        title={t('recruiteesInRecruitment.changeStageTitle')}>
                        {t('recruiteesInRecruitment.changeStage')}
                    </button>
                </div>

                {this.renderModal()}
            </>
        );
    }

    private renderModal = () => {
        const { t, recruitee } = this.props;
        const photoUrl = endpoints.getRecruiteePhotoFileEndpoint(recruitee.id);

        return (
            <InModal
                isOpen={this.state.isModalOpen}
                onClose={this.onModalNo}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'>
                <InModalHeader titleText={t('recruiteesInRecruitment.changeStageTitle')} onClose={this.onModalNo} />
                <InModalBody className='align-items-center bg-white'>
                    <PersonInfoBlock
                        firstName={recruitee.firstName}
                        lastName={recruitee.lastName}
                        photoUrl={photoUrl}
                        size='large'
                    />
                    {this.props.isStagesLoading && <Preloader />}
                    {!this.props.isStagesLoading && (
                        <ListChoice>{this.props.availableStages.map(this.renderAvailableStageOption)}</ListChoice>
                    )}
                </InModalBody>
                <InModalFooter>
                    <ButtonWithPreloader
                        className='btn btn-default font-bold btn-w-m'
                        isSaving={this.state.isModalSubmitting}
                        onClick={this.onModalNo}>
                        {t('general.button.cancel')}
                    </ButtonWithPreloader>
                    <ButtonWithPreloader
                        className='btn btn-primary font-bold btn-w-m'
                        isSaving={this.state.isModalSubmitting}
                        showSpinner
                        onClick={this.onModalYes}>
                        {t('general.button.change')}
                    </ButtonWithPreloader>
                </InModalFooter>
            </InModal>
        );
    };

    private renderAvailableStageOption = (stage: GetRecruitmentRecruiteeStageListForSetQueryResult) => {
        return (
            <ListChoiceItem
                key={`available-stage-${stage.id}`}
                value={stage}
                onChange={this.onAvailableStageCheckChange}
                multi={false}
                checked={this.state.selectedNewStage && this.state.selectedNewStage.id === stage.id}
                name='change-stage'
                label={stage.name || ''}></ListChoiceItem>
        );
    };

    private onAvailableStageCheckChange = (stage: GetRecruitmentRecruiteeStageListForSetQueryResult) => {
        this.setState({
            selectedNewStage: stage
        });
    };

    private onChangeClick = () => {
        this.props.fetchStagesForRecruitee();

        this.setState({
            isModalOpen: true
        });
    };

    private onModalYes = async () => {
        const { t } = this.props;

        if (this.state.selectedNewStage) {
            this.setState({
                isModalSubmitting: true
            });

            const results = await this.props.updateRecruiteeInRecruitmentStage(this.state.selectedNewStage.id);

            this.setState({
                isModalSubmitting: false,
                isModalOpen: false
            });

            if (results.httpStatus < 300) {
                toastr.success(
                    t('recruiteesInRecruitment.stageUpdateSuccess', { stage: this.state.selectedNewStage.name }),
                    `${this.props.recruitee.firstName} ${this.props.recruitee.lastName}`
                );
            } else {
                toastr.success(
                    t('recruiteesInRecruitment.stageUpdateError', { stage: this.state.selectedNewStage.name }),
                    `${this.props.recruitee.firstName} ${this.props.recruitee.lastName}`
                );
            }
        }
    };

    private onModalNo = () => {
        this.setState({
            isModalOpen: false
        });
    };
}

const mapStateToProps = (state: State): RecruiteeInRecruitmentStageEditorStateProps => ({
    availableStages: listMapper(
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.list,
        state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.items
    ),
    isStagesLoading: state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.isLoading,
    stagesError: state.recruitmentRecruiteeStages.currentRecruiteeAvailableStages.error
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch,
    props: RecruiteeInRecruitmentStageEditorProps & RouteComponentProps<RecruiteeInRecruitmentStageEditorRouteProps>
): RecruiteeInRecruitmentStageEditorDispatchProps => ({
    fetchStagesForRecruitee: () =>
        dispatch(
            RecruitmentRecruiteeStagesThunks.getStagesForRecruiteeInRecruitment(
                props.recruitee.id,
                props.match.params.id
            )
        ),
    updateRecruiteeInRecruitmentStage: (newStageId: string) =>
        dispatch(
            RecruitmentRecruiteeStagesThunks.setStageForRecruiteeInRecruitment(
                newStageId,
                props.recruitee.id,
                props.match.params.id
            )
        )
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteeInRecruitmentStageEditor))
);
