import { FileUpload, LabelFieldHorizontal } from '@emplo/react-inspinia';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Cover letter question is not editable.
 */

export const QuestionnaireCoverLetterPreview = () => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.coverLetter.label')}>
            <FileUpload
                disabled
                labelButton={t('questionnaires.questionnaireForm.question.resume.button')}
                placeholder={t('questionnaires.questionnaireForm.question.coverLetter.placeholder')}
                name=''
                setFieldValue={_.noop}
            />
        </LabelFieldHorizontal>
    );
};
