import { TFunction } from 'i18next';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import { Activity, MessageSentActivityData } from '../../../../../api/recruitees/recruitees.dto';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import { ActivityItem } from './ActivityItem';

export default class MessageSentEvent extends ActivityItem {
    constructor(protected itemData: Activity, protected activityData: MessageSentActivityData, protected t: TFunction) {
        super(itemData, activityData, t);
    }

    renderNode() {
        return (
            <div className='bg-info'>
                <i className='fa fa-envelope' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.activityData.atsUserId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.atsUserFirstName}
                lastName={this.activityData.atsUserLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        return this.t('recruiteeView.activities.messageSent.title');
    }

    getContent() {
        return <div>{this.activityData.text}</div>;
    }
}
