import React from 'react';
import styles from './PersonInfoBlock.module.scss';
import classNames from 'classnames';
import HiringManagerStar from '../recruitments/common/HiringManagerStar';

type Size = 'tiny' | 'small' | 'medium' | 'large';

interface PersonInfoBlockProps {
    size?: Size;
    photoUrl: string;
    firstName: string;
    lastName: string;
    className?: string;
    personNameClassName?: string;
    showStar?: boolean;
}

export default (props: React.PropsWithChildren<PersonInfoBlockProps>) => {
    return (
        <div className={classNames(styles.container, props.className)}>
            <div className={styles.photo}>
                <div
                    className={classNames(
                        styles.photoWrapper,
                        props.size === 'large' && styles.photoWrapperLarge,
                        props.size === 'tiny' && styles.photoWrapperTiny,
                        props.size === 'small' && styles.photoWrapperSmall
                    )}
                    style={{ backgroundImage: `url(${props.photoUrl})` }}>
                    <img src={props.photoUrl} alt={`${props.firstName} ${props.lastName}`} />
                    {props.showStar && <HiringManagerStar />}
                </div>
            </div>
            <div className={styles.personal}>
                <span
                    className={classNames(
                        'd-block mb-1',
                        props.size === 'tiny' && styles.nameTiny,
                        props.size === 'small' && styles.nameSmall,
                        props.size === 'large' && styles.nameLarge,
                        props.personNameClassName
                    )}>
                    {props.firstName} {props.lastName}
                </span>
                {props.children}
            </div>
        </div>
    );
};
