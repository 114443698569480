import { StageType } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';

export function isStageTypeEditable(type: StageType) {
    switch (type) {
        case StageType.RejectedApplication:
        case StageType.New:
        case StageType.Hired:
            return false;
        default:
            return true;
    }
}
