import {
    Dropdown,
    DropdownItem,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    Label,
    LabelType,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import toastr from 'toastr';

import { ThunkActionResult } from '../../../api/_commons/thunks.common';
import { endpoints } from '../../../api/endpoints';
import {
    HiringManagerListViewHiringManagerListView,
    RecruitmentOwnerListViewRecruitmentOwnerListView,
    RecruitmentStatus,
} from '../../../api/recruitments/recruitments.dto';
import { RoutePath } from '../../../routes';
import { TOOLTIP } from '../../../tooltips';
import { date, dateAgo } from '../../../utils/format';
import PersonInfoBlock from '../../common/PersonInfoBlock';
import styles from './RecruitmentsList.module.scss';

interface RecruitmentsListItemProps {
    id: string;
    title: string;
    deadline: string;
    positionName: string;
    recruitmentStatus: RecruitmentStatus;
    candidatesCount: number;
    applicantsCount: number;
    newRecruiteesCount: number;
    hiringManager?: HiringManagerListViewHiringManagerListView;
    recruitmentOwner: RecruitmentOwnerListViewRecruitmentOwnerListView;
    doDeleteRecruitment: (id: string) => Promise<ThunkActionResult>;
    reloadRecruitments: () => void;
}

interface OwnState {
    isDeleteModalOpen: boolean;
    isRedirectToEdit: boolean;
}

class RecruitmentsListItem extends Component<RecruitmentsListItemProps & WithTranslation, OwnState> {
    state: OwnState = {
        isDeleteModalOpen: false,
        isRedirectToEdit: false
    };

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const {
            t,
            id,
            title,
            deadline,
            positionName,
            recruitmentStatus,
            candidatesCount,
            applicantsCount,
            newRecruiteesCount,
            hiringManager,
            recruitmentOwner
        } = this.props;
        const statusText = this.getStatusText(recruitmentStatus);
        const statusLabelType = this.getStatusLabelType(recruitmentStatus);

        const itemLink =
            recruitmentStatus === RecruitmentStatus.DRAFT
                ? RoutePath.recruitmentsEditRecruitment(id)
                : RoutePath.recruitmentsRecruitmentDetails(id);
        const dropdownItems = this.renderDropdownItems();

        if (this.state.isRedirectToEdit) {
            return <Redirect to={RoutePath.recruitmentsEditRecruitment(this.props.id)} push />;
        }

        return (
            <tr>
                <td className='align-top'>
                    <Link to={itemLink} className='d-block pl-3 pr-3'>
                        <Label type={statusLabelType} className='w-100'>
                            {statusText}
                        </Label>
                    </Link>
                </td>
                <td className='w-100'>
                    <Link to={itemLink} className={styles.grid}>
                        <div className={classNames(styles.gridTitle, 'mb-3')}>{title}</div>
                        <div className={styles.gridPosition}>
                            <small>{t('recruitments.position')}:</small>
                            <br />
                            <span className='font-bold'>{positionName}</span>
                        </div>
                        <div className={styles.gridManager}>
                            <small>{t('recruitments.manager')}:</small>
                            <br />
                            {hiringManager ? (
                                <PersonInfoBlock
                                    firstName={hiringManager.firstName}
                                    lastName={hiringManager.lastName}
                                    photoUrl={endpoints.getAtsUserAvatarEndpoint(hiringManager.id)}
                                    size='tiny'
                                    personNameClassName='font-bold'
                                    className='mb-0'
                                />
                            ) : (
                                    <span className='font-bold'>-</span>
                                )}
                        </div>
                        <div className={styles.gridOwner}>
                            <small>{t('recruitments.owner')}:</small>
                            <br />
                            <PersonInfoBlock
                                firstName={recruitmentOwner.firstName}
                                lastName={recruitmentOwner.lastName}
                                photoUrl={endpoints.getAtsUserAvatarEndpoint(recruitmentOwner.id)}
                                size='tiny'
                                personNameClassName='font-bold'
                                className='mb-0'
                            />
                        </div>
                        <div className={styles.gridCandidates}>
                            <small>{t('recruitments.candidatesApplicants')}:</small>
                            <br />
                            <div className={styles.itemCandidatesCountContainer}>
                                <span className='font-bold'>
                                    {candidatesCount} / {applicantsCount}
                                </span>
                                {newRecruiteesCount > 0 && (
                                    <Label type={LabelType.DANGER} className={styles.newApplicationsLabel}>
                                        {t('recruitments.newRecruiteesLabel', { count: newRecruiteesCount })}
                                    </Label>
                                )}
                            </div>
                        </div>
                        <div className={styles.deadline}>
                            <small>{t('recruitments.deadline')}:</small>
                            <br />
                            <span>
                                <span className='font-bold'>{date(deadline)}</span>
                                <span className='text-small text-danger font-weight-bold pl-1'>
                                    {dateAgo(deadline)}
                                </span>
                            </span>
                        </div>
                    </Link>
                </td>
                <td>
                    {dropdownItems.length > 0 && (
                        <Dropdown trigger={this.renderActionsButton()} align='se'>
                            {dropdownItems}
                        </Dropdown>
                    )}

                    <InModal
                        isOpen={this.state.isDeleteModalOpen}
                        onClose={this.onDeleteRecruitmentCancel}
                        className='animated a500 fadeInDown'
                        backdropClassNames='animated a500 backdropFadeIn'>
                        <InModalHeader titleText={t('recruitments.deleteRecruitmentTitle')} />
                        <InModalBody>
                            <span>
                                <Trans i18nKey='recruitments.deleteRecruitmentMessage' values={{ recruitment: title }}>
                                    Are you sure you want to delete recruitment <strong>{{ title }}</strong>?
                                </Trans>
                            </span>
                        </InModalBody>
                        <InModalFooter>
                            <button
                                type='button'
                                className='btn btn-default font-bold btn-w-m'
                                onClick={this.onDeleteRecruitmentCancel}>
                                {t('general.button.cancel')}
                            </button>
                            <button
                                type='button'
                                className='btn btn-danger font-bold btn-w-m'
                                onClick={this.onDeleteRecruitmentConfirm}>
                                {t('general.button.delete')}
                            </button>
                        </InModalFooter>
                    </InModal>
                </td>
            </tr>
        );
    }

    private renderActionsButton = () => {
        const { t } = this.props;

        return (
            <button
                className={classNames('btn btn-white', styles.btnActions)}
                data-tip={t('recruitments.moreActions')}
                data-for={TOOLTIP.TOP}>
                <i className='fa fa-ellipsis-v' />
            </button>
        );
    };

    private renderDropdownItems = () => {
        const { t, recruitmentStatus } = this.props;
        const results: React.ReactNode[] = [];

        // allow editing of drafts, pending, open
        if ([RecruitmentStatus.DRAFT, RecruitmentStatus.PENDING, RecruitmentStatus.OPEN].includes(recruitmentStatus)) {
            results.push(
                <DropdownItem
                    key={`dropdown-item-edit-${this.props.id}`}
                    onClick={this.onEditRecruitmentClick}
                    icon={<i className='fa fa-fw fa-pencil' />}
                    label={t('general.button.edit')}
                />
            );
        }

        // allow deleting drafts and pending
        if ([RecruitmentStatus.DRAFT, RecruitmentStatus.PENDING].includes(recruitmentStatus)) {
            results.push(
                <DropdownItem
                    key={`dropdown-item-delete-${this.props.id}`}
                    onClick={this.onDeleteRecruitmentClick}
                    icon={<i className='fa fa-fw fa-times' />}
                    label={t('general.button.delete')}
                />
            );
        }

        return results;
    };

    private onEditRecruitmentClick = () => {
        this.setState({
            isRedirectToEdit: true
        });
    };

    private onDeleteRecruitmentClick = () => {
        this.setState({
            isDeleteModalOpen: true
        });
    };

    private onDeleteRecruitmentCancel = () => {
        this.setState({
            isDeleteModalOpen: false
        });
    };

    private onDeleteRecruitmentConfirm = async () => {
        const { t } = this.props;

        this.setState({
            isDeleteModalOpen: false
        });

        const results = await this.props.doDeleteRecruitment(this.props.id);
        if (results.httpStatus < 300) {
            toastr.success(t('recruitments.deleteRecruitmentSuccess'), t('general.success'));
            this.props.reloadRecruitments();
        } else {
            toastr.error(t('recruitments.deleteRecruitmentError'), t('general.error'));
        }
    };

    private getStatusText(status: RecruitmentStatus) {
        const { t } = this.props;

        switch (status) {
            case RecruitmentStatus.DRAFT:
                return t('recruitments.filter.draft');
            case RecruitmentStatus.PENDING:
                return t('recruitments.filter.pending');
            case RecruitmentStatus.OPEN:
                return t('recruitments.filter.open');
            case RecruitmentStatus.CLOSED:
                return t('recruitments.filter.closed');
        }
    }

    private getStatusLabelType(status: RecruitmentStatus): LabelType {
        switch (status) {
            case RecruitmentStatus.DRAFT:
                return LabelType.WARNING;
            case RecruitmentStatus.OPEN:
                return LabelType.INFO;
            case RecruitmentStatus.CLOSED:
                return LabelType.DANGER;
            case RecruitmentStatus.PENDING:
            default:
                return LabelType.PRIMARY;
        }
    }
}

export default withTranslation()(RecruitmentsListItem);
