import { normalize, schema } from 'normalizr';
import { Dictionary } from 'lodash';
import {
    RecruitmentRecruiteeStageListViewDto,
    RecruitmentRecruiteeStageEditViewDto,
    GetRecruitmentRecruiteeStageListForSetQueryResult
} from './recruitmentRecruiteeStages.dto';
import { stageTypeSchema } from '../settings/settings.schema';

export const SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES = 'recruitmentRecruiteeStages';
export const SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES_FOR_RECRUITEE_IN_RECRUITMENT =
    'recruitmentRecruiteeStagesForRecruiteeInRecruitment';

export const recruitmentRecruiteeStageSchema = new schema.Entity(SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES, {
    stageType: stageTypeSchema
});

export const recruitmentRecruiteeStageForRecruiteeInRecruitmentSchema = new schema.Entity(
    SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES_FOR_RECRUITEE_IN_RECRUITMENT
);

export function convertApiRecruitmentRecruiteeStagesToSchema(response: RecruitmentRecruiteeStageListViewDto[]) {
    const parsed = normalize(response, [recruitmentRecruiteeStageSchema]);

    return {
        stagesIds: parsed.result as string[],
        stages: parsed.entities[SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES] as Dictionary<
            RecruitmentRecruiteeStageListViewDtoNormalized
        >
    };
}

export function convertApiRecruitmentRecruiteeStagesForRecruiteeInRecruitmentToSchema(
    response: GetRecruitmentRecruiteeStageListForSetQueryResult[]
) {
    const parsed = normalize(response, [recruitmentRecruiteeStageForRecruiteeInRecruitmentSchema]);

    return {
        stagesIds: parsed.result as string[],
        stages: parsed.entities[SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES_FOR_RECRUITEE_IN_RECRUITMENT] as Dictionary<
            GetRecruitmentRecruiteeStageListForSetQueryResult
        >
    };
}

export function convertApiRecruitmentRecruiteeStageEditsToSchema(response: RecruitmentRecruiteeStageEditViewDto[]) {
    const parsed = normalize(response, [recruitmentRecruiteeStageSchema]);

    return {
        stagesIds: parsed.result as string[],
        stages: parsed.entities[SCHEMA_KEY_RECRUITMENT_RECRUITEE_STAGES] as Dictionary<
            RecruitmentRecruiteeStageEditViewDtoNormalized
        >
    };
}

export type RecruitmentRecruiteeStageListViewDtoNormalized = Omit<RecruitmentRecruiteeStageListViewDto, 'stageType'> & {
    stageType: number;
};

export type RecruitmentRecruiteeStageEditViewDtoNormalized = Omit<RecruitmentRecruiteeStageEditViewDto, 'stageType'> & {
    stageType: number;
};
