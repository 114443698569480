import { Api } from '../_commons/http';
import { ThunkAction, ThunkActionResult } from '../_commons/thunks.common';
import { endpoints } from '../endpoints';
import { RecruitmentPositionsActions } from './recruitmentPositions.actions';
import {
    EditRecruitmentPositionCommand,
    GetRecruitmentPositionsListForEditQueryResult,
    GetRecruitmentPositionsListQueryResultPagedResult,
} from './recruitmentPositions.dto';
import {
    convertApiRecruitmentPositionEditsToSchema,
    convertApiRecruitmentPositionsToSchema,
} from './recruitmentPositions.schema';

const DEFAULT_PAGE_SIZE = 1000;

export class RecruitmentPositionsThunks {
    static getRecruitmentPositionsEdit = (
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async dispatch => {
        dispatch(RecruitmentPositionsActions.getRecruitmentPositionsEditStarted());

        const { data, status, error } = await Api().get<GetRecruitmentPositionsListForEditQueryResult>(
            endpoints.getRecruitmentPositionsEdit,
            {
                pageNumber: page,
                pageSize
            }
        );

        if (data) {
            const parsed = convertApiRecruitmentPositionEditsToSchema(data.recruitmentPositionForEditViewDtos.result);

            dispatch(
                RecruitmentPositionsActions.getRecruitmentPositionsEditFinished({
                    ids: parsed.ids,
                    recruitmentPositions: parsed.recruitmentPositions,
                    totalCount: data.recruitmentPositionForEditViewDtos.totalCount,
                    pageNumber: data.recruitmentPositionForEditViewDtos.pageNumber
                })
            );
        } else {
            dispatch(
                RecruitmentPositionsActions.getRecruitmentPositionsEditFailed({ error: error!, httpStatus: status })
            );
        }
    };

    static updateRecruitmentPositionEdit = (
        command: EditRecruitmentPositionCommand
    ): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruitmentPositionsActions.updateRecruitmentPositionEditStarted());

        const { status, error } = await Api().post(endpoints.updateRecruitmentPositionsEdit, command);

        if (status === 200) {
            dispatch(RecruitmentPositionsActions.updateRecruitmentPositionEditFinished(command));
        } else {
            dispatch(
                RecruitmentPositionsActions.updateRecruitmentPositionEditFailed({ error: error!, httpStatus: status })
            );
        }

        return {
            httpStatus: status,
            error
        };
    };

    static deleteRecruitmentPositionEdit = (id: string): ThunkAction<ThunkActionResult> => async dispatch => {
        dispatch(RecruitmentPositionsActions.deleteRecruitmentPositionEditStarted());

        const { status, error } = await Api().delete(endpoints.getDeleteRecruitmentPositionEndpoint(id));

        if (status === 200) {
            dispatch(RecruitmentPositionsActions.deleteRecruitmentPositionEditFinished(id));
        } else {
            dispatch(
                RecruitmentPositionsActions.deleteRecruitmentPositionEditFailed({ error: error!, httpStatus: status })
            );
        }

        return {
            httpStatus: status,
            error
        };
    };

    static getRecruitmentPositions = (
        startingWith: string = '',
        page: number = 1,
        pageSize: number = DEFAULT_PAGE_SIZE
    ): ThunkAction => async dispatch => {
        dispatch(RecruitmentPositionsActions.getRecruitmentPositionsStarted());

        const { data, status, error } = await Api().get<GetRecruitmentPositionsListQueryResultPagedResult>(
            endpoints.getRecruitmentPositions,
            {
                startingWith,
                page,
                pageSize
            }
        );

        if (data) {
            const parsed = convertApiRecruitmentPositionsToSchema(data.result);

            dispatch(
                RecruitmentPositionsActions.getRecruitmentPositionsFinished({
                    ids: parsed.ids,
                    recruitmentPositions: parsed.recruitmentPositions
                })
            );
        } else {
            dispatch(RecruitmentPositionsActions.getRecruitmentPositionsFailed({ error: error!, httpStatus: status }));
        }
    };
}
