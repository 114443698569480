import _ from 'lodash';
import { combineReducers } from 'redux';

import { QuestionnairesAction, QuestionnairesActionTypes } from './questionnaires.actions';
import { initialState, QuestionnairesState } from './questionnaires.state';

const questionnairesReducer = (
    state: QuestionnairesState['questionnaires'] = initialState.questionnaires,
    action: QuestionnairesAction
): QuestionnairesState['questionnaires'] => {
    switch (action.type) {
        case QuestionnairesActionTypes.CLEAR_QUESTIONNAIRES:
            return {
                ...state,
                list: [],
                items: {}
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_FINISHED:
            return {
                ...state,
                list: [...(action.response.pageNumber === 1 ? [] : state.list), ...action.response.ids],
                items: { ...(action.response.pageNumber === 1 ? {} : state.items), ...action.response.questionnaires },
                totalCount: action.response.totalCount,
                page: action.response.pageNumber,
                isLoading: false,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_STARTED:
            return {
                ...state,
                isWriting: true
            };

        case QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_FINISHED:
            const newItems = { ...state.items };
            delete newItems[action.id];

            return {
                ...state,
                list: state.list.filter(questionnaireId => questionnaireId !== action.id),
                items: newItems,
                isWriting: false
            };

        case QuestionnairesActionTypes.DELETE_QUESTIONNAIRE_FAILED:
            return {
                ...state,
                isWriting: false,
                error: action.error
            };

        case QuestionnairesActionTypes.SET_QUESTIONNAIRES_LIST_FILTERS:
            return {
                ...state,
                filters: action.filters
            };

        default:
            return state;
    }
};

const questionnaireDetailsReducer = (
    state: QuestionnairesState['questionnaireDetails'] = initialState.questionnaireDetails,
    action: QuestionnairesAction
): QuestionnairesState['questionnaireDetails'] => {
    switch (action.type) {
        case QuestionnairesActionTypes.GET_QUESTIONNAIRE_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRE_FINISHED:
            return {
                ...state,
                questionnaire: {
                    ...action.response.questionnaire
                },
                isLoading: false,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

const questionnairesForRecruitmentReducer = (
    state: QuestionnairesState['questionnairesForRecruitment'] = initialState.questionnairesForRecruitment,
    action: QuestionnairesAction
): QuestionnairesState['questionnairesForRecruitment'] => {
    switch (action.type) {
        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_FINISHED:
            return {
                ...state,
                list: [...action.response.ids],
                items: _.reduce(
                    action.response.questionnaires,
                    (prev, curr) => ({
                        ...prev,
                        [curr.id]: curr
                    }),
                    state.items
                ),
                isLoading: false,
                error: null
            };

        case QuestionnairesActionTypes.GET_QUESTIONNAIRES_FOR_RECRUITMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};

export default combineReducers<QuestionnairesState>({
    questionnaires: questionnairesReducer,
    questionnaireDetails: questionnaireDetailsReducer,
    questionnairesForRecruitment: questionnairesForRecruitmentReducer
});
