import { Dictionary } from 'lodash';
import { createSelector } from 'reselect';

import { State } from '../../store/state';
import { BudgetCurrency } from './settings.state';

export const selectCurrency = createSelector(
    (state: State) => state.settings.currencies.items,
    (state: State, currencyId: number) => currencyId,
    (currencies: Dictionary<BudgetCurrency>, currencyId: number) => currencies[currencyId]
);
