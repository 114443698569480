import 'react-bootstrap-typeahead/css/Typeahead.css';

import classNames from 'classnames';
import React, { Component, HTMLProps } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { GetRecruitmentPositionsListQueryResult } from '../../../api/recruitmentPositions/recruitmentPositions.dto';
import { RecruitmentPositionsThunks } from '../../../api/recruitmentPositions/recruitmentPositions.thunk';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';

const ON_CHANGE_REQUEST_DELAY = 300;

export interface PositionNameValue {
    id?: string;
    name: string;
}

type Option = GetRecruitmentPositionsListQueryResult & {
    customOption?: boolean;
};

type Props = Omit<HTMLProps<HTMLInputElement>, 'value'> & {
    value: PositionNameValue;
    name: string;
    newSelectionPrefix?: string;
    foundItems: GetRecruitmentPositionsListQueryResult[];
    fetchItems: (startingWith: string, page?: number, pageSize?: number) => void;
    setFieldValue: (field: string, value: PositionNameValue) => void;
};

class PositionName extends Component<Props> {
    private requestTask?: number;
    private defaultId = uuid.v4();

    componentDidMount() {
        // load all initially
        this.props.fetchItems('');
    }

    // componentDidUpdate(prevProps: Props) {
    //     if (!_.isEqual(prevProps.foundItems, this.props.foundItems)) {
    //         // check if entered value exists in found items
    //         this.props.setFieldValue(this.props.name, { name: inputValue });
    //     }
    // }

    render() {
        return (
            <Typeahead<Option>
                id={this.props.id || this.defaultId}
                options={this.props.foundItems}
                labelKey='localizedPositionName'
                newSelectionPrefix={this.props.newSelectionPrefix}
                filterBy={this.filterOption}
                inputProps={{
                    className: classNames('form-control', this.props.className),
                }}
                defaultInputValue={`${this.props.value.name}`}
                onInputChange={this.onTextChange}
                onChange={this.onOptionSelected}
                placeholder={this.props.placeholder}
                allowNew
                selectHintOnEnter
            />
        );
    }

    private filterOption = () => {
        return true;
    };

    private onOptionSelected = (options: Option[]) => {
        if (!options.length) {
            return;
        }

        const option = options[0];
        const value = option.customOption
            ? { name: option.localizedPositionName }
            : { id: option.id, name: option.localizedPositionName };

        this.props.setFieldValue(this.props.name, value);
    };

    private onTextChange = (inputValue: string) => {
        // set input value as new position
        this.props.setFieldValue(this.props.name, { name: inputValue });

        if (this.requestTask !== undefined) {
            // clear timeout
            window.clearTimeout(this.requestTask);
            this.requestTask = undefined;
        }

        this.requestTask = window.setTimeout(async () => {
            // fetch items
            await this.props.fetchItems(inputValue);
            // check if entered value exists in the list of found items
            // if so, automatically select it
            const foundItem = this.props.foundItems.find((item) => item.localizedPositionName === inputValue);
            if (foundItem) {
                this.props.setFieldValue(this.props.name, { id: foundItem.id, name: foundItem.localizedPositionName });
            }
        }, ON_CHANGE_REQUEST_DELAY);
    };
}

const mapStateToProps = (state: State) => ({
    foundItems: listMapper(
        state.recruitmentPositions.recruitmentPositions.list,
        state.recruitmentPositions.recruitmentPositions.items
    ),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    fetchItems: (startingWith: string, page: number = 1, pageSize: number = 1000) =>
        dispatch(RecruitmentPositionsThunks.getRecruitmentPositions(startingWith, page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PositionName);
