import {
    ErrorContext,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    LabelFieldHorizontal,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';

enum FormField {
    noteText = 'noteText'
}

export interface FormValues {
    [FormField.noteText]: string;
}

const defaultValues: FormValues = {
    noteText: ''
};

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (formData: FormValues) => void;
}

class RecruiteeActionAddNoteModal extends Component<Props & WithTranslation> {
    private readonly validationSchema = yup.object().shape({
        [FormField.noteText]: yup.string().required()
    });

    render() {
        return (
            <InModal
                isOpen={this.props.isOpen}
                onClose={this.props.closeModal}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'>
                <Formik
                    render={this.renderForm}
                    initialValues={defaultValues}
                    onSubmit={this.props.onSubmit}
                    validationSchema={this.validationSchema}
                />
            </InModal>
        );
    }

    private renderForm = (props: FormikProps<FormValues>) => {
        const { t } = this.props;
        const { errors, touched } = props;

        return (
            <ErrorContext.Provider value={{ errors, touched }}>
                <Form>
                    <InModalHeader
                        titleText={t('recruiteeView.addNoteTitle')}
                        className='border-bottom-0'
                        onClose={this.props.closeModal}
                    />
                    <InModalBody className='bg-white p-0'>
                        <LabelFieldHorizontal for={FormField.noteText} className='mb-0'>
                            <textarea
                                className='form-control'
                                rows={14}
                                name={FormField.noteText}
                                value={props.values.noteText}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                placeholder={t('recruiteeView.addNoteTextPlaceholder')}
                            />
                        </LabelFieldHorizontal>
                    </InModalBody>
                    <InModalFooter className='border-top-0'>
                        <button
                            onClick={this.props.closeModal}
                            className={classNames('btn btn-default btn-lg btn-w-m font-bold')}>
                            {t('general.button.close')}
                        </button>
                        <button type='submit' className={classNames('btn btn-primary btn-lg btn-w-m ml-3 font-bold')}>
                            {t('general.button.save')}
                        </button>
                    </InModalFooter>
                </Form>
            </ErrorContext.Provider>
        );
    };
}

export default withTranslation()(RecruiteeActionAddNoteModal);
