import { Dropdown, DropdownItem } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
    GetRecruitmentRecruiteeStageListForSetQueryResult,
} from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import styles from './RecruiteeStageSelect.module.scss';

interface Props {
    stageId: string;
    availableStages: GetRecruitmentRecruiteeStageListForSetQueryResult[];
    onStageChange: (stage: GetRecruitmentRecruiteeStageListForSetQueryResult) => void;
}

class RecruiteeStageSelect extends Component<Props & WithTranslation> {
    render() {
        const { t } = this.props;

        return (
            <div className={styles.container}>
                <span className='font-weight-bold'>{t('recruiteeView.stage')}: </span>
                <Dropdown trigger={this.renderTrigger()} align='se'>
                    {this.props.availableStages.map(this.renderDropdownItem)}
                </Dropdown>
            </div>
        );
    }

    private renderTrigger = () => {
        const stage = this.props.availableStages.find(stage => this.props.stageId === stage.id);

        if (stage) {
            return (
                <button className={classNames('btn btn-success', styles.stageButton)}>
                    <span className='mr-2'>{stage.name}</span>
                    <i className='fa fa-caret-down' />
                </button>
            );
        }

        return null;
    };

    private renderDropdownItem = (stage: GetRecruitmentRecruiteeStageListForSetQueryResult) => {
        return (
            <DropdownItem
                key={stage.id}
                label={stage.name || ''}
                className={stage.id === this.props.stageId ? 'font-bold' : ''}
                onClick={() => {
                    if (this.props.stageId !== stage.id) {
                        this.props.onStageChange(stage);
                    }
                }}
            />
        );
    };
}

export default withTranslation()(RecruiteeStageSelect);
