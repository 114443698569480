import { SpinnerCircle } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React from 'react';

type Props = React.HtmlHTMLAttributes<HTMLButtonElement> & {
    isSaving: boolean;
    showSpinner?: boolean;
};

export default (props: React.PropsWithChildren<Props>) => {
    const { isSaving, showSpinner, ...buttonProps } = props;

    return (
        <button
            {...buttonProps}
            type='button'
            className={classNames('d-flex justify-content-center align-items-center', props.className)}
            disabled={isSaving}>
            {showSpinner && isSaving && <SpinnerCircle spinnerSize='small' inline className='mt-0 ml-0 mb-0 mr-2' />}
            {props.children}
        </button>
    );
};
