import { LabelFieldHorizontal, TextInput } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

import { AnswerableQuestionnaireElement, QuestionnaireAddress } from '../../../../api/questionnaires/questionnaires.dto';
import { generateLabelAffixIfRequired } from '../_util';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from '../QuestionnaireElement';
import QuestionForm from './QuestionForm';

class questionnaireAddressForm extends Component<
    WithTranslation & QuestionnaireElementFormProps<QuestionnaireAddress>
    > {
    render() {
        return (
            <QuestionForm
                itemData={this.props.itemData}
                index={this.props.index}
                onSubmit={this.props.save}
                onCancel={this.props.exitEditing}
                render={this.renderFields}
                isQuestionEditable={false}
                handleTypeChange={this.props.handleTypeChange}
                elementType={this.props.elementType}
            />
        );
    }

    private renderFields = () => {
        const { t } = this.props;

        return (
            <LabelFieldHorizontal for='' label={t('questionnaires.questionnaireForm.question.address.label')}>
                <TextInput
                    disabled
                    value={t('questionnaires.questionnaireForm.addMenu.field.Address.placeholder') as string}
                />
            </LabelFieldHorizontal>
        );
    };
}

export const QuestionnaireAddressForm = withTranslation()(questionnaireAddressForm);

export const QuestionnaireAddressPreview = (props: QuestionnaireElementPreviewProps<QuestionnaireAddress>) => {
    const { t } = useTranslation();

    return (
        <LabelFieldHorizontal
            for=''
            label={
                t('questionnaires.questionnaireForm.question.address.label') +
                t(generateLabelAffixIfRequired(props.itemData as AnswerableQuestionnaireElement))
            }>
            <TextInput />
        </LabelFieldHorizontal>
    );
};
