import { FilledQuestionnaireAction, FilledQuestionnaireActionTypes } from './filledQuestionnaire.actions';
import { FilledQuestionnaireState, initialState } from './filledQuestionnaire.state';

export default (state: FilledQuestionnaireState = initialState, action: FilledQuestionnaireAction) => {
    switch (action.type) {
        case FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_STARTED:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_FINISHED:
            return {
                ...state,
                data: action.response.data,
                isLoading: false,
                error: null
            };

        case FilledQuestionnaireActionTypes.GET_FILLED_QUESTIONNAIRE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};
