import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { ActivityDashboardListView } from '../../api/dashboard/dashboard.dto';
import { endpoints } from '../../api/endpoints';
import {
    ActivityType,
    JobApplicationSentActivityData,
    MeetingScheduledActivityData,
    MessageSentActivityData,
    NoteAddedActivityData,
    PhoneCallPerformedActivityData,
    ScoreChangedActivityData,
    StageChangedActivityData,
} from '../../api/recruitees/recruitees.dto';
import { RoutePath } from '../../routes';
import { dateTimeAgo } from '../../utils/format';
import ActivitiesListItem, { ActivitiesListItemProps } from './ActivitiesListItem';

interface ActivitiesListProps {
    items: ActivityDashboardListView[];
}

class ActivitiesList extends Component<ActivitiesListProps & WithTranslation> {
    static defaultProps = {
        isLoading: false,
        items: [],
        error: null
    };

    render() {
        const activityItems = this.props.items.map(this.renderActivityItem);

        return <div className='feed-activity-list js-feed-list'>{activityItems}</div>;
    }

    private renderActivityItem = (item: ActivityDashboardListView) => {
        const props = this.convertItemToComponentData(item);

        return <ActivitiesListItem key={item.id} {...props}></ActivitiesListItem>;
    };

    private convertItemToComponentData = (item: ActivityDashboardListView): ActivitiesListItemProps => {
        const { t } = this.props;

        switch (item.activityType) {
            case ActivityType.JobApplicationSent: {
                const data = item.activityData as JobApplicationSentActivityData;

                return {
                    avatar: endpoints.getRecruiteePhotoFileEndpoint(item.recruiteeId),

                    content: (
                        <>
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.appliedForPosition')}
                            <br />
                            <strong>{data.positionName}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp),
                    link: RoutePath.recruiteesRecruiteeDetails(data.jobApplicationId)
                };
            }
            case ActivityType.ScoreChanged: {
                const data = item.activityData as ScoreChangedActivityData;
                const score = '';

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            <Trans
                                i18nKey='dashboard.activities.scored'
                                tOptions={{
                                    score: data.newScore
                                }}>
                                changed score to <strong>{{ score }}</strong> for
                            </Trans>
                            <br />
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp)
                };
            }
            case ActivityType.PhoneCallPerformed: {
                const data = item.activityData as PhoneCallPerformedActivityData;

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.phoneCall')}
                            <br />
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.activityTime)
                };
            }
            case ActivityType.MessageSent: {
                const data = item.activityData as MessageSentActivityData;

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.messageSent')}
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp)
                };
            }
            case ActivityType.NoteAdded: {
                const data = item.activityData as NoteAddedActivityData;

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.noteAdded')}
                            <br />
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp)
                };
            }
            case ActivityType.StageChanged: {
                const data = item.activityData as StageChangedActivityData;

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.stageChanged')}
                            <br />
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp)
                };
            }
            case ActivityType.MeetingScheduled: {
                const data = item.activityData as MeetingScheduledActivityData;

                return {
                    avatar: endpoints.getAtsUserAvatarEndpoint(data.atsUserId),
                    content: (
                        <>
                            <strong>{`${data.atsUserFirstName} ${data.atsUserLastName}`}</strong>
                            &nbsp;
                            {t('dashboard.activities.meetingScheduled')}
                            <br />
                            <strong>{`${item.recruiteeFirstName} ${item.recruiteeLastName}`}</strong>
                        </>
                    ),
                    when: dateTimeAgo(item.creationTimestamp)
                };
            }
        }
    };
}

export default withTranslation()(ActivitiesList);
