import { Dictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import {
    EditRecruitmentPositionCommand,
    GetRecruitmentPositionsListQueryResult,
    RecruitmentPositionForEditViewDto,
} from './recruitmentPositions.dto';

export enum RecruitmentPositionsActionTypes {
    CLEAR_RECRUITMENT_POSITIONS_EDIT = 'CLEAR_RECRUITMENT_POSITIONS_EDIT',
    GET_RECRUITMENT_POSITIONS_EDIT_STARTED = 'GET_RECRUITMENT_POSITIONS_EDIT_STARTED',
    GET_RECRUITMENT_POSITIONS_EDIT_FINISHED = 'GET_RECRUITMENT_POSITIONS_EDIT_FINISHED',
    GET_RECRUITMENT_POSITIONS_EDIT_FAILED = 'GET_RECRUITMENT_POSITIONS_EDIT_FAILED',

    GET_RECRUITMENT_POSITIONS_STARTED = 'GET_RECRUITMENT_POSITIONS_STARTED',
    GET_RECRUITMENT_POSITIONS_FINISHED = 'GET_RECRUITMENT_POSITIONS_FINISHED',
    GET_RECRUITMENT_POSITIONS_FAILED = 'GET_RECRUITMENT_POSITIONS_FAILED',

    UPDATE_RECRUITMENT_POSITION_STARTED = 'UPDATE_RECRUITMENT_POSITION_STARTED',
    UPDATE_RECRUITMENT_POSITION_FINISHED = 'UPDATE_RECRUITMENT_POSITION_FINISHED',
    UPDATE_RECRUITMENT_POSITION_FAILED = 'UPDATE_RECRUITMENT_POSITION_FAILED',

    DELETE_RECRUITMENT_POSITION_STARTED = 'DELETE_RECRUITMENT_POSITION_STARTED',
    DELETE_RECRUITMENT_POSITION_FINISHED = 'DELETE_RECRUITMENT_POSITION_FINISHED',
    DELETE_RECRUITMENT_POSITION_FAILED = 'DELETE_RECRUITMENT_POSITION_FAILED'
}

export const RecruitmentPositionsActions = {
    clearRecruitmentPositionsEdit: () => createAction(RecruitmentPositionsActionTypes.CLEAR_RECRUITMENT_POSITIONS_EDIT),
    getRecruitmentPositionsEditStarted: () =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_STARTED),
    getRecruitmentPositionsEditFinished: (response: GetRecruitmentPositionsEditFinishedParams) =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_FINISHED, { response }),
    getRecruitmentPositionsEditFailed: (error: ErrorState) =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_EDIT_FAILED, { error }),

    getRecruitmentPositionsStarted: () =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_STARTED),
    getRecruitmentPositionsFinished: (response: GetRecruitmentPositionsFinishedParams) =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_FINISHED, { response }),
    getRecruitmentPositionsFailed: (error: ErrorState) =>
        createAction(RecruitmentPositionsActionTypes.GET_RECRUITMENT_POSITIONS_FAILED, { error }),

    updateRecruitmentPositionEditStarted: () =>
        createAction(RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_STARTED),
    updateRecruitmentPositionEditFinished: (command: EditRecruitmentPositionCommand) =>
        createAction(RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_FINISHED, { command }),
    updateRecruitmentPositionEditFailed: (error: ErrorState) =>
        createAction(RecruitmentPositionsActionTypes.UPDATE_RECRUITMENT_POSITION_FAILED, { error }),

    deleteRecruitmentPositionEditStarted: () =>
        createAction(RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_STARTED),
    deleteRecruitmentPositionEditFinished: (id: string) =>
        createAction(RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_FINISHED, { id }),
    deleteRecruitmentPositionEditFailed: (error: ErrorState) =>
        createAction(RecruitmentPositionsActionTypes.DELETE_RECRUITMENT_POSITION_FAILED, { error })
};

interface GetRecruitmentPositionsEditFinishedParams {
    ids: string[];
    recruitmentPositions: Dictionary<RecruitmentPositionForEditViewDto>;
    pageNumber: number;
    totalCount: number;
}

interface GetRecruitmentPositionsFinishedParams {
    ids: string[];
    recruitmentPositions: Dictionary<GetRecruitmentPositionsListQueryResult>;
}

export type RecruitmentPositionsAction = ActionsUnion<typeof RecruitmentPositionsActions>;
