import { Checkbox, ErrorContext, Hr, LabelFieldHorizontal, TextInput } from '@emplo/react-inspinia';
import { Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { boolean as yupBoolean, object as yupObject, ObjectSchema, Schema, string as yupString } from 'yup';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { QuestionnaireBuilderActions } from '../../../../api/questionnaires/builder/builder.actions';
import { QuestionnaireBuilderElement } from '../../../../api/questionnaires/builder/builder.state';
import {
    AnswerableQuestionnaireElement,
    AnswerableQuestionnaireElementWithQuestion,
    BaseQuestionnaireElement,
    QuestionnaireElement,
    QuestionnaireElementType,
} from '../../../../api/questionnaires/questionnaires.dto';
import { ClearIndicator, DropdownIndicator, GroupHeading, reactSelectStyles } from '../../../common/ReactSelect';
import { AddQuestionnaireElementOption, MENU_ITEMS_OTHER, MENU_ITEMS_QUESTIONS } from '../_util';
import styles from './QuestionForm.module.scss';

type QuestionFormProps<ElementType = BaseQuestionnaireElement> = WithTranslation & {
    onSubmit: (formValues: ElementType) => void;
    itemData: ElementType;
    elementType: QuestionnaireElementType;
    index: number;
    onCancel: () => void;
    /**
     * Yup validation schema.
     */
    validationSchema?: any;
    isQuestionEditable?: boolean;
    isRequiredEditable?: boolean;
    render?: (formikProps: FormikProps<ElementType>) => React.ReactNode;
    handleTypeChange: (newType: QuestionnaireElementType) => void;
};

interface DispatchProps {
    addItem: (element: QuestionnaireBuilderElement, index?: number) => void;
    removeItem: (element: QuestionnaireBuilderElement) => void;
}

class QuestionForm<T extends QuestionnaireElement> extends Component<QuestionFormProps<T> & DispatchProps> {
    private validationSchema: ObjectSchema;

    static defaultProps = {
        isQuestionEditable: true,
        isRequiredEditable: true
    };

    constructor(props: QuestionFormProps<T> & DispatchProps) {
        super(props);

        // setup validation schema config object based on passed props
        let validationConfig: Record<string, Schema<any>> = {};

        if (props.isQuestionEditable) {
            validationConfig.question = yupString().required();
        }

        if (props.isRequiredEditable) {
            validationConfig.isRequired = yupBoolean().required();
        }

        this.validationSchema = yupObject()
            .shape(validationConfig)
            .concat(props.validationSchema);
    }

    render() {
        return (
            <Formik
                initialValues={this.props.itemData}
                validationSchema={this.validationSchema}
                render={this.renderForm}
                onSubmit={this.onSubmit}
            />
        );
    }

    private renderForm = (formikProps: FormikProps<T>) => {
        const { t, isQuestionEditable, isRequiredEditable } = this.props;

        return (
            <ErrorContext.Provider value={{ errors: formikProps.errors, touched: formikProps.touched }}>
                <form onSubmit={formikProps.handleSubmit}>
                    {/* question label */}

                    <div className='row'>
                        {isQuestionEditable && (
                            <div className='col'>
                                <LabelFieldHorizontal
                                    for='question'
                                    label={t('questionnaires.questionnaireForm.questionEditor.questionLabel')}>
                                    <TextInput
                                        name='question'
                                        value={
                                            (formikProps.values as AnswerableQuestionnaireElementWithQuestion).question
                                        }
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                        )}
                        <div className='col'>
                            <LabelFieldHorizontal for='$type' label={t('questionnaires.questionnaireForm.fieldType')}>
                                <ReactSelect
                                    className={styles.typeSelect}
                                    classNamePrefix='type-select'
                                    name='$type'
                                    options={this.getFieldTypeOptions()}
                                    value={this.getValueOption(this.props.elementType)}
                                    onChange={this.onTypeChange}
                                    formatOptionLabel={this.formatOptionLabel}
                                    placeholder=''
                                    isSearchable={false}
                                    components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: null,
                                        ClearIndicator,
                                        GroupHeading
                                    }}
                                    styles={reactSelectStyles}
                                    menuPlacement='auto'
                                />
                            </LabelFieldHorizontal>
                        </div>
                    </div>

                    {/* render additional questionType-specific fields */}
                    {this.props.render && this.props.render(formikProps)}

                    {/* form's footer - isRequired checkbox, Cancel and Save buttons */}
                    <Hr type='solid' />
                    <div className='row justify-content-between align-items-center form-group'>
                        <div className='col-auto'>
                            {isRequiredEditable && (
                                <Checkbox
                                    label={t('general.required')}
                                    name='isRequired'
                                    defaultChecked={(formikProps.values as AnswerableQuestionnaireElement).isRequired}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                />
                            )}
                        </div>
                        <div className='col-auto'>
                            <button onClick={this.props.onCancel} className='btn btn-default btn-w-m font-bold m-r-sm'>
                                {t('general.button.cancel')}
                            </button>
                            <button className='btn btn-primary btn-w-m font-bold'>{t('general.button.save')}</button>
                        </div>
                    </div>
                </form>
            </ErrorContext.Provider>
        );
    };

    private getValueOption = (value: QuestionnaireElementType): AddQuestionnaireElementOption | undefined =>
        [...MENU_ITEMS_QUESTIONS, ...MENU_ITEMS_OTHER].find(option => option.type === value);

    private formatOptionLabel = (option: AddQuestionnaireElementOption) => {
        const { t } = this.props;

        return (
            <div className={styles.option}>
                <i className={`fa faw fa-${option.icon}`} />
                <span>{t(option.label)}</span>
            </div>
        );
    };

    private getFieldTypeOptions = () => {
        const { t } = this.props;

        return [
            {
                label: t('questionnaires.questionnaireForm.addMenu.headerFieldType'),
                options: MENU_ITEMS_QUESTIONS
            },
            {
                label: t('questionnaires.questionnaireForm.addMenu.headerOtherElement'),
                options: MENU_ITEMS_OTHER
            }
        ];
    };

    private onTypeChange = (option: any) => {
        this.props.handleTypeChange(option.type);
    };

    private onSubmit = (formData: T) => {
        const newItemData: T = {
            ...this.props.itemData,
            ...formData
        };

        this.props.onSubmit(newItemData);
    };
}

// expose component's props as without the HOC's props
type QuestionFormPropsExternal<T> = Omit<QuestionFormProps<T>, keyof WithTranslation>;

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    addItem: (element: QuestionnaireBuilderElement, index?: number) =>
        dispatch(QuestionnaireBuilderActions.addElement(element, index)),
    removeItem: (element: QuestionnaireBuilderElement) => dispatch(QuestionnaireBuilderActions.removeElement(element))
});

export default withTranslation()(connect(null, mapDispatchToProps)(QuestionForm)) as <T>(
    props: QuestionFormPropsExternal<T>
) => any;
