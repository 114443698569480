import { Preloader, TextInput } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruitersBrowserActions } from '../../../../api/recruitersBrowser/recruitersBrowser.actions';
import {
    GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult,
} from '../../../../api/recruitersBrowser/recruitersBrowser.dto';
import {
    RecruitersBrowserFilters,
    RecruitersBrowserItem as RecruitersBrowserStateItem,
} from '../../../../api/recruitersBrowser/recruitersBrowser.state';
import { RecruitersBrowserThunks } from '../../../../api/recruitersBrowser/recruitersBrowser.thunk';
import { ATSUserForRecruitmentEditView } from '../../../../api/recruitments/recruitments.dto';
import { State } from '../../../../store/state';
import { listMapper } from '../../../../utils/listMapper';
import ErrorPage from '../../../pages/ErrorPage';
import styles from './RecruitersBrowser.module.scss';
import RecruitersBrowserItem from './RecruitersBrowserItem';

interface Props {
    initialSelected?: RecruitersBrowserStateItem[];
    recruitmentOwner?: ATSUserForRecruitmentEditView;
}

interface StateProps {
    filters: RecruitersBrowserFilters;
    items: RecruitersBrowserStateItem[];
    isLoading: boolean;
    error: ErrorState;
}

interface DispatchProps {
    updateFilters: (searchQuery: string, skipUserIds: string[]) => void;
    selectRecruiter: (recruiter: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult) => void;
    deselectRecruiter: (id: string) => void;
    clearSelectedRecruiters: () => void;
    fetchItems: (page?: number, pageSize?: number) => Promise<void>;
    preloadRecruiters: (recruiters: RecruitersBrowserStateItem[]) => void;
}

class RecruitersBrowser extends Component<Props & StateProps & DispatchProps & WithTranslation> {
    async componentDidMount() {
        // clear filters
        this.props.updateFilters('', []);

        // set selected items
        this.props.clearSelectedRecruiters();
        await this.props.fetchItems();
        this.props.preloadRecruiters(this.props.initialSelected || []);
    }

    render() {
        const { isLoading, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <div className={styles.container}>
                <div className={styles.filtersContainer}>{this.renderFilters()}</div>

                {isLoading && <Preloader overlay />}

                <div className={styles.hiringManagerContainer}>{this.renderRecruitmentOwner()}</div>

                <div className={styles.listContainer}>{this.props.items.map(this.renderItem)}</div>
            </div>
        );
    }

    private renderFilters = () => {
        const { t } = this.props;

        return (
            <div className='input-group'>
                <TextInput
                    value={this.props.filters.searchQuery}
                    onChange={this.onFilterNameChange}
                    onKeyDown={this.onSearchQueryKeyDown}
                    placeholder={t('general.searchNames')}
                />
                <span className='input-group-append'>
                    <button className='btn btn-primary btn-outline font-bold' onClick={this.submitSearch}>
                        <i className='fa fa-search' />
                        <span className={styles.searchLabel}>{t('general.search')}</span>
                    </button>
                </span>
            </div>
        );
    };

    private renderItem = (item: RecruitersBrowserStateItem) => (
        <RecruitersBrowserItem
            {...item}
            id={item.recruiterATSUserId}
            key={item.recruiterATSUserId}
            isChecked={item.isSelected}
            onChange={item.isSelected ? this.onItemDeselected : this.onItemSelected}
        />
    );

    private renderRecruitmentOwner = () => {
        if (!this.props.recruitmentOwner) {
            return null;
        }

        return <RecruitersBrowserItem {...this.props.recruitmentOwner} isChecked />;
    };

    private onFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.props.updateFilters(e.currentTarget.value, this.props.filters.skipUserIds);

    private onSearchQueryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.submitSearch();
        }
    };

    private onItemSelected = (id: string) => {
        const addedItem = this.props.items.find(item => item.recruiterATSUserId === id);

        if (addedItem) {
            this.props.selectRecruiter(addedItem);
        }
    };

    private onItemDeselected = (id: string) => {
        this.props.deselectRecruiter(id);
    };

    private submitSearch = () => {
        this.props.fetchItems();
    };
}

const mapStateToProps = (state: State): StateProps => ({
    filters: state.recruitersBrowser.recruitmentTeam.filters,
    items: listMapper(state.recruitersBrowser.recruitmentTeam.list, state.recruitersBrowser.recruitmentTeam.items),
    isLoading: state.recruitersBrowser.recruitmentTeam.isLoading,
    error: state.recruitersBrowser.recruitmentTeam.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    updateFilters: (searchQuery: string, skipUserIds: string[]) =>
        dispatch(RecruitersBrowserActions.setRecruitersBrowserFilters({ searchQuery, skipUserIds })),
    fetchItems: (page: number = 1, pageSize: number = 1000) =>
        dispatch(RecruitersBrowserThunks.getUsersListForRecruitmentTeamMembersChoice(page, pageSize)),
    selectRecruiter: (recruiter: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult) =>
        dispatch(RecruitersBrowserActions.selectRecruitersBrowserRecruiter(recruiter)),
    deselectRecruiter: (id: string) => dispatch(RecruitersBrowserActions.deselectRecruitersBrowserRecruiter(id)),
    clearSelectedRecruiters: () => dispatch(RecruitersBrowserActions.clearSelectedRecruiters()),
    preloadRecruiters: (recruiters: RecruitersBrowserStateItem[]) =>
        dispatch(RecruitersBrowserActions.preloadRecruitersBrowserRecruiters(recruiters))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitersBrowser));
