import React, { useState } from 'react';
import RecruiteeScoreStar from './RecruiteeScoreStar';
import styles from './RecruiteeScoreStars.module.scss';
import classNames from 'classnames';

interface Props {
    starsCount: number;
    initialScore: number;
    onScoreChange?: (newScore: number) => void;
    className?: string;
    disableHover?: boolean;
}

export default (props: Props) => {
    const [hoverIdx, setHoverIdx] = useState<number | undefined>();
    const isHoveringStars = hoverIdx !== undefined;
    const stars = [];

    const onStarMouseOver = (idx: number) => !props.disableHover && setHoverIdx(idx);

    const onStarMouseOut = () => setHoverIdx(undefined);

    for (let i = 0; i < props.starsCount; ++i) {
        let isFilled = i < props.initialScore;

        if (isHoveringStars) {
            isFilled = i <= hoverIdx!;
        }

        stars.push(
            <RecruiteeScoreStar
                isFilled={isFilled}
                key={i}
                index={i}
                onMouseOver={onStarMouseOver}
                onMouseOut={onStarMouseOut}
                onClick={() => props.onScoreChange && props.onScoreChange(i + 1)}
            />
        );
    }

    return <div className={classNames(styles.container, props.className)}>{stars}</div>;
};
