import React from 'react';

import { ApiError } from '../../api/_commons/http';
import Forbidden from './Forbidden';
import InternalError from './InternalError';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';

interface Props {
    error: ApiError;
}

export default (props: Props) => {
    switch (props.error.httpStatus) {
        case 401:
            return <Unauthorized />;
        case 403:
            return <Forbidden />;
        case 404:
            return <NotFound />;
        default:
            return <InternalError />;
    }
};
