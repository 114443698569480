import { createNewQuestionnaireElement } from '../../../components/questionnaires/builder/_util';
import { FormValues } from '../../../components/questionnaires/QuestionnaireForm';
import {
    AddQuestionnaireCommand,
    AnswerableQuestionnaireElement,
    AnswerableQuestionnaireElementWithQuestion,
    QuestionnaireElement,
    QuestionnaireHeader,
    QuestionnaireMultipleChoice,
    QuestionnaireTextParagraph,
    QuestionQuestionnaireElementType,
    StructureQuestionnaireElementType,
    UpdateQuestionnaireCommand,
} from '../questionnaires.dto';
import { QuestionnaireBuilderElement } from './builder.state';

export function questionnaireFormValuesToAddQuestionnairePostParams(values: FormValues): AddQuestionnaireCommand {
    const elements: QuestionnaireElement[] = [];
    let pageNumber = 1,
        sectionNumber = 1,
        order = 1;

    values.elements.forEach(el => {
        if (el.$type === StructureQuestionnaireElementType.PageBreak) {
            ++pageNumber;
            sectionNumber = 1;
        } else if (el.$type === StructureQuestionnaireElementType.SectionBreak) {
            ++sectionNumber;
        } else {
            let newElement = {
                id: el.id,
                $type: el.$type,
                order: order,
                pageNumber: pageNumber,
                sectionNumber: sectionNumber
            } as QuestionnaireElement;

            switch (newElement.$type) {
                case QuestionQuestionnaireElementType.QuestionnaireHeader:
                    newElement.text = (el as QuestionnaireHeader).text || '';
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireSingleChoice: // multiple and single choice have exact same structure
                case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
                    const elCast = el as QuestionnaireMultipleChoice;

                    newElement.options = elCast.options || {};
                    newElement.isRequired = elCast.isRequired || false;
                    newElement.question = elCast.question || '';
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireTextParagraph:
                    (newElement as QuestionnaireTextParagraph).text = (el as QuestionnaireTextParagraph).text;
                    break;
                default:
                    (newElement as AnswerableQuestionnaireElement).isRequired =
                        (el as AnswerableQuestionnaireElement).isRequired || false;
                    (newElement as AnswerableQuestionnaireElementWithQuestion).question =
                        (el as AnswerableQuestionnaireElementWithQuestion).question || '';
                    break;
            }

            elements.push(newElement);

            ++order;
        }
    });

    const questionnaireData: AddQuestionnaireCommand = {
        questionnaireName: values.questionnaireName,
        isDraft: values.isDraft,
        questionnaireStructure: {
            elements: elements
        }
    };

    return questionnaireData;
}

export function questionnaireFormValuesToUpdateQuestionnairePostParams(
    id: string,
    values: FormValues
): UpdateQuestionnaireCommand {
    // currently the data structure is the same as when adding new questionnaire,
    // so we can just wrap `questionnaireFormValuesToAddQuestionnairePostParams` function
    return {
        ...(questionnaireFormValuesToAddQuestionnairePostParams(values) as UpdateQuestionnaireCommand),
        id
    };
}

export const convertQuestionnaireElementToBuilderElement = (el: QuestionnaireElement): QuestionnaireBuilderElement => ({
    ...el,
    isEditing: false
});

export function convertApiQuestionnaireStructureToBuilderStructure(
    elements: QuestionnaireElement[]
): QuestionnaireBuilderElement[] {
    const results: QuestionnaireBuilderElement[] = [];

    let prevPageNumber = 1;
    let prevSectionNumber = 1;

    elements.forEach(element => {
        // add page break
        if (element.pageNumber > prevPageNumber) {
            results.push({
                ...createNewQuestionnaireElement((t: string) => t)({
                    $type: StructureQuestionnaireElementType.PageBreak
                }),
                isEditing: false
            });

            prevPageNumber = element.pageNumber;
            prevSectionNumber = 1;
        }

        // add section break
        if (element.sectionNumber > prevSectionNumber) {
            results.push({
                ...createNewQuestionnaireElement((t: string) => t)({
                    $type: StructureQuestionnaireElementType.SectionBreak
                }),
                isEditing: false
            });

            prevPageNumber = element.pageNumber;
            prevSectionNumber = element.sectionNumber;
        }

        results.push({
            ...element,
            isEditing: false
        });
    });

    return results;
}
