const queryParameterFinder = /([^&=]+)=?([^&]*)/g;

function decode(str: string) {
    return decodeURIComponent(str.replace(/\+/g, ' '));
}

export class UrlParser {
    static parse(query: string, symbol: string = '?'): any {
        /// <summary>Add an URL parser to JQuery that returns an object
        /// This function is meant to be used with an URL like the window.location
        /// Use: $.parseParams('http://mysite.com/?let=string') or $.parseParams() to parse the window.location
        /// Simple letiable:  ?let=abc                        returns {let: "abc"}
        /// Simple object:    ?let.length=2&let.scope=123     returns {let: {length: "2", scope: "123"}}
        /// Simple array:     ?let[]=0&let[]=9                returns {let: ["0", "9"]}
        /// Array with index: ?let[0]=0&let[1]=9              returns {let: ["0", "9"]}
        /// Nested objects:   ?my.let.is.here=5               returns {my: {let: {is: {here: "5"}}}}
        /// All together:     ?let=a&my.let[]=b&my.cookie=no  returns {let: "a", my: {let: ["b"], cookie: "no"}}
        /// You just cant have an object in an array, ?let[1].test=abc DOES NOT WORK</summary>
        /// <param name="query" type="String">The query string to parse.</param>
        /// <param name="symbol" type="String">(Optional) The character that will be treated as a begining of letiables chain.</param>
        if (symbol === undefined) {
            symbol = '?';
        }

        // recursive function to construct the result object
        function createElement(params: any, key: string, value: string) {
            key = key + '';
            // if the key is a property
            if (key.indexOf('.') !== -1 && key.indexOf('[') === -1) {
                // extract the first part with the name of the object
                let list = key.split('.');
                // the rest of the key
                let newKey = key.split(/\.(.+)?/)[1];
                // create the object if it doesnt exist
                if (list[0] && !params[list[0]]) {
                    params[list[0]] = {};
                }
                // if the key is not empty, create it in the object
                if (newKey !== '') {
                    if (list[0]) {
                        createElement(params[list[0]], newKey, value);
                    } else {
                        createElement(params, newKey, value);
                    }
                } else {
                    console.warn('parseParams :: empty property in key "' + key + '"');
                }
            }
            // if the key is an array
            else if (key.indexOf('[') !== -1) {
                // extract the array name
                let list = key.split('['),
                    originalKey = key;

                key = list[0];

                // extract the index of the array
                list = list[1].split(']');

                let index = list[0];

                // if index is empty, just push the value at the end of the array
                if (index === '') {
                    if (!params) {
                        params = {};
                    }
                    if (!params[key] || !Array.isArray(params[key])) {
                        params[key] = [];
                    }

                    params[key].push(value);
                } else {
                    // add the value at the index (must be an integer)
                    if (!params) {
                        params = {};
                    }
                    if (!params[key] || !Array.isArray(params[key])) {
                        params[key] = [];
                    }

                    let newKey = originalKey.split(/\.(.+)?/)[1];
                    newKey = newKey ? '.' + newKey : '';

                    if (newKey.indexOf('.') !== -1) {
                        if (!params[key][parseInt(index, 10)]) {
                            params[key][parseInt(index, 10)] = {};
                        }
                        createElement(params[key][parseInt(index, 10)], newKey, value);
                    } else {
                        params[key][parseInt(index, 10)] = value;
                    }
                }
            } else {
                // just normal key
                if (!params) {
                    params = {};
                }
                params[key] = value;
            }
        }
        // be sure the query is a string
        query = query + '';
        if (query === '') {
            query = window.location + '';
        }
        let resultParams = {};
        if (query) {
            // remove # from end of query
            if (symbol !== '#') {
                if (query.indexOf('#') !== -1) {
                    query = query.substr(0, query.indexOf('#'));
                }
            }

            // remove ? at the begining of the query
            if (query.indexOf(symbol) !== -1) {
                query = query.substr(query.indexOf(symbol) + 1, query.length);
            } else {
                return {};
            }
            // empty parameters
            if (query === '') {
                return {};
            }

            // execute a createElement on every key and value
            let parameter = queryParameterFinder.exec(query);
            while (parameter) {
                let key = decode(parameter[1]);
                let value = decode(parameter[2]);
                createElement(resultParams, key, value);
                parameter = queryParameterFinder.exec(query);
            }
        }
        return resultParams;
    }

    static encode(
        href: string,
        object: any,
        doesTreatArrayAsList: boolean = false,
        doesEncodeComponents: boolean = true
    ): string {
        /// <summary>Encode an object to an url string
        /// This function creates full URL with uri encoded GET values
        /// Use: $.encodeUrl('example.com', {let: "test", len: 1}) returns "example.com?let=test&len=1"</summary>
        /// <param name="href" type="string">Base URL string (optional)</param>
        /// <param name="object" type="Object">The object that will be encoded as an URL string</param>

        // properly encodes GET value
        function tryEncodeValue(value: string) {
            return doesEncodeComponents ? encodeURIComponent(value) : value;
        }

        // trim empty quote
        function trimEmptyParams(url: string) {
            if (url.indexOf('?&') !== -1) {
                url = url.replace('?&', '?');
            }
            if (url.indexOf('&&') !== -1) {
                url = url.replace(/&&/g, '&');
            }
            if (url.indexOf('?') === url.length - 1) {
                return url.substr(0, url.length - 1);
            }
            return url;
        }

        // recursive function to construct the result string
        function createString(element: any, nest: any) {
            if (element === null || element === undefined) {
                return '';
            }
            if (Array.isArray(element)) {
                if (!doesTreatArrayAsList) {
                    let countArr = 0,
                        urlArr = '';

                    for (let t = 0; t < element.length; t++) {
                        if (countArr > 0) {
                            urlArr += '&';
                        }

                        urlArr += createString(element[t], nest + '[' + countArr + ']');

                        countArr++;
                    }
                    return urlArr;
                } else {
                    let countArr = 0,
                        urlArr = '';

                    for (let t = 0; t < element.length; t++) {
                        if (countArr > 0) {
                            urlArr += '&';
                        }
                        urlArr += nest + '=' + tryEncodeValue(element[t]);
                        countArr++;
                    }

                    return urlArr;
                }
            } else if (typeof element === 'object') {
                let countObj = 0,
                    urlObj = '';

                for (let propObj in element) {
                    if (element.hasOwnProperty(propObj)) {
                        if (countObj > 0) {
                            urlObj += '&';
                        }
                        urlObj += createString(element[propObj], nest + '.' + propObj);
                        countObj++;
                    }
                }
                return urlObj;
            } else {
                return nest + '=' + tryEncodeValue(element);
            }
        }

        let resultUrl = href || '',
            count = 0;

        if (resultUrl.length > 0) {
            if (resultUrl.indexOf('?') < 0) {
                resultUrl += '?';
            } else {
                resultUrl += '&';
            }
        } else {
            resultUrl = '?';
        }

        // execute a createString on every property of object
        for (let prop in object) {
            if (object.hasOwnProperty(prop)) {
                if (count > 0 && object[prop] !== undefined) {
                    resultUrl += '&';
                }
                resultUrl += createString(object[prop], prop);
                count++;
            }
        }

        return trimEmptyParams(resultUrl);
    }

    static encodeOnlyQueryParams(object: any, doesTreatArrayAsList: boolean = false): string {
        return this.encode('', object, doesTreatArrayAsList).substr(1);
    }
}
