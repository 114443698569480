import { Dropdown, DropdownItem, InModal, InModalBody, InModalFooter, InModalHeader } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { Trans, useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import toastr from 'toastr';

import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruitmentStatus } from '../../../../api/recruitments/recruitments.dto';
import { RecruitmentViewDtoNormalized } from '../../../../api/recruitments/recruitments.schema';
import { RecruitmentsThunks } from '../../../../api/recruitments/recruitments.thunk';
import { RoutePath } from '../../../../routes';
import { State } from '../../../../store/state';
import { TOOLTIP } from '../../../../tooltips';
import styles from '../RecruitmentView.module.scss';

interface StateProps {
    recruitment: RecruitmentViewDtoNormalized;
}

interface DispatchProps {
    handleDeleteRecruitment: (id: string) => Promise<ThunkActionResult<void>>;
}

interface ContainerState {
    isDeleteModalOpen: boolean;
    isRedirectingToEdit: boolean;
    isRedirectingToList: boolean;
}

class MoreActionsButtonContainer extends Component<StateProps & DispatchProps & WithTranslation, ContainerState> {
    state: ContainerState = {
        isDeleteModalOpen: false,
        isRedirectingToEdit: false,
        isRedirectingToList: false,
    };

    render() {
        if (this.state.isRedirectingToList) {
            return <Redirect to={RoutePath.recruitmentsList()} push />;
        }

        if (this.state.isRedirectingToEdit) {
            return <Redirect to={RoutePath.recruitmentsEditRecruitment(this.props.recruitment.id)} push />;
        }

        return (
            <MoreActionsButton
                recruitment={this.props.recruitment}
                isDeleteModalOpen={this.state.isDeleteModalOpen}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
                handleCloseDeleteModal={this.handleCloseDeleteModal}
                handleDeleteConfirm={this.handleDeleteRecruitment}
            />
        );
    }

    private handleEditClick = () => this.setState({ isRedirectingToEdit: true });

    private handleDeleteClick = () => this.setState({ isDeleteModalOpen: true });

    private handleCloseDeleteModal = () => this.setState({ isDeleteModalOpen: false });

    private handleDeleteRecruitment = async () => {
        const { t } = this.props;

        this.setState({
            isDeleteModalOpen: false,
        });

        const results = await this.props.handleDeleteRecruitment(this.props.recruitment.id);
        if (results.httpStatus < 300) {
            toastr.success(t('recruitments.deleteRecruitmentSuccess'), t('general.success'));
            this.setState({
                isRedirectingToList: true,
            });
        } else {
            toastr.error(t('recruitments.deleteRecruitmentError'), t('general.error'));
        }
    };
}

interface Props {
    recruitment: RecruitmentViewDtoNormalized;
    isDeleteModalOpen: boolean;
    handleEditClick: () => void;
    handleDeleteClick: () => void;
    handleCloseDeleteModal: () => void;
    handleDeleteConfirm: () => void;
}

const MoreActionsButton = (props: Props) => {
    const { t } = useTranslation();
    const isEditable = [RecruitmentStatus.DRAFT, RecruitmentStatus.PENDING, RecruitmentStatus.OPEN].includes(
        props.recruitment.status
    );
    const isDeletable = [RecruitmentStatus.DRAFT, RecruitmentStatus.PENDING].includes(props.recruitment.status);
    const title = props.recruitment.title;

    let buttonComponent: ReactNode = null;

    if (isDeletable) {
        // render both edit and delete in dropdown
        buttonComponent = (
            <Dropdown
                trigger={
                    <button
                        type='button'
                        className={classNames(
                            'btn btn-lg btn-default p-0 ml-2 d-flex justify-content-center align-items-center h-100',
                            styles.toolBtn
                        )}
                        data-tip={t('recruitmentView.moreActions')}
                        data-for={TOOLTIP.TOP}>
                        <i className='fa faw fa-ellipsis-v' />
                    </button>
                }
                align='se'>
                <DropdownItem
                    label={t('general.button.edit')}
                    icon={<i className='fa faw fa-pencil' />}
                    onClick={props.handleEditClick}
                />
                <DropdownItem
                    label={t('general.button.delete')}
                    icon={<i className='fa faw fa-times' />}
                    onClick={props.handleDeleteClick}
                />
            </Dropdown>
        );
    } else if (isEditable) {
        // render only Edit button
        buttonComponent = (
            <Link
                to={RoutePath.recruitmentsEditRecruitment(props.recruitment.id)}
                className={classNames(
                    'btn btn-lg btn-default p-0 ml-2 d-flex justify-content-center align-items-center',
                    styles.toolBtn
                )}
                data-tip={t('general.button.edit')}
                data-for={TOOLTIP.TOP}>
                <i className='fa faw fa-pencil' />
            </Link>
        );
    }

    return (
        <>
            {buttonComponent}

            <InModal
                isOpen={props.isDeleteModalOpen}
                onClose={props.handleDeleteConfirm}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'>
                <InModalHeader titleText={t('recruitments.deleteRecruitmentTitle')} />
                <InModalBody>
                    <span>
                        <Trans i18nKey='recruitments.deleteRecruitmentMessage' values={{ recruitment: title }}>
                            Are you sure you want to delete recruitment <strong>{{ title }}</strong>?
                        </Trans>
                    </span>
                </InModalBody>
                <InModalFooter>
                    <button
                        type='button'
                        className='btn btn-default font-bold btn-w-m'
                        onClick={props.handleCloseDeleteModal}>
                        {t('general.button.cancel')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-danger font-bold btn-w-m'
                        onClick={props.handleDeleteConfirm}>
                        {t('general.button.delete')}
                    </button>
                </InModalFooter>
            </InModal>
        </>
    );
};

export default connect(
    (state: State): StateProps => ({
        recruitment: state.recruitments.recruitmentDetails.recruitment!,
    }),
    (dispatch: ThunkDispatch): DispatchProps => ({
        handleDeleteRecruitment: (id: string) => dispatch(RecruitmentsThunks.deleteRecruitment(id)),
    })
)(withTranslation()(MoreActionsButtonContainer));
