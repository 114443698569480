import { isComponentTypeDeletable } from '../../../components/questionnaires/builder/_util';
import { QuestionnaireBuilderAction, QuestionnaireBuilderActionTypes } from './builder.actions';
import { initialState, QuestionnaireBuilderState } from './builder.state';

export default (
    state: QuestionnaireBuilderState = initialState,
    action: QuestionnaireBuilderAction
): QuestionnaireBuilderState => {
    switch (action.type) {
        case QuestionnaireBuilderActionTypes.LOAD_QUESTIONNAIRE_STRUCTURE:
            return {
                ...state,
                elements: action.elements
            };

        case QuestionnaireBuilderActionTypes.ADD_ELEMENT: {
            const newElements = [...state.elements];
            const insertAtIndex = action.index === undefined ? newElements.length : action.index;
            newElements.splice(insertAtIndex, 0, action.element);

            return {
                ...state,
                elements: newElements
            };
        }
        case QuestionnaireBuilderActionTypes.REMOVE_ELEMENT: {
            if (!isComponentTypeDeletable(action.element.$type)) {
                return { ...state };
            }

            const elementIdx = state.elements.findIndex(el => el.id === action.element.id);
            const newElements = [...state.elements];

            if (elementIdx > -1) {
                newElements.splice(elementIdx, 1);
            }

            return {
                ...state,
                elements: newElements
            };
        }
        case QuestionnaireBuilderActionTypes.MOVE_ELEMENT: {
            const currentIndex = state.elements.findIndex(checkedItem => checkedItem.id === action.element.id);
            const newElements = [...state.elements];

            if (currentIndex > -1) {
                newElements.splice(currentIndex, 1);
                // if moving below, we need to decrement `newIndex` by 1, because the new item is placed after the
                // existing item with the same index value
                // without decrementing, the item would be placed 1 place lower than intended
                newElements.splice(
                    action.newIndex > currentIndex ? action.newIndex - 1 : action.newIndex,
                    0,
                    action.element
                );
            }

            return {
                ...state,
                elements: newElements
            };
        }
        case QuestionnaireBuilderActionTypes.UPDATE_ELEMENT: {
            // deep copy of the items
            const newElements = state.elements.map(currentItem => {
                // return new item's data if this is the one (checked by item.id)
                if (currentItem.id === action.formData.id) {
                    // when adding new item - isAdding = true, this only changes when saving the item's form
                    return { ...action.formData, isAdding: false };
                } else {
                    return { ...currentItem };
                }
            });

            return {
                ...state,
                elements: newElements
            };
        }
        case QuestionnaireBuilderActionTypes.EDIT_ELEMENT_START: {
            return {
                ...state,
                elements: state.elements.map(el => ({
                    ...el,
                    isEditing: el.id === action.elementId ? true : el.isEditing
                }))
            };
        }
        case QuestionnaireBuilderActionTypes.EDIT_ELEMENT_END: {
            return {
                ...state,
                elements: state.elements.map(el => ({
                    ...el,
                    isEditing: el.id === action.elementId ? false : el.isEditing
                }))
            };
        }
        default:
            return state;
    }
};
