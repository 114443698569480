import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { endpoints } from '../../../../api/endpoints';
import { RECRUITEE_MAX_SCORE } from '../../../../api/recruitees/recruitees.dto';
import { RecruiteeAllScoresSummaryItem } from '../../../../api/recruitees/recruitees.state';
import { date } from '../../../../utils/format';
import RecruiteeScoreStars from '../RecruiteeScoreStars';
import styles from './RecruiteeReviewsList.module.scss';

type Props = RecruiteeAllScoresSummaryItem;

export default (props: Props) => {
    const { t } = useTranslation();
    const photoUrl = endpoints.getAtsUserAvatarEndpoint(props.scoringATSUserId);

    return (
        <div className={styles.item}>
            <div className={classNames(styles.col, styles.colPhoto)}>
                <div className={styles.colPhotoContainer} style={{ backgroundImage: `url(${photoUrl})` }}>
                    <img src={photoUrl} alt={t('general.avatar')} />
                </div>
            </div>
            <div className={classNames(styles.col, styles.colName)}>
                <div className={styles.colNameUserName}>
                    {props.scoringATSUserFirstName} {props.scoringATSUserLastName}
                </div>
            </div>
            <div className={classNames(styles.col, styles.colScore)}>
                <div className={styles.colScoreWrapper}>
                    <RecruiteeScoreStars
                        initialScore={props.score}
                        starsCount={RECRUITEE_MAX_SCORE}
                        className={styles.scoreStars}
                        disableHover
                    />
                    <div className={styles.colScoreDate}>{date(props.scoringTime)}</div>
                </div>
            </div>
        </div>
    );
};
