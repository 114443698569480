import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
    AnswerableQuestionnaireElementWithQuestion,
    QuestionnaireAddressAnswer,
    QuestionnaireAnswersDto,
    QuestionnaireElement,
    QuestionnaireElementType,
    QuestionnaireStructureDto,
    QuestionQuestionnaireAnswerType,
    QuestionQuestionnaireElementType,
} from '../../../../api/questionnaires/questionnaires.dto';
import {
    ApplicationFormQuestionnaireElement,
    Header,
    renderQuestionnaireAnswerElement,
    Section,
    Step,
} from './ApplicationFormElement';

interface ApplicationFormPaneContainerProps {
    answers: QuestionnaireAnswersDto;
    filledQuestionnaireStructure: QuestionnaireStructureDto;
    renderStaticContent?: boolean;
    jobApplicationId: string;
}

class ApplicationFormPaneContainer extends Component<ApplicationFormPaneContainerProps> {
    render() {
        // create list of question/answers to display (ApplicationFormQuestionnaireElement)
        const answers: ApplicationFormQuestionnaireElement[] = this.props.filledQuestionnaireStructure.elements
            .filter(filterOutAddress)
            .map(element => ({
                element,
                answer: this.props.answers.additionalQuestionnaireAnswers.find(
                    answer => answer.questionId === element.id
                )
            }));

        let paneProps: ApplicationFormPaneProps = {
            ...this.props.answers,
            address: this.getAddressString(),
            answers,
            renderStaticContent: this.props.renderStaticContent,
            jobApplicationId: this.props.jobApplicationId
        };

        return <ApplicationFormPane {...paneProps} />;
    }

    private getAddressString(): string | undefined {
        const addressAnswer = this.props.answers.additionalQuestionnaireAnswers.find(
            answer => answer.$type === QuestionQuestionnaireAnswerType.QuestionnaireAddressAnswer
        );

        if (addressAnswer) {
            return (addressAnswer as QuestionnaireAddressAnswer).value;
        }
    }
}

export function filterOutAddress(element: QuestionnaireElement) {
    const filteredTypes: QuestionnaireElementType[] = [QuestionQuestionnaireElementType.QuestionnaireAddress];
    return !filteredTypes.includes(element.$type);
}

interface ApplicationFormPaneProps {
    firstName?: string;
    lastName?: string;
    prefix?: string;
    email?: string;
    phoneNumber?: string;
    address?: string;
    answers: ApplicationFormQuestionnaireElement[];
    renderStaticContent?: boolean;
    jobApplicationId: string;
}

class applicationFormPane extends Component<ApplicationFormPaneProps & WithTranslation> {
    private questionnaireAnswerElementRenderer = renderQuestionnaireAnswerElement(this.props.t);

    render() {
        const { t, firstName, lastName, prefix, phoneNumber, email, address } = this.props;

        return (
            <>
                {this.props.renderStaticContent && (
                    <>
                        <Step step={1} />

                        <Header text={t('recruiteesForm.personalInformation.label')} />

                        <div className='row mt-4'>
                            <div className='col-12 col-md-4'>
                                <div className='font-bold'>{t('recruiteesForm.personalInformation.fullName')}</div>
                                <div>
                                    {firstName} {lastName}
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className='font-bold'>{t('recruiteesForm.personalInformation.title')}</div>
                                <div>{prefix}</div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className='font-bold'>{t('recruiteesForm.personalInformation.phoneNumber')}</div>
                                <div>
                                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-12 col-md-4'>
                                <div className='font-bold'>{t('recruiteesForm.personalInformation.E-mail')}</div>
                                <div>
                                    <a href={`mailto:${email}`}>{email}</a>
                                </div>
                            </div>
                            {address !== undefined && (
                                <div className='col-12 col-md-4'>
                                    <div className='font-bold'>{t('recruiteesForm.personalInformation.Address')}</div>
                                    <div>{address || '-'}</div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {this.renderQuestionnaireAnswers(this.props.answers)}
            </>
        );
    }

    private renderQuestionnaireAnswers = (elements: ApplicationFormQuestionnaireElement[]) => {
        const { t } = this.props;

        // track elements' page and section
        // when page changes, render page break
        // when section changes, render section break
        // render element based on its $type
        let currentPage = 1;
        let currentSection = 1;

        const renderQuestionnaireAnswer = (element: ApplicationFormQuestionnaireElement) => {
            const results = [];
            let elementLabel: string | undefined;

            if (element.element.pageNumber > currentPage) {
                results.push(<Step step={element.element.pageNumber} key={`step-${element.element.id}`} />);

                currentPage = element.element.pageNumber;
                currentSection = 1;
            } else if (element.element.sectionNumber > currentSection) {
                results.push(<Section key={`section-${element.element.id}`} />);
                currentSection = element.element.sectionNumber;
            }

            switch (element.element.$type) {
                case QuestionQuestionnaireElementType.QuestionnaireAddress:
                    elementLabel = t('questionnaires.questionnaireForm.question.address.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireCoverLetter:
                    elementLabel = t('questionnaires.questionnaireForm.question.coverLetter.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireEducation:
                    elementLabel = t('questionnaires.questionnaireForm.question.education.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireExperience:
                    elementLabel = t('questionnaires.questionnaireForm.question.experience.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnairePhoto:
                    elementLabel = t('questionnaires.questionnaireForm.question.photo.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireResume:
                    elementLabel = t('questionnaires.questionnaireForm.question.resume.label');
                    break;
                case QuestionQuestionnaireElementType.QuestionnaireSummary:
                    elementLabel = t('questionnaires.questionnaireForm.question.summary.label');
                    break;
                default:
                    elementLabel = (element.element as AnswerableQuestionnaireElementWithQuestion).question;
                    break;
            }

            results.push(this.questionnaireAnswerElementRenderer(this.props.jobApplicationId, element, elementLabel));

            return results;
        };

        return elements.map(renderQuestionnaireAnswer);
    };
}

const ApplicationFormPane = withTranslation()(applicationFormPane);

export default ApplicationFormPaneContainer;
