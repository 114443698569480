import { PageLayout } from '@emplo/react-inspinia';
import React from 'react';
import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import Login from './Login';

export function loginRouteRenderer(props: RouteComponentProps<any>): ReactNode {
    return (
        <PageLayout {...props}>
            <Login />
        </PageLayout>
    );
}
