import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../api/_commons/thunks.common';
import { JobApplicationsByRefererDatabaseDto } from '../../api/dashboard/dashboard.dto';
import { DashboardState } from '../../api/dashboard/dashboard.state';
import { DashboardThunks } from '../../api/dashboard/dashboard.thunk';
import { RecruitmentRecruiteeStageListViewDto } from '../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.dto';
import { RecruitmentRecruiteeStagesThunks } from '../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { State } from '../../store/state';
import ErrorPage from '../pages/ErrorPage';
import ActivitiesList from './ActivitiesList';
import CandidatesBySourceChart from './CandidatesBySourceChart';
import CandidatesByStageChart from './CandidatesByStageChart';
import NewApplicationsChart from './NewApplicationsChart';
import RecruitmentsSummary from './RecruitmentsSummary';

type StateProps = DashboardState & {
    recruitmentStages: Dictionary<RecruitmentRecruiteeStageListViewDto>;
};

interface DispatchProps {
    fetchData: () => void;
    fetchAllStages: () => void;
}

class Dashboard extends Component<StateProps & DispatchProps & WithTranslation> {
    componentDidMount() {
        this.props.fetchData();
        this.props.fetchAllStages();
    }

    render() {
        const { t, isLoading, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent className='animated fadeIn' title={t('general.mainMenu.dashboard')}>
                {isLoading && <Preloader overlay />}

                <div className='row'>
                    <div className='col-8'>
                        {/* <div className='row m-b-md'>
                                    <div className='col-4'>
                                        <select className='form-control'>
                                            <option value=''>All recruitments</option>
                                        </select>
                                    </div>
                                    <div className='col-3'>
                                        <select className='form-control'>
                                            <option value=''>This week</option>
                                        </select>
                                    </div>
                                </div> */}

                        <div className='ibox'>
                            <div className='ibox-content'>
                                <RecruitmentsSummary {...this.props.dashboardData.recruitmentAndRecruiteeAggregates} />
                            </div>
                        </div>

                        <div className='ibox'>
                            <div className='ibox-title'>
                                <h5>{t('dashboard.newApplications.title')}</h5>
                            </div>
                            <div className='ibox-content'>
                                <NewApplicationsChart data={this.props.dashboardData.newJobApplicationsGroupedByDay} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='ibox h-100 d-flex flex-column'>
                                    <div className='ibox-title'>
                                        <h5>{t('dashboard.candidatesBySource.title')}</h5>
                                    </div>
                                    <div className='ibox-content flex-grow-1'>
                                        <CandidatesBySourceChart
                                            data={this.props.dashboardData.jobApplicationsGroupedByReferer}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='ibox h-100 d-flex flex-column'>
                                    <div className='ibox-title'>
                                        <h5>{t('dashboard.candidatesByStage.title')}</h5>
                                    </div>
                                    <div className='ibox-content flex-grow-1'>
                                        {!isLoading && (
                                            <CandidatesByStageChart
                                                recruitmentStages={this.props.recruitmentStages}
                                                data={this.props.dashboardData.recruitmentRecruiteesGroupedByStage}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='ibox h-100-n25 d-flex flex-column'>
                            <div className='ibox-title'>
                                <h5>{t('dashboard.recentActivities')}</h5>
                            </div>
                            <div className='ibox-content flex-grow-1'>
                                <ActivitiesList items={this.props.dashboardData.recentActivities}></ActivitiesList>
                            </div>
                        </div>
                    </div>
                </div>
            </SidebarLayoutFixedContent>
        );
    }
}

function sortJobApplicationData(a: JobApplicationsByRefererDatabaseDto, b: JobApplicationsByRefererDatabaseDto) {
    return a.count > b.count ? -1 : 1;
}

const mapStateToProps = (state: State): StateProps => ({
    ...state.dashboard,
    dashboardData: {
        ...state.dashboard.dashboardData,
        jobApplicationsGroupedByReferer: state.dashboard.dashboardData.jobApplicationsGroupedByReferer.sort(
            sortJobApplicationData
        )
    },
    isLoading: state.dashboard.isLoading || state.recruitmentRecruiteeStages.allStages.isLoading,
    recruitmentStages: state.recruitmentRecruiteeStages.allStages.items
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchData: () => dispatch(DashboardThunks.getDashboardData()),
    fetchAllStages: () => dispatch(RecruitmentRecruiteeStagesThunks.getStages())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));
