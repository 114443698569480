import _, { Dictionary } from 'lodash';
import { normalize, schema } from 'normalizr';

import { GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult } from './recruitersBrowser.dto';
import { RecruitersBrowserItem } from './recruitersBrowser.state';

const RECRUITERS_BROWSER_RECRUITERS_KEY = 'recruiters_browser_recruiters';

export const recruitersBrowserRecruiterSchema = new schema.Entity(
    RECRUITERS_BROWSER_RECRUITERS_KEY,
    {},
    {
        idAttribute: 'recruiterATSUserId'
    }
);

export function convertApiRecruitersBrowserRecruitersToSchema(
    response: GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult[]
) {
    const parsed = normalize(response, [recruitersBrowserRecruiterSchema]);

    const items: Dictionary<RecruitersBrowserItem> = _.mapValues(
        parsed.entities[RECRUITERS_BROWSER_RECRUITERS_KEY] as Dictionary<
            GetATSUsersListForRecruitmentTeamMembersChoiceQueryResult
        >,
        item => ({
            ...item,
            isSelected: false
        })
    );

    return {
        ids: parsed.result as string[],
        items
    };
}
