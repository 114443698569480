import classNames from 'classnames';
import React, { Component } from 'react';

import { endpoints } from '../../../api/endpoints';
import { ATSUserForRecruitmentEditView } from '../../../api/recruitments/recruitments.dto';
import PersonInfoBlock from '../../common/PersonInfoBlock';
import styles from './RecruitersList.module.scss';

type Props = ATSUserForRecruitmentEditView & {
    onDelete: (id: string) => void;
};

export default class RecruitersListItem extends Component<Props> {
    render() {
        const { id, firstName, lastName } = this.props;
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(id);

        return (
            <div className={styles.item}>
                <div className={styles.itemName}>
                    <PersonInfoBlock
                        firstName={firstName}
                        lastName={lastName}
                        photoUrl={photoUrl}
                        size='small'
                        className='m-0'
                    />
                </div>
                <div className={styles.itemDelete}>
                    <button className='btn btn-default btn-flat border-0' onClick={this.onDelete}>
                        <i className={classNames('fa fa-times', styles.btnDelete)} />
                    </button>
                </div>
            </div>
        );
    }

    private onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onDelete(this.props.id);
    };
}
