import { TFunction } from 'i18next';
import { ComponentType } from 'react';
import uuid from 'uuid';

import { QuestionnaireBuilderElement } from '../../../api/questionnaires/builder/builder.state';
import {
    AnswerableQuestionnaireElement,
    QuestionnaireElementType,
    QuestionQuestionnaireElementType,
    StructureQuestionnaireElementType,
} from '../../../api/questionnaires/questionnaires.dto';
import { PageBreakPreview } from './element/PageBreak';
import { QuestionnaireAddressForm, QuestionnaireAddressPreview } from './element/QuestionnaireAddress';
import { QuestionnaireAttachmentForm, QuestionnaireAttachmentPreview } from './element/QuestionnaireAttachment';
import { QuestionnaireCoverLetterPreview } from './element/QuestionnaireCoverLetter';
import { QuestionnaireEducationPreview } from './element/QuestionnaireEducation';
import { QuestionnaireExperiencePreview } from './element/QuestionnaireExperience';
import { QuestionnaireHeaderForm, QuestionnaireHeaderPreview } from './element/QuestionnaireHeader';
import { QuestionnaireMultiLineTextForm, QuestionnaireMultiLineTextPreview } from './element/QuestionnaireMultiLineText';
import { QuestionnaireMultipleChoiceForm, QuestionnaireMultipleChoicePreview } from './element/QuestionnaireMultipleChoice';
import { QuestionnairePhotoForm, QuestionnairePhotoPreview } from './element/QuestionnairePhoto';
import { QuestionnaireResumePreview } from './element/QuestionnaireResume';
import { QuestionnaireSingleChoiceForm, QuestionnaireSingleChoicePreview } from './element/QuestionnaireSingleChoice';
import { QuestionnaireSingleLineTextForm, QuestionnaireSingleLineTextPreview } from './element/QuestionnaireSingleLineText';
import { QuestionnaireSummaryPreview } from './element/QuestionnaireSummary';
import { QuestionnaireTextParagraphForm, QuestionnaireTextParagraphPreview } from './element/QuestionnaireTextParagraph';
import { SectionBreakPreview } from './element/SectionBreak';
import { QuestionnaireElementFormProps, QuestionnaireElementPreviewProps } from './QuestionnaireElement';

export function getComponentFormType(
    type: QuestionnaireElementType
): ComponentType<QuestionnaireElementFormProps<any>> {
    switch (type) {
        case QuestionQuestionnaireElementType.QuestionnaireAddress:
            return QuestionnaireAddressForm;
        case QuestionQuestionnaireElementType.QuestionnaireAttachment:
            return QuestionnaireAttachmentForm;
        // case QuestionQuestionnaireElementType.QuestionnaireCoverLetter: // cover letter is not editable
        // case QuestionQuestionnaireElementType.QuestionnaireEducation:
        // case QuestionQuestionnaireElementType.QuestionnaireExperience:
        case QuestionQuestionnaireElementType.QuestionnaireHeader:
            return QuestionnaireHeaderForm;
        // case QuestionQuestionnaireElementType.QuestionnaireMultiLineText:
        case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
            return QuestionnaireMultipleChoiceForm;
        case QuestionQuestionnaireElementType.QuestionnaireMultiLineText:
            return QuestionnaireMultiLineTextForm;
        // case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
        case QuestionQuestionnaireElementType.QuestionnairePhoto:
            return QuestionnairePhotoForm;
        // case QuestionQuestionnaireElementType.QuestionnaireResume: // resume is not editable
        case QuestionQuestionnaireElementType.QuestionnaireSingleChoice:
            return QuestionnaireSingleChoiceForm;
        case QuestionQuestionnaireElementType.QuestionnaireSingleLineText:
        default:
            return QuestionnaireSingleLineTextForm;
        // case QuestionQuestionnaireElementType.QuestionnaireSummary: // summary is not editable
        case QuestionQuestionnaireElementType.QuestionnaireTextParagraph:
            return QuestionnaireTextParagraphForm;
    }
}

export function getComponentPreviewType(
    type: QuestionnaireElementType
): ComponentType<QuestionnaireElementPreviewProps<any>> {
    switch (type) {
        case StructureQuestionnaireElementType.PageBreak:
            return PageBreakPreview;
        case StructureQuestionnaireElementType.SectionBreak:
            return SectionBreakPreview;
        case QuestionQuestionnaireElementType.QuestionnaireAddress:
            return QuestionnaireAddressPreview;
        case QuestionQuestionnaireElementType.QuestionnaireAttachment:
            return QuestionnaireAttachmentPreview;
        case QuestionQuestionnaireElementType.QuestionnaireCoverLetter:
            return QuestionnaireCoverLetterPreview;
        case QuestionQuestionnaireElementType.QuestionnaireEducation:
            return QuestionnaireEducationPreview;
        case QuestionQuestionnaireElementType.QuestionnaireExperience:
            return QuestionnaireExperiencePreview;
        case QuestionQuestionnaireElementType.QuestionnaireHeader:
            return QuestionnaireHeaderPreview;
        // case QuestionQuestionnaireElementType.QuestionnaireMultiLineText:
        case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
            return QuestionnaireMultipleChoicePreview;
        case QuestionQuestionnaireElementType.QuestionnaireMultiLineText:
            return QuestionnaireMultiLineTextPreview;
        // case QuestionQuestionnaireElementType.QuestionnaireMultipleChoice:
        case QuestionQuestionnaireElementType.QuestionnairePhoto:
            return QuestionnairePhotoPreview;
        case QuestionQuestionnaireElementType.QuestionnaireResume:
            return QuestionnaireResumePreview;
        case QuestionQuestionnaireElementType.QuestionnaireSingleChoice:
            return QuestionnaireSingleChoicePreview;
        case QuestionQuestionnaireElementType.QuestionnaireSingleLineText:
        default:
            return QuestionnaireSingleLineTextPreview;
        case QuestionQuestionnaireElementType.QuestionnaireSummary:
            return QuestionnaireSummaryPreview;
        case QuestionQuestionnaireElementType.QuestionnaireTextParagraph:
            return QuestionnaireTextParagraphPreview;
    }
}

export function isComponentTypeUnique(type: QuestionnaireElementType): boolean {
    switch (type) {
        case QuestionQuestionnaireElementType.QuestionnaireAddress:
        case QuestionQuestionnaireElementType.QuestionnaireCoverLetter:
        case QuestionQuestionnaireElementType.QuestionnaireEducation:
        case QuestionQuestionnaireElementType.QuestionnaireExperience:
        case QuestionQuestionnaireElementType.QuestionnairePhoto:
        case QuestionQuestionnaireElementType.QuestionnaireResume:
        case QuestionQuestionnaireElementType.QuestionnaireSummary:
            return true;
        default:
            return false;
    }
}

export function isComponentTypeEditable(type: QuestionnaireElementType): boolean {
    switch (type) {
        case QuestionQuestionnaireElementType.QuestionnaireCoverLetter:
        case QuestionQuestionnaireElementType.QuestionnaireEducation:
        case QuestionQuestionnaireElementType.QuestionnaireExperience:
        case QuestionQuestionnaireElementType.QuestionnaireResume:
        case QuestionQuestionnaireElementType.QuestionnaireSummary:
        case StructureQuestionnaireElementType.PageBreak:
        case StructureQuestionnaireElementType.SectionBreak:
            return false;
        default:
            return true;
    }
}

export function isComponentTypeWide(type: QuestionnaireElementType): boolean {
    switch (type) {
        case StructureQuestionnaireElementType.PageBreak:
            return true;
        default:
            return false;
    }
}

export function isComponentTypeDeletable(type: QuestionnaireElementType): boolean {
    switch (type) {
        case QuestionQuestionnaireElementType.QuestionnaireResume:
            return false;
        default:
            return true;
    }
}

export function generateLabelAffixIfRequired(element: AnswerableQuestionnaireElement) {
    if (element.isRequired) {
        return 'questionnaires.questionnaireForm.question.questionLabelRequiredAffix';
    }

    return '';
}

interface NewQuestionnaireElementInitialData {
    id?: string;
    $type?: QuestionnaireElementType;
    question?: string;
    isRequired?: boolean;
    text?: string;
}

/**
 * Returns a function for creating new `QuestionnaireElement` from the provided initial data.
 * @param t (optional) TFunction for translating the `question` field.
 */
export function createNewQuestionnaireElement(
    t?: TFunction
): (initialData: NewQuestionnaireElementInitialData) => QuestionnaireBuilderElement {
    return (initialData: NewQuestionnaireElementInitialData): QuestionnaireBuilderElement => {
        const id = initialData.id || uuid.v4(),
            $type = initialData.$type || QuestionQuestionnaireElementType.QuestionnaireSingleLineText,
            isRequired = initialData.isRequired || false;

        const defaultQuestionString = `questionnaires.questionnaireForm.addMenu.field.${$type}.name`;
        const questionString = initialData.question || defaultQuestionString;
        const question = t ? t(questionString) : questionString;

        const result = {
            id,
            $type,
            question,
            isRequired,
            order: 0,
            pageNumber: 0,
            sectionNumber: 0,
            text: initialData.text || question,
            isEditing: false
        };

        return result as QuestionnaireBuilderElement;
    };
}

export interface AddQuestionnaireElementOption {
    type: QuestionnaireElementType;
    icon?: string;
    label: string;
}

export const MENU_ITEMS_QUESTIONS: AddQuestionnaireElementOption[] = [
    {
        type: QuestionQuestionnaireElementType.QuestionnaireSingleLineText,
        icon: 'ellipsis-h',
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireSingleLineText.name'
    },
    {
        type: QuestionQuestionnaireElementType.QuestionnaireMultiLineText,
        icon: 'align-justify',
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireMultiLineText.name'
    },
    {
        type: QuestionQuestionnaireElementType.QuestionnaireSingleChoice,
        icon: 'dot-circle-o',
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireSingleChoice.name'
    },
    {
        type: QuestionQuestionnaireElementType.QuestionnaireMultipleChoice,
        icon: 'check-square',
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireMultipleChoice.name'
    },
    {
        type: QuestionQuestionnaireElementType.QuestionnaireAttachment,
        icon: 'paperclip',
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireAttachment.name'
    }
];

export const MENU_ITEMS_OTHER: AddQuestionnaireElementOption[] = [
    {
        type: QuestionQuestionnaireElementType.QuestionnaireHeader,
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireHeader.name'
    },
    {
        type: QuestionQuestionnaireElementType.QuestionnaireTextParagraph,
        label: 'questionnaires.questionnaireForm.addMenu.field.QuestionnaireTextParagraph.name'
    },
    {
        type: StructureQuestionnaireElementType.PageBreak,
        label: 'questionnaires.questionnaireForm.addMenu.field.PageBreak.name'
    },
    {
        type: StructureQuestionnaireElementType.SectionBreak,
        label: 'questionnaires.questionnaireForm.addMenu.field.SectionBreak.name'
    }
];
