import {
    DatePickerInput,
    ErrorContext,
    InModal,
    InModalBody,
    InModalFooter,
    InModalHeader,
    LabelFieldHorizontal,
    Preloader,
    Select,
    TextInput,
    Wysiwyg,
} from '@emplo/react-inspinia';
import classNames from 'classnames';
import { EditorState } from 'draft-js';
import { Form, Formik, FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';

import { ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { GetAvailableTimezonesQueryResult } from '../../../../api/settings/settings.dto';
import { SettingsThunks } from '../../../../api/settings/settings.thunk';
import { State } from '../../../../store/state';
import { getCurrentDateFormat, getLocalTimezone } from '../../../../utils/format';
import { listMapper } from '../../../../utils/listMapper';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onSubmit: (formData: FormValues) => void;
}

enum FormField {
    meetingName = 'meetingName',
    invitedPeople = 'invitedPeople',
    meetingDate = 'meetingDate',
    meetingTime = 'meetingTime',
    timeZoneId = 'timeZoneId',
    durationInMinutes = 'durationInMinutes',
    messageContent = 'messageContent'
}

export interface FormValues {
    [FormField.meetingName]: string;
    [FormField.invitedPeople]: string;
    [FormField.meetingDate]: string;
    [FormField.meetingTime]: string;
    [FormField.timeZoneId]: string;
    [FormField.durationInMinutes]: number;
    [FormField.messageContent]: EditorState;
}

const defaultValues: FormValues = {
    meetingName: '',
    invitedPeople: '',
    meetingDate: '',
    meetingTime: '',
    timeZoneId: getLocalTimezone(),
    durationInMinutes: 60,
    messageContent: EditorState.createEmpty()
};

interface StateProps {
    timezones: GetAvailableTimezonesQueryResult[];
    isLoading: boolean;
}

interface DispatchProps {
    fetchTimezones: () => void;
}

class RecruiteeActionMeetingForm extends Component<Props & StateProps & DispatchProps & WithTranslation> {
    private readonly validationSchema = yup.object().shape({
        [FormField.meetingName]: yup.string().required(),
        [FormField.meetingDate]: yup.date().required(),
        [FormField.meetingTime]: yup
            .string()
            .required()
            .test('time', this.props.t('general.validation.timeInvalid'), value =>
                value ? /[0-2]?[0-9]:[0-5][0-9]/.test(value) : false
            ),
        [FormField.timeZoneId]: yup.string().required(),
        [FormField.durationInMinutes]: yup.number().required()
    });

    componentDidMount() {
        if (this.props.timezones.length === 0) {
            this.props.fetchTimezones();
        }
    }

    render() {
        if (this.props.isLoading) {
            return <Preloader />;
        }

        return (
            <InModal
                isOpen={this.props.isOpen}
                onClose={this.props.closeModal}
                className='animated a500 fadeInDown'
                backdropClassNames='animated a500 backdropFadeIn'
                size='large'>
                <Formik
                    render={this.renderForm}
                    initialValues={defaultValues}
                    onSubmit={this.props.onSubmit}
                    validationSchema={this.validationSchema}
                />
            </InModal>
        );
    }

    private renderForm = (props: FormikProps<FormValues>) => {
        const { t } = this.props;
        const { errors, touched } = props;

        return (
            <ErrorContext.Provider value={{ errors, touched }}>
                <Form>
                    <InModalHeader
                        titleText={t('recruiteeView.scheduleMeeting.title')}
                        className='border-bottom-0'
                        onClose={this.props.closeModal}
                    />
                    <InModalBody className='bg-white'>
                        <div className='row'>
                            <div className='col-12'>
                                <LabelFieldHorizontal
                                    for={FormField.meetingName}
                                    label={t('recruiteeView.scheduleMeeting.meetingName')}>
                                    <TextInput
                                        name={FormField.meetingName}
                                        value={props.values.meetingName}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <LabelFieldHorizontal
                                    for={FormField.invitedPeople}
                                    label={t('recruiteeView.scheduleMeeting.invitedPeople')}>
                                    <TextInput
                                        name={FormField.invitedPeople}
                                        value={props.values.invitedPeople}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-lg'>
                                <LabelFieldHorizontal
                                    for={FormField.meetingDate}
                                    label={t('recruiteeView.scheduleMeeting.meetingDate')}>
                                    <DatePickerInput
                                        name={FormField.meetingDate}
                                        value={props.values[FormField.meetingDate]}
                                        selected={
                                            props.values[FormField.meetingDate]
                                                ? new Date(props.values[FormField.meetingDate])
                                                : null
                                        }
                                        onChange={props.setFieldValue}
                                        onBlur={props.handleBlur}
                                        dateFormat={getCurrentDateFormat().datePicker}
                                        placeholderText={getCurrentDateFormat().display}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                            <div className='col-12 col-lg'>
                                <LabelFieldHorizontal
                                    for={FormField.meetingTime}
                                    label={t('recruiteeView.scheduleMeeting.meetingTime')}>
                                    <div className='input-group-prepend'>
                                        <div className='input-group-addon'>
                                            <i className='fa fa-clock-o' />
                                        </div>
                                    </div>
                                    <TextInput
                                        name={FormField.meetingTime}
                                        value={props.values.meetingTime}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        placeholder={t('general.timePlaceholder')}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                            <div className='col-12 col-lg'>
                                <LabelFieldHorizontal
                                    for={FormField.durationInMinutes}
                                    label={t('recruiteeView.scheduleMeeting.duration')}>
                                    <Select
                                        name={FormField.durationInMinutes}
                                        value={props.values.durationInMinutes}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        className='w-100'>
                                        <option value={30}>{t('general.30minutes')}</option>
                                        <option value={60}>{t('general.1hour')}</option>
                                        <option value={120}>{t('general.2hours')}</option>
                                        <option value={180}>{t('general.3hours')}</option>
                                    </Select>
                                </LabelFieldHorizontal>
                            </div>
                            <div className='col-12 col-lg'>
                                <LabelFieldHorizontal
                                    for={FormField.timeZoneId}
                                    label={t('recruiteeView.scheduleMeeting.meetingTimeZone')}>
                                    <Select
                                        name={FormField.timeZoneId}
                                        value={props.values.timeZoneId}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        className='w-100'>
                                        {this.props.timezones.map(this.renderTimezoneOption)}
                                    </Select>
                                </LabelFieldHorizontal>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <LabelFieldHorizontal for={FormField.messageContent}>
                                    <Wysiwyg
                                        name={FormField.messageContent}
                                        editorState={props.values.messageContent}
                                        setFieldValue={props.setFieldValue}
                                        wrapperStyle={{ height: 200 }}
                                    />
                                </LabelFieldHorizontal>
                            </div>
                        </div>
                    </InModalBody>
                    <InModalFooter>
                        <button
                            onClick={this.props.closeModal}
                            className={classNames('btn btn-default btn-lg btn-w-m font-bold')}>
                            {t('general.button.close')}
                        </button>
                        <button type='submit' className={classNames('btn btn-primary btn-lg btn-w-m ml-3 font-bold')}>
                            {t('general.button.save')}
                        </button>
                    </InModalFooter>
                </Form>
            </ErrorContext.Provider>
        );
    };

    private renderTimezoneOption = (timezone: GetAvailableTimezonesQueryResult) => {
        return (
            <option key={timezone.id} value={timezone.id}>
                {timezone.displayName} {timezone.id}
            </option>
        );
    };
}

const mapStateToProps = (state: State): StateProps => ({
    timezones: listMapper(state.settings.timezones.list, state.settings.timezones.items),
    isLoading: state.settings.timezones.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchTimezones: () => dispatch(SettingsThunks.getTimezones())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruiteeActionMeetingForm));
