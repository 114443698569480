import { Dictionary } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
    RecruiteeListViewDto,
    RecruiteeViewDto,
    GetRecruiteesInRecruitmentListQueryResult,
    GetJobApplicationsListForRecruiteeQueryResult,
    GetAllScoresSummaryForRecruiteeInRecruitmentQueryResult,
    GetRecruitmentRecruiteeActivityListQueryResult,
    Activity
} from './recruitees.dto';
import { RecruiteeItem, RecruiteeInRecruitmentItem, RecruiteeApplicationItem } from './recruitees.state';
import { QuestionQuestionnaireElementType, QuestionnaireResumeAnswer } from '../questionnaires/questionnaires.dto';
import _ from 'lodash';

export const SCHEMA_KEY_RECRUITEES = 'recruitees';
export const SCHEMA_KEY_RECRUITEES_IN_RECRUITMENT = 'recruitees_in_recruitment';
export const SCHEMA_KEY_RECRUITEES_RECRUITEE_APPLICATIONS = 'recruitees_recruitee_applications';
export const SCHEMA_KEY_RECRUITEES_ALL_SCORES_FOR_RECRUITEE_SUMMARY = 'recruitees_all_scores_for_recruitee_summary';
export const SCHEMA_KEY_RECRUITEES_RECRUITMENT_RECRUITEE_EVENT_HISTORY =
    'recruitees_recruitment_recruitee_event_history';

export const recruiteeSchema = new schema.Entity(SCHEMA_KEY_RECRUITEES);
export const recruiteeInRecruitmentSchema = new schema.Entity(SCHEMA_KEY_RECRUITEES_IN_RECRUITMENT);
export const recruiteeApplicationSchema = new schema.Entity(SCHEMA_KEY_RECRUITEES_RECRUITEE_APPLICATIONS);
export const recruiteeAllScoresSummarySchema = new schema.Entity(
    SCHEMA_KEY_RECRUITEES_ALL_SCORES_FOR_RECRUITEE_SUMMARY
);
export const recruitmentRecruiteeEventHistorySchema = new schema.Entity(
    SCHEMA_KEY_RECRUITEES_RECRUITMENT_RECRUITEE_EVENT_HISTORY
);

export function convertApiRecruiteesToSchema(response: RecruiteeListViewDto[]) {
    const parsed = normalize(response, [recruiteeSchema]);

    return {
        recruiteesIds: parsed.result as string[],
        recruitees: parsed.entities[SCHEMA_KEY_RECRUITEES] as Dictionary<RecruiteeItem>
    };
}

export function convertApiRecruiteesInRecruitmentToSchema(response: GetRecruiteesInRecruitmentListQueryResult[]) {
    const parsed = normalize(response, [recruiteeInRecruitmentSchema]);

    return {
        recruiteesIds: parsed.result as string[],
        recruitees: parsed.entities[SCHEMA_KEY_RECRUITEES_IN_RECRUITMENT] as Dictionary<RecruiteeInRecruitmentItem>
    };
}

export function convertApiRecruiteeToSchema(response: RecruiteeViewDto) {
    const parsed = normalize(response, recruiteeSchema);

    const parsedRecruitee = {
        ...parsed.entities[SCHEMA_KEY_RECRUITEES][parsed.result],
        resumeFileIdentifier: extractNewestResumeFileIdentifierFromQuestionnaireAnswers(
            parsed.entities[SCHEMA_KEY_RECRUITEES][parsed.result] as RecruiteeViewDto
        )
    };

    return {
        recruiteeId: parsed.result as string,
        recruitee: parsedRecruitee as RecruiteeViewDtoNormalized
    };
}

export function convertApiRecruiteeApplicationsToSchema(response: GetJobApplicationsListForRecruiteeQueryResult[]) {
    const parsed = normalize(response, [recruiteeApplicationSchema]);

    return {
        applicationsIds: parsed.result as string[],
        applications: parsed.entities[SCHEMA_KEY_RECRUITEES_RECRUITEE_APPLICATIONS] as Dictionary<
            RecruiteeApplicationItem
        >
    };
}

export function convertApiAllScoresForRecruiteeSummaryToSchema(
    response: GetAllScoresSummaryForRecruiteeInRecruitmentQueryResult
) {
    return {
        scores: response.recruitmentRecruiteeScoresSummaryRows.result
    };
}

export function convertApiRecruitmentRecruiteeEventHistoryToSchema(
    response: GetRecruitmentRecruiteeActivityListQueryResult[]
) {
    const parsed = normalize(response, [recruitmentRecruiteeEventHistorySchema]);

    return {
        ids: parsed.result as string[],
        items: parsed.entities[SCHEMA_KEY_RECRUITEES_RECRUITMENT_RECRUITEE_EVENT_HISTORY] as Dictionary<Activity>
    };
}

export type RecruiteeViewDtoNormalized = RecruiteeViewDto & {
    resumeFileIdentifier?: string;
};

/**
 * Searches for resume question answer and returns the submitted resume file's ID if such was submitted.
 */
function extractNewestResumeFileIdentifierFromQuestionnaireAnswers(recruitee: RecruiteeViewDto): string | void {
    if (!recruitee.authorizedData) {
        return;
    }

    const structure = recruitee.authorizedData.filledQuestionnaireStructure;
    const answers = recruitee.authorizedData.filledQuestionnaireAnswers;

    const resumeQuestion = _.find(
        structure.elements,
        questionnaireElement => questionnaireElement.$type === QuestionQuestionnaireElementType.QuestionnaireResume
    );

    if (resumeQuestion) {
        const resumeAnswer = _.find(
            answers.additionalQuestionnaireAnswers,
            answer => answer.questionId === resumeQuestion.id
        );
        return resumeAnswer && (resumeAnswer as QuestionnaireResumeAnswer).fileIdentifier;
    }
}
