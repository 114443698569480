import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { RecruitmentRecruiteeStagesThunks } from '../../../api/recruitmentRecruiteeStages/recruitmentRecruiteeStages.thunk';
import { RecruitmentAvailableStatusesThunks } from '../../../api/recruitments/availableStatuses/availableStatuses.thunk';
import { selectRecruitmentDetails } from '../../../api/recruitments/recruitments.selector';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import Details from './Details';

interface RecruitmentViewRouteParams {
    id: string;
}

export default () => {
    const dispatch = useDispatch();
    const recruitmentId = useRouteMatch<RecruitmentViewRouteParams>().params.id;
    const fetchRecruitment = useCallback(() => dispatch(RecruitmentsThunks.getRecruitment(recruitmentId)), [
        dispatch,
        recruitmentId
    ]);
    const fetchAvailableStatuses = useCallback(
        () => dispatch(RecruitmentAvailableStatusesThunks.getAvailableStatusesForRecruitment(recruitmentId)),
        [dispatch, recruitmentId]
    );
    const fetchAllStages = useCallback(() => dispatch(RecruitmentRecruiteeStagesThunks.getStages()), [dispatch]);
    const recruitmentStatus = useSelector(selectRecruitmentDetails)?.status;

    useEffect(() => {
        ReactTooltip.rebuild();

        fetchRecruitment();
        fetchAvailableStatuses();
        fetchAllStages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if status has changed - reload available statuses
        fetchAvailableStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recruitmentStatus]);

    return <RecruitmentView />;
};

const RecruitmentView = React.memo(() => {
    const { t } = useTranslation();
    const recruitment = useSelector(selectRecruitmentDetails);
    const isLoading = useSelector<State, boolean>(state => state.recruitments.recruitmentDetails.isLoading);
    const isWriting = useSelector<State, boolean>(state => state.recruitments.recruitmentDetails.isWriting);

    return (
        <SidebarLayoutFixedContent
            className='animated fadeIn'
            title={recruitment && recruitment.title}
            breadcrumbs={
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to={RoutePath.recruitmentsList()}>{t('general.mainMenu.recruitments')}</Link>
                    </li>
                    <li className='breadcrumb-item active'>
                        <strong>{recruitment && recruitment.title}</strong>
                    </li>
                </ol>
            }>
            {isWriting && <Preloader overlay />}

            {isLoading ? <Preloader overlay /> : recruitment && <Details />}
        </SidebarLayoutFixedContent>
    );
});
