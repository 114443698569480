import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import { EditorState } from 'draft-js';
import { Dictionary } from 'lodash';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';

import { ErrorState } from '../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../api/_commons/thunks.common';
import { QuestionnaireListViewForRecruitmentDto } from '../../../api/questionnaires/questionnaires.dto';
import { QuestionnairesThunks } from '../../../api/questionnaires/questionnaires.thunk';
import { AddRecruitmentCommand, ATSUserForRecruitmentEditView } from '../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { BudgetCurrency } from '../../../api/settings/settings.state';
import { SettingsThunks } from '../../../api/settings/settings.thunk';
import { RoutePath } from '../../../routes';
import { State } from '../../../store/state';
import { listMapper } from '../../../utils/listMapper';
import ErrorPage from '../../pages/ErrorPage';
import RecruitmentForm, {
    FormValues,
    recruitmentFormValuesToAddRecruitmentPostParams,
    VACANT_POSTS_MIN_VAL,
} from '../RecruitmentForm';

const initialFormData: FormValues = {
    title: '',
    deadline: '',
    vacantPosts: VACANT_POSTS_MIN_VAL,
    budgetPerPositionMin: 0,
    budgetPerPositionMax: 0,
    budgetCurrency: undefined, // undefined == none selected
    newOrExistingPosition: { name: '' },
    questionnaireId: undefined, // undefined == none selected
    jobTitle: '',
    jobDescription: EditorState.createEmpty(),
    remoteWorkIsPossible: false,
    keywords: [],
    isDraft: false,
    recruitmentTeamMembers: []
};

interface NewRecruitmentProps {
    /**
     * Action for submitting the form data to the API endpoint. Returns new item's ID.
     */
    doCreateRecruitment: (recruitmentData: AddRecruitmentCommand) => Promise<ThunkActionResult<string>>;
    fetchCurrencies: () => void;
    fetchQuestionnaires: () => void;
    currencies: BudgetCurrency[];
    questionnaires: QuestionnaireListViewForRecruitmentDto[];
    isLoading: boolean;
    error: ErrorState;
    currentUser: ATSUserForRecruitmentEditView;
}

interface OwnState {
    isSubmitting: boolean;
}

class NewRecruitment extends Component<NewRecruitmentProps & WithTranslation & RouteComponentProps, OwnState> {
    state: OwnState = {
        isSubmitting: false
    };

    componentDidMount() {
        this.props.fetchCurrencies();
        this.props.fetchQuestionnaires();
    }

    render() {
        const { t, isLoading, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        const initialValues: FormValues = {
            ...initialFormData,
            recruitmentOwner: this.props.currentUser
        };

        return (
            <SidebarLayoutFixedContent
                className='animated fadeIn'
                title={t('recruitments.newRecruitment')}
                breadcrumbs={
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                            <Link to={RoutePath.recruitmentsList()}>{t('general.mainMenu.recruitments')}</Link>
                        </li>
                        <li className='breadcrumb-item active'>
                            <strong>{t('recruitments.newRecruitment')}</strong>
                        </li>
                    </ol>
                }>
                {isLoading && <Preloader overlay />}

                <RecruitmentForm
                    initialValues={initialValues}
                    onSubmit={this.onSubmit}
                    isSubmitting={this.state.isSubmitting}
                    currencies={this.props.currencies}
                    questionnaires={this.props.questionnaires}
                />
            </SidebarLayoutFixedContent>
        );
    }

    private onSubmit = async (values: FormValues) => {
        const { t } = this.props;
        const recruitmentData = recruitmentFormValuesToAddRecruitmentPostParams(values);

        this.setState({
            isSubmitting: true
        });

        const results = await this.props.doCreateRecruitment(recruitmentData);
        if (results.httpStatus < 300) {
            toastr.success(t('recruitmentsNew.success.message'), t('recruitmentsNew.success.title'));
            this.props.history.push(RoutePath.recruitmentsList());
        } else {
            toastr.error(t('recruitmentsNew.error.message'), t('recruitmentsNew.error.title'));
            this.setState({
                isSubmitting: false
            });
        }
    };
}

const NewRecruitmentWithTranslation = withTranslation()(NewRecruitment);

const mapStateToProps = (state: State) => ({
    currencies: listMapper(state.settings.currencies.list, state.settings.currencies.items),
    questionnaires: listMapper<QuestionnaireListViewForRecruitmentDto, undefined, string>(
        state.questionnaires.questionnairesForRecruitment.list,
        state.questionnaires.questionnairesForRecruitment.items as Dictionary<QuestionnaireListViewForRecruitmentDto>
    ),
    isLoading: state.settings.currencies.isLoading || state.questionnaires.questionnairesForRecruitment.isLoading,
    error: state.settings.currencies.error || state.questionnaires.questionnairesForRecruitment.error,
    currentUser: {
        id: state.user.userId || '',
        firstName: state.user.firstName || '',
        lastName: state.user.lastName || '',
        avatar: state.user.avatar
    }
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    doCreateRecruitment: (recruitmentData: AddRecruitmentCommand) =>
        dispatch(RecruitmentsThunks.addRecruitment(recruitmentData)),
    fetchCurrencies: () => dispatch(SettingsThunks.getCurrencies()),
    fetchQuestionnaires: () => dispatch(QuestionnairesThunks.getQuestionnairesForRecruitment(1, 1000))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewRecruitmentWithTranslation));
