import _ from 'lodash';
import React from 'react';

import {
    QuestionnaireAnswersDto,
    QuestionnaireStructureDto,
    QuestionQuestionnaireElementType,
} from '../../../api/questionnaires/questionnaires.dto';
import ApplicationFormPaneContainer from '../../recruitees/view/applicationForm/ApplicationFormPane';

interface RecruiteeApplicationFormProps {
    filledQuestionnaireStructure: QuestionnaireStructureDto;
    filledQuestionnaireAnswers: QuestionnaireAnswersDto;
    currentStep?: number;
    jobApplicationId: string;
}

export default (props: RecruiteeApplicationFormProps) => {
    // remove elements which are displayed in other places (e.g. resume, photo, address)
    // sort the elements by `order`
    const excludedQuestionTypes = [
            QuestionQuestionnaireElementType.QuestionnaireAddress,
            QuestionQuestionnaireElementType.QuestionnairePhoto,
            QuestionQuestionnaireElementType.QuestionnaireResume
        ],
        questionnaireStructure = {
            elements: _.filter(
                props.filledQuestionnaireStructure.elements,
                el => !excludedQuestionTypes.includes(el.$type as QuestionQuestionnaireElementType)
            ).sort((a, b) => (a.order > b.order ? 1 : -1))
        };

    if (props.currentStep !== undefined) {
        questionnaireStructure.elements = props.filledQuestionnaireStructure.elements.filter(
            element => element.pageNumber === props.currentStep
        );
    }

    return (
        <ApplicationFormPaneContainer
            answers={props.filledQuestionnaireAnswers}
            filledQuestionnaireStructure={questionnaireStructure}
            renderStaticContent={props.currentStep === undefined || props.currentStep === 1}
            jobApplicationId={props.jobApplicationId}
        />
    );
};
