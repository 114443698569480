import { TextInput } from '@emplo/react-inspinia';
import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../../api/_commons/thunks.common';
import { RecruitmentsActions } from '../../../api/recruitments/recruitments.actions';
import { RecruitmentStatus } from '../../../api/recruitments/recruitments.dto';
import { RecruitmentsThunks } from '../../../api/recruitments/recruitments.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../constants';
import { State } from '../../../store/state';
import styles from './RecruitmentsList.module.scss';

interface RecruitmentsListFiltersStateProps {
    searchQuery?: string;
    activeStatus?: RecruitmentStatus;
}

interface RecruitmentsListFiltersDispatchProps {
    doUpdateFilters: (searchQuery?: string, status?: RecruitmentStatus) => void;
    doReloadRecruitments: () => void;
}

type RecruitmentsListFiltersProps = RecruitmentsListFiltersStateProps &
    RecruitmentsListFiltersDispatchProps &
    WithTranslation;

class RecruitmentsListFilters extends Component<RecruitmentsListFiltersProps> {
    render() {
        const { t } = this.props;

        return (
            <div className='row m-b-md'>
                <div className='col-6'>
                    <div className='input-group'>
                        <TextInput
                            name='search'
                            placeholder={t('recruitments.filter.inputPlaceholder')}
                            onChange={this.onSearchQueryChange}
                            onKeyDown={this.onSearchQueryKeyDown}
                            value={this.props.searchQuery || ''}
                        />
                        <span className='input-group-append'>
                            <button className='btn btn-white' onClick={this.props.doReloadRecruitments}>
                                <i className='fa fa-search text-navy' />
                            </button>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className='row align-items-center'>
                        <StatusFilterItem
                            label={t('recruitments.filter.all')}
                            isActive={this.props.activeStatus === undefined}
                            onClick={this.onStatusFilterClick}
                        />
                        <StatusFilterItem
                            label={t('recruitments.filter.open')}
                            status={RecruitmentStatus.OPEN}
                            isActive={this.props.activeStatus === RecruitmentStatus.OPEN}
                            onClick={this.onStatusFilterClick}
                        />
                        <StatusFilterItem
                            label={t('recruitments.filter.pending')}
                            status={RecruitmentStatus.PENDING}
                            isActive={this.props.activeStatus === RecruitmentStatus.PENDING}
                            onClick={this.onStatusFilterClick}
                        />
                        <StatusFilterItem
                            label={t('recruitments.filter.draft')}
                            status={RecruitmentStatus.DRAFT}
                            isActive={this.props.activeStatus === RecruitmentStatus.DRAFT}
                            onClick={this.onStatusFilterClick}
                        />
                        <StatusFilterItem
                            label={t('recruitments.filter.closed')}
                            status={RecruitmentStatus.CLOSED}
                            isActive={this.props.activeStatus === RecruitmentStatus.CLOSED}
                            onClick={this.onStatusFilterClick}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onSearchQueryKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            this.props.doReloadRecruitments();
        }
    };

    private onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value || undefined;
        this.props.doUpdateFilters(newValue, this.props.activeStatus);
    };

    private onStatusFilterClick = (status?: RecruitmentStatus) => {
        this.props.doUpdateFilters(this.props.searchQuery, status);
        this.props.doReloadRecruitments();
    };
}

const mapStateToProps = (state: State): RecruitmentsListFiltersStateProps => ({
    searchQuery: state.recruitments.allRecruitments.filters.searchQuery,
    activeStatus: state.recruitments.allRecruitments.filters.status
});

const mapDispatchToProps = (dispatch: ThunkDispatch): RecruitmentsListFiltersDispatchProps => ({
    doUpdateFilters: (searchQuery?: string, status?: RecruitmentStatus) =>
        dispatch(
            RecruitmentsActions.setRecruitmentsFilters({
                searchQuery,
                status
            })
        ),
    doReloadRecruitments: () => dispatch(RecruitmentsThunks.getRecruitments(1, PAGE_SIZE_DEFAULT, true)),
});

interface StatusFilterItemProps {
    label: string;
    isActive: boolean;
    status?: RecruitmentStatus; // undefined for "all" option
    onClick: (status?: RecruitmentStatus) => void;
}

class StatusFilterItem extends Component<StatusFilterItemProps> {
    render() {
        return (
            <button
                className={classNames(
                    'btn px-4 mr-1',
                    styles.filterBtn,
                    this.props.isActive ? 'btn-primary' : 'btn-link'
                )}
                onClick={this.onClick}>
                {this.props.label}
            </button>
        );
    }

    private onClick = () => {
        this.props.onClick(this.props.status);
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentsListFilters));
