import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ThunkDispatch } from '../../api/_commons/thunks.common';
import { AuthThunks } from '../../api/auth/auth.thunks';
import { endpoints } from '../../api/endpoints';
import { State } from '../../store/state';
import styles from './Login.module.scss';
import logoEmplo from './logo_emplo.svg';

interface StateProps {
    isLogged: boolean;
    isCheckingCredentials: boolean;
}

interface DispatchProps {
    checkAuth: () => void;
}

class Login extends Component<StateProps & DispatchProps & WithTranslation> {
    render() {
        const { t } = this.props;
        const redirectPath = typeof window !== 'undefined' ? window.location.pathname : '';

        return (
            <div className={classNames('animated fadeInDown', styles.container)}>
                <div className='ibox'>
                    <div className={classNames('ibox-content', styles.loginForm)}>
                        <h1 className={styles.welcome}>{t('login.welcome')}</h1>
                        <p>&nbsp;</p>
                        <a
                            href={endpoints.getLoginEndpoint(redirectPath)}
                            className={classNames('btn btn-default', styles.btnLogin)}>
                            <div className={styles.btnLoginInner}>
                                <img className={styles.btnLoginIcon} src={logoEmplo} alt='emplo' />
                                <span>{t('login.withEmplo')}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): StateProps => ({
    isLogged: state.auth.isLogged,
    isCheckingCredentials: state.auth.isCheckingCredentials
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    checkAuth: () => dispatch(AuthThunks.checkAuthState())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
