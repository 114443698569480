import { ListStateWithLoadingState } from '../_commons/state.common';
import { CurrencyDto, StageTypeDto, ATSLanguageDto, GetAvailableTimezonesQueryResult } from './settings.dto';

export const initialState: SettingsState = {
    currencies: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    stageTypes: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    languages: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    },
    timezones: {
        list: [],
        items: {},
        totalCount: 0,
        page: 0,
        isLoading: false,
        error: null
    }
};

export interface SettingsState {
    currencies: ListStateWithLoadingState<number, BudgetCurrency>;
    stageTypes: ListStateWithLoadingState<number, StageType>;
    languages: ListStateWithLoadingState<number, Language>;
    timezones: ListStateWithLoadingState<string, GetAvailableTimezonesQueryResult>;
}

export type BudgetCurrency = CurrencyDto;

export type StageType = StageTypeDto;

export type Language = ATSLanguageDto;
