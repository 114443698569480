import { Preloader, SidebarLayoutFixedContent } from '@emplo/react-inspinia';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { ErrorState } from '../../../../api/_commons/state.common';
import { ThunkActionResult, ThunkDispatch } from '../../../../api/_commons/thunks.common';
import { RecruitmentPositionsActions } from '../../../../api/recruitmentPositions/recruitmentPositions.actions';
import { RecruitmentPositionsThunks } from '../../../../api/recruitmentPositions/recruitmentPositions.thunk';
import { PAGE_SIZE_DEFAULT } from '../../../../constants';
import { State } from '../../../../store/state';
import ErrorPage from '../../../pages/ErrorPage';
import RecruitmentPositionsList from './RecruitmentPositionsList';

interface StateProps {
    isWriting: boolean;
    error: ErrorState;
}

interface DispatchProps {
    fetchItems: (page: number) => void;
    clearItems: () => void;
    doDeleteItem: (id: string) => Promise<ThunkActionResult>;
}

class RecruitmentPositions extends Component<StateProps & DispatchProps & WithTranslation> {
    constructor(props: StateProps & DispatchProps & WithTranslation) {
        super(props);

        this.props.clearItems();
    }

    componentDidMount() {
        this.props.fetchItems(1);
    }

    render() {
        const { t, isWriting, error } = this.props;

        if (error) {
            return <ErrorPage error={error} />;
        }

        return (
            <SidebarLayoutFixedContent className='animated fadeIn' title={t('general.mainMenu.recruitmentPositions')}>
                {isWriting && <Preloader overlay />}

                <RecruitmentPositionsList doDeleteItem={this.onDeleteItem} />
            </SidebarLayoutFixedContent>
        );
    }

    private onDeleteItem = async (id: string) => {
        const { t } = this.props;

        const results = await this.props.doDeleteItem(id);
        if (results.httpStatus < 300) {
            toastr.success(t('positionNames.deleteSuccessMessage'), t('general.success'));
        } else {
            toastr.error(t('positionNames.deleteErrorMessage'), t('general.error'));
        }
    };
}

const mapStateToProps = (state: State): StateProps => ({
    isWriting: state.recruitmentPositions.recruitmentPositionEdits.isWriting,
    error: state.recruitmentPositions.recruitmentPositionEdits.error
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
    fetchItems: (page: number) =>
        dispatch(RecruitmentPositionsThunks.getRecruitmentPositionsEdit(page, PAGE_SIZE_DEFAULT)),
    clearItems: () => dispatch(RecruitmentPositionsActions.clearRecruitmentPositionsEdit()),
    doDeleteItem: (id: string) => dispatch(RecruitmentPositionsThunks.deleteRecruitmentPositionEdit(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecruitmentPositions));
