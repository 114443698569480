import classNames from 'classnames';
import { TFunction } from 'i18next';
import React from 'react';

import { endpoints } from '../../../../../api/endpoints';
import { Activity, RECRUITEE_MAX_SCORE, ScoreChangedActivityData } from '../../../../../api/recruitees/recruitees.dto';
import PersonInfoBlock from '../../../../common/PersonInfoBlock';
import RecruiteeScoreStars from '../../../common/RecruiteeScoreStars';
import { ActivityItem } from './ActivityItem';
import styles from './ActivityItem.module.scss';

export default class ScoreChangedEvent extends ActivityItem {
    constructor(
        protected itemData: Activity,
        protected activityData: ScoreChangedActivityData,
        protected t: TFunction
    ) {
        super(itemData, activityData, t);
    }

    renderNode() {
        return (
            <div className={classNames('bg-warning', styles.nodePhone)}>
                <i className='fa fa-star' />
            </div>
        );
    }

    getUserInfo(): React.ReactNode {
        const photoUrl = endpoints.getAtsUserAvatarEndpoint(this.activityData.atsUserId);

        return (
            <PersonInfoBlock
                firstName={this.activityData.atsUserFirstName}
                lastName={this.activityData.atsUserLastName}
                photoUrl={photoUrl}
                size='tiny'
                className='mb-0 mt-3 text-small'
            />
        );
    }

    getTitle() {
        return this.t('recruiteeView.activities.scoreChanged.title', { score: this.activityData.newScore });
    }

    getContent() {
        return (
            <div>
                <RecruiteeScoreStars
                    initialScore={this.activityData.newScore}
                    starsCount={RECRUITEE_MAX_SCORE}
                    disableHover
                    className={styles.scoreStars}
                />
                <div dangerouslySetInnerHTML={{ __html: this.activityData.comment }} />
            </div>
        );
    }
}
