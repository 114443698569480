import { Dictionary, NumericDictionary } from 'lodash';

import { ActionsUnion, createAction } from '../_commons/actions.common';
import { ErrorState } from '../_commons/state.common';
import { ATSLanguageDto, GetAvailableTimezonesQueryResult, StageTypeDto } from './settings.dto';
import { BudgetCurrency } from './settings.state';

export enum SettingsActionTypes {
    GET_CURRENCIES_STARTED = 'SETTINGS_GET_CURRENCIES_STARTED',
    GET_CURRENCIES_FINISHED = 'SETTINGS_GET_CURRENCIES_FINISHED',
    GET_CURRENCIES_FAILED = 'SETTINGS_GET_CURRENCIES_FAILED',

    GET_STAGE_TYPES_STARTED = 'SETTINGS_GET_STAGE_TYPES_STARTED',
    GET_STAGE_TYPES_FINISHED = 'SETTINGS_GET_STAGE_TYPES_FINISHED',
    GET_STAGE_TYPES_FAILED = 'SETTINGS_GET_STAGE_TYPES_FAILED',

    GET_LANGUAGES_STARTED = 'SETTINGS_GET_LANGUAGES_STARTED',
    GET_LANGUAGES_FINISHED = 'SETTINGS_GET_LANGUAGES_FINISHED',
    GET_LANGUAGES_FAILED = 'SETTINGS_GET_LANGUAGES_FAILED',

    GET_TIMEZONES_STARTED = 'SETTINGS_GET_TIMEZONES_STARTED',
    GET_TIMEZONES_FINISHED = 'SETTINGS_GET_TIMEZONES_FINISHED',
    GET_TIMEZONES_FAILED = 'SETTINGS_GET_TIMEZONES_FAILED'
}

export const SettingsActions = {
    getCurrenciesStarted: () => createAction(SettingsActionTypes.GET_CURRENCIES_STARTED),
    getCurrenciesFinished: (response: GetCurrenciesFinishedParams) =>
        createAction(SettingsActionTypes.GET_CURRENCIES_FINISHED, { response }),
    getCurrenciesFailed: (error: ErrorState) => createAction(SettingsActionTypes.GET_CURRENCIES_FAILED, { error }),

    getStageTypesStarted: () => createAction(SettingsActionTypes.GET_STAGE_TYPES_STARTED),
    getStageTypesFinished: (response: GetStageTypesFinishedParams) =>
        createAction(SettingsActionTypes.GET_STAGE_TYPES_FINISHED, { response }),
    getStageTypesFailed: (error: ErrorState) => createAction(SettingsActionTypes.GET_STAGE_TYPES_FAILED, { error }),

    getLanguagesStarted: () => createAction(SettingsActionTypes.GET_LANGUAGES_STARTED),
    getLanguagesFinished: (response: GetLanguagesFinishedParams) =>
        createAction(SettingsActionTypes.GET_LANGUAGES_FINISHED, { response }),
    getLanguagesFailed: (error: ErrorState) => createAction(SettingsActionTypes.GET_LANGUAGES_FAILED, { error }),

    getTimezonesStarted: () => createAction(SettingsActionTypes.GET_TIMEZONES_STARTED),
    getTimezonesFinished: (response: GetTimezonesFinishedParams) =>
        createAction(SettingsActionTypes.GET_TIMEZONES_FINISHED, { response }),
    getTimezonesFailed: (error: ErrorState) => createAction(SettingsActionTypes.GET_TIMEZONES_FAILED, { error })
};

interface GetCurrenciesFinishedParams {
    ids: number[];
    currencies: NumericDictionary<BudgetCurrency>;
}

interface GetStageTypesFinishedParams {
    ids: number[];
    stageTypes: NumericDictionary<StageTypeDto>;
}

interface GetLanguagesFinishedParams {
    ids: number[];
    languages: NumericDictionary<ATSLanguageDto>;
}

interface GetTimezonesFinishedParams {
    ids: string[];
    timezones: Dictionary<GetAvailableTimezonesQueryResult>;
}

export type SettingsAction = ActionsUnion<typeof SettingsActions>;
